import {Injectable} from '@angular/core';
import {Order} from '@models/business/order.model';
import {Traceability} from '@models/business/traceability.model';
import {Event} from '@models/workflow/event.model';
import {State} from '@models/workflow/state.model';
import {Transition} from '@models/workflow/transition.model';
import {Workflow} from '@models/workflow/workflow.model';

const STYLES = {
  PREFERRED: 'success',
  NORMAL: 'secondary',
  DANGER: 'warning'
};

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private workflows: Array<Workflow>;

  constructor() {
    this.workflows = [];

    // Delivery
    const deliveryWorkflow = new Workflow('DELIVERY');

    const providerIcon = 'building';
    const deliveryStateReadyForHaulage = new State('READY_FOR_HAULAGE', 'workflows.status.ready_for_haulage', providerIcon);
    const deliveryStateOnTheWayToCustomer = new State('ON_THE_WAY_TO_CUSTOMER', 'workflows.status.on_the_way_to_customer', 'car');
    const deliveryStateOnTheWayToProvider = new State('ON_THE_WAY_TO_PROVIDER', 'workflows.status.on_the_way_to_provider', 'car');
    const deliveryStateDelivered = new State('DELIVERED', 'workflows.status.delivered', 'check');
    const deliveryStateToProcessProvider = new State('TO_PROCESS_PROVIDER', 'workflows.status.to_process_provider', providerIcon);

    const deliveryEventEditPayment = new Event('PAYMENT_UPDATE', 'workflows.events.edit_payment', 'pen', {
      withPayment: true,
      withComment: true,
      withSignature: true
    }, 1, STYLES.DANGER, true, true);
    const deliveryEventTakeInCharge = new Event('TAKING_CHARGE_BY_HAULER', 'workflows.events.take_in_charge', 'car', null, 1, STYLES.PREFERRED, null, true);
    const deliveryEventSendBackToHaulage = new Event('ORDER_SEND_BACK_READY_FOR_HAULAGE', 'workflows.events.send_back_ready_for_haulage', providerIcon, null, 9, STYLES.NORMAL, null, true);
    const deliveryEventBackToDelivery = new Event('BACK_TO_DELIVERY', 'workflows.events.back_to_delivery', 'car', null, 8, STYLES.NORMAL, null, true);
    const deliveryEventUnableToCompleteDelivery = new Event('UNABLE_TO_COMPLETE_DELIVERY', 'workflows.events.unable_to_complete_delivery', 'exclamation', {
      withReasonChoicesOther: ['reason.absent', 'reason.addressNotFound', 'reason.other'],
      withPicture: true
    }, 2, STYLES.DANGER, null, true);
    const deliveryEventCustomerDelivery = new Event('CUSTOMER_DELIVERY', 'workflows.events.customer_delivery', 'check', {
      withPayment: true,
      withComment: true,
      withDeliveryConstraints: true,
      withPicture: true,
      withSignature: true,
      withValidateDistance: true,
      withDoorCode: true,
    }, 1, STYLES.PREFERRED, true, true);
    const deliveryEventBackToProvider = new Event('BACK_TO_PROVIDER', 'workflows.events.back_to_provider', providerIcon, {
      withComment: true,
      withSignature: true
    }, 7, STYLES.NORMAL, null, true);
    const deliveryEventTransferToOtherHauler = new Event('TRANSFER_TO_HAULER', 'workflows.events.transfer_to_hauler', 'share', {
      withHaulerChoice: true
    }, 5, STYLES.NORMAL, null, true);

    /**
     * Events that not available for hauler
     */
    const recurringOrderPreparedEvent = new Event('RECURRING_ORDER_PREPARED', 'workflows.events.to_process_provider_recurring', providerIcon, null, null, null, null, false);
    const orderPreparedEvent = new Event('ORDER_PREPARED', 'workflows.events.order_created', providerIcon, null, null, null, null, false);
    const cancellationEvent = new Event('CANCELLATION', 'workflows.events.cancelled', 'times', null, null, null, null, false);

    const PreparingRecurringOrderTransition = new Transition(deliveryWorkflow.name, deliveryStateToProcessProvider, deliveryStateReadyForHaulage, recurringOrderPreparedEvent, true, true, true);
    const PreparingOrderTransition = new Transition(deliveryWorkflow.name, deliveryStateToProcessProvider, deliveryStateReadyForHaulage, orderPreparedEvent, true, true, true);
    const cancellationOrderTransition = new Transition(deliveryWorkflow.name, deliveryStateReadyForHaulage, deliveryStateReadyForHaulage, cancellationEvent, true, true, true);


    // DELIVERED -> DELIVERED => EDIT_PAYMENT
    const deliveryTransitionEditPayment = new Transition(deliveryWorkflow.name, deliveryStateDelivered, deliveryStateDelivered, deliveryEventEditPayment, false, false, false);
    // READY_FOR_HAULAGE -> TAKING_CHARGE_BY_HAULER => ON_THE_WAY_TO_CUSTOMER
    const deliveryTransitionTakeInCharge = new Transition(deliveryWorkflow.name, deliveryStateReadyForHaulage, deliveryStateOnTheWayToCustomer, deliveryEventTakeInCharge, false, true, true);
    // ON_THE_WAY_TO_CUSTOMER -> ORDER_SEND_BACK_READY_FOR_HAULAGE => READY_FOR_HAULAGE
    const deliveryTransitionBackToDelivery = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToCustomer, deliveryStateReadyForHaulage, deliveryEventSendBackToHaulage, true, true, true);
    // ON_THE_WAY_TO_CUSTOMER -> UNABLE_TO_COMPLETE_DELIVERY => ON_THE_WAY_TO_PROVIDER
    const deliveryTransitionUnableToCompleteDelivery = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToCustomer, deliveryStateOnTheWayToProvider, deliveryEventUnableToCompleteDelivery, false, false);
    // ON_THE_WAY_TO_CUSTOMER -> CUSTOMER_DELIVERY => DELIVERED
    const deliveryTransitionCustomerDelivery = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToCustomer, deliveryStateDelivered, deliveryEventCustomerDelivery, false, false);
    // ON_THE_WAY_TO_PROVIDER -> BACK_TO_PROVIDER => TO_PROCESS_PROVIDER
    const deliveryTransitionBackToProvider = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToProvider, deliveryStateToProcessProvider, deliveryEventBackToProvider, false, true);
    // ON_THE_WAY_TO_PROVIDER -> BACK_TO_DELIVERY => ON_THE_WAY_TO_CUSTOMER
    const deliveryTransitionBackToDelivery2 = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToProvider, deliveryStateOnTheWayToCustomer, deliveryEventBackToDelivery);

    const deliveryTransitionWhileOnTheWayToCustomerTransferToHauler = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToCustomer, deliveryStateOnTheWayToCustomer, deliveryEventTransferToOtherHauler, true, true, true);
    const deliveryTransitionWhileBackToProviderTransferToHauler = new Transition(deliveryWorkflow.name, deliveryStateOnTheWayToProvider, deliveryStateOnTheWayToProvider, deliveryEventTransferToOtherHauler, true, true, true);

    deliveryWorkflow.transitions.push(deliveryTransitionEditPayment);
    deliveryWorkflow.transitions.push(deliveryTransitionTakeInCharge);
    deliveryWorkflow.transitions.push(deliveryTransitionBackToDelivery);
    deliveryWorkflow.transitions.push(deliveryTransitionUnableToCompleteDelivery);
    deliveryWorkflow.transitions.push(deliveryTransitionCustomerDelivery);
    deliveryWorkflow.transitions.push(deliveryTransitionBackToProvider);
    deliveryWorkflow.transitions.push(deliveryTransitionBackToDelivery2);

    deliveryWorkflow.transitions.push(deliveryTransitionWhileOnTheWayToCustomerTransferToHauler);
    deliveryWorkflow.transitions.push(deliveryTransitionWhileBackToProviderTransferToHauler);

    deliveryWorkflow.transitions.push(PreparingRecurringOrderTransition);
    deliveryWorkflow.transitions.push(PreparingOrderTransition);
    deliveryWorkflow.transitions.push(cancellationOrderTransition);

    this.workflows.push(deliveryWorkflow);

    // Pickup
    const pickupWorkflow = new Workflow('PICKUP');

    const pickupStateReadyForHaulage = new State('READY_FOR_HAULAGE', 'workflows.status.ready_for_haulage', providerIcon);
    const pickupStateOnTheWayToCustomer = new State('ON_THE_WAY_TO_CUSTOMER', 'workflows.status.on_the_way_to_customer', 'car');
    const pickupStateOnTheWayToProvider = new State('ON_THE_WAY_TO_PROVIDER', 'workflows.status.on_the_way_to_provider', 'car');
    const pickupStateCompleted = new State('COMPLETED', 'workflows.status.completed', 'check');
    const pickupStateToProcessProviderError = new State('TO_PROCESS_PROVIDER_ERROR', 'workflows.status.to_process_provider_error', providerIcon);

    const pickupEventEditPayment = new Event('PAYMENT_UPDATE', 'workflows.events.edit_payment', 'pen', {
      withPayment: true,
      withPicture: true
    }, 1, STYLES.DANGER, true, true);
    const pickupEventAssigning = new Event('ASSIGNING', 'workflows.events.take_in_charge', 'car', null, 1, STYLES.PREFERRED, null, true);
    const pickupEventPickup = new Event('PICKUP', 'workflows.events.pickup', 'car', {
      withPayment: true,
      withPicture: true,
      withSignature: true,
      withValidateDistance: true,
      withDoorCode: true,
    }, 3, STYLES.PREFERRED, true, true);
    const pickupEventUnableToPickup = new Event('UNABLE_TO_PICKUP', 'workflows.events.unable_to_pickup', 'exclamation', {
      withReasonChoicesOther: ['reason.absent', 'reason.addressNotFound', 'reason.packageAbsent', 'reason.other'],
      withPicture: true,
    }, 4, STYLES.DANGER, null, true);
    const pickupEventBackProvider = new Event('BACK_PROVIDER', 'workflows.events.back_provider', 'inbox', {
      withComment: true,
      withSignature: true
    }, 5, STYLES.NORMAL, null, true);
    const pickupEventTransferToOtherHauler = new Event('TRANSFER_TO_HAULER', 'workflows.events.transfer_to_hauler', 'share', {
      withHaulerChoice: true
    }, 6, STYLES.NORMAL, null, true);

    const pickupEventSentBackToReady = new Event('ORDER_SEND_BACK_READY_FOR_HAULAGE', 'workflows.events.send_back_ready_for_haulage', providerIcon, null, 9, STYLES.NORMAL, null, true);

    /**
     * Events that not available for hauler
     */
    const pickupCreationEvent = new Event('PICKUP_CREATION', 'workflows.events.pickup', 'car', null, null, null, null, false);
    const pickupCreationTransition = new Transition(pickupWorkflow.name, pickupStateReadyForHaulage, pickupStateReadyForHaulage, pickupCreationEvent, true, true, true);

    const pickupCancelledState = new State('CANCELLED', 'workflows.events.cancelled', 'times', null, null);
    const pickupCancellationEvent = new Event('CANCELLATION', 'workflows.events.cancelled', 'times', null, null, null, null, false);
    // READY_FOR_HAULAGE -> CANCELLATION => CANCELLED
    const pickupCancellationOrderTransition = new Transition(pickupWorkflow.name, pickupStateReadyForHaulage, pickupCancelledState, pickupCancellationEvent, true, true, true);

    // COMPLETED -> COMPLETED => EDIT_PAYMENT
    const pickupTransitionEditPayment = new Transition(pickupWorkflow.name, pickupStateCompleted, pickupStateCompleted, pickupEventEditPayment, false, true, true);
    // READY_FOR_HAULAGE -> ASSIGNING => ON_THE_WAY_TO_CUSTOMER
    const pickupTransitionAssigning = new Transition(pickupWorkflow.name, pickupStateReadyForHaulage, pickupStateOnTheWayToCustomer, pickupEventAssigning, false, true, true);
    // ON_THE_WAY_TO_CUSTOMER -> PICKUP => ON_THE_WAY_TO_PROVIDER
    const pickupTransitionPickup = new Transition(pickupWorkflow.name, pickupStateOnTheWayToCustomer, pickupStateOnTheWayToProvider, pickupEventPickup, false, false, true);
    // ON_THE_WAY_TO_CUSTOMER -> UNABLE_TO_PICKUP => TO_PROCESS_PROVIDER_ERROR
    const pickupTransitionTakeInCharge = new Transition(pickupWorkflow.name, pickupStateOnTheWayToCustomer, pickupStateToProcessProviderError, pickupEventUnableToPickup, false, false);
    // ON_THE_WAY_TO_PROVIDER -> COMPLETED => BACK_PROVIDER
    const pickupTransitionCompleted = new Transition(pickupWorkflow.name, pickupStateOnTheWayToProvider, pickupStateCompleted, pickupEventBackProvider);
    // Pushing back to READY_FOR_HAULAGE
    const pickupTransitionBackToReadyForHaulage = new Transition(pickupWorkflow.name, pickupStateOnTheWayToCustomer, pickupStateReadyForHaulage, pickupEventSentBackToReady, true, true, true);

    const pickupTransitionWhileOnTheWayToCustomerTransferToHauler = new Transition(pickupWorkflow.name, pickupStateOnTheWayToCustomer, pickupStateOnTheWayToCustomer, pickupEventTransferToOtherHauler, true, true, true);
    const pickupTransitionWhileOnTheWayToProviderTransferToHauler = new Transition(pickupWorkflow.name, pickupStateOnTheWayToProvider, pickupStateOnTheWayToProvider, pickupEventTransferToOtherHauler, true, true, true);

    pickupWorkflow.transitions.push(pickupTransitionEditPayment);
    pickupWorkflow.transitions.push(pickupTransitionAssigning);
    pickupWorkflow.transitions.push(pickupTransitionBackToReadyForHaulage);
    pickupWorkflow.transitions.push(pickupTransitionPickup);
    pickupWorkflow.transitions.push(pickupTransitionTakeInCharge);
    pickupWorkflow.transitions.push(pickupTransitionCompleted);
    pickupWorkflow.transitions.push(pickupTransitionWhileOnTheWayToCustomerTransferToHauler);
    pickupWorkflow.transitions.push(pickupTransitionWhileOnTheWayToProviderTransferToHauler);
    pickupWorkflow.transitions.push(pickupCancellationOrderTransition);

    pickupWorkflow.transitions.push(pickupCreationTransition);

    this.workflows.push(pickupWorkflow);
  }

  getAvailableTransitionForStateAndWorkflow(workflowStr: string, state: string): Array<Transition> {
    if (!workflowStr || !state) {
      return [];
    }
    const workflow = this.getWorkflowForName(workflowStr);
    const filteredTransitions = workflow.transitions.filter(transition => transition.from.name === state.toUpperCase() && transition.event.isAvailableToHaulerEmployee);
    filteredTransitions.sort((a, b): number => {
      if (a.event.rank < b.event.rank) {
        return -1;
      }
      if (a.event.rank > b.event.rank) {
        return 1;
      }
      return 0;
    });
    return filteredTransitions;
  }

  getWorkflowForName(workflowStr: string): Workflow {
    if (!workflowStr) {
      return null;
    }
    return this.workflows.find(workflow => workflow.name === workflowStr.toUpperCase());
  }

  /**
   * get the event by order and traceability
   *
   */
  getEventByOrder(order: Order, trace: Traceability): Event {
    const workFlow = this.getWorkflowForName(order.stateMachineWorkflow);
    const transition = workFlow.transitions.find(trans => trans.event.name === trace.event);
    return transition ? transition.event : null;
  }

  getStateFromString(state: string, workflow: string): State {
    const workFlow = this.getWorkflowForName(workflow);

    const from = workFlow.transitions.find(trans => trans.from.name === state).from;
    if (from) {
      return from;
    } else {
      return workFlow.transitions.find(trans => trans.to.name === state).to;
    }
  }

}
