import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ManifestConfirmationText} from '@models/business/manifest-confirmation-text.model';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';

@Component({
  templateUrl: './manifest-confirmation.page.html',
  styleUrls: ['./manifest-confirmation.page.scss'],
})
export class ManifestConfirmationPage {

  manifestId: number;
  manifestItems: Array<OrderManifestItem>;
  confirmationText: ManifestConfirmationText;
  confirmed = false;
  form: UntypedFormGroup;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
  ) {
    this.manifestId = this.navParams.get('manifestId');
    this.confirmationText = this.navParams.get('confirmationText');
    this.manifestItems = this.navParams.get('manifestItems');
    this.manifestItems.sort((a, b) => a.item.label.localeCompare(b.item.label));
  }

  get invalid() {
    return !this.confirmed || this.manifestItems.find(item => item.isInvalid());
  }

  get withRejected() {
    return this.manifestItems.find(item => item.acceptedOnDelivery === false);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  confirm() {
    this.modalCtrl.dismiss({
      confirmation: {
        id: this.manifestId,
        text: this.confirmationText.text,
        value: this.confirmed
      },
      manifestItems: this.manifestItems
    });
  }
}
