import {AbstractControl} from '@angular/forms';
import {UntypedFormArray} from '@angular/forms';
import {Validators} from '@angular/forms';
import {UntypedFormControl} from '@angular/forms';
import {UntypedFormGroup} from '@angular/forms';
import {TransitionParameters} from '@app/pages/transition-detail/transition-parameters.model';
import {DeliveryConstraints} from '@models/business/delivery-constraints.model';
import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {Hauler} from '@models/business/hauler.model';
import {ManifestConfirmationText} from '@models/business/manifest-confirmation-text.model';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';
import {OrderManifest} from '@models/business/order-manifest.model';
import {Order} from '@models/business/order.model';
import {Payment, Picture, TransitionData} from '@models/business/transition-data.model';
import {TransitionDetail} from '@models/business/transition-detail.model';

export class TransitionDataFormConverter {

  form: UntypedFormGroup;

  constructor(private config: TransitionDetail,
              private order: Order,
              private hauler: Hauler,
              private parameters: TransitionParameters = TransitionParameters.allActivated(),
  ) {
    this.createForm();
  }

  createForm() {
    const group = new UntypedFormGroup({});

    let withoutDoorCode: boolean = true;

    if (this.config.withDoorCode && this.parameters.withDoorCode && this.order.lockerData) {
      withoutDoorCode = false;
      group.addControl('doorCode', new UntypedFormControl());
    }

    // FIXME pour l'instant, withReason et withReasonChoicesOther sont incompatibles
    if (this.config.withReason && this.parameters.withReason && withoutDoorCode) {
      group.addControl('reason', new UntypedFormControl(''));
    }

    if (this.config.withReasonChoices && this.config.withReasonChoices.length > 0 && withoutDoorCode) {
      group.addControl('reasonChoices', new UntypedFormControl('', Validators.required));
    }

    if (this.config.withReasonChoicesOther && this.config.withReasonChoicesOther.length > 0 && this.parameters.withReasonChoicesOther && withoutDoorCode) {
      const formGroup = new UntypedFormGroup({
        reasonChoices: new UntypedFormControl('', Validators.required),
        reason: new UntypedFormControl(null)
      }, ReasonsValidator.reasonValidator(this.config.withReasonChoicesOther));
      group.addControl('reasonChoicesOther', formGroup);
    }

    if (this.config.withComment && this.parameters.withComment) {
      group.addControl('comment', new UntypedFormControl(''));
    }

    if (this.config.withSignature && this.parameters.withSignature && withoutDoorCode) {
      group.addControl('signatureImage', new UntypedFormControl());
    }

    if (this.config.withPicture && this.parameters.withPicture) {
      group.addControl('pictures', new UntypedFormArray([]));
      if (this.config.withDeliveryConstraints) {
        let constraints: DeliveryConstraints;
        if (this.order.id) {
          // Specific order
          const provider = this.hauler && this.hauler.linkedProviders.find(p => p.id === this.order.tenantId);
          if (provider) {
            constraints = provider.configuration.deliveryConstraints || DeliveryConstraints.doNothingConstraints();
          } else {
            constraints = DeliveryConstraints.doNothingConstraints();
          }
        } else {
          constraints = this.consolidatedConstraints();
        }

        if (constraints.requirePictureOnDelivery) {
          group.get('pictures').setValidators(Validators.required);
        }
      }
    }

    if (this.config.withPayment && this.parameters.withPayment && withoutDoorCode) {
      if (this.parameters.withPaymentDiffCheck) {
        const unbalancedPaymentCommentControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
        group.addControl('unbalancedPaymentComment', unbalancedPaymentCommentControl);
        group.addControl('payments', new UntypedFormArray([this.createPaymentGroup()], PaymentsValidator.totalAmountValidator(this.order.amount, unbalancedPaymentCommentControl)));
      } else {
        group.addControl('payments', new UntypedFormArray([this.createPaymentGroup()]));
      }
    }

    if (this.config.withHaulerChoice && this.parameters.withHaulerChoice && withoutDoorCode) {
      group.addControl('haulerChoice', new UntypedFormControl(null, Validators.required));
    }

    if (this.config.withDeliveryConstraints && this.parameters.withInterlocutor && withoutDoorCode) {
      let constraints: DeliveryConstraints;
      let withManifest: boolean;
      if (this.order.id) {
        // Specific order
        const provider = this.hauler && this.hauler.linkedProviders.find(p => p.id === this.order.tenantId);
        if (provider) {
          constraints = provider.configuration.deliveryConstraints || DeliveryConstraints.doNothingConstraints();
          withManifest = constraints.manifestEnabled && this.order.manifest && this.order.manifest.items && this.order.manifest.items.length > 0;
        } else {
          constraints = DeliveryConstraints.doNothingConstraints();
          withManifest = false;
        }
      } else {
        constraints = this.consolidatedConstraints();
        withManifest = false;
      }

      if (constraints.displaySignatureOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
        const formControl = new UntypedFormControl();
        // formControl = new FormControl(null, Validators.required);
        group.addControl('signatureImage', formControl);
        if (constraints.requireSignatureOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
          group.get('signatureImage').setValidators(Validators.required);
        }
      }

      if (constraints.displayIdentityOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
        const formControl = new UntypedFormControl();
        group.addControl('recipientIdentity', formControl);
        if (constraints.requireIdentityOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
          // formControl.setValidators(Validators.required);
          group.get('recipientIdentity').setValidators(Validators.required);
        }
      }

      if (constraints.displayRelationWithClientOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
        const formControl = new UntypedFormControl();
        group.addControl('relationWithClient', formControl);
        if (constraints.requireRelationWithClientOnDelivery || (constraints.requireInfoOnBomDelivery && withManifest)) {
          group.get('relationWithClient').setValidators(Validators.required);
        }
        const formGroup = new UntypedFormGroup({
          relationWithClient: formControl,
          relationWithClientOther: new UntypedFormControl()
        }, LinkedValidator.linkedFieldRequirement('relationWithClient', 'other', 'relationWithClientOther'));

        group.addControl('relationWithClientGroup', formGroup);
      }

      if (withManifest && withoutDoorCode) {
        group.addControl('manifest', new UntypedFormGroup({
          confirmation: new UntypedFormGroup({
            id: new UntypedFormControl({}),
            text: new UntypedFormControl({}),
            value: new UntypedFormControl(null, Validators.required),
          }),
          items: new UntypedFormArray([])
        }));
      }
    }

    this.form = group;
  }

  toForm(): UntypedFormGroup {
    return this.form;
  }

  setData(data: TransitionData) {
    if (data) {
      if (this.config.withReason) {
        if (data.reason) {
          const control = this.form.get('reason');
          if (control) {
            control.setValue(data.reason);
          }
        }
        if (data.selectedReason) {
          const control = this.form.get('reasonChoices');
          if (control) {
            control.setValue(data.selectedReason);
          }
        }
      }

      if (this.config.withReasonChoicesOther) {
        const parent = this.form.get('reasonChoicesOther');
        if (parent) {
          if (data.reason) {
            const control = parent.get('reason');
            if (control) {
              control.setValue(data.reason);
            }
          }

          if (data.selectedReason) {
            const control = parent.get('reasonChoices');
            if (control) {
              control.setValue(data.selectedReason);
            }
          }
        }
      }

      if (data.pictures) {
        data.pictures.forEach((picture) => {
          this.addPicture(picture);
        });
      }

      if (data.payments && data.payments.length > 0) {
        const payments = this.form.get('payments') as UntypedFormArray;
        // Cleanup current form
        if (payments) {
          while (payments.length > 0) {
            payments.removeAt(0);
          }

          data.payments.forEach((payment) => {
            const group = this.addPayment();
            group.get('collectedAmount').setValue(payment.collectedAmount);
            group.get('paymentType').setValue(payment.type);
          });

          if (data.unbalancedPaymentComment) {
            const control = this.form.get('unbalancedPaymentComment');
            if (control) {
              control.setValue(data.unbalancedPaymentComment);
            }
          }
        }
      }

      if (data.haulerChoice) {
        const control = this.form.get('haulerChoice');
        if (control) {
          control.setValue(data.haulerChoice);
        }
      }

      if (data.comment) {
        const control = this.form.get('comment');
        if (control) {
          control.setValue(data.comment);
        }
      }

      if (data.signatureImage) {
        const control = this.form.get('signatureImage');
        if (control) {
          control.setValue(data.signatureImage);
        }
      }

      if (data.deliveryRecipient) {
        if (data.deliveryRecipient.identity) {
          const control = this.form.get('recipientIdentity');
          if (control) {
            control.setValue(data.deliveryRecipient.identity);
          }
        }

        if (data.deliveryRecipient.relation || data.deliveryRecipient.relationMore) {
          const group = this.form.get('relationWithClientGroup') as UntypedFormGroup;
          if (group) {
            if (data.deliveryRecipient.relation) {
              const control = group.get('relationWithClient');
              if (control) {
                control.setValue(data.deliveryRecipient.relation);
              }
            }
            if (data.deliveryRecipient.relationMore) {
              const control = group.get('relationWithClientOther');
              if (control) {
                control.setValue(data.deliveryRecipient.relationMore);
              }
            }
          }
        }
      }

      if (data.manifest) {
        const group = this.form.get('manifest') as UntypedFormGroup;
        if (group) {
          const confirmation = group.get('confirmation');
          if (confirmation) {
            confirmation.get('value').setValue(true);
            if (data.manifest.id) {
              confirmation.get('id').setValue(data.manifest.id);
            }

            if (data.manifest.confirmationText) {
              confirmation.get('text').setValue(data.manifest.confirmationText);
            }
          }

          const items = group.get('items') as UntypedFormArray;
          if (items) {
            if (items.controls.length === 0) {
              data.manifest.items.forEach(item => {
                items.push(new UntypedFormGroup({
                  id: new UntypedFormControl(item.id),
                  value: new UntypedFormControl(item.acceptedOnDelivery)
                }));
              });
            }
          }
        }
      }
    }
  }

  private consolidatedConstraints(): DeliveryConstraints {
    const constraints = DeliveryConstraints.displayAllWithoutRequirements();
    if (this.hauler && this.hauler.linkedProviders) {
      this.hauler.linkedProviders.forEach(provider => {
        const providerConstraints = provider.configuration.deliveryConstraints || DeliveryConstraints.doNothingConstraints();
        if (providerConstraints.displayIdentityOnDelivery === true) {
          constraints.displayIdentityOnDelivery = true;
        }
        if (providerConstraints.displayRelationWithClientOnDelivery === true) {
          constraints.displayRelationWithClientOnDelivery = true;
        }
        if (providerConstraints.displaySignatureOnDelivery === true) {
          constraints.displaySignatureOnDelivery = true;
        }
        if (providerConstraints.requireIdentityOnDelivery === true) {
          constraints.requireIdentityOnDelivery = true;
        }
        if (providerConstraints.requireRelationWithClientOnDelivery === true) {
          constraints.requireRelationWithClientOnDelivery = true;
        }
        if (providerConstraints.requireSignatureOnDelivery === true) {
          constraints.requireSignatureOnDelivery = true;
        }
        if (providerConstraints.requireSignatureOnDelivery === true) {
          constraints.requireSignatureOnDelivery = true;
        }
      });
    }
    return constraints;
  }

  toData(): TransitionData {
    const data: TransitionData = new TransitionData();
    const source = this.form.value;

    if (this.config.withReason && source.reason) {
      data.reason = source.reason;
    }

    if (this.config.withReasonChoices && this.config.withReasonChoices.length > 0 && source.reasonChoices) {
      data.selectedReason = source.reasonChoices.value;
    }

    if (this.config.withReasonChoicesOther && this.config.withReasonChoicesOther.length > 0 && source.reasonChoicesOther) {
      data.selectedReason = source.reasonChoicesOther.reasonChoices;
      if (source.reasonChoicesOther.reason) {
        data.reason = source.reasonChoicesOther.reason;
      }
    }

    if (this.config.withComment && source.comment) {
      data.comment = source.comment;
    }

    if (this.config.withSignature && source.signatureImage) {
      data.signatureImage = source.signatureImage;
    }

    if (this.config.withPicture && source.pictures) {
      source.pictures.map((picture) => data.addPicture(picture.picture));
    }

    if (this.config.withPayment && source.payments) {
      source.payments.map((payment: any) => data.addPayment(new Payment(parseFloat(payment.collectedAmount), payment.paymentType)));
      data.unbalancedPaymentComment = source.unbalancedPaymentComment;
    }

    if (this.config.withHaulerChoice && source.haulerChoice) {
      data.haulerChoice = source.haulerChoice.toString();
    }

    if (this.config.withDeliveryConstraints) {
      let constraints: DeliveryConstraints;
      if (this.order.id) {
        const provider = this.hauler && this.hauler.linkedProviders.find(p => p.id === this.order.tenantId);
        if (provider) {
          constraints = provider.configuration.deliveryConstraints || DeliveryConstraints.doNothingConstraints();
        } else {
          constraints = DeliveryConstraints.doNothingConstraints();
        }
      } else {
        constraints = this.consolidatedConstraints();
      }

      if (!data.deliveryRecipient) {
        data.deliveryRecipient = new DeliveryRecipient();
      }
      if (constraints.displaySignatureOnDelivery && source.signatureImage) {
        data.signatureImage = source.signatureImage;
      }

      if (constraints.displayIdentityOnDelivery && source.recipientIdentity) {
        data.deliveryRecipient.identity = source.recipientIdentity;
      }

      if (constraints.displayRelationWithClientOnDelivery && source.relationWithClientGroup) {
        data.deliveryRecipient.relation = source.relationWithClientGroup.relationWithClient;
        data.deliveryRecipient.relationMore = source.relationWithClientGroup.relationWithClientOther;
      }
      // Manage manifestEnabled
      if ((constraints.manifestEnabled || constraints.requireInfoOnBomDelivery) && source.manifest) {
        if (!data.manifest) {
          data.manifest = new OrderManifest();
          data.manifest.confirmationText = new ManifestConfirmationText();
        }
        data.manifest.id = source.manifest.confirmation.id;
        data.manifest.confirmationText.text = source.manifest.confirmation.text;

        data.manifest.items = [];
        source.manifest.items.forEach(item => {
          const manifestItem = new OrderManifestItem();
          manifestItem.id = item.id;
          manifestItem.acceptedOnDelivery = item.value;
          data.manifest.items.push(manifestItem);
        });
      }
    }

    return data;
  }

  private createPaymentGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      collectedAmount: new UntypedFormControl(null, Validators.required),
      paymentType: new UntypedFormControl('', Validators.required),
    }, PaymentsValidator.paymentDetailValidator());
  }

  addPayment(): UntypedFormGroup {
    const payments = this.form.get('payments') as UntypedFormArray;
    const paymentGroup = this.createPaymentGroup();
    payments.push(paymentGroup);
    return paymentGroup;
  }

  removePayment(index: number) {
    const payments = this.form.get('payments') as UntypedFormArray;
    payments.removeAt(index);
  }

  private createPictureGroup(picture: Picture): UntypedFormGroup {
    return new UntypedFormGroup({
      picture: new UntypedFormControl(picture)
    });
  }

  addPicture(picture: Picture) {
    const pictures = this.form.get('pictures') as UntypedFormArray;
    pictures.push(this.createPictureGroup(picture));
  }

  removePicture(index: number) {
    const pictures = this.form.get('pictures') as UntypedFormArray;
    pictures.removeAt(index);
  }

}

class ReasonsValidator {
  static reasonValidator(options: Array<string>) {
    const lastOption = options[options.length - 1];
    return (c: AbstractControl) => {
      const choices = c.get('reasonChoices');
      const reason = c.get('reason');
      if (choices.value === lastOption) {
        reason.setValidators(Validators.required);
        if (!reason.value || !reason.valid) {
          return {byPass: true, byChoices: choices.invalid, byReason: reason.invalid};
        }
      } else {
        reason.clearValidators();
      }
      return choices.invalid || reason.invalid ? {
        valid: false,
        byChoices: choices.invalid,
        byReason: reason.invalid
      } : null;
    };
  }
}

class LinkedValidator {
  static linkedFieldRequirement(sourceFieldName: string, fieldValue: string, linkedFieldName: string) {

    let thisControl: UntypedFormControl;
    let sourceControl: UntypedFormControl;
    let linkedControl: UntypedFormControl;

    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      if (!thisControl) {
        thisControl = control as UntypedFormControl;
        sourceControl = control.get(sourceFieldName) as UntypedFormControl;
        linkedControl = control.get(linkedFieldName) as UntypedFormControl;
        if (!sourceControl) {
          throw new Error(`linkedFieldRequirement(): ${sourceFieldName} is not found in parent group`);
        }
        if (!linkedControl) {
          throw new Error(`linkedFieldRequirement(): ${linkedFieldName} is not found in parent group`);
        }

        sourceControl.valueChanges.subscribe((value) => {
          if (value === fieldValue) {
            linkedControl.setValidators(Validators.required);
          } else {
            linkedControl.setValue(null);
            linkedControl.clearValidators();
          }
          linkedControl.updateValueAndValidity();
        });
      }

      if (!sourceControl || !linkedControl) {
        return null;
      }


    };

  }
}

class PaymentsValidator {
  static totalAmountValidator(val: number, comment: UntypedFormControl) {
    let thisControl: AbstractControl;

    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      if (!thisControl) {
        thisControl = control;
        comment.valueChanges.subscribe(value => {
          if (value != null && value !== undefined) {
            thisControl.updateValueAndValidity();
          }
        });
      }

      const sum = thisControl.value
        .map(payment => payment.collectedAmount ? parseFloat(payment.collectedAmount) : 0)
        .reduce((acc, cur) => acc + cur, 0)
      ;

      const err: any = {sum, expected: val, needComment: true};
      if (sum !== val) {
        if (comment) {
          comment.setValidators(Validators.required);
          if (comment.valid && comment.value) {
            return null;
          } else {
            return err;
          }
        }
        return err;
      } else {
        if (comment) {
          comment.clearValidators();
          comment.setValue(null);
        }
      }
    };
  }

  static paymentDetailValidator() {
    return (c: AbstractControl) => {
      const collectedAmount = c.get('collectedAmount');
      const paymentType = c.get('paymentType');

      const isRealCollectedAmount = collectedAmount.value && collectedAmount.value !== 0 && collectedAmount.value !== '0';
      if (isRealCollectedAmount) {
        paymentType.setValidators(Validators.required);
      } else {
        paymentType.clearValidators();
        paymentType.reset(null, {onlySelf: true});
      }

      return collectedAmount.invalid || (isRealCollectedAmount && paymentType.invalid) || (isRealCollectedAmount && !paymentType.value) ? {err: 'invalid'} : null;
    };

  }
}
