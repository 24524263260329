import {Component} from '@angular/core';
import {Order} from '@models/business/order.model';
import {Traceability} from '@models/business/traceability.model';
import {UserSettings} from '@models/settings/settings.model';
import {DateTimeCombinationEnum} from '@models/units-and-format/date-format.enum';
import {NavParams} from '@ionic/angular';
import {NavController} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {WorkflowService} from '@services/workflow/workflow.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IconName} from '@fortawesome/fontawesome-svg-core';

const CONFIG = {
  GA: {
    PAGE_NAME: 'OrderTraceabilityPage'
  }
};

@Component({
  selector: 'mobilus-order-traceability',
  templateUrl: './order-traceability.page.html',
  styleUrls: ['./order-traceability.page.scss'],
})
export class OrderTraceabilityPage {

  order: Order;
  userSettings: UserSettings = new UserSettings();
  private unsubscribe: Subject<void> = new Subject<void>();
  dateTimeType: DateTimeCombinationEnum = DateTimeCombinationEnum.dateAndTime;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              private workflowService: WorkflowService,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private mobileContextService: MobileContextService, ) {
    const view = this.navParams.get('orderView');
    this.order = view.order;
    this.order.traceabilities.sort((traceA, traceB) =>
      Number(traceB.dateTime.format('X')) - Number(traceA.dateTime.format('X')));

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((userSettings) => {
        this.userSettings = userSettings;
      });
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  getIcon(trace: Traceability): IconName {
    if (trace.event === 'DAILY_TRANSFER') {
      return 'retweet';
    } else if (trace.event === 'NONE' && trace.targetState === trace.sourceState) {
      return 'pen';
    }
    const event = this.workflowService.getEventByOrder(this.order, trace);
    return event ? event.icon as IconName : 'pen';
  }

}
