import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Picture} from '@models/business/transition-data.model';
import {CameraResultType, CameraSource, ImageOptions} from '@capacitor/camera';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import * as moment from 'moment';
import * as watermark from 'watermarkjs';
import {CapacitorPlugins} from '@services/capacitor-plugins/capacitor-plugins';
import {UserSettings} from "@models/settings/settings.model";
import {MobileContextService} from "@services/mobile-configuration-service/mobile-context.service";
import {AlertController, Platform} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PictureService {

  private lastUserSettings: UserSettings;

  constructor(private platform: Platform,
              private sanitizer: DomSanitizer,
              private translateService: TranslateService,
              private alertController: AlertController,
              private backgroundGeolocationService: BackgroundGeolocationService,
              private mobileContextService: MobileContextService,
              private capacitorPlugins: CapacitorPlugins
  ) {
    this.platform.ready()
      .then(() => {
        this.mobileContextService.userSettingsObservable
          .subscribe((userSettings) => {
            this.lastUserSettings = userSettings;
          });
      });
  }

  initPictureChooser(): Promise<Picture | void> {
    if (this.lastUserSettings && this.lastUserSettings.promptForGalleryWhenTakingPicture) {
      return this.chooseSourceOfPicture()
        .then((source) => {
          return this.takePicture(source);
        });
    } else {
      return this.takePicture(CameraSource.Camera);
    }
  }

  async chooseSourceOfPicture(): Promise<CameraSource> {
    return new Promise(async (resolve: (source: CameraSource) => void) => {
      const alert = await this.alertController.create({
        header: this.translateService.instant('picture.chooser.source'),
        subHeader: null,
        message: null,
        cssClass: 'confirm-dialog',
        buttons: [
          {
            text: this.translateService.instant('picture.chooser.gallery').toUpperCase(),
            handler: () => {
              resolve(CameraSource.Photos);
            }
          },
          {
            text: this.translateService.instant('picture.chooser.camera').toUpperCase(),
            handler: () => {
              resolve(CameraSource.Camera);
            }
          }
        ]
      });
      alert.present();
    });
  }

  takePicture(cameraSource: CameraSource): Promise<Picture | void> {
    const picture = new Picture();

    picture.mimeType = 'image/jpeg';
    picture.extension = 'jpg';

    const options: ImageOptions = {
      quality: 40,
      saveToGallery: false,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: cameraSource ? cameraSource : CameraSource.Camera,
      width: 1024,
      height: 1024
    };

    const promise = new Promise<Picture | void>((resolve, reject) => {
      this.capacitorPlugins.getCameraPlugin().getPhoto(options).then((image) => {
        picture.base64String = image.base64String;
        const dataUrl = image.dataUrl;
        this.watermarkImage(dataUrl, cameraSource)
          .then(watermarked => {
            picture.base64String = watermarked.split(',', 2)[1];
            picture.safeUrl = this.sanitizer.bypassSecurityTrustUrl(watermarked);
            resolve(picture);
          });
      }, (err) => {
        console.log('rejecting on error', err);
        reject(err);
      });

    });
    return promise;
  }

  private watermarkImage(dataUrl: string, cameraSource: CameraSource) {
    const opacity = 0.8;
    const position = this.backgroundGeolocationService.getLastKnownPosition();
    const now = moment().format();
    let caption = now;
    if (position) {
      caption += ` @ (${position.latitude}, ${position.longitude})`;
      if (position.accuracy) {
        caption += ` ± ${position.accuracy}m`;
      }
    }
    if (cameraSource === CameraSource.Photos) {
      caption += ` ${this.translateService.instant('picture.watermark.from-gallery')}`;
    }

    return fetch(dataUrl)
      .then(res => res.blob())
      .then(blob => {
        return watermark([blob], {type: 'image/jpeg'})
          .dataUrl(watermark.text.lowerLeft(caption, '20px Arial', '#000', opacity))
          .then(value => {
            return value;
          });
      });
  }
}
