<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button>
        <img src="assets/images/logo_menu.png"/>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'pages.report-forms.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  
  <ion-item>
    <ion-select justify="space-between" [(ngModel)]="reportTypeActive">
      <span slot="label" translate="pages.report-forms.report_type"></span>
      <ion-select-option [value]="report" *ngFor="let report of reportTypes">{{'pages.report-forms.' + report + '.title' |
        translate}}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <div id="form">
    <ion-item class="date-time-container" button="true" id="open-date-picker" [disabled]="false && isDateTimeDisabled">
      <ion-label>
        <span translate="pages.report-forms.report_date"></span>
      </ion-label>
      <ion-text slot="end">{{ reportRequest.startDate | DatePipe: null:userSettings.dateFormat:DateTimeCombinationEnum.dateOnly }}</ion-text>
      <ion-modal trigger="open-date-picker" #modalReportDatetime class="datetime-modal">
        <ng-template>
        <ion-content force-overscroll="false" show-backdrop="true">
          <ion-datetime
            #reportDatetime show-default-buttons="true" [value]="reportRequest.startDate" [locale]="userSettings.getLocate()" presentation="date"
            [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
            [multiple]="false" (ionChange)="onDateChange(reportDatetime.value)">
          </ion-datetime>
        </ion-content>
      </ng-template>
      </ion-modal>
    </ion-item>
    <div *ngIf="reportTypeActive==='trips-reports'" class="trips-reports-container">
      <ion-item>
        <ion-input type="number" [(ngModel)]="reportRequest.extraForDistanceAbove">
          <div slot="label">
            {{translateDistanceText('pages.trips-reports.extra_distance_km')}}
          </div>
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-checkbox labelPlacement="end" justify="start" [(ngModel)]="reportRequest.excludeAppNumber">
          {{'pages.report-forms.trips-reports.excludeAddressCopies' | translate}}
        </ion-checkbox>
      </ion-item>
    </div>
    <ion-label class="form-label">{{'pages.report-forms.drugstores' | translate}}</ion-label>
    <ion-item lines="none" *ngFor="let provider of reportRequest.providers">
      <ion-checkbox labelPlacement="end" justify="start" [(ngModel)]="provider.checked">
        {{provider.label}}
      </ion-checkbox>
    </ion-item>
    <div *ngIf="user?.isHaulerEmployeeManager()">
      <ion-label class="form-label"
                 *ngIf="reportRequest.haulerEmployees.length">{{'pages.report-forms.haulers' | translate}}</ion-label>
      <ion-item lines="none" *ngFor="let employee of reportRequest.haulerEmployees">
        <ion-checkbox labelPlacement="end" justify="start" [(ngModel)]="employee.checked">
          {{employee.label}}
        </ion-checkbox>
      </ion-item>

      <ion-item lines="none">
        <ion-checkbox labelPlacement="end" justify="start" (ionChange)="manageCourierCheckboxes($any($event).detail.checked)"
          [(ngModel)]="allCourierChecked">
          {{'pages.report-forms.all' | translate}}
        </ion-checkbox>
      </ion-item>

      <ion-item lines="none">
        <ion-checkbox labelPlacement="end" justify="start" (ionChange)="manageCourierCheckboxes(!$any($event).detail.checked)"
          [disabled]="!oneCourierChecked" [(ngModel)]="noneChecked">
          {{'pages.report-forms.none' | translate}}
        </ion-checkbox>
      </ion-item>

    </div>
    <div>
      <ion-label class="form-label">{{'pages.report-forms.extras' | translate}}</ion-label>
      <ion-item lines="none">
        <ion-checkbox labelPlacement="end" justify="start" [(ngModel)]="includeTagsReport">
          {{'pages.report-forms.includeTagsReport' | translate}}
        </ion-checkbox>
      </ion-item>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button [disabled]="!reportRequest.hasActiveProviders() || !reportRequest.hasActiveHaulerEmployees()"
                expand="full" class="button-block" (click)="onGenerateReport()" color="success">{{
      'pages.report-forms.button' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
