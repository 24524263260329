import {OrderGroup} from '@models/order-helper/order-group.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';

export class OrderListForStatus {
  status: OrderStatus;
  _toBeServed: Array<OrderGroup> = [];
  _groupedOrders: Array<OrderGroup> = [];
  rawOrders: OrderGroup;
  _rawGroup: Array<OrderGroup>;

  constructor(status: OrderStatus) {
    this.status = status;
    this._toBeServed = this._groupedOrders;
    this.rawOrders = new OrderGroup(null);
    this._rawGroup = [this.rawOrders];
  }

  get size() {
    return this.rawOrders ? this.rawOrders.size : 0;
  }

  get groups(): Array<OrderGroup> {
    return this._toBeServed;
  }

  serveRaw(serveRaw: boolean) {
    if (serveRaw) {
      this._toBeServed = this._rawGroup;
    } else {
      this._toBeServed = this._groupedOrders;
    }
  }

  addGroup(group: OrderGroup) {
    this._groupedOrders.push(group);
    // FIXME sort with pickup group here?
    this._groupedOrders.sort((a: OrderGroup, b: OrderGroup) => a.name.localeCompare(b.name));
  }
}
