import {ViewChild} from '@angular/core';
import {Component} from '@angular/core';
import {OnDestroy} from '@angular/core';
import {OnInit} from '@angular/core';
import {Provider} from '@models/business/provider.model';
import {Tag} from '@models/business/tag.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {SelectedProvider} from '@models/selected-provider.model';
import {UserSettings} from '@models/settings/settings.model';
import {StatusSettings} from '@models/settings/status-settings.model';
import {Platform} from '@ionic/angular';
import {MenuController} from '@ionic/angular';
import {IonContent} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {SettingsManager} from '@services/settings-providers/settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const CONFIG = {
  SORT_AND_FILTER_MENU_ID: 'SORT_AND_FILTER_MENU',
  GA: {
    PAGE_NAME: 'OrderListOptionsMenuPage',
    EVENT: {
      'SORTING-SORT_CHANGED': {
        NAME: 'sorting',
        ACTION: 'sort_changed'
      },
      'FILTERING-FILTER_CHANGED': {
        NAME: 'filtering',
        ACTION: 'filter_changed'
      }
    }
  }
};

@Component({
  selector: 'mobilus-order-list-options-menu',
  templateUrl: './order-list-options-menu.page.html',
  styleUrls: ['./order-list-options-menu.page.scss'],
})
export class OrderListOptionsMenuPage implements OnInit, OnDestroy {

  @ViewChild(IonContent, {static: true}) content: IonContent;

  providers: Array<Provider> = [];
  settings: UserSettings;
  filterableTags: Array<Tag> = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  statusSettings: StatusSettings = new StatusSettings();
  searching: boolean;
  private status: OrderStatus;

  constructor(private mobileContextService: MobileContextService,
              private menuCtrl: MenuController,
              private settingsManager: SettingsManager,
              private ga: MblsAnalyticsService,
              private platform: Platform,
              private log: LogService, ) {
    this.platform.ready()
      .then(() => {
        this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
      });
  }

  ngOnInit(): void {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((settings) => {
        this.settings = settings;
        this.generateTags();
        if (this.status) {
          this.statusSettings = this.settings.settingsForStatus(this.status);
          this.searching = this.statusSettings.activeFilter.searching;
        }
        // this.content.resize();
      });

    this.mobileContextService.haulerObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.generateTags();
      });

    this.mobileContextService.orderStatusObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((status) => {
        this.status = status;
        this.statusSettings = this.settings.settingsForStatus(status);
        this.searching = this.statusSettings.activeFilter.searching;
        // this.content.resize();
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  generateTags() {
    if (this.settings && this.settings.selectedProviders) {
      const tagNames: Array<string> = [];
      this.filterableTags = [];
      this.providers = this.settings.selectedProviders
        .filter((provider: SelectedProvider) => (provider.selected && provider.visible));

      this.providers.forEach((provider: Provider) => {
        // manage tag for inactive providers
        provider.tags.forEach((tag: Tag) => {
          if (tag.onMobile && tagNames.indexOf(tag.label) < 0) {
            tagNames.push(tag.label);
            this.filterableTags.push(tag);
          }
        });
      });
    }
  }

  close() {
    this.menuCtrl.close(CONFIG.SORT_AND_FILTER_MENU_ID);
  }

  filterChanged() {
    this.settingsManager.filterChanged();
    this.ga.trackEvent(CONFIG.GA.EVENT['FILTERING-FILTER_CHANGED'].NAME, CONFIG.GA.EVENT['FILTERING-FILTER_CHANGED'].ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT['FILTERING-FILTER_CHANGED']} with GA`, error));
  }

  tagFilterChanged(key, isChecked) {
    if (isChecked) {
      this.statusSettings.activeFilter.tagFilter.push(key);
    } else {
      const idx = this.statusSettings.activeFilter.tagFilter.indexOf(key);
      if (idx > -1) {
        this.statusSettings.activeFilter.tagFilter.splice(idx, 1);
      }
    }
    this.filterChanged();
  }

  timeConstraintFilterChanged() {
    this.filterChanged();
  }

  selectedTag(key: string): boolean {
    return this.statusSettings.activeFilter.tagFilter.indexOf(key) > -1;
  }

  searchToggle() {
    if (this.searching) {
      this.statusSettings.search.allFields = !this.statusSettings.search.allFields;
      this.statusSettings.activeFilter.allFields = '';
      this.filterChanged();
    }
    this.close();
  }
}
