import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

// PROVIDERS
import { LogService } from '@services/log/log.service';

/**
 * Provider to show/hide blocker/progress UI
 * Please note that present can be invoked several times and the provider will track the number of operations,
 * requiring blocker UI running simulteniously and show only one progress indicator which will be dismissed
 * when all the operations are complete. This is specifically useful when multiple http requests are executed
 * in parallel
 */
@Injectable({
    providedIn: 'root'
})
export class Loading {

    public loader: HTMLIonLoadingElement = null;
    public numberOfLoadersBeingInProgress: number = null;

    constructor(
        private loadingCtrl: LoadingController,
        private log: LogService
    ) {
        this.numberOfLoadersBeingInProgress = 0;
    }

    public async present(): Promise<void> {
        if (this.numberOfLoadersBeingInProgress < 0) {
            this.log.error(`[Loading] present - numberOfLoadersBeingInProgress is below zero: ${this.numberOfLoadersBeingInProgress}`);
            this.numberOfLoadersBeingInProgress = 0;
        }

        this.numberOfLoadersBeingInProgress++;
        if (this.numberOfLoadersBeingInProgress === 1) {
            this.loader = await this.loadingCtrl.create();
            await this.loader.present();
        }
    }
    public async dismiss(): Promise<boolean> {
        if (this.numberOfLoadersBeingInProgress < 1) {
            this.log.error(`[Loading] dismiss - numberOfLoadersBeingInProgress is below one: ${this.numberOfLoadersBeingInProgress}`);
            this.numberOfLoadersBeingInProgress = 1;
        }

        this.numberOfLoadersBeingInProgress--;
        if (this.numberOfLoadersBeingInProgress === 0) {
            if (this.loader != null) {
                return this.loader.dismiss();
            } else {
                this.log.error('[Loading] dismiss - loader is not defined');
            }
        }
        return true;
    }

}
