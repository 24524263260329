import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonObject} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('Policies')
export class Policies {
  @JsonProperty('lastUpdate', MomentDateConverter, true)
  lastUpdate: moment.Moment = undefined;

  @JsonProperty('privacyPolicyUrl', String, true)
  privacyPolicyUrl: string = undefined;

  @JsonProperty('termsUrl', String, true)
  termsUrl: string = undefined;

}
