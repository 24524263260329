import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {StatusTabs} from '@app/pages/status-tabs/status-tabs.page';

const routes: Routes = [
  {
    path: '',
    component: StatusTabs,
    children: [
      {
        path: 'ready',
        children: [
          {
            path: '',
            loadChildren: () => import('../orders-list/orders-list.module').then(m => m.OrderListPageModule)
          }
        ]
      },
      {
        path: 'back',
        children: [
          {
            path: '',
            loadChildren: () => import('../orders-list/orders-list.module').then(m => m.OrderListPageModule)
          }
        ]
      },
      {
        path: 'delivery',
        children: [
          {
            path: '',
            loadChildren: () => import('../orders-list/orders-list.module').then(m => m.OrderListPageModule)
          }
        ]
      },
      {
        path: '',
        redirectTo: '/status-tabs/delivery',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class StatusTabsRoutingModule {
}
