import {HttpEvent} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpInterceptor} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {TokenService} from '@services/auth/token.service';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JwtTokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const endpointService = this.injector.get(EndpointService);
    if (req.url.startsWith(endpointService.currentEndpoint) && (req.url.includes('/mobile/v2') || req.url.includes('/file/') || req.url.includes('/oauth/token'))) {
      // console.log('using intercept', req.url, req.method);
      const tokenService = this.injector.get(TokenService);
      const token = tokenService.oauthToken;
      if (token && token.access_token) {
        return next.handle(req.clone({headers: req.headers.set('Authorization', `Bearer ${token.access_token}`)}));
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
