import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'MapSettingsPage'
  }
};

// @note this page does not seem to be used anywhere so we can comment it out or remove it completely
@Component({
  templateUrl: './map-settings.page.html',
  styleUrls: ['./map-settings.page.scss'],
})
export class MapSettingsPage implements OnInit {

  rank = 8;

  constructor(private navParams: NavParams,
              private modalCtrl: ModalController,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ngOnInit() {
    this.rank = this.navParams.get('sequence');
  }

  closeModal() {
    this.modalCtrl.dismiss({rank: this.rank});
  }

}
