import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('DeliveryRecipient')
export class DeliveryRecipient {

  @JsonProperty('identity', String, true)
  identity: string = undefined;

  @JsonProperty('relation', String, true)
  relation: string = undefined;

  @JsonProperty('relationMore', String, true)
  relationMore: string = undefined;

  hasContent(): boolean {
    return !!(this.identity || this.relation || this.relationMore);
  }
}
