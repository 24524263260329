<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>{{'messages.information' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <h4>{{message?.title | translate}}</h4>
  <div class="message">
    {{message?.subTitle | translate}}
    <ul>
      <li *ngFor="let transition of transitionsRequireInternet">{{ 'workflows.events.' + transition | translate}}</li>
    </ul>
  </div>
</ion-content>
