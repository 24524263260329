import {Component} from '@angular/core';
import {AccountingReport} from '@models/reports/accountingReport.model';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'AccountingReportModalPage'
  }
};

@Component({
  templateUrl: './accounting-report-modal.page.html',
  styleUrls: ['./accounting-report-modal.page.scss'],
})
export class AccountingReportModalPage {

  public reportType: string;
  public report: AccountingReport;
  public count = 0;
  public items = [];
  public reports = [];
  public haulerReport = [];
  tagsLabel = [];
  tagsLabelCount: Map<string, number> = new Map<string, number>();
  ordersTags = [];
  public includeTags: boolean;

  constructor(public navParams: NavParams,
              private modalCtrl: ModalController,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
    this.reportType = this.navParams.get('reportType');
    this.report = this.navParams.get('report');
    this.haulerReport = this.navParams.get('haulerReport');
    this.reports = this.reportType === 'pages.accounting-reports.orders_cancelled' ?
      this.report.ret.CANCELLED : this.report.ret.DELIVERED;

    for (let i = 0; i < 30; i++) {
      if (i >= this.reports.length) {
        break;
      }
      this.items.push(this.reports[i]);
      this.count = i;
    }

    this.includeTags = this.navParams.get('includeTags');
    if (this.includeTags) {
      this.getTagsReport();
    }
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  getAccountPaymentTypes(accountName: string) {
    const account = this.haulerReport.find((item: any) => item.key === accountName);
    return Object.keys(account ? account.value : {});
  }

  getAmount(accountName: string, type: string): number {
    const account = this.haulerReport.find(name => name.key === accountName);
    if (account) {
      let total;
      if (type === 'amount') {
        total = Object.keys(account.value).reduce((a, b) => a + account.value[b].amount, 0);
      } else if (type === 'collectedAmount') {
        total = Object.keys(account.value).reduce((a, b) => a + account.value[b].collectedAmount, 0);
      } else {
        total = Object.keys(account.value).reduce((a, b) => a + account.value[b].nb, 0);
      }
      return total && total.toFixed(2);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  doInfinite(infiniteScroll) {
    for (let i = this.count; i < 30 + this.count; i++) {
      if (i >= this.reports.length) {
        break;
      }
      this.items.push(this.reports[i]);
    }
    infiniteScroll.complete();
    if (this.items.length > this.reports.length) {
      infiniteScroll.enable(false);
    }
  }


  getTagsReport() {
    if (this.reports) {
      for (let i = 0; i < this.reports.length; i++) {
        if (this.reports[i].order.tags.length > 0) {
          for (let j = 0; j < this.reports[i].order.tags.length; j++) {
            const tag = this.reports[i].order.tags[j];
            if (this.tagsLabel.includes(tag.label)) {
              this.tagsLabelCount.set(tag.label, this.tagsLabelCount.get(tag.label) + 1);
            } else {
              this.tagsLabel.push(tag.label);
              this.tagsLabelCount.set(tag.label, 1);
              this.ordersTags.push(tag);
            }
          }
        }
      }
    }
  }

  getNumbersOfTags(tag) {
    return this.tagsLabelCount.get(tag);
  }

  getTagIcon(tag) {
    for (let i = 0; i < this.ordersTags.length; i++) {
      if (this.ordersTags[i].label === tag) {
        return this.ordersTags[i];
      }
    }
  }
}
