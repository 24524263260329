<ion-header>
  <ion-toolbar>
    <ion-title><span [translate]="title"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withPayment && !doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectPayment()">
              <fa-icon [icon]="['fal', 'credit-card']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectPayment"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withComment">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectComment()">
              <fa-icon [icon]="['fas', 'comment']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectComment"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withSignature && !doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectSignature()">
              <fa-icon [icon]="['fas', 'pen-square']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectSignature"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withDeliveryConstraints && ! doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectInterlocutor()">
              <fa-icon [icon]="['fas', 'user']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectInterlocutor"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withHaulerChoice && !doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button" (click)="selectHauler()">
              <fa-icon [icon]="['fas', 'users']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectHauler"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withReasonChoicesOther && config.withReasonChoicesOther.length > 0 &&! doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectReasonChoicesOther()">
              <fa-icon [icon]="['fas', 'question']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectReasonChoicesOther"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withReason &&! doorCode">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button" (click)="selectReason()">
              <fa-icon [icon]="['fas', 'question']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.withReason"></span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="transitions-buttons-col" *ngIf="config.withPicture">
            <ion-button fill="solid" color="primary" shape="round" expand="block" class="transition-button"
                        (click)="selectPicture()">
              <fa-icon [icon]="['fas', 'camera']"></fa-icon>
              <span class="transitions-buttons-text" translate="transition.actions.selectPicture"></span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
  </ion-toolbar>
</ion-header>


<ion-content>
  <ion-list>
    <!--
        <ion-item *ngIf="signatureImage">
          <button ion-button (click)="removeSignature()" *ngIf="signatureImage &amp;&amp; signatureImage.value">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
          <img [src]="signatureImage.value" *ngIf="signatureImage &amp;&amp; signatureImage.value" height="50%" width="50%"/>
        </ion-item>
    -->
    <ion-item *ngFor="let orderView of clonedOrderViews; let i = index" (click)="transitionOrder(orderView)"
              class="item-no-padding ion-text-wrap">
      <mobilus-order-item-transition class="order-item-transition-width " [orderView]="orderView" [forceLongAddress]="true" [index]="i"
                             [styleInvalidity]="true" [transition]="transition" [hauler]="hauler" [showDoorCode]="true"></mobilus-order-item-transition>
    </ion-item>

  </ion-list>

  <ion-grid *ngIf="!doorCode">
    <ion-row>
      <ion-col size="7"><span translate="transition.total"></span></ion-col>
      <ion-col size="5">
        <ion-row>
          <ion-col size="6" class="subtotal">
            <fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon>
            {{ expectedTotal | number:'1.2' }}</ion-col>
          <ion-col size="6" class="subtotal">
            <fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon>
            {{ calculatedTotal | number:'1.2' }}</ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="actions-footer">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row>
        <ion-col size="6" class="transitions-buttons-col">
          <ion-button expand="full" size="large" class="transition-button" (click)="dismiss()"
                      color="secondary" fill="solid">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="transitions-buttons-text" translate="actions.cancel"></span>
          </ion-button>
        </ion-col>
        <ion-col size="6" class="transitions-buttons-col">
          <mobilus-transition-button  [transition]="transition" [connected]="connected" [disableOnClick]="true"
                             (onClick)="activate($event)"></mobilus-transition-button>
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-footer>
