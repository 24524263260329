<ion-content>
  <ion-grid class="ion-padding">
    <ion-row class="ion-align-items-center">
      <ion-col size="10" class="ion-no-padding">
        <h2 class="no-margin">
          <mobilus-sequence-number [order]="order" *ngIf="order.sequence" style="font-size: 0.6em"></mobilus-sequence-number>
          {{ order.client.optimizedName() }}
        </h2>
      </ion-col>
      <ion-col size="2" class="ion-no-padding">
        <span class="ion-float-right"><b>{{ order.amount | currency:'USD':'symbol-narrow' }}</b></span>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col size="8" class="provider-padding">
        <div>
          <span><fa-icon [icon]="['fas', 'building']"></fa-icon>&nbsp;
            <b>{{ order.tenantName }}</b>&nbsp;
            <a *ngIf="providerPhoneNumber" [href]="'tel:' + providerPhoneNumber">
              <fa-icon [icon]="['fas', 'phone-alt']"></fa-icon>
            </a>
          </span>
        </div>
      </ion-col>
      <ion-col size="4" class="desired-arrival-time-padding">
        <span class="ion-float-right">
          <mobilus-desired-arrival [type]="order.desiredArrivalTime" [start]="order.desiredArrivalTimeHour"
                           [end]="order.desiredArrivalTimeHourEnd"></mobilus-desired-arrival>
        <mobilus-desired-arrival
          *ngIf="(!order.desiredArrivalTime || order.desiredArrivalTime === 'ANY') && order.client.driverOwnedInfo"
          [type]="order.client.driverOwnedInfo.desiredArrivalTime"
          [start]="order.client.driverOwnedInfo.desiredArrivalTimeHour"
          [constraint]="false"
          [end]="order.client.driverOwnedInfo.desiredArrivalTimeHourEnd"></mobilus-desired-arrival>
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.calculatedArrivalTime">
      <span>{{'order.calculatedArrivalTime.label' | translate}}&nbsp;: {{order.calculatedArrivalTime | Time:settings.hourFormat}}</span>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.averageDeliveryMoment">
      <ion-col size="8" class="ion-no-padding">
        <span translate="order.averageDeliveryMoment.label"></span>
        <mobilus-average-arrival
          [time]="order.averageDeliveryMoment | MobilusDesiredArrivalTime:settings.hourFormat"></mobilus-average-arrival>
      </ion-col>
      <ion-col size="4" class="ion-no-padding">
      <span class="ion-float-right"><mobilus-order-countdown-clock [order]="order"
                                                           [reference]="reference"></mobilus-order-countdown-clock></span>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.deliveryAddress.lockerIntegration">
      <ion-col size="2" class="provider-padding">
        <fa-icon [icon]="['fas', 'table']"></fa-icon>
      </ion-col>
      <ion-col size="3" class="provider-padding">
        {{ 'locker.type.' + order.lockerData.configuration.integrationType | translate }}
      </ion-col>
      <ion-col size="7" class="ion-no-padding" *ngIf="order.deliveryAddress.lockerIntegration">
        <span style="font-weight: bold;">{{ order.deliveryAddress.lockerIntegration.description }}</span>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col size="10" class="ion-no-padding">
        <span>{{ order.deliveryAddress.addressOneLine }}</span>
        <span *ngIf="order.deliveryAddress.appartNumber">{{'pages.order-detail.apartment_number' | translate}}
          : {{ order.deliveryAddress.appartNumber }}</span>
        <span *ngIf="order.deliveryAddress.notes">
          <br>
         <fa-icon [icon]="['fal', 'comment-alt']"></fa-icon>
          {{order.deliveryAddress.notes}}
        </span>
      </ion-col>
      <ion-col size="2" class="message-and-call-button">
        <ion-button fill="outline" (click)="openInMap()">
          <fa-icon [icon]="['fas', 'map']"></fa-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let phoneNumber of order.client.phoneNumbers" class="ion-align-items-center">
      <ion-col size="8" class="ion-no-padding" *ngIf="phoneNumber.label === 'Mobile'">
        <span class="text-bold">{{ phoneNumber.label }}:</span> <span>{{ phoneNumber.number | phone }}</span>
      </ion-col>
      <ion-col size="10" class="ion-no-padding" *ngIf="phoneNumber.label !== 'Mobile'">
        <span class="text-bold">{{ phoneNumber.label }}:</span> <span>{{ phoneNumber.number | phone }}</span>
      </ion-col>
      <ion-col size="2" class="message-and-call-button" *ngIf="phoneNumber.label === 'Mobile'">
        <ion-button fill="outline" [href]="'sms:' + phoneNumber.number">
          <fa-icon [icon]="['fas', 'comments-alt']"></fa-icon>
        </ion-button>
      </ion-col>
      <ion-col size="2" class="message-and-call-button">
        <ion-button fill="outline" href="tel:{{ phoneNumber.number }}">
          <fa-icon [icon]="['fas', 'phone-alt']"></fa-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center"
             *ngIf="(order.tags && order.tags.length > 0) || (order.client.tags && order.client.tags.length > 0)">
      <ion-col class="ion-no-padding">
        <mobilus-tags [tags]="order.tags" [short]="false"></mobilus-tags>
        <mobilus-tags [tags]="order.client.tags" [short]="false"></mobilus-tags>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.bags && order.bags.length > 0">
      <ion-col class="ion-no-padding">
        <mobilus-bags [bags]="order.bags" [metadata]="order.metadata"></mobilus-bags>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="order.notes || order.client.notes" class="row-separator ion-align-items-center">
      <ion-col class="ion-no-padding">
        <hr/>
      </ion-col>
    </ion-row>
    <!-- @formatter:off -->
    <ion-row class="ion-align-items-center" *ngIf="order.notes">
      <ion-col size="1" style="padding: 0">
        <ion-badge class="order_notes_badge" style="margin: 0">
          <fa-icon [icon]="['fas', 'comment']"></fa-icon>
        </ion-badge>
      </ion-col>
      <ion-col class="ion-no-padding" size="11">
        <ion-item class="mbls-notes mbls_order_notes">
          {{ order.notes }}
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.client.notes">
      <ion-col size="1" style="padding: 0">
        <ion-badge class="client_notes_badge" style="margin: 0">
          <fa-icon [icon]="['fas', 'comment']"></fa-icon>
        </ion-badge>
      </ion-col>
      <ion-col class="ion-no-padding" size="11">
        <ion-item class="mbls-notes">
          {{ order.client.notes }}
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- @formatter:on -->
    <ion-row class="ion-align-items-center row-separator">
      <ion-col class="ion-no-padding">
        <hr>
      </ion-col>
    </ion-row>


    <ion-row class="ion-align-items-center" *ngIf="order.externalReference">
      <ion-col style="padding: 0">
        <span class="text-bold"
              translate="pages.order-detail.order_external_reference"></span> {{order.externalReference}}
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center row-separator"
             *ngIf="order.externalReference && order.client.externalReference">
      <ion-col class="ion-no-padding">
        <hr/>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center" *ngIf="order.client.externalReference">
      <ion-col style="padding: 0">
        <span class="text-bold"
              translate="pages.order-detail.client_external_reference"></span> {{order.client.externalReference}}
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center">
      <ion-col size="7" class="ion-no-padding">
        <span class="text-bold" translate="pages.order-detail.driver_notes"></span>
      </ion-col>
      <ion-col size="3">
        <mobilus-desired-arrival *ngIf="order.client.driverOwnedInfo" [type]="order.client.driverOwnedInfo.desiredArrivalTime"
                         [start]="order.client.driverOwnedInfo.desiredArrivalTimeHour" [constraint]="false"
                         [end]="order.client.driverOwnedInfo.desiredArrivalTimeHourEnd"></mobilus-desired-arrival>
      </ion-col>

      <ion-col size="2" class="message-and-call-button">
        <ion-button fill="outline" (click)="editDriverNotes()">
          <fa-icon
            [icon]="['fas', 'pencil']"></fa-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" *ngIf="order.client.driverOwnedInfo && order.client.driverOwnedInfo.notes">
      <ion-col size="2" class="ion-align-self-start">
        <ion-badge class="driver_notes_badge">
          <fa-icon [icon]="['fas', 'comment']"></fa-icon>
        </ion-badge>
      </ion-col>
      <ion-col class="ion-align-self-start ion-no-padding" size="10">
        <ion-item class="mbls-notes">
          {{ order.client.driverOwnedInfo.notes }}
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center row-separator" *ngIf="order.payments?.length">
      <ion-col class="ion-no-padding">
        <hr/>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="order.payments?.length" class="payment ion-align-items-center">
      <ion-col size="12">
        <span><fa-icon
          [icon]="['fas', 'dollar-sign']"></fa-icon> <b>{{'pages.order-detail.payment_info' | translate}}</b></span>
      </ion-col>
      <ion-row *ngFor="let payment of order.payments" class="paymentCollected">
        <ion-col size="12">
          <span>{{'transition.collectedAmount.label' | translate}}:</span> <span
          class="ion-float-right"><b>{{ payment.collectedAmount | currency:'USD':'symbol-narrow' }}</b></span>
        </ion-col>
        <ion-col size="12" *ngIf="payment.type">
          <span>{{'transition.paymentMode.selected' | translate}}:</span>
          <span class="ion-float-right" [ngSwitch]="payment.type">
            {{ 'transition.paymentType.' + payment.type.toLowerCase() | translate}}
            <fa-icon *ngSwitchCase="'CASH'" [icon]="['fal', 'money-bill']" [ngClass]="['fa-lg']"></fa-icon>
            <fa-icon *ngSwitchCase="'CREDIT_FILES'" [icon]="['fal', 'address-card']" [ngClass]="['fa-lg']"></fa-icon>
            <fa-icon *ngSwitchCase="'CREDIT'" [icon]="['fal', 'credit-card']" [ngClass]="['fa-lg']"></fa-icon>
            <fa-icon *ngSwitchCase="'CLIENT_ACCOUNT'" [icon]="['fal', 'address-book']" [ngClass]="['fa-lg']"></fa-icon>
            <fa-icon *ngSwitchCase="'CHECK'" [icon]="['fal', 'pen-square']"></fa-icon>
          </span>
        </ion-col>
      </ion-row>
    </ion-row>
    <ng-container *ngIf="order.deliveryRecipient && order.deliveryRecipient.hasContent()"
                  class="ion-align-items-center">
      <ion-row class="payment">
        <ion-col size="12">
        <span><fa-icon
          [icon]="['fas', 'user-edit']"></fa-icon> <b>{{'pages.order-detail.deliveryRecipient' | translate}}</b></span>
        </ion-col>

        <ion-col>
          <ion-grid>
            <ion-row *ngIf="order.deliveryRecipient.relation && order.deliveryRecipient.relation !== 'other'">
              <ion-col>
                <span translate="order.deliveryRecipient.relation"></span>
              </ion-col>
              <ion-col>
                {{ 'transition.relationWithClient.relations.' + order.deliveryRecipient.relation | translate }}
              </ion-col>
            </ion-row>
            <ion-row *ngIf="order.deliveryRecipient.relationMore">
              <ion-col>
                <span translate="order.deliveryRecipient.relationMore"></span>
              </ion-col>
              <ion-col>
                {{ order.deliveryRecipient.relationMore }}
              </ion-col>
            </ion-row>
            <ion-row *ngIf="order.deliveryRecipient.identity">
              <ion-col>
                <span translate="order.deliveryRecipient.identity"></span>
              </ion-col>
              <ion-col>
                {{ order.deliveryRecipient.identity }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
</ion-content>
