import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserProfile} from '@models/user-profile.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';

@Component({
  templateUrl: './profile-edit.page.html',
  styleUrls: ['./profile-edit.page.scss'],
})
export class ProfileEditPage {
  public userProfile: UserProfile;
  public updateProfileForm: UntypedFormGroup;
  private newLastName;
  private newFirstName;
  private newPhoneNumber;
  private newEmail;

  constructor(private modalCtrl: ModalController,
              private mobileContextService: MobileContextService,
              private formBuilder: UntypedFormBuilder) {

    this.mobileContextService.userProfileObservable.subscribe((userProfile) => {
      this.userProfile = userProfile;
    });

    this.prepareUpdateProfileForm();
  }

  prepareUpdateProfileForm() {
    this.updateProfileForm = this.formBuilder.group({
      firstName: new UntypedFormControl(this.userProfile.firstName, Validators.required),
      lastName: new UntypedFormControl(this.userProfile.lastName, Validators.required),
      phoneNumber: new UntypedFormControl(this.userProfile.phoneNumber),
      email: new UntypedFormControl(this.userProfile.email, Validators.email)
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  updateProfile() {
    this.newFirstName = this.updateProfileForm.value.firstName || this.userProfile.firstName;
    this.newLastName = this.updateProfileForm.value.lastName || this.userProfile.lastName;
    this.newPhoneNumber = this.updateProfileForm.value.phoneNumber;
    this.newEmail = this.updateProfileForm.value.email;

    if (this.newFirstName !== this.userProfile.firstName
      || this.newLastName !== this.userProfile.lastName
      || this.newPhoneNumber !== this.userProfile.phoneNumber
      || this.newEmail !== this.userProfile.email) {
      this.mobileContextService
        .updateUserProfile(this.newFirstName, this.newLastName, this.newPhoneNumber, this.newEmail)
        .subscribe((data) => {
          this.close();
        });
    } else {
      this.close();
    }
  }

}
