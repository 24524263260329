export enum MapOptionEnum {
  APPLE,
  GOOGLE,
  WAZE,
  SYSTEM
}

export namespace MapOptionEnum {
  export function getMap(map: MapOptionEnum) {
    switch (+map) {
      case MapOptionEnum.APPLE:
        return 'APPLE';
      case MapOptionEnum.GOOGLE:
        return 'GOOGLE';
      case MapOptionEnum.WAZE:
        return 'WAZE';
      case MapOptionEnum.SYSTEM:
        return 'SYSTEM';
      default:
        return 'SYSTEM';
    }
  }
}

export enum MapSearchOptionEnum {
  ADDRESS,
  COORDINATES,
  ADDRESS_AND_COORDINATES
}

export namespace MapSearchOptionEnum {
  export function getMapSearchOption(mapSearchOption: MapSearchOptionEnum) {
    switch (+mapSearchOption) {
      case MapSearchOptionEnum.ADDRESS:
        return 'ADDRESS';
      case MapSearchOptionEnum.COORDINATES:
        return 'COORDINATES';
      case MapSearchOptionEnum.ADDRESS_AND_COORDINATES:
        return 'ADDRESS_AND_COORDINATES';
      default:
        return 'ADDRESS_AND_COORDINATES';
    }
  }
}
