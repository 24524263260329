import {AppInfoCheckin} from '@models/check-in/app-info.model';
import {DeviceInfoCheckin} from '@models/check-in/device-info.model';
import {SimInfoCheckin} from '@models/check-in/sim-info.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('MobileCheckIn')
export class MobileCheckIn {

  @JsonProperty('deviceInfo', DeviceInfoCheckin)
  deviceInfo: DeviceInfoCheckin = undefined;

  @JsonProperty('appInfo', AppInfoCheckin)
  appInfo: AppInfoCheckin = undefined;

  @JsonProperty('simInfo', SimInfoCheckin, true)
  simInfo: SimInfoCheckin = undefined;

  @JsonProperty('permissions', String, true)
  permissions: string = undefined;

  // @JsonProperty("pushTokens", [PushTokenInfoCheckin], true)
  // pushTokens: Array<PushTokenInfoCheckin> = undefined;

  @JsonProperty('userSettings', String, true)
  userSettings: string = undefined;

}
