import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Tag} from '@models/business/tag.model';

@Component({
  selector: 'mobilus-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  @Input() tags: Array<Tag>;
  @Input() short: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.tags = this.tags.sort((a: Tag, b: Tag) => {
      if (a.shortLabel < b.shortLabel) {
        return -1;
      } else if (a.shortLabel > b.shortLabel) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
