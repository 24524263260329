export class ActiveFilter {
  allFields = '';
  tagFilter: Array<string> = [];
  withTimeConstraint = false;

  get filtering(): boolean {
    return (this.allFields !== '') || (this.tagFilter && (this.tagFilter.length > 0)) || this.withTimeConstraint;
  }

  get searching(): boolean {
    return (this.allFields !== '');
  }

  set searching(value: boolean) {

  }
}
