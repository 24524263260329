<ion-grid [ngClass]="{'even_row' : index % 2 === 0, 'odd_row' : index % 2 !== 0}"
          [class.invalid]="styleInvalidity && !orderView.valid">
  <ion-row>
    <ion-col size="7" class="mbls-text-ellipsis">
      <ion-row>
        <ion-col>
          <ion-badge [hidden]="orderView.order.stateMachineWorkflow !== 'PICKUP'" class="workflow_badge"
                     [translate]="'workflows.abbrev.pickup'"></ion-badge>
          <mobilus-pending-indicator *ngIf="orderView.order.local"></mobilus-pending-indicator>
          {{ orderView.order.client.optimizedName() }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="mbls-text-ellipsis mbls-address">
          {{ orderView.order.deliveryAddress.addressOneLine }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="displayProvider">
        <ion-col>
          <span class="tenant-name mbls-text-ellipsis">{{ orderView.order.tenantName }}</span>
        </ion-col>
      </ion-row>
    </ion-col>

    <ion-col size="5">
      <ion-row *ngIf="!withDoorCode">
        <ion-col size="6">
          <fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon>
          {{ orderView.order.amount | number:'1.2' }}
        </ion-col>
        <ion-col size="6" *ngIf="orderView.transitionData">
          <div *ngFor="let payment of orderView.transitionData.payments">
            <span
              [class.collected_amount_diff]="orderView.order.amount !== payment.collectedAmount">{{ payment.collectedAmount | number:'1.2' }}</span>
            <fa-icon [icon]="['fal', 'money-bill']" *ngIf="payment.type === 'CASH'"></fa-icon>
            <fa-icon [icon]="['fal', 'address-card']" *ngIf="payment.type === 'CREDIT_FILES'"></fa-icon>
            <fa-icon [icon]="['fal', 'credit-card']" *ngIf="payment.type === 'CREDIT'"></fa-icon>
            <fa-icon [icon]="['fal', 'address-book']" *ngIf="payment.type === 'CLIENT_ACCOUNT'"></fa-icon>
            <fa-icon [icon]="['fal', 'pen-square']" *ngIf="payment.type === 'CHECK'"></fa-icon>
            <fa-icon [icon]="['fal', 'ban']" *ngIf="!payment.type"></fa-icon>
          </div>
        </ion-col>
        <ion-col size="12" *ngIf="orderView.transitionData && orderView.transitionData.unbalancedPaymentComment">
          <span>{{ orderView.transitionData.unbalancedPaymentComment }}</span>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="orderView.transitionData && orderView.transitionData.comment">
        <ion-col>
          <span class="mbls-text-ellipsis comment">{{ orderView.transitionData.comment }}</span>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="orderView.transitionData && orderView.transitionData.signatureImage && !withDoorCode">
        <ion-col style="min-height: 4em;">
          <fa-icon [icon]="['fas', 'pen-square']"></fa-icon>
          <img [src]="orderView.transitionData.signatureImage" class="signature_thumb"
               *ngIf="orderView.transitionData.signatureImage"/>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="orderView.transitionData && orderView.transitionData.selectedReason && !withDoorCode">
        <ion-col><span [translate]="orderView.transitionData.selectedReason"></span><span
          *ngIf="orderView.transitionData && orderView.transitionData.reason">: {{ orderView.transitionData.reason}}</span>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="orderView.transitionData && orderView.transitionData.haulerChoiceName && !withDoorCode">
        <ion-col>
          <fa-icon [icon]="['fas', 'share']"></fa-icon>
          {{orderView.transitionData.haulerChoiceName}}
        </ion-col>
      </ion-row>

      <ng-container *ngIf="showDoorCode && orderView.order.lockerData">
        <ion-row>
          <ion-col>
            <fa-icon [icon]="['fas', 'table']"></fa-icon>
            {{ 'locker.type.' + orderView.order.lockerData.configuration.integrationType | translate }}
            <span style="font-weight: bold;">{{ orderView.order.deliveryAddress.lockerIntegration.description }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <fa-icon [icon]="['fas', 'key']"></fa-icon>
            {{ orderView.order.lockerData.details.courierDoorCode }}
          </ion-col>
        </ion-row>
      </ng-container>

    </ion-col>
  </ion-row>
  <!--
    <ion-row>
      <ion-col>
        <pre>{{ orderView.transitionData | json }}</pre>
      </ion-col>
    </ion-row>
  -->
</ion-grid>
