import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AccordionListComponent} from '@app/components/accordion-list/accordion-list.component';
import {AverageArrivalComponent} from '@app/components/average-arrival/average-arrival.component';
import {AwaitingTransitionIndicatorComponent} from '@app/components/awaiting-transition-indicator/awaiting-transition-indicator.component';
import {BagComponent} from '@app/components/bag/bag.component';
import {BagsComponent} from '@app/components/bags/bags.component';
import {DesiredArrivalComponent} from '@app/components/desired-arrival/desired-arrival.component';
import {DistanceWidgetComponent} from '@app/components/distance-widget/distance-widget.component';
import {ManifestItemComponent} from '@app/components/manifest-item/manifest-item.component';
import {OfflineIndicatorComponent} from '@app/components/offline-indicator/offline-indicator.component';
import {OrderCountdownClockComponent} from '@app/components/order-countdown-clock/order-countdown-clock.component';
import {OrderItemTransitionComponent} from '@app/components/order-item-transition/order-item-transition.component';
import {OrderItemComponent} from '@app/components/order-item/order-item.component';
import {PendingIndicatorComponent} from '@app/components/pending-indicator/pending-indicator.component';
import {ReloaderComponent} from '@app/components/reloader/reloader';
import {RouteSummaryDisplayComponent} from '@app/components/route-summary-display/route-summary-display.component';
import {SequenceNumberComponent} from '@app/components/sequence-number/sequence-number.component';
import {TagComponent} from '@app/components/tag/tag.component';
import {TagsComponent} from '@app/components/tags/tags.component';
import {TransitionButtonComponent} from '@app/components/transition-button/transition-button.component';
import {TransitionsDebugComponent} from '@app/components/transitions-debug/transitions-debug.component';
import {TransitionDetailDebugComponent} from '@app/components/transition-detail-debug/transition-detail-debug.component';
import {TriStateToggleComponent} from '@app/components/tri-state-toggle/tri-state-toggle.component';
import {UsagePoliciesComponent} from '@app/components/usage-policies/usage-policies.component';
import {DatePipe} from '@app/pipes/date-pipe/date.pipe';
import {DistanceUnitPipe} from '@app/pipes/distance/distance-unit.pipe';
import {DistancePipe} from '@app/pipes/distance/distance.pipe';
import {MobilusDesiredArrivalTimePipe} from '@app/pipes/distance/mobilus-distance.pipe';
import {PipesModule} from '@app/pipes/pipes.module';
import {TimePipe} from '@app/pipes/time/time.pipe';
import {DirectivesModule} from '@app/directives/directives.module';
import {FaLayersTextComponent} from '@fortawesome/angular-fontawesome';
import {FaLayersComponent} from '@fortawesome/angular-fontawesome';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateDirective} from '@ngx-translate/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatePipe} from '@ngx-translate/core';
import {TranslateLoader} from '@ngx-translate/core';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MomentModule} from 'ngx-moment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    OrderItemComponent,
    TagComponent,
    TagsComponent,
    BagComponent,
    BagsComponent,
    DesiredArrivalComponent,
    OrderCountdownClockComponent,
    AccordionListComponent,
    OfflineIndicatorComponent,
    TransitionButtonComponent,
    OrderItemTransitionComponent,
    AwaitingTransitionIndicatorComponent,
    PendingIndicatorComponent,
    ReloaderComponent,
    RouteSummaryDisplayComponent,
    SequenceNumberComponent,
    DistanceWidgetComponent,
    ManifestItemComponent,
    TriStateToggleComponent,
    AverageArrivalComponent,
    UsagePoliciesComponent,
    TransitionsDebugComponent,
    TransitionDetailDebugComponent
  ],
  imports: [
    IonicModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    PipesModule,
    DirectivesModule,
    MomentModule,
    FontAwesomeModule,
    CommonModule,
  ],
  providers: [
    DatePipe,
    TimePipe,
    DistancePipe,
    MobilusDesiredArrivalTimePipe,
    DistanceUnitPipe
  ],
  exports: [
    OrderItemComponent,
    TagComponent,
    TagsComponent,
    BagComponent,
    BagsComponent,
    DesiredArrivalComponent,
    OrderCountdownClockComponent,
    AccordionListComponent,
    OfflineIndicatorComponent,
    TransitionButtonComponent,
    OrderItemTransitionComponent,
    AwaitingTransitionIndicatorComponent,
    PendingIndicatorComponent,
    ReloaderComponent,
    RouteSummaryDisplayComponent,
    SequenceNumberComponent,
    DistanceWidgetComponent,
    ManifestItemComponent,
    TriStateToggleComponent,
    AverageArrivalComponent,
    UsagePoliciesComponent,
    FaIconComponent,
    FaLayersComponent,
    FaLayersTextComponent,
    TranslatePipe,
    TranslateDirective,
    TransitionsDebugComponent,
    TransitionDetailDebugComponent
  ]
})
export class ComponentsModule {
}
