import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { NgModule } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { AdminPageModule } from '@app/pages/admin/admin.module';
import { DiagnosticPageModule } from '@app/pages/diagnostic/diagnostic.module';
import { DriverNotesModalPageModule } from '@app/pages/driver-notes-modal/driver-notes-modal.module';
import { LocationDisclosurePageModule } from '@app/pages/location-disclosure/location-disclosure.module';
import { LoginPageModule } from '@app/pages/login/login.module';
import { MapFilterPageModule } from '@app/pages/map-filter/map-filter.module';
import { OrderListOptionsMenuPageModule } from '@app/pages/order-list-options-menu/order-list-options-menu.module';
import { UserLocationAdderPageModule } from '@app/pages/setting/user-location-adder/user-location-adder.module';
import { SignaturePinModalPageModule } from '@app/pages/signature-pin-modal/signature-pin-modal.module';
import { StatusTabsModule } from '@app/pages/status-tabs/status-tabs.module';
import { FaIconComponent, FaIconLibrary, FaLayersComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons';

import { faAddressBook } from '@fortawesome/pro-light-svg-icons';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons';
import { faAdjust } from '@fortawesome/pro-light-svg-icons';
import { faAmbulance } from '@fortawesome/pro-light-svg-icons';
import { faAnchor } from '@fortawesome/pro-light-svg-icons';
import { faArrows } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH } from '@fortawesome/pro-light-svg-icons';
import { faArrowsV } from '@fortawesome/pro-light-svg-icons';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import { faBan } from '@fortawesome/pro-light-svg-icons';
import { faBarcode } from '@fortawesome/pro-light-svg-icons';
import { faBed } from '@fortawesome/pro-light-svg-icons';
import { faBeer } from '@fortawesome/pro-light-svg-icons';
import { faBell as faBellLight } from '@fortawesome/pro-light-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faBellSlash } from '@fortawesome/pro-light-svg-icons';
import { faBicycle } from '@fortawesome/pro-light-svg-icons';
import { faBinoculars } from '@fortawesome/pro-light-svg-icons';
import { faBirthdayCake } from '@fortawesome/pro-light-svg-icons';
import { faBold } from '@fortawesome/pro-light-svg-icons';
import { faBomb } from '@fortawesome/pro-light-svg-icons';
import { faBook } from '@fortawesome/pro-light-svg-icons';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons';
import { faBuilding as faBuildingLight } from '@fortawesome/pro-light-svg-icons';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons';
import { faBullseye } from '@fortawesome/pro-light-svg-icons';
import { faBus } from '@fortawesome/pro-light-svg-icons';
import { faCalculator } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCamera as faCameraLight } from '@fortawesome/pro-light-svg-icons';
import { faCar as faCarLight } from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareDown } from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareLeft } from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareRight } from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareUp } from '@fortawesome/pro-light-svg-icons';
import { faCartArrowDown } from '@fortawesome/pro-light-svg-icons';
import { faCartPlus } from '@fortawesome/pro-light-svg-icons';
import { faCertificate } from '@fortawesome/pro-light-svg-icons';
import { faChartArea } from '@fortawesome/pro-light-svg-icons';
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import { faChartLine } from '@fortawesome/pro-light-svg-icons';
import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faChild } from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { faClock as faClockLight } from '@fortawesome/pro-light-svg-icons';
import { faCloud } from '@fortawesome/pro-light-svg-icons';
import { faCode as faCodeLight } from '@fortawesome/pro-light-svg-icons';
import { faCodeBranch as faCodeBranchLight } from '@fortawesome/pro-light-svg-icons';
import { faCoffee } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { faCogs as faCogsLight } from '@fortawesome/pro-light-svg-icons';
import { faComment as faCommentLight } from '@fortawesome/pro-light-svg-icons';
import { faCommentAlt as faCommentAltLight } from '@fortawesome/pro-light-svg-icons';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import { faCompass } from '@fortawesome/pro-light-svg-icons';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { faCrop } from '@fortawesome/pro-light-svg-icons';
import { faCrosshairs as faCrossHairsLight } from '@fortawesome/pro-light-svg-icons';
import { faCube } from '@fortawesome/pro-light-svg-icons';
import { faCubes } from '@fortawesome/pro-light-svg-icons';
import { faDatabase } from '@fortawesome/pro-light-svg-icons';
import { faDesktop } from '@fortawesome/pro-light-svg-icons';
import { faDiamond } from '@fortawesome/pro-light-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope as faEnvelopeLight } from '@fortawesome/pro-light-svg-icons';
import { faEraser } from '@fortawesome/pro-light-svg-icons';
import { faExchange } from '@fortawesome/pro-light-svg-icons';
import { faExclamation as faExclamationLight } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle as faExclamationTriangleLight } from '@fortawesome/pro-light-svg-icons';
import { faEye as faEyeLight } from '@fortawesome/pro-light-svg-icons';
import { faEyeDropper } from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash as faEyeSlashLight } from '@fortawesome/pro-light-svg-icons';
import { faFax } from '@fortawesome/pro-light-svg-icons';
import { faFemale } from '@fortawesome/pro-light-svg-icons';
import { faFighterJet } from '@fortawesome/pro-light-svg-icons';
import { faFileImage } from '@fortawesome/pro-light-svg-icons';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faFilm } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterLight } from '@fortawesome/pro-light-svg-icons';
import { faFire } from '@fortawesome/pro-light-svg-icons';
import { faFireExtinguisher } from '@fortawesome/pro-light-svg-icons';
import { faFlag } from '@fortawesome/pro-light-svg-icons';
import { faFlagCheckered } from '@fortawesome/pro-light-svg-icons';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { faFrown } from '@fortawesome/pro-light-svg-icons';
import { faFutbol } from '@fortawesome/pro-light-svg-icons';
import { faGamepad } from '@fortawesome/pro-light-svg-icons';
import { faGavel } from '@fortawesome/pro-light-svg-icons';
import { faGift } from '@fortawesome/pro-light-svg-icons';
import { faGlassMartini } from '@fortawesome/pro-light-svg-icons';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import { faHdd } from '@fortawesome/pro-light-svg-icons';
import { faHeadphones } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeartbeat } from '@fortawesome/pro-light-svg-icons';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faHospital } from '@fortawesome/pro-light-svg-icons';
import { faHotel } from '@fortawesome/pro-light-svg-icons';
import { faHSquare } from '@fortawesome/pro-light-svg-icons';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { faInbox as faInboxLight } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { faKey as faKeyLight } from '@fortawesome/pro-light-svg-icons';
import { faKeyboard } from '@fortawesome/pro-light-svg-icons';
import { faLaptop } from '@fortawesome/pro-light-svg-icons';
import { faLeaf } from '@fortawesome/pro-light-svg-icons';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons';
import { faListAlt } from '@fortawesome/pro-light-svg-icons';
import { faMagic } from '@fortawesome/pro-light-svg-icons';
import { faMagnet } from '@fortawesome/pro-light-svg-icons';
import { faMale } from '@fortawesome/pro-light-svg-icons';
import { faMapMarker } from '@fortawesome/pro-light-svg-icons';
import { faMedkit } from '@fortawesome/pro-light-svg-icons';
import { faMicrophone } from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneSlash } from '@fortawesome/pro-light-svg-icons';
import { faMinus } from '@fortawesome/pro-light-svg-icons';
import { faMobile as faMobileLight } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { faMoon } from '@fortawesome/pro-light-svg-icons';
import { faMotorcycle } from '@fortawesome/pro-light-svg-icons';
import { faMusic } from '@fortawesome/pro-light-svg-icons';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { faPaintBrush } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { faPencil as faPencilLight } from '@fortawesome/pro-light-svg-icons';
import { faPenSquare as faPenSquareLight } from '@fortawesome/pro-light-svg-icons';
import { faPenSquare } from '@fortawesome/pro-light-svg-icons';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { faPlane } from '@fortawesome/pro-light-svg-icons';
import { faPlug } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { faPowerOff } from '@fortawesome/pro-light-svg-icons';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons';
import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import { faQuestion as faQuestionLight } from '@fortawesome/pro-light-svg-icons';
import { faRandom } from '@fortawesome/pro-light-svg-icons';
import { faRecycle } from '@fortawesome/pro-light-svg-icons';
import { faRoad } from '@fortawesome/pro-light-svg-icons';
import { faRocket } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faShield } from '@fortawesome/pro-light-svg-icons';
import { faShip } from '@fortawesome/pro-light-svg-icons';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { faSignal as faSignalLight } from '@fortawesome/pro-light-svg-icons';
import { faSitemap } from '@fortawesome/pro-light-svg-icons';
import { faSlidersH as faSlidersHLight } from '@fortawesome/pro-light-svg-icons';
import { faSpaceShuttle } from '@fortawesome/pro-light-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStarHalf } from '@fortawesome/pro-light-svg-icons';
import { faStethoscope } from '@fortawesome/pro-light-svg-icons';
import { faStreetView } from '@fortawesome/pro-light-svg-icons';
import { faSuitcase } from '@fortawesome/pro-light-svg-icons';
import { faSun } from '@fortawesome/pro-light-svg-icons';
import { faSync as faSyncLight } from '@fortawesome/pro-light-svg-icons';
import { faTablet } from '@fortawesome/pro-light-svg-icons';
import { faTachometer } from '@fortawesome/pro-light-svg-icons';
import { faTag } from '@fortawesome/pro-light-svg-icons';
import { faTags } from '@fortawesome/pro-light-svg-icons';
import { faTasks } from '@fortawesome/pro-light-svg-icons';
import { faTaxi } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons';
import { faTicket } from '@fortawesome/pro-light-svg-icons';
import { faTimes as faTimesLight } from '@fortawesome/pro-light-svg-icons';
import { faTint } from '@fortawesome/pro-light-svg-icons';
import { faToggleOff } from '@fortawesome/pro-light-svg-icons';
import { faTrash as faTrashLight } from '@fortawesome/pro-light-svg-icons';
import { faTty } from '@fortawesome/pro-light-svg-icons';
import { faUmbrella } from '@fortawesome/pro-light-svg-icons';
import { faUniversity } from '@fortawesome/pro-light-svg-icons';
import { faUnlock } from '@fortawesome/pro-light-svg-icons';
import { faUserMd } from '@fortawesome/pro-light-svg-icons';
import { faUsers as faUsersLight } from '@fortawesome/pro-light-svg-icons';
import { faUserSecret } from '@fortawesome/pro-light-svg-icons';
import { faUtensils } from '@fortawesome/pro-light-svg-icons';
import { faUtensilSpoon } from '@fortawesome/pro-light-svg-icons';
import { faVideo } from '@fortawesome/pro-light-svg-icons';
import { faWheelchair } from '@fortawesome/pro-light-svg-icons';
import { faWifi as faWifiLight } from '@fortawesome/pro-light-svg-icons';
import { faWrench } from '@fortawesome/pro-light-svg-icons';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';

import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { faBuilding as faBuildingRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCar as faCarRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCheck as faCheckRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faClock as faClockRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { faDirections } from '@fortawesome/pro-regular-svg-icons';
import { faExclamation as faExclamationRegular } from '@fortawesome/pro-regular-svg-icons';
import { faInbox as faInboxRegular } from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltUp } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarked } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarker as faMapMarkerRegular } from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { faRetweet as faRetweetRegular } from '@fortawesome/pro-regular-svg-icons';
import { faShare as faShareRegular } from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaDown as faSortAlphaDownRegular } from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericDown } from '@fortawesome/pro-regular-svg-icons';
import { faTable } from '@fortawesome/pro-solid-svg-icons';
import { faAlarmExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faArchive } from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import { faBug } from '@fortawesome/pro-solid-svg-icons';
import { faBuilding as faBuildingSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { faCar as faCarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import { faClock as faClockSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons';
import { faCode } from '@fortawesome/pro-solid-svg-icons';
import { faCodeBranch as faCodeBranchSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCogs as faCogsSolid } from '@fortawesome/pro-solid-svg-icons';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { faCommentAlt as faCommentAltSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons';
import { faCrosshairs as faCrossHairsSolid } from '@fortawesome/pro-solid-svg-icons';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { faEmptySet } from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons';
import { faExclamation as faExclamationSolid } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/pro-solid-svg-icons';
import { faExpand } from '@fortawesome/pro-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { faEyeSlash as faEyeSlashSolid } from '@fortawesome/pro-solid-svg-icons';
import { faFileSpreadsheet } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { faHelicopter } from '@fortawesome/pro-solid-svg-icons';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import { faInbox as faInboxSolid } from '@fortawesome/pro-solid-svg-icons';
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import { faLocationSlash } from '@fortawesome/pro-solid-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { faMapMarker as faMapMarkerSolid } from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerEdit } from '@fortawesome/pro-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { faMobile as faMobileSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { faPenSquare as faPenSquareSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { faRetweet as faRetweetSolid } from '@fortawesome/pro-solid-svg-icons';
import { faRoute } from '@fortawesome/pro-solid-svg-icons';
import { faRulerCombined } from '@fortawesome/pro-solid-svg-icons';
import { faServer } from '@fortawesome/pro-solid-svg-icons';
import { faShare as faShareSolid } from '@fortawesome/pro-solid-svg-icons';
import { faShredder } from '@fortawesome/pro-solid-svg-icons';
import { faSignal } from '@fortawesome/pro-solid-svg-icons';
import { faSignature } from '@fortawesome/pro-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSlidersH as faSlidersHSolid } from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaDown as faSortAlphaDownSolid } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faSync as faSyncSolid } from '@fortawesome/pro-solid-svg-icons';
import { faThList } from '@fortawesome/pro-solid-svg-icons';
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import { faUnlink } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faUserClock } from '@fortawesome/pro-solid-svg-icons';
import { faUserEdit } from '@fortawesome/pro-solid-svg-icons';
import { faUsers as faUsersSolid } from '@fortawesome/pro-solid-svg-icons';
import { faUsersCog } from '@fortawesome/pro-solid-svg-icons';
import { faWifi } from '@fortawesome/pro-solid-svg-icons';

import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IonicModule, Platform } from '@ionic/angular';
import { DeviceInfo } from '@models/information/device-info.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { AuthenticationService } from '@services/auth/authentication.service';
import { OAuth2Service } from '@services/auth/oauth2.service';
import { TokenService } from '@services/auth/token.service';
import { AwaitingTransitionStoreService } from '@services/awaiting-transition/awaiting-transition-store.service';
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { ClientService } from '@services/client/client.service';
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { DeviceInfoService } from '@services/device-info/device-info.service';
import { VersionsListenerService } from '@services/device-info/versions-listener.service';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { FeaturesService } from '@services/features/features.service';
import { MblsAnalyticsService } from '@services/mbls-analytics-service/MblsAnalyticsService';
import { HaulerEmployeeProvider } from '@services/hauler-employee/hauler-employee-provider.service';
import { JwtTokenInterceptor } from '@services/interceptors/jwt-token.interceptor';
import { TimeoutInterceptor } from '@services/interceptors/timeout.interceptors';
import { UnauthorizedInterceptor } from '@services/interceptors/unauthorized.interceptor';
import { IonicDeploy } from '@services/ionic-deploy/ionic-deploy.service';
import { LanguageService } from '@services/language-service/language.service';
import { LogService } from '@services/log/log.service';
import { MapService } from '@services/map/map.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { NotificationService } from '@services/notification-service/notification.service';
import { NotificationStoreService } from '@services/notification-store/notification-store.service';
import { OrderService } from '@services/order-service/order.service';
import { OrderStoreService } from '@services/order-store/order-store.service';
import { OrderSyncService } from '@services/order-sync/order-sync.service';
import { PictureService } from '@services/picture/picture.service';
import { PositionService } from '@services/position-service/position.service';
import { PushService } from '@services/push/push.service';
import { RemoteManagementService } from '@services/remote-management/remote-management.service';
import { ReportingService } from '@services/reporting-service/reporting.service';
import { ReportsService } from '@services/reports-service/reports.service';
import { ScreenService } from '@services/screen-service/screen.service';
import { ErrorsLoggingAndSessionRecordingService } from '@services/sentry-service/errors-logging-and-session-recording.service';
import { SettingsManager } from '@services/settings-providers/settings.service';
import { StorageService } from '@services/storage-service/storage.service';
import { TransitionService } from '@services/transition/transition.service';
import { UserService } from '@services/user/user.service';
import { WorkflowService } from '@services/workflow/workflow.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MomentModule } from 'ngx-moment';
import { MyApp } from './app.component';
import { AccountingReportModalPageModule } from './pages/accounting-report-modal/accounting-report-modal.module';
import { AccountingReportsPageModule } from './pages/accounting-reports/accounting-reports.module';
import { BugReportPageModule } from './pages/bug-report/bug-report.module';
import { ConnectionLostDetailPageModule } from './pages/connection-lost-detail/connection-lost-detail.module';
import { HaulerEmployeesPageModule } from './pages/hauler-employees/hauler-employees.module';
import { HelpPageModule } from './pages/help/help.module';
import { ManifestConfirmationPageModule } from './pages/manifest-confirmation/manifest-confirmation.module';
import { MapSettingsPageModule } from './pages/map-settings/map-settings.module';
import { MqttLostDetailPageModule } from './pages/mqtt-lost-detail/mqtt-lost-detail.module';
import { MultipleOrderTransitionPageModule } from './pages/multiple-order-transition/multiple-order-transition.module';
import { OptimizeOptionsPageModule } from './pages/optimize-options/optimize-options.module';
import { OrderDetailTabsPageModule } from './pages/order-detail-tabs/order-detail-tabs.module';
import { OrderDetailPageModule } from './pages/order-detail/order-detail.module';
import { OrderManifestPageModule } from './pages/order-manifest/order-manifest.module';
import { OrderTraceabilityPageModule } from './pages/order-traceability/order-traceability.module';
import { RemoteCommandMessageModalPageModule } from './pages/remote-command-message-modal/remote-command-message-modal.module';
import { ReorderModalPageModule } from './pages/reorder-modal/reorder-modal.module';
import { ReportsFormsPageModule } from './pages/reports-forms/reports-forms.module';
import { SelectRecipientPageModule } from './pages/select-recipient/select-recipient.module';
import { SignaturePageModule } from './pages/signature/signature.module';
import { SortPopoverPageModule } from './pages/sort-popover/sort-popover.module';
import { TransitionDetailPageModule } from './pages/transition-detail/transition-detail.module';
import { TripReportModalPageModule } from './pages/trip-report-modal/trip-report-modal.module';
import { PipesModule } from './pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BiometricService } from '@services/biometric/biometric.service';
import { BiometricOptInPageModule } from '@app/pages/biometric-opt-in/biometric-opt-in.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';
import { CommonUtils } from '@services/utils/common-utils';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { DeviceUtils } from '@services/utils/device-utils';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { GeolocationUtils } from '@services/utils/geolocation-utils';
import { GeoUtils } from '@services/utils/geo-utils';
// import { GeolocationStoreService } from '@services/geolocation-store/geolocation-store.service';
import { HttpErrorFormatter } from '@services/formatters/http-error-formatter';
import { HttpUtils } from '@services/utils/http-utils';
import { NativeBackgroundGeolocationService } from '@services/background-geolocation/native-background-geolocation.service';
import { PersistentStorage } from '@services/persistent-storage/persistent-storage';
import { PWABackgroundGeolocationService } from '@services/background-geolocation/pwa-background-geolocation.service';
import { TranslateService } from '@ngx-translate/core';

// NOTE: fake geolocation for testing
// import { GeolocationUtilsFake } from '@services/utils/geolocation-utils.fake';

// See the important note below
// import { config, dom, library as fontawesomeSvgCoreLibrary } from '@fortawesome/fontawesome-svg-core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function deviceFactory(capacitorPlugins: CapacitorPlugins,  deviceUtils: DeviceUtils, platform: Platform) {
  return new DeviceInfo(capacitorPlugins, deviceUtils, platform);
}

Sentry.init({
  dsn: 'https://4052662060534bf2bde6ef4a648a0f10@sentry.io/5186798',
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: false
    }),
  ],
  debug: true // FIXME remove
});

export const IONIC_EVENT_TOPIC = {
  REMOTE_COMMAND: 'REMOTE_COMMAND',
  NAVIGATION: 'NAVIGATION'
};

// NOTE: factory for the background geolocation service (either PWA or native)
export function backgroundGeolocationServiceFactory(
  capacitorPlugins: CapacitorPlugins,
  commonUtils: CommonUtils,
  deviceInfo: DeviceInfo,
  deviceUtils: DeviceUtils,
  endpointService: EndpointService,
  geolocationUtils: GeolocationUtils,
  geoUtils: GeoUtils,
  httpClient: HttpClient,
  httpErrorFormatter: HttpErrorFormatter,
  httpUtils: HttpUtils,
  mobileContextService: MobileContextService,
  logService: LogService,
  persistentStorage: PersistentStorage,
  platform: Platform,
  translateService: TranslateService
): BackgroundGeolocationService {
  if (platform.is('hybrid') !== true) {
    return new PWABackgroundGeolocationService(
      capacitorPlugins,
      commonUtils,
      deviceInfo,
      deviceUtils,
      endpointService,
      geolocationUtils,
      geoUtils,
      httpClient,
      httpErrorFormatter,
      httpUtils,
      mobileContextService,
      logService,
      persistentStorage,
      platform,
      translateService
    );
  }
  return new NativeBackgroundGeolocationService(
    capacitorPlugins,
    deviceInfo,
    deviceUtils,
    endpointService,
    geolocationUtils,
    mobileContextService,
    logService,
    platform,
    translateService
  );
}

export function initializePersistentStorage(persistentStorage: PersistentStorage) {
  return (): Promise<void> => {
    return persistentStorage.init();
  };
}

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  isDevelopment = false;

  constructor(private isDebug: IsDebug, private platform: Platform, private log: LogService) {
    this.platform.ready()
      .then(() => {
        if (this.platform.is('cordova')) {
          this.isDebug.getIsDebug().then(debug => {
            this.isDevelopment = debug;
          });
        } else {
          this.isDevelopment = true;
        }
      });
  }

  handleError(error: any): void {
    try {
      if (!this.isDevelopment) {
        Sentry.captureException(error.originalError || error);
      } else {
        // NOTE: do we want to rethrow the error in development mode? or log it to the console?
        throw error;
      }
    } catch (e) {
      this.log.error('Unable to capture exception via Ionic Pro monitoring.', e);
    }
  }
}

@NgModule({
    bootstrap: [MyApp],
    declarations: [MyApp],
    imports: [
        // Import the login page first to access it through the LoggedIn Guard
        LoginPageModule,
        AccountingReportModalPageModule,
        AccountingReportsPageModule,
        AdminPageModule,
        AppRoutingModule,
        BrowserModule,
        BugReportPageModule,
        ConnectionLostDetailPageModule,
        CurrencyMaskModule,
        DiagnosticPageModule,
        DirectivesModule,
        DriverNotesModalPageModule,
        FontAwesomeModule,
        HaulerEmployeesPageModule,
        HelpPageModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'courierdb',
            // NOTE: sqlite sometimes does not work well in debug mode; it does not simply return/resolve so the app hangs
            // @note revert the below to enable sqlite for the production!!!
            // driverOrder: [Drivers.LocalStorage]
            // driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        ManifestConfirmationPageModule,
        MapFilterPageModule,
        MapSettingsPageModule,
        MomentModule,
        MqttLostDetailPageModule,
        MultipleOrderTransitionPageModule,
        LocationDisclosurePageModule,
        BiometricOptInPageModule,
        OptimizeOptionsPageModule,
        OrderDetailPageModule,
        OrderDetailTabsPageModule,
        OrderListOptionsMenuPageModule,
        OrderManifestPageModule,
        OrderTraceabilityPageModule,
        PipesModule,
        ReactiveFormsModule,
        RemoteCommandMessageModalPageModule,
        ReorderModalPageModule,
        ReportsFormsPageModule,
        SelectRecipientPageModule,
        SignaturePageModule,
        SignaturePinModalPageModule,
        SortPopoverPageModule,
        StatusTabsModule,
        TransitionDetailPageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        TripReportModalPageModule,
        TripReportModalPageModule,
        UserLocationAdderPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule
    ],
    providers: [
        AuthenticationService,
        AwaitingTransitionStoreService,
        {
            provide: BackgroundGeolocationService,
            useFactory: backgroundGeolocationServiceFactory,
            deps: [
                CapacitorPlugins,
                CommonUtils,
                DeviceInfo,
                DeviceUtils,
                EndpointService,
                GeolocationUtils,
                // GeolocationUtilsFake,
                GeoUtils,
                HttpClient,
                HttpErrorFormatter,
                HttpUtils,
                MobileContextService,
                LogService,
                PersistentStorage,
                Platform,
                TranslateService
            ]
        },
        ClientService,
        ConnectionStatusService,
        DeviceInfoService,
        Diagnostic,
        EndpointService,
        ErrorHandler,
        ErrorsLoggingAndSessionRecordingService,
        FeaturesService,
        Geolocation,
        MblsAnalyticsService,
        HaulerEmployeeProvider,
        Insomnia,
        IonicDeploy,
        IsDebug,
        LanguageService,
        LogService,
        MapService,
        MobileContextService,
        NotificationService,
        NotificationStoreService,
        OAuth2Service,
        OrderService,
        OrderStoreService,
        OrderSyncService,
        PictureService,
        PositionService,
        PushService,
        RemoteManagementService,
        ReportingService,
        ReportsService,
        ScreenOrientation,
        ScreenService,
        SettingsManager,
        StorageService,
        TokenService,
        TransitionService,
        UserService,
        VersionsListenerService,
        WorkflowService,
        BiometricService,
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [
                BackgroundGeolocationService,
                VersionsListenerService,
                ErrorsLoggingAndSessionRecordingService,
                ScreenService,
            ],
            multi: true
        },
        { provide: APP_INITIALIZER, useFactory: initializePersistentStorage, deps: [PersistentStorage], multi: true },
        {
            provide: DeviceInfo, useFactory: deviceFactory, deps: [CapacitorPlugins, DeviceUtils, Platform]
        },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true
        },
    ],
    exports: [
        FaIconComponent,
        FaLayersComponent,
    ]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    /*
     Font Awesome
    */
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faArchive,
      faTimesLight,
      faCheckSolid,
      faTrash,
      faSlidersHSolid,
      faMobileSolid,
      faMapMarkerEdit,
      faSignOut,
      faInboxSolid,
      faUsersSolid,
      faHistory,
      faClipboardList,
      faBellSolid,
      faUsersCog,
      faQuestion,
      faCloudDownload,
      faDownload,
      faExclamationTriangleSolid,
      faLink,
      faUnlink,
      faCheckSquare,
      faMap,
      faRetweetSolid,
      faRoute,
      faSpinner,
      faListAlt,
      faSortAlphaDownRegular,
      faSortNumericDown,
      faArrowDown,
      faClockRegular,
      faFilter,
      faQuestionCircle,
      faCloudDownloadAlt,
      faCarSolid,
      faExclamationSolid,
      faShareSolid,
      faBuildingSolid,
      faArrowCircleLeft,
      faExclamationTriangleLight,
      faMedkit,
      faCubes,
      faPhone,
      faPenSquareLight,
      faCertificate,
      faCogsSolid,
      faCircleRegular,
      faDollarSign,
      faComment,
      faHelicopter,
      faCaretRight,
      faCloud,
      faArchive,
      faTimesCircle,
      faMoneyBill,
      faAddressCard,
      faCreditCard,
      faAddressBook,
      faBan,
      faPenSquareSolid,
      faCloudUpload,
      faCheckCircle,
      faEmptySet,
      faChartArea,
      faCarLight,
      faUniversity,
      faChartBar,
      faBellLight,
      faBellSlash,
      faBuildingLight,
      faCalendar,
      faCaretSquareLeft,
      faCaretSquareRight,
      faCircleLight,
      faCircleNotch,
      faClockLight,
      faCodeBranchLight,
      faUtensils,
      faTachometer,
      faEnvelopeLight,
      faEyeDropper,
      faFileImage,
      faFilePdf,
      faFileImage,
      faFlag,
      faFrown,
      faFutbol,
      faCogsLight,
      faGlassMartini,
      faUsersLight,
      faHdd,
      faHeart,
      faHospital,
      faHSquare,
      faKeyboard,
      faLifeRing,
      faLightbulb,
      faChartLine,
      faMoneyBill,
      faMoon,
      faNewspaper,
      faPenSquare,
      faImage,
      faChartPie,
      faSyncLight,
      faSlidersHLight,
      faUtensilSpoon,
      faSquare,
      faStar,
      faSun,
      faThumbtack,
      faVideo,
      faFileSpreadsheet,
      faArrowRight,
      faUser,
      faEnvelopeSolid,
      faSyncSolid,
      faSignal,
      faWifi,
      faLocationArrow,
      faCamera,
      faApple,
      faAndroid,
      faTruck,
      faCode,
      faCodeBranchSolid,
      faServer,
      faBug,
      faShredder,
      faSignOutAlt,
      faExternalLink,
      faPhoneAlt,
      faCommentAltSolid,
      faEyeSlashLight,
      faEyeLight,
      faTimesSolid,
      faDirections,
      faMapMarked,
      faCrossHairsSolid,
      faMapMarkerSolid,
      faMapMarkerRegular,
      faBuildingSolid,
      faClockSolid,
      faAlarmExclamation,
      faLocationSlash,
      faCommentAltLight,
      faCommentsAlt,
      faPencil,
      faUserEdit,
      faClipboard,
      faRetweetRegular,
      faPen,
      faCheckRegular,
      faExclamationRegular,
      faShareRegular,
      faBuildingRegular,
      faInboxRegular,
      faCrossHairsLight,
      faLongArrowAltUp,
      faThList,
      faSortAlphaDownSolid,
      faLock,
      faRulerCombined,
      faCalendarDay,
      faLanguage,
      faCircle,
      faUserClock,
      faMobileLight,
      faUndo,
      faEyeSlashSolid,
      faEyeSolid,
      faMinusCircle,
      faSignature,
      faCarRegular,
      faAmbulance,
      faAdjust,
      faAnchor,
      faArchive,
      faArrows,
      faArrowsH,
      faArrowsV,
      faAsterisk,
      faAt,
      faBed,
      faBarcode,
      faBeer,
      faBell,
      faBicycle,
      faBinoculars,
      faBirthdayCake,
      faBold,
      faBomb,
      faBook,
      faBookmark,
      faBriefcase,
      faBullhorn,
      faBullseye,
      faBus,
      faCalculator,
      faCameraLight,
      faCaretSquareDown,
      faCaretSquareUp,
      faCartArrowDown,
      faCartPlus,
      faCheck,
      faChild,
      faCodeLight,
      faCoffee,
      faCog,
      faCommentLight,
      faComments,
      faCompass,
      faCrop,
      faCube,
      faDatabase,
      faDesktop,
      faDiamond,
      faEdit,
      faEraser,
      faExchange,
      faExclamationLight,
      faFax,
      faFemale,
      faFighterJet,
      faFilm,
      faFilterLight,
      faFire,
      faFireExtinguisher,
      faFlagCheckered,
      faFolder,
      faFolderOpen,
      faGamepad,
      faGavel,
      faGift,
      faGlobe,
      faGraduationCap,
      faHeadphones,
      faHeartbeat,
      faHome,
      faHotel,
      faInboxLight,
      faKeyLight,
      faLaptop,
      faLeaf,
      faMagic,
      faMagnet,
      faMale,
      faMapMarker,
      faMicrophone,
      faMicrophoneSlash,
      faMinus,
      faMotorcycle,
      faMusic,
      faPaintBrush,
      faPaperPlane,
      faPaw,
      faPencilLight,
      faPlane,
      faPlug,
      faPlus,
      faPlusSquare,
      faPowerOff,
      faPrint,
      faPuzzlePiece,
      faQrcode,
      faQuestionLight,
      faRandom,
      faRecycle,
      faRoad,
      faRocket,
      faSearch,
      faShield,
      faShip,
      faShoppingCart,
      faSignalLight,
      faSitemap,
      faSpaceShuttle,
      faStarHalf,
      faStethoscope,
      faStreetView,
      faSuitcase,
      faTablet,
      faTag,
      faTags,
      faTasks,
      faTaxi,
      faThumbsDown,
      faTicket,
      faTint,
      faToggleOff,
      faTrashLight,
      faTty,
      faUmbrella,
      faUnlock,
      faUserMd,
      faUserSecret,
      faWheelchair,
      faWifiLight,
      faWrench,
      faInfoCircle,
      faWrench,
      faExpand,
      faInfoSquare,
      faChevronRight,
      faTable,
      faKey,
      faCcVisa,
      faCcMastercard,
      faCcAmex,
      faFileInvoiceDollar
    );

    // IMPORTANT NOTE: it seems that it is not trivial to dynamically render markers with the combination of
    // multiple font awesome icons (one icon is not a problem!)
    // The option is to use SVG Javascript Code package (https://fontawesome.com/v5.15/how-to-use/on-the-web/advanced/svg-javascript-core)
    // which gives the max flexibility and is the most powerful option however there are couple caveats.
    // (1) In addition to adding icons to FaIconLibrary from @fortawesome/angular-fontawesome
    // we also need to add the required icons to the library from @fortawesome/fontawesome-svg-core - see
    // https://github.com/FortAwesome/angular-fontawesome/issues/171#issuecomment-529817190
    // (2) potential perfomance degradation compared to other options
    // For more details see https://fontawesome.com/v5.15/how-to-use/on-the-web/other-topics/performance
    // fontawesomeSvgCoreLibrary.add(
    //   faCarSolid,
    //   faBuildingSolid,
    //   faMapMarker,
    //   faMapMarkerSolid,
    //   faMapMarkerRegular,
    //   faUser,
    //   faUsersSolid,
    // );
    // dom.i2svg();
    // dom.watch();
    // FINAL DECISION BASED ON TESTING ON REAL DEVICES: use webfonts since the above approach, even that it works on the desktop,
    // does not work reliably on real devices and shows white screen of death or cause other problems
    // as discussed here https://github.com/FortAwesome/angular-fontawesome/issues/171

  }
}

