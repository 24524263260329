import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('AppInfoCheckin')
export class AppInfoCheckin {

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('unifiedVersion', String)
  unifiedVersion: string = undefined;

  @JsonProperty('nativeVersion', String)
  nativeVersion: string = undefined;

  @JsonProperty('ionicVersion', String)
  ionicVersion: string = undefined;

}
