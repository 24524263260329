import { JsonProperty } from 'json2typescript';
import { JsonObject } from 'json2typescript';

type OrderMetadataManifestItemType = 'BAG';
type OrderMetadataManifestItemValue = 'Box' | 'Freezer' | 'Parcel';

@JsonObject('OrderMetadataManifestItem')
export class OrderMetadataManifestItem {

  @JsonProperty('id', String, true)
  id: string = undefined;

  @JsonProperty('type', String, true)
  type: OrderMetadataManifestItemType = undefined;

  @JsonProperty('value', String, true)
  value: OrderMetadataManifestItemValue = undefined;

}
