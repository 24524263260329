import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {ReportingService} from '@services/reporting-service/reporting.service';
import {UserReport} from '@services/reporting-service/user-report.model';
import {UserProfile} from '@models/user-profile.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';


const CONFIG = {
  GA: {
    PAGE_NAME: 'BugReportPage',
    EVENT: {
      SENDING_REPORT: {
        NAME: 'reporting',
        ACTION: 'report_sent'
      }
    }
  }
};

@Component({
  templateUrl: './bug-report.page.html',
  styleUrls: ['./bug-report.page.scss'],
})
export class BugReportPage {
  public userProfile: UserProfile;
  public sendReportForm: UntypedFormGroup;
  private reportType;
  private reportText;
  private reportContactEmail;
  private reportContactPhone;

  constructor(private modalCtrl: ModalController,
              private reportingService: ReportingService,
              private mobileContextService: MobileContextService,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private formBuilder: UntypedFormBuilder
  ) {
    this.reportType = 'bug';
    this.reportText = '';
    this.mobileContextService.userProfileObservable.subscribe((userProfile) => {
      this.userProfile = userProfile;
      this.reportContactEmail = userProfile.email;
      this.reportContactPhone = userProfile.phoneNumber;
    });

    this.prepareSendReportForm();
  }

  prepareSendReportForm() {
    this.sendReportForm = this.formBuilder.group({
      reportType: new UntypedFormControl(this.reportType),
      reportText: new UntypedFormControl(this.reportText),
      reportContactEmail: new UntypedFormControl(this.reportContactEmail, Validators.email),
      reportContactPhone: new UntypedFormControl(this.reportContactPhone)
    });
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  sendReport() {
    this.reportType = this.sendReportForm.value.reportType;
    this.reportText = this.sendReportForm.value.reportText;
    this.reportContactEmail = this.sendReportForm.value.reportContactEmail;
    this.reportContactPhone = this.sendReportForm.value.reportContactPhone;
    this.ga.trackEvent(CONFIG.GA.EVENT.SENDING_REPORT.NAME, CONFIG.GA.EVENT.SENDING_REPORT.ACTION)
      .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.SENDING_REPORT} with GA`, error));
    const report = new UserReport(this.reportType, this.reportText, this.reportContactEmail, this.reportContactPhone);
    this.reportingService.sendSnapshotReport(report);
    this.modalCtrl.dismiss({confirm: true});
  }
}
