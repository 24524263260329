import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {PopoverController} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'MapFilterPage',
    EVENT: {
      MAP_FILTER_CHANGED: {
        NAME: 'map_filter_changed',
        ACTION: 'map-filtered'
      },
    }
  }
};

@Component({
  templateUrl: './map-filter.page.html',
  styleUrls: ['./map-filter.page.scss', '../map/map.page.scss']
})
export class MapFilterPage implements OnInit {
  selection: string = 'ALL';

  constructor(private popoverCtrl: PopoverController,
              private navParams: NavParams,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ngOnInit(): void {
    this.selection = this.navParams.get('filterSelection');
  }

  filterChanged(detail) {
    this.ga.trackEvent(CONFIG.GA.EVENT.MAP_FILTER_CHANGED.NAME, CONFIG.GA.EVENT.MAP_FILTER_CHANGED.ACTION, 'MapFilterChanged').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.MAP_FILTER_CHANGED} with GA`, error));
    this.popoverCtrl
      .dismiss(
        {
          filterSelection: detail
        });
  }
}
