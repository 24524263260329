import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {UserSettings} from '@models/settings/settings.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'mobilus-distance-widget',
  templateUrl: './distance-widget.component.html',
  styleUrls: ['./distance-widget.component.scss'],
})
export class DistanceWidgetComponent {

  @Input() distance: number;
  @Input() bearing: string;
  @Input() unit: string;
  settings: UserSettings = new UserSettings();
  unsubscribe: Subject<void> = new Subject<void>();

  constructor() {

  }
}
