import {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {DateTimeCombinationEnum} from '@models/units-and-format/date-format.enum';
import {DateFormatEnum} from '@models/units-and-format/date-format.enum';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';
import * as moment from 'moment';


@Pipe({
  name: 'DatePipe',
})

export class DatePipe implements PipeTransform {

  constructor() {
  }

  /**
   * value, string : time
   * hourFormat, string: hour format (standard or military)
   * dateFormat, string: date format (DMY, MDY or YMD)
   * dateType, string: date type (Date Only, Date And Time, Date Picker, Time And Date or Date And Time With No Seconds)'
   * formatOnly, Boolean: return the format only, or the formatted time
   */
  transform(value: any, hourFormat: TimeFormatEnum, dateFormat: DateFormatEnum, dateTimeCombinationEnum: DateTimeCombinationEnum, forTimePicker: boolean = false, formatOnly: boolean = false): any {


    if (value) {
      if (forTimePicker) {
        return DateFormatEnum.getFormat(dateFormat).split('-').join('/');
      } else {
        switch (dateTimeCombinationEnum) {
          case DateTimeCombinationEnum.dateOnly: {
            const date = DateFormatEnum.getFormat(dateFormat);
            if (formatOnly) {
              return date;
            }
            return moment(value).format(date);
          }
          case DateTimeCombinationEnum.dateAndTime: {
            const dateTimeFormat = DateFormatEnum.getFormat(dateFormat) + ' ' + TimeFormatEnum.getFormat(+hourFormat);
            if (formatOnly) {
              return dateTimeFormat;
            }
            return moment(value).format(dateTimeFormat);
          }
          case DateTimeCombinationEnum.timeAndDate: {
            const timeDateFormat = TimeFormatEnum.getFormat(hourFormat) + ' ' + DateFormatEnum.getFormat(dateFormat);
            if (formatOnly) {
              return timeDateFormat;
            }
            return moment(value).format(timeDateFormat);
          }
          default: {
            return moment(value).format(DateFormatEnum.getFormat(dateFormat));
          }
        }
      }
    }
  }

}
