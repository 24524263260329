<ion-header>

  <ion-toolbar>
    <ion-title><span translate="pages.drawer-menu.diagnostic"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="checkEverything()">
        <fa-icon [icon]="['fas', 'sync']"></fa-icon>
      </ion-button>

      <ion-button (click)="dismiss()" class="text-xxlarge" *ngIf="withCloseButton">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <ion-list>
    <ion-item>
      <fa-icon slot="start" [icon]="['fas', 'signal']" *ngIf="!network.type || network.type !== 'wifi'"></fa-icon>
      <fa-icon slot="start" [icon]="['fas', 'wifi']" *ngIf="network.type && network.type === 'wifi'"></fa-icon>
      <p>
        <span *ngIf="network.isOnline">
          <span translate="diagnostic.network.connected" class="diag_available"></span>&nbsp;
        </span>

        <span *ngIf="!network.isOnline">
          <span translate="diagnostic.network.disconnected" class="diag_unavailable"></span>&nbsp;
        </span>

        <span *ngIf="network.type">({{ 'diagnostic.network.type.' + network.type | translate }})</span>&nbsp;

        <span class="diag_explanation" *ngIf="!network.isOnline"><br/> <span
          translate="diagnostic.network.warn"></span></span>
      </p>

      <fa-icon slot="end" [icon]="['fas', 'check']" *ngIf="network.isOnline" class="diag_available"></fa-icon>
      <fa-icon slot="end" [icon]="['fas', 'exclamation-triangle']" *ngIf="!network.isOnline"
               class="diag_unavailable"></fa-icon>
    </ion-item>

    <ion-item>
      <fa-icon slot="start" [icon]="['fas', 'location-arrow']"></fa-icon>
      <div>
        <p *ngIf="deviceInfo.permissions.location.isEnabled">
          <span translate="diagnostic.location.enabled" class="diag_available"></span>
        </p>
        <p *ngIf="deviceInfo.permissions.location.mode">
          (<span>{{ 'diagnostic.location.mode.' + deviceInfo.permissions.location.mode | translate }}
          </span>)
        </p>
        <div *ngIf="!deviceInfo.permissions.location.isEnabled">
          <p>
            <span translate="diagnostic.location.disabled" class="diag_unavailable"></span>
          </p>
          <p>
            <span translate="diagnostic.location.disabled_warn" class="diag_explanation"></span>
          </p>
        </div>

        <div *ngIf="!deviceInfo.permissions.location.isAuthorized">
          <p>
            <span translate="diagnostic.location.unauthorized" class="diag_unavailable"></span>
          </p>
          <p>
            <span translate="diagnostic.location.unauthorized_warn" class="diag_explanation"></span>
          </p>
        </div>

      </div>
      <ion-button slot="end" (click)="requestLocationPermissions()"
                  *ngIf="!deviceInfo.permissions.location.isAuthorized">
        <span translate="diagnostic.location.requestPermission"></span>
      </ion-button>
      <fa-icon slot="end" [icon]="['fas', 'check']" *ngIf="deviceInfo.permissions.location.isEnabled"
               class="diag_available"></fa-icon>
      <fa-icon slot="end" [icon]="['fas', 'exclamation-triangle']" *ngIf="!deviceInfo.permissions.location.isEnabled"
               class="diag_unavailable"></fa-icon>
    </ion-item>

    <ion-item>
      <fa-icon slot="start" [icon]="['fas', 'camera']"></fa-icon>
      <p>
        <span *ngIf="deviceInfo.permissions.camera.isAvailable">
          <span translate="diagnostic.camera.available" class="diag_available"
                *ngIf="deviceInfo.permissions.camera.isAvailable"></span>&nbsp;</span>
        <span
          *ngIf="!deviceInfo.permissions.camera.isAvailable && !deviceInfo.permissions.camera.isAuthorized && deviceInfo.permissions.camera.isPresent">
          <span translate="diagnostic.camera.unauthorized" class="diag_unavailable"
                *ngIf="!deviceInfo.permissions.camera.isAvailable && !deviceInfo.permissions.camera.isAuthorized && deviceInfo.permissions.camera.isPresent"></span>&nbsp;</span>

        <span
          *ngIf="!deviceInfo.permissions.camera.isAvailable || !deviceInfo.permissions.camera.isPresent || !deviceInfo.permissions.camera.isAuthorized">
          <span translate="diagnostic.camera.warn" class="diag_explanation"
                *ngIf="!deviceInfo.permissions.camera.isAvailable || !deviceInfo.permissions.camera.isPresent || !deviceInfo.permissions.camera.isAuthorized"></span>&nbsp;</span>

        <ion-button slot="end" (click)="requestCameraPermissions()"
                    [hidden]="deviceInfo.permissions.camera.isAuthorized || !deviceInfo.permissions.camera.isPresent">
          <span translate="diagnostic.camera.requestPermission"></span>
        </ion-button>
      </p>

      <fa-icon slot="end" [icon]="['fas', 'check']"
               *ngIf="deviceInfo.permissions.camera.isAvailable && deviceInfo.permissions.camera.isAuthorized"
               class="diag_available"></fa-icon>
      <fa-icon slot="end" [icon]="['fas', 'exclamation-triangle']"
               *ngIf="!deviceInfo.permissions.camera.isAvailable || !deviceInfo.permissions.camera.isAuthorized"
               class="diag_unavailable"></fa-icon>
    </ion-item>

    <ion-item *ngIf="isIos || isAndroid">
      <fa-icon slot="start" [icon]="['fab', 'apple']" *ngIf="isIos"></fa-icon>
      <fa-icon slot="start" [icon]="['fab', 'android']" *ngIf="isAndroid"></fa-icon>
      <p>
        <span class="diag_emphasis">{{ osVersion }}</span> &nbsp;

        <span class="diag_unavailable diag_explanation"
              *ngIf="!supportedOsVersion && isIos">{{ 'diagnostic.os.ios.unsupported' | translate:{version: SUPPORTED_OS.ios} }}</span>
        <span class="diag_unavailable diag_explanation"
              *ngIf="!supportedOsVersion && isAndroid">{{ 'diagnostic.os.android.unsupported' | translate:{version: SUPPORTED_OS.android} }}</span>
      </p>

      <fa-icon slot="end" [icon]="['fas', 'exclamation-triangle']"
               *ngIf="!supportedOsVersion"
               class="diag_unavailable"></fa-icon>
    </ion-item>

    <ion-item *ngIf="haulerCode">
      <fa-icon slot="start" [icon]="['fas', 'truck']"></fa-icon>
      <p>
        <span class="diag_emphasis">{{ haulerCode }}</span>
      </p>
    </ion-item>

    <ion-item (click)="tap()">
      <fa-icon slot="start" [icon]="['fas', 'code']"></fa-icon>
      <p>
        <span class="diag_emphasis">{{ deviceInfo.unifiedVersion }}</span>
      </p>
    </ion-item>

    <ion-item mobilusLongPress (long-press)="resetLocalChannel()">
      <fa-icon slot="start" [icon]="['fas', 'code-branch']"></fa-icon>
      <p>
      <span class="diag_emphasis"
            style="text-transform: capitalize;">{{ 'deploy.channels.' + ionicDeploy.channel.trim().toLowerCase() | translate }}</span>
      </p>
      <ion-button slot="end" (click)="checkForUpdate()" *ngIf="!deployStatus.newVersionAvailable">
        <span translate="deploy.checkForUpdate"></span>
      </ion-button>
    </ion-item>

    <ion-item *ngIf="endpointHint">
      <fa-icon slot="start" [icon]="['fas', 'server']"></fa-icon>
      <p>{{ endpointHint }}</p>
    </ion-item>

    <ion-item>
      <fa-icon slot="start" [icon]="['fas', 'bug']"></fa-icon>
      <p>
        <span class="diag_explanation" translate="diagnostic.debugReport.description"></span>
      </p>
      <ion-button slot="end" (click)="sendDebugReport()">
        <span translate="diagnostic.debugReport.action"></span>
      </ion-button>
      <fa-icon slot="end" [icon]="['fas', 'envelope']" class="diag_available"></fa-icon>

    </ion-item>

    <ion-item class="ion-text-wrap">
      <fa-icon slot="start" [icon]="['fas', 'shredder']"></fa-icon>
      <p>
        <span translate="diagnostic.delete-db.action"></span>
      </p>
      <ion-button slot="end" (click)="deleteDbs()" color="danger">
        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
      </ion-button>
    </ion-item>

    <ion-item (click)="killApp()" *ngIf="isCordova && !isIos">
      <fa-icon slot="start" [icon]="['fas', 'sign-out-alt']"></fa-icon>
      <p>
        <span translate="pages.drawer-menu.exitApp"></span>
      </p>
    </ion-item>

    <ion-item *ngIf="deployStatus.newVersionAvailable">
      <fa-layers slot="start" class="update-cloud">
        <fa-icon [icon]="['fas', 'download']" size="sm"
                 transform="up-8"></fa-icon>
      </fa-layers>
      <p>
        <span class="diag_emphasis" translate="deploy.newVersionAvailable"></span>
      </p>
      <ion-button slot="end" (click)="installNewVersion()">
        <span translate="deploy.newVersionInstall"></span>
      </ion-button>
    </ion-item>

    <!--
      THIS IS USED FOR DEV ONLY
    -->
    <!-- <ion-item>
      <ion-button style="width:100%;" expand="full" (click)="openSample()" color="danger">
        SAMPLE
      </ion-button>
    </ion-item> -->

  </ion-list>

</ion-content>
