import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Descriptor')
export class Descriptor {

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('labelKey', String)
  labelKey: string = undefined;

  @JsonProperty('icon', String)
  icon: string = undefined;

  @JsonProperty('rank', Number, true)
  rank = 0;

  @JsonProperty('style', String, true)
  style: string = undefined;

  @JsonProperty('emphasized', Boolean, true)
  emphasized = false;

  constructor(name: string, labelKey: string, icon: string, rank?: number, style?: string, emphasized?: boolean) {
    this.name = name;
    this.labelKey = labelKey;
    this.icon = icon;
    this.rank = rank;
    this.style = style;
    this.emphasized = emphasized;
  }
}
