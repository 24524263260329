import { OrderMetadataManifestItem } from '@models/business/order-metadata-manifest-item.model';
import { JsonProperty } from 'json2typescript';
import { JsonObject } from 'json2typescript';

@JsonObject('OrderMetadata')
export class OrderMetadata {

  @JsonProperty('manifest', [OrderMetadataManifestItem], true)
  manifest: Array<OrderMetadataManifestItem> = [];

}
