import {MQTTStatusEnumConverter} from '@models/push-messages/converters.model';
import {MQTTStatusEnum} from '@models/push-messages/mqtt-status-enums.model';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('MqttStatusMessage')
export class MqttStatusMessage {

  @JsonProperty('status', MQTTStatusEnumConverter)
  status: MQTTStatusEnum = undefined;
  @JsonProperty('deviceUUID', String)
  deviceUUID: string = undefined;
  @JsonProperty('haulerEmployeeUuid', String, true)
  haulerEmployeeUuid: string = undefined;
  @JsonProperty('date', MomentDateConverter, true)
  date: moment.Moment = undefined;

  constructor(status: MQTTStatusEnum, haulerEmployeeUuid: string, deviceUUID: string) {
    this.status = status;
    this.haulerEmployeeUuid = haulerEmployeeUuid;
    this.deviceUUID = deviceUUID;
    this.date = moment();
  }
}
