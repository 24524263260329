import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ManifestConfirmationText')
export class ManifestConfirmationText {

  @JsonProperty('text', String, true)
  text: string = undefined;

}
