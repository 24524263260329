import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('DriverOwnedInfo')
export class DriverOwnedInfo {

  @JsonProperty('notes', String, true)
  notes: string = undefined;

  @JsonProperty('desiredArrivalTime', String, true)
  desiredArrivalTime: string = undefined;

  @JsonProperty('desiredArrivalTimeHour', String, true)
  desiredArrivalTimeHour: string = undefined;

  @JsonProperty('desiredArrivalTimeHourEnd', String, true)
  desiredArrivalTimeHourEnd: string = undefined;

}
