<ion-header>

  <ion-toolbar>
    <ion-title>{{reportType | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="openLegend()" *ngIf="report.trips.length &&
      (reportType==='pages.trips-reports.recaps' || reportType==='pages.trips-reports.travels')">
        <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
      </ion-button>
      <ion-button (click)="closeModal()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-no-padding">
  <p id="empty-list-message" *ngIf="!report.trips.length">
    {{'messages.empty_list' | translate}}
  </p>
  <ion-grid *ngIf="reportType==='pages.trips-reports.travels' && report.trips.length">
    <ion-row>
      <ion-col size="4">
        <ion-grid class="fixed-grid">
          <ion-row class="header">
            <ion-col class="header-color">
              {{'pages.trips-reports.customer' | translate}}
            </ion-col>
          </ion-row>
          <ion-row *ngFor="let trip of items; let i = index">
            <ion-col class="hidden-md align-content-left"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.client.lastName.toUpperCase()}} {{trip.order.client.firstName}}
              <br>
              <span class="address"> {{trip.order.deliveryAddress.addressOneLine}}</span>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col style="overflow: auto">
        <ion-grid class="unfixed-grid">
          <ion-row class="header">
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.delivery_period' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.prepared_by' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.call_time' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.prep_time' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.departure_time' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.delivery_time' | translate}}
            </ion-col>
            <ion-col size="6" class="header-color">
              {{'pages.trips-reports.delivery_message' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.total_service_time' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.delivery_hauler' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{translateDistanceText('pages.trips-reports.distance_drugstore')}}
            </ion-col>
            <ion-col size="5" class="header-color">
              {{'pages.trips-reports.drugstore' | translate}}
            </ion-col>
            <ion-col size="4" class="header-color">
              {{'pages.trips-reports.reference' | translate}}
            </ion-col>
          </ion-row>
          <ion-row *ngFor="let trip of items; let i = index">
            <ion-col size="4" class="hidden-md space-evenly"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.desiredArrivalTimeHour
            && 'desiredArrival.' + trip.order.desiredArrivalTime.toLowerCase() |
              translate:{
              start: trip.order.desiredArrivalTimeHour  | Time: userSettings.hourFormat,
              end: trip.order.desiredArrivalTimeHourEnd || trip.order.desiredArrivalTimeHour | Time: userSettings.hourFormat
            } }}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.creationTraceability?.operatorCode}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.callTime | Time: userSettings.hourFormat}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center date-time-display"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.creationTraceability?.dateTime |  DatePipe: userSettings.hourFormat: userSettings.dateFormat:dateTimeType }}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center date-time-display"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.firstTakeInChargeByHaylerTraceability?.dateTime | DatePipe: userSettings.hourFormat: userSettings.dateFormat:dateTimeType:true}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center date-time-display"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.traceability?.dateTime | DatePipe: userSettings.hourFormat: userSettings.dateFormat:dateTimeType}}
            </ion-col>
            <ion-col size="6" class="hidden-md space-evenly"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.traceability.message}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{(trip.totalServiceTime / (1000 * 60 * 60)).toFixed(2)}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.haulerEmployee?.firstName}} {{trip.haulerEmployee?.lastName}}
            </ion-col>
            <ion-col size="4" class="hidden-md align-content-center"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.deliveryAddress.distanceFromProvider | Distance: userSettings.distanceUnit:false:false: '1.1-2'}}
            </ion-col>
            <ion-col size="5" class="hidden-md space-evenly"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.tenantName}}
            </ion-col>
            <ion-col size="4" class="hidden-md space-evenly"
                     [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
              {{trip.order.reference}}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="unfixed-grid" *ngIf="report.trips.length && reportType==='pages.trips-reports.recaps'">
    <ion-row class="header">
      <ion-col size="9" class="recap header-color">
      </ion-col>
      <ion-col size="3" class="recap header-color">
        {{'pages.trips-reports.number' | translate}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="recap">
        {{'pages.trips-reports.travels' | translate}}
      </ion-col>
      <ion-col size="3" class="recap amount">
        {{report.recap.nbTrips}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="recap">
        {{'pages.trips-reports.identical_address' | translate}}
      </ion-col>
      <ion-col size="3" class="recap amount">
        {{report.recap.nbTripsWithoutJoined}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="recap">
        {{'pages.trips-reports.extra_distance_orders' | translate}}
      </ion-col>
      <ion-col size="3" class="recap amount">
        {{report.recap.nbExtraForDistance}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="recap">
        {{translateDistanceText('pages.trips-reports.extra_distance_km')}}
      </ion-col>
      <ion-col size="3" class="recap amount">
        {{report.recap.nbExtraKmForDistance?.toFixed(2) | Distance: userSettings.distanceUnit:false:false}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="recap">
        {{'pages.trips-reports.urgent_orders' | translate}}
      </ion-col>
      <ion-col size="3" class="recap amount">
        {{report.recap.nbEmergencyOrders}}
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="100px">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="{{ 'messages.loading' | translate }}">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
