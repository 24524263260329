import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {StatusTabsRoutingModule} from '@app/pages/status-tabs/status-tabs.router.module';
import {StatusTabs} from '@app/pages/status-tabs/status-tabs.page';
import {PipesModule} from '@app/pipes/pipes.module';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: StatusTabs
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ComponentsModule,
    PipesModule,
    StatusTabsRoutingModule
  ],
  declarations: [StatusTabs]
})
export class StatusTabsModule {

}
