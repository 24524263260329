import { Injectable } from '@angular/core';
import { BuildInfo } from '@models/information/build-info.model';
import { NetworkStatus } from '@models/information/network-status.model';
import { Permissions } from '@models/information/permissions.model';
import { Platform } from '@ionic/angular';
import { JsonProperty } from 'json2typescript';
import { JsonObject } from 'json2typescript';
import * as moment from 'moment';
import { CapacitorPlugins } from '@app/services/capacitor-plugins/capacitor-plugins';
import { DeviceUtils } from '@services/utils/device-utils';

@JsonObject('DeviceInfo')
@Injectable()
export class DeviceInfo {

  @JsonProperty('firebaseToken', String, true)
  firebaseToken: string = undefined;

  batteryLevel: number;
  isPlugged: boolean;

  permissions: Permissions = new Permissions();

  @JsonProperty('networkStatus', NetworkStatus, true)
  networkStatus: NetworkStatus = undefined;

  // simInfo: object;

  @JsonProperty('preferredLanguage', String, true)
  preferredLanguage: string = undefined;

  appName: string;

  packageName: string;

  @JsonProperty('buildInfo', BuildInfo, true)
  buildInfo: BuildInfo = undefined;

  ionicVersion: string; // TODO
  nativeVersion: string;
  nativeVersionCode: string | number;
  osVersion: string;

  isDebug: boolean;
  isVirtual: boolean;
  manufacturer: string;
  model: string;
  platform: string;
  timeZone: string;

  _uuid: string;

  constructor(
    private capacitorPlugins: CapacitorPlugins,
    private deviceUtils: DeviceUtils,
    private ionicPlatform: Platform
  ) {
    this.init();
  }

  private async init() {
    this.networkStatus = new NetworkStatus();

    this.timeZone = moment.tz.guess();

    if (await this.deviceUtils.isRunningOnRealDevice() == true) {
      const devicePlugin = this.capacitorPlugins.getDevicePlugin();
      const appPlugin = this.capacitorPlugins.getAppPlugin();

      try {
        const deviceInfo = await devicePlugin.getInfo();
        const deviceId = await devicePlugin.getId();
        const appInfo = await appPlugin.getInfo();

        this.osVersion = deviceInfo.osVersion;
        this.nativeVersion = appInfo.version;
        this.nativeVersionCode = appInfo.version;
        this.isVirtual = deviceInfo.isVirtual;
        this.manufacturer = deviceInfo.manufacturer;
        this.model = deviceInfo.model;
        this.platform = deviceInfo.platform;
        this.uuid = deviceId.identifier;
        this.appName = 'Mobilus';
        this.packageName = 'ca.mobilus.mobile.hauler';

      } catch (error) {
        console.error('Unable to get device or/and app info.', error);
      }

      try {
        const deviceBatteryInfo = await devicePlugin.getBatteryInfo();
        this.batteryLevel = deviceBatteryInfo.batteryLevel;
        this.isPlugged = deviceBatteryInfo.isCharging;
      } catch (error) {
        console.error('Unable to get battery info.', error);
      }
    }

    console.log('platform ready,');
    if (this.ionicPlatform.is('desktop')) {
      console.log('platform is core! or something else');
    } else {
      console.log('platform is not core', window.location, window);
      if (this.ionicPlatform.is('android')) {
        console.log('on a device running Android.');
      }
      if (this.ionicPlatform.is('cordova')) {
        console.log('on a device running Cordova.');
      }
      if (this.ionicPlatform.is('desktop')) {
        console.log('on a desktop device.');
      }
      if (this.ionicPlatform.is('ios')) {
        console.log('on a device running iOS.');
      }
      if (this.ionicPlatform.is('ipad')) {
        console.log('on an iPad device.');
      }
      if (this.ionicPlatform.is('iphone')) {
        console.log('on an iPhone device.');
      }
      if (this.ionicPlatform.is('mobile')) {
        console.log('on a mobile device.');
      }
      if (this.ionicPlatform.is('mobileweb')) {
        console.log('in a browser on a mobile device.');
      }
      if (this.ionicPlatform.is('phablet')) {
        console.log('on a phablet device.');
      }
      if (this.ionicPlatform.is('tablet')) {
        console.log('on a tablet device.');
      }
    }
  }

  get unifiedVersion(): string {
    if (this.buildInfo && this.buildInfo.version) {
      return this.buildInfo.version;
    } else {
      return 'DEV_VERSION';
    }
  }

  get deviceDateTime(): string {
    return moment().format();
  }

  isDevice(): boolean {
    return this.ionicPlatform.is('cordova');
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(uuid: string) {
    this._uuid = uuid;
  }

  // phoneNumber(): string {
  //   if (this.simInfo) {
  //     return this.simInfo['phoneNumber'];
  //   } else {
  //     return null;
  //   }
  // }
  //
  // carrierName(): string {
  //   if (this.simInfo) {
  //     return this.simInfo['carrierName'];
  //   } else {
  //     return null;
  //   }
  // }
  //
  // countryCode(): string {
  //   if (this.simInfo) {
  //     return this.simInfo['countryCode'];
  //   } else {
  //     return null;
  //   }
  // }
  //
  // networkType(): string {
  //   if (!this.simInfo) {
  //     return null;
  //   }
  //   switch (this.simInfo['networkType']) {
  //     case 0: {
  //       return 'unknown';
  //     }
  //     case 1: {
  //       return 'GPRS';
  //     }
  //     case 2: {
  //       return 'EDGE';
  //     }
  //     case 3: {
  //       return 'UMTS';
  //     }
  //     case 4: {
  //       return 'CDMA';
  //     }
  //     case 5: {
  //       return 'EVDO rev 0';
  //     }
  //     case 6: {
  //       return 'EVDO rev A';
  //     }
  //     case 7: {
  //       return '1xRTT';
  //     }
  //     case 8: {
  //       return 'HSDPA';
  //     }
  //     case 9: {
  //       return 'HSUPA';
  //     }
  //     case 10: {
  //       return 'HSPA';
  //     }
  //     case 11: {
  //       return 'iDen';
  //     }
  //     case 12: {
  //       return 'EVDO rev B';
  //     }
  //     case 13: {
  //       return 'LTE';
  //     }
  //     case 14: {
  //       return 'eHRPD';
  //     }
  //     case 15: {
  //       return 'HSPA+';
  //     }
  //     case 16: {
  //       return 'GSM';
  //     }
  //     case 17: {
  //       return 'TD-SCDMA';
  //     }
  //     case 18: {
  //       return 'IWLAN';
  //     }
  //
  //     default: {
  //       return 'unknown';
  //     }
  //   }
  // }
}

