<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'pages.accounting-reports.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <ion-item (click)="onShowReport('pages.accounting-reports.payment_report')">
      <fa-icon [icon]="['fas', 'file-spreadsheet']" slot="start"></fa-icon>
      {{ 'pages.accounting-reports.payment_report' | translate }}
      <fa-icon [icon]="['fas', 'arrow-right']" slot="end"></fa-icon>
    </ion-item>
    <div *ngIf="isHaulerManager">
      <ion-item *ngFor="let hauler of report?.byHaulerEmployeeIDAndByPaymentTypes"
                (click)="onShowReport(hauler.key.reference, hauler.value)">
        <fa-icon [icon]="['fas', 'user']" slot="start"></fa-icon>
        {{ hauler.key.reference }}
        <fa-icon [icon]="['fas', 'arrow-right']" slot="end"></fa-icon>
      </ion-item>
    </div>
    <ion-item (click)="onShowReport('pages.accounting-reports.orders_delivered')">
      <fa-icon [icon]="['fas', 'check-circle']" slot="start"></fa-icon>
      {{ 'pages.accounting-reports.orders_delivered' | translate }}
      <fa-icon [icon]="['fas', 'arrow-right']" slot="end"></fa-icon>
    </ion-item>
    <ion-item (click)="onShowReport('pages.accounting-reports.orders_cancelled')">
      <fa-icon [icon]="['fas', 'times-circle']" slot="start"></fa-icon>
      {{ 'pages.accounting-reports.orders_cancelled' | translate }}
      <fa-icon [icon]="['fas', 'arrow-right']" slot="end"></fa-icon>
    </ion-item>
  </ion-list>
</ion-content>
