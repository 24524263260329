import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {DirectivesModule} from '@app/directives/directives.module';
import {PipesModule} from '@app/pipes/pipes.module';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {DiagnosticPage} from './diagnostic.page';
import {SamplePage} from '@app/components/sample/sample.page';

const routes: Routes = [
  {
    path: '',
    component: DiagnosticPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    IonicModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
  ],
  declarations: [
    DiagnosticPage,
    SamplePage
  ]
})
export class DiagnosticPageModule {
}
