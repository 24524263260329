import {OrderStatus} from '@models/order-helper/order-status.enum';
import {OrderStatusEnumConverter} from '@models/push-messages/converters.model';
import {ActiveFilter} from '@models/settings/active-filter.model';
import {SearchOptions} from '@models/settings/search-options.model';
import {JsonObject} from 'json2typescript';
import {JsonProperty} from 'json2typescript';

@JsonObject('StatusSettings')
export class StatusSettings {

  @JsonProperty('status', OrderStatusEnumConverter, true)
  status: OrderStatus = undefined;

  @JsonProperty('sortOrder', String, true)
  sortOrder = 'lastName';

  @JsonProperty('previousSortOrder', String, true)
  previousSortOrder = 'lastName';

  @JsonProperty('groupByProvider', Boolean, true)
  groupByProvider = true;

  activeFilter: ActiveFilter = new ActiveFilter();
  search: SearchOptions = new SearchOptions();

}
