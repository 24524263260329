import {Order} from '@models/business/order.model';
import {NumberStringConverter} from '@models/push-messages/converters.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('OrderMessage')
export class OrderMessage {

  @JsonProperty('id', NumberStringConverter)
  id: string = undefined;

  @JsonProperty('reference', String)
  reference: string = undefined;

  @JsonProperty('date', String)
  date: string = undefined;

  @JsonProperty('status', String)
  status: string = undefined;

  @JsonProperty('workflow', String)
  workflow: string = undefined;

  @JsonProperty('version', Number)
  version: number = undefined;

  @JsonProperty('actorId', Number, true)
  actorId: number = undefined;

  @JsonProperty('order', Order, true)
  order: Order = undefined;

  @JsonProperty('assigningProvider', Boolean, true)
  assigningProvider = false;

  isPertinent(haulerEmployeeId: number): boolean {
    if (this.assigningProvider) {
      return this.actorId !== null && this.actorId.toString() === haulerEmployeeId.toString();
    } else {
      return SUPPORTED_STATUS.indexOf(this.status) > -1
        || this.actorId === null || this.actorId.toString() === haulerEmployeeId.toString();
    }

  }
}

export const SUPPORTED_STATUS = [
  'READY_FOR_HAULAGE',
  'ON_THE_WAY_TO_PROVIDER',
  'ON_THE_WAY_TO_CUSTOMER'
];
