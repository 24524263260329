import {PositionTypeEnum} from '@models/information/position-type.enum';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {MQTTStatusEnum} from '@models/push-messages/mqtt-status-enums.model';
import {RemoteManagementCommandEnum} from '@models/push-messages/remote-management-command.enum.model';
import {MapOptionEnum, MapSearchOptionEnum} from '@models/settings/map-option.enum';
import {DateFormatEnum} from '@models/units-and-format/date-format.enum';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';
import {JsonCustomConvert} from 'json2typescript';
import {JsonConverter} from 'json2typescript';
import {ColorModeEnum} from '@models/settings/ColorModeEnum.model';

@JsonConverter
export class NumberStringConverter implements JsonCustomConvert<string> {
  serialize(numberValue: string): number {
    // eslint-disable-next-line radix
    return parseInt(numberValue);
  }

  deserialize(numberValue: number): string {
    return numberValue.toString();
  }
}

@JsonConverter
export class BooleanStringEnumConverter implements JsonCustomConvert<boolean> {
  serialize(booleanValue: boolean): any {
    return booleanValue ? 'true' : 'false';
  }

  deserialize(booleanValue: string): boolean {
    return booleanValue === 'true';
  }
}

@JsonConverter
export class RemoteManagementCommandEnumConverter implements JsonCustomConvert<RemoteManagementCommandEnum> {
  serialize(enumValue: RemoteManagementCommandEnum): any {
    return RemoteManagementCommandEnum[enumValue];
  }

  deserialize(enumValue: string): RemoteManagementCommandEnum {
    return RemoteManagementCommandEnum[enumValue.toUpperCase()];
  }
}

@JsonConverter
export class MQTTStatusEnumConverter implements JsonCustomConvert<MQTTStatusEnum> {
  serialize(enumValue: MQTTStatusEnum): string {
    return MQTTStatusEnum[enumValue];
  }

  deserialize(enumValue: string): MQTTStatusEnum {
    return MQTTStatusEnum[enumValue.toUpperCase()];
  }
}

@JsonConverter
export class PositionTypeEnumConverter implements JsonCustomConvert<PositionTypeEnum> {
  serialize(enumValue: PositionTypeEnum): string {
    return PositionTypeEnum[enumValue];
  }

  deserialize(enumValue: string): PositionTypeEnum {
    return PositionTypeEnum[enumValue.toUpperCase()];
  }
}

@JsonConverter
export class OrderStatusEnumConverter implements JsonCustomConvert<OrderStatus> {
  serialize(enumValue: OrderStatus): string {
    return OrderStatus[enumValue];
  }

  deserialize(enumValue: string): OrderStatus {
    return OrderStatus[enumValue.toUpperCase()];
  }
}

@JsonConverter
export class DateFormatEnumConverter implements JsonCustomConvert<DateFormatEnum> {
  serialize(data: DateFormatEnum): string {
    return DateFormatEnum[data.toString()];
  }

  deserialize(data: string): DateFormatEnum {
    return DateFormatEnum[data];
  }
}

@JsonConverter
export class DistanceSystemEnumConverter implements JsonCustomConvert<DistanceSystemEnum> {
  serialize(data: DistanceSystemEnum): string {
    return DistanceSystemEnum[data];
  }

  deserialize(data: string): DistanceSystemEnum {
    return DistanceSystemEnum[data.toUpperCase()];
  }
}

@JsonConverter
export class TimeFormatEnumConverter implements JsonCustomConvert<TimeFormatEnum> {
  serialize(data: TimeFormatEnum): string {
    return TimeFormatEnum[data];
  }

  deserialize(data: string): TimeFormatEnum {
    return TimeFormatEnum[data];
  }
}

@JsonConverter
export class MapOptionEnumConverter implements JsonCustomConvert<MapOptionEnum> {
  serialize(data: MapOptionEnum): string {
    return MapOptionEnum[data];
  }

  deserialize(data: string): MapOptionEnum {
    return MapOptionEnum[data.toUpperCase()];
  }
}

@JsonConverter
export class MapQueryOptionEnumConverter implements JsonCustomConvert<MapSearchOptionEnum> {
  serialize(data: MapSearchOptionEnum): string {
    return MapSearchOptionEnum[data];
  }

  deserialize(data: string): MapSearchOptionEnum {
    return MapSearchOptionEnum[data.toUpperCase()];
  }
}

@JsonConverter
export class ColorModeEnumColorModeEnumConverter implements JsonCustomConvert<ColorModeEnum> {
  serialize(data: ColorModeEnum): string {
    return ColorModeEnum[data];
  }

  deserialize(data: string): ColorModeEnum {
    return ColorModeEnum[data.toUpperCase()];
  }
}
