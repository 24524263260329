import {Order} from '@models/business/order.model';
import {Traceability} from '@models/business/traceability.model';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('TripReport')
export class TripReport {

  @JsonProperty('trips', Object)
  trips: Array<ReportAccountingItem> = undefined;
  @JsonProperty('recap', Object)
  recap: Trip = undefined;
}

interface Trip {
  nbEmergencyOrders: number;
  nbExtraForDistance: number;
  nbExtraKmForDistance: number;
  nbTrips: number;
  nbTripsWithoutJoined: number;
}

interface ReportAccountingItem {
  firstTakeInChargeByHaylerTraceability: Traceability;
  totalServiceTime: string;
  creationTraceability: Traceability;
  haulerEmployee: HaulerEmployee;
  order: Order;
  provider: string;
  traceability: Traceability;
}
