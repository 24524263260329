import { Injectable } from '@angular/core';
import * as moment from 'moment';

// MODELS
import { AppError, SortDirection, SortedPropertyType } from '@models/base/base';

// PROVIDERS
import { HttpErrorFormatter } from '@services/formatters/http-error-formatter';
import { HttpUtils } from '@services/utils/http-utils';

@Injectable({
    providedIn: 'root'
})
export class CommonUtils {

    private readonly MIN_MOMENT = moment('1970-01-01T00:00:00.000Z');

    constructor(
        private httpErrorFormatter: HttpErrorFormatter,
        private httpUtils: HttpUtils
    ) {
    }

    public promiseTimeout(ms: number, promise: Promise<any>): Promise<any> {

        // Create a promise that rejects in <ms> milliseconds
        const timeout = new Promise((_resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                const appError = new AppError(false, 'Operation timeout has occurred');
                reject(appError);
            }, ms);
        });

        // Returns a race between our timeout and the passed in promise
        return Promise.race([
            promise,
            timeout
        ]);

    }

    public wait = (ms: number) => new Promise(r => setTimeout(r, ms));

    public retryOperation(operation: () => Promise<any>, shouldRetryFn: (args?: any) => boolean, delay: number, times: number): Promise<any> {

        return new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
            return operation()
                .then(resolve)
                .catch((reason) => {
                    if (shouldRetryFn(reason) === true && times - 1 > 0) {
                        return this.wait(delay)
                            .then(() => this.retryOperation(operation, shouldRetryFn, delay, times - 1))
                            .then(resolve)
                            .catch(reject);
                    }
                    return reject(reason);
                });
        });

    }

    /**
     * Utility method to sort array element objects by the specific property 
     */
    public sortArrayByProperty<T>(data: T[], sortedPropertyName: string, sortedPropertyType?: SortedPropertyType, direction?: SortDirection): T[] {
        if (data && Array.isArray(data) && sortedPropertyName) {

            let directionOrder = -1; // asc by default
            if (direction && direction === SortDirection.DESC) {
                directionOrder = 1;
            }

            // sort as dates
            const sortDateTime = (a: T, b: T) => {
                const aPropertyAsMoment =
                    (a[sortedPropertyName] !== null && moment(a[sortedPropertyName], moment.ISO_8601).isValid()) ?
                        moment(a[sortedPropertyName], moment.ISO_8601) :
                        this.MIN_MOMENT;
                const bPropertyAsMoment =
                    (b[sortedPropertyName] !== null && moment(b[sortedPropertyName], moment.ISO_8601).isValid()) ?
                        moment(b[sortedPropertyName], moment.ISO_8601) :
                        this.MIN_MOMENT;

                if (aPropertyAsMoment.isBefore(bPropertyAsMoment)) {
                    return directionOrder;
                } else if (aPropertyAsMoment.isAfter(bPropertyAsMoment)) {
                    return -1 * directionOrder;
                } else {
                    return 0;
                }
            };
            const sortMoment = (a: T, b: T) => {
                const aPropertyAsMoment: moment.Moment = a[sortedPropertyName] != null ? a[sortedPropertyName] as moment.Moment : this.MIN_MOMENT;
                const bPropertyAsMoment: moment.Moment = b[sortedPropertyName] != null ? b[sortedPropertyName] as moment.Moment : this.MIN_MOMENT;

                if (aPropertyAsMoment.isBefore(bPropertyAsMoment)) {
                    return directionOrder;
                } else if (aPropertyAsMoment.isAfter(bPropertyAsMoment)) {
                    return -1 * directionOrder;
                } else {
                    return 0;
                }
            };
            // sorting boolean in ASC - true first; otherwise false first
            const sortBoolean = (a: T, b: T) => {
                // sort as boolean
                return (a[sortedPropertyName] === b[sortedPropertyName]) ? 0 : a[sortedPropertyName] ? directionOrder : -1 * directionOrder;
            };
            // sort as strings without taking case into account
            const sortStringCaseInsensitive = (a: T, b: T) => {
                const aPropertyValue = (a[sortedPropertyName] !== null && (typeof a[sortedPropertyName] === 'string' || a[sortedPropertyName] instanceof String)) ?
                    (a[sortedPropertyName] as string).toLocaleLowerCase() : a[sortedPropertyName];
                const bPropertyValue = (b[sortedPropertyName] !== null && (typeof b[sortedPropertyName] === 'string' || b[sortedPropertyName] instanceof String)) ?
                    (b[sortedPropertyName] as string).toLocaleLowerCase() : b[sortedPropertyName];

                if (aPropertyValue < bPropertyValue) {
                    return directionOrder;
                } else if (aPropertyValue > bPropertyValue) {
                    return -1 * directionOrder;
                } else {
                    return 0;
                }
            };
            // default sort as strings or number
            const sortDefault = (a: T, b: T) => {
                if (a[sortedPropertyName] < b[sortedPropertyName]) {
                    return directionOrder;
                } else if (a[sortedPropertyName] > b[sortedPropertyName]) {
                    return -1 * directionOrder;
                } else {
                    return 0;
                }
            };

            if (sortedPropertyType === SortedPropertyType.DATETIME) {
                return data.sort(sortDateTime);
            } if (sortedPropertyType === SortedPropertyType.MOMENT) {
                return data.sort(sortMoment);
            } else if (sortedPropertyType === SortedPropertyType.BOOLEAN) {
                return data.sort(sortBoolean);
            } else if (sortedPropertyType === SortedPropertyType.STRING_CASE_INSENSITIVE) {
                return data.sort(sortStringCaseInsensitive);
            } else {
                return data.sort(sortDefault);
            }
        } else {
            return data;
        }
    }

    /**
     * Method to check if the object is generic javascript error (please also see https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js)
     *
     * @param {Object} object An object to check if this is a generic javascript error
     * @return {boolean} Returns true if this is a generic javascript error, otherwise false
     */
    public isJSError(object: any): boolean {
        if (object) {
            return object instanceof Error;
        }
        return false;
    }

    /**
     * Method to stringify object containing circular references
     * Another implementation - see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
     *
     * @param {Object} object An object to check if this is a generic javascript error
     * @return {boolean} Returns true if this is a generic javascript error, otherwise false
     */
    public safeStringify(obj: any, indent = 2): string {
        if (this.isJSError(obj) === true) {
            // NOTE: JS Error object has no enumerable props so when stringified it returns empty object
            // see https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
            const plainObject = {};
            Object.getOwnPropertyNames(obj).forEach((key) => {
                plainObject[key] = obj[key];
            });
            obj = plainObject;
        }
        let cache = [];
        const retVal = JSON.stringify(
            obj,
            (key, value) =>
                typeof value === 'object' && value !== null
                    ? cache.includes(value)
                        ? undefined // Duplicate reference found, discard key
                        : cache.push(value) && value // Store value in our collection
                    : value,
            indent
        );
        cache = null;
        return retVal;
    }

    /**
     * Method to generate javascript object hash
     * @param {Object} object An object to generate hash from
     * @return {number} Returns object hash code as a number
     * 
     * see https://stackoverflow.com/a/7616484/5151355
     */
    public hashCode(object: object): number {
        const stringifiedObject = this.safeStringify(object);
        let hash = 0, i: number, chr: number;
        if (stringifiedObject.length === 0) return hash;
        for (i = 0; i < stringifiedObject.length; i++) {
            chr = stringifiedObject.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // NOTE: Convert to 32bit integer
        }
        return hash;
    };
  
    /**
     * Method to convert any error to AppError object
     */
    public convertToAppError(error: any, defaultErrorMessage?: string): AppError {
        if (error) {
            if (error instanceof AppError) {
                return error;
            } else if (this.isJSError(error) === true) {
                const errorMessage = defaultErrorMessage || `Unexpected error has occurred. Error Details: ${this.safeStringify(error)}`;
                return new AppError(false, errorMessage);
            } else if (this.httpUtils.isHttpError(error)) {
                const errorMessage = defaultErrorMessage || this.httpErrorFormatter.getFriendlyMessage(error);
                return new AppError(false, errorMessage);
            } else if (typeof error === 'string') {
                return new AppError(false, error);
            } else {
                const errorMessage = defaultErrorMessage || `Unexpected error has occurred. Error Details: ${this.safeStringify(error)}`;
                return new AppError(false, errorMessage);
            }
        }
        return new AppError(false, 'Unexpected error has occurred. Please try again.');
    }

}

