import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {OrderManifest} from '@models/business/order-manifest.model';
import {Position} from '@models/business/position.model';
import {TransitionData} from '@models/business/transition-data.model';
import {Picture} from '@models/business/transition-data.model';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('HttpTransitionRequest')
export class HttpTransitionRequest {

  @JsonProperty('orderId', String)
  orderId: string = undefined;

  @JsonProperty('event', String)
  event: string = undefined;

  @JsonProperty('transitionDate', MomentDateConverter, true)
  transitionDate: moment.Moment = undefined;

  @JsonProperty('assignToSecUser', Boolean)
  assignToSecUser: boolean = undefined;

  @JsonProperty('userMessage', String, true)
  userMessage: string = undefined;

  @JsonProperty('selectedReason', String, true)
  selectedReason: string = undefined;

  @JsonProperty('userReason', String, true)
  userReason: string = undefined;

  @JsonProperty('paymentType', String, true)
  paymentType: string = undefined;

  @JsonProperty('paymentMessage', String, true)
  paymentMessage: string = undefined;

  @JsonProperty('collectedAmount', String, true)
  collectedAmount: string = undefined;

  @JsonProperty('signature', String, true)
  signature: string = undefined;

  @JsonProperty('haulerChoice', String, true)
  haulerChoice: string = undefined;

  @JsonProperty('impersonate', Boolean, true)
  impersonate = false;

  @JsonProperty('prescriptionPictures', [Picture], true)
  prescriptionPictures: Array<Picture> = [];

  @JsonProperty('sequence', Number, true)
  sequence: number = undefined;

  @JsonProperty('position', Position, true)
  position: Position = undefined;

  @JsonProperty('deliveryRecipient', DeliveryRecipient, true)
  deliveryRecipient: DeliveryRecipient = undefined;

  @JsonProperty('manifest', OrderManifest, true)
  manifest: OrderManifest = undefined;

  constructor(orderId: string, event: string, transitionDate: moment.Moment, transitionData: TransitionData, sequenceNumber?: number, position?: Position) {
    this.orderId = orderId;
    this.event = event;
    this.assignToSecUser = true;
    this.transitionDate = transitionDate;
    this.position = position;
    if (sequenceNumber && sequenceNumber > 0) {
      this.sequence = sequenceNumber;
    }
    if (transitionData) {
      this.selectedReason = transitionData.selectedReason;
      this.userReason = transitionData.reason;
      this.userMessage = transitionData.comment;

      if (transitionData.payments && transitionData.payments.length > 0) {
        // FIXME when we support multiple payments
        const payment = transitionData.payments[0];
        this.paymentType = payment.type;
        this.collectedAmount = payment.collectedAmount.toString();
        this.paymentMessage = transitionData.unbalancedPaymentComment;
      }

      if (transitionData.signatureImage) {
        this.signature = transitionData.signatureImage;
      }

      if (transitionData.pictures) {
        this.prescriptionPictures = transitionData.pictures;
        // Prevent sending base64 pictures as they are already uploaded to S3
        if (this.prescriptionPictures) {
          for (const prescriptionPicture of this.prescriptionPictures) {
            prescriptionPicture.base64String = undefined;
          }
        }
      }

      if (transitionData.haulerChoice) {
        this.assignToSecUser = false;
        this.haulerChoice = transitionData.haulerChoice;
      }

      if (transitionData.impersonate) {
        this.assignToSecUser = false;
        this.impersonate = true;
      }

      if (transitionData.deliveryRecipient) {
        this.deliveryRecipient = transitionData.deliveryRecipient;
      }

      if (transitionData.manifest) {
        this.manifest = transitionData.manifest;
      }

    }
  }

}
