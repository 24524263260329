import {NotificationData} from '@models/push-messages/notification-data.model';
import {OrderMessage} from '@models/push-messages/order-message.model';
import {RemoteManagementCommandData} from '@models/push-messages/remote-management-command.data.model';
import * as moment from 'moment';

export class PushMessage {
  notificationData: NotificationData;
  orderData: OrderMessage;
  remoteManagementCommandData: RemoteManagementCommandData;
  timestamp: moment.Moment = undefined;
}
