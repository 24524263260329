import {TraceabilityActor} from '@models/business/traceability-actor.model';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('Traceability')
export class Traceability {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('dateTime', MomentDateConverter)
  dateTime: moment.Moment = undefined;

  @JsonProperty('actor', TraceabilityActor)
  actor: TraceabilityActor = undefined;

  @JsonProperty('event', String)
  event: string = undefined;

  @JsonProperty('sourceState', String)
  sourceState: string = undefined;

  @JsonProperty('targetState', String)
  targetState: string = undefined;

  @JsonProperty('message', String, true)
  message: string = undefined;

  @JsonProperty('unbalancedPaymentComment', String, true)
  unbalancedPaymentComment: string = undefined;

  @JsonProperty('operatorCode', String, true)
  operatorCode: string = undefined;

}
