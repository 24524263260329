export class CheckableItem {

  id: number;

  label: string;

  checked: boolean;

  constructor(id: number, name: string) {
    this.id = id;
    this.label = name;
    this.checked = true;
  }
}
