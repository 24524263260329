import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

// NATIVE
import BackgroundGeolocation, { Config as GeolocationConfigForNative, CurrentPositionRequest, Location, LocationAccuracy, State } from '@transistorsoft/capacitor-background-geolocation';

// MODELS
import { AppError } from '@models/base/base';
import { DeviceInfo } from '@models/information/device-info.model';
import { GeolocationConfigBase, GeolocationConfigForPWA } from '@models/configuration/background-geolocation.model';
import { Position } from '@models/business/position.model';

// PROVIDERS
import { DeviceUtils } from '@services/utils/device-utils';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { GeolocationUtils } from '@services/utils/geolocation-utils';
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

export class NativeBackgroundGeolocationService extends BackgroundGeolocationService {

  private providerEnabled = false;
  // NOTE: getCurrentPosition options
  private readonly currentPositionRequest: CurrentPositionRequest = {
    timeout: 30,          // 30 second timeout to fetch location
    maximumAge: 5000     // Accept the last-known-location if not older than 5000 ms.
  }

  constructor(
    capacitorPlugins: CapacitorPlugins,
    deviceInfo: DeviceInfo,
    deviceUtils: DeviceUtils,
    endpointService: EndpointService,
    geolocationUtils: GeolocationUtils,
    mobileContextService: MobileContextService,
    logService: LogService,
    platform: Platform,
    translate: TranslateService
  ) {
    super(capacitorPlugins, deviceInfo, deviceUtils, endpointService, geolocationUtils, mobileContextService, logService, platform, translate);
    this.init();
  }

  /**
   * Method to retrieve the current Location.
   *
   * The position will also be sent to onLocation callback
   * See https://transistorsoft.github.io/cordova-background-geolocation-lt/classes/backgroundgeolocation.html#getcurrentposition
   */
  public async recheckPosition(): Promise<Position | undefined> {
    if (!BackgroundGeolocation) {
      return;
    }

    try {
      const state: State = await BackgroundGeolocation.getState();
      if (state.enabled === true) {
        const currentLocation: Location = await BackgroundGeolocation.getCurrentPosition(this.currentPositionRequest);
        if (currentLocation.coords) {
            const currentPosition = new Position(currentLocation.coords.latitude, currentLocation.coords.longitude, currentLocation.coords.accuracy, moment(currentLocation.timestamp));
            return currentPosition;
        } else {
            this.logService.info('[NativeBackgroundGeolocationService] recheckPosition - unable to extract position from the current location', currentLocation);
        }
      }
    } catch (e) {
      // NOTE: ignore this error
    }
  }

  protected async init(): Promise<void> {
    await super.init();

    const info = await this.deviceUtils.getDeviceInfo();
    // NOTE: En mode émulation, récupérer la position à intervalles réguliers
    if (info && info.isVirtual) {
      setInterval(async () => {
        try {
          const state: State = await BackgroundGeolocation.getState();
          if (state.enabled === true) {
            BackgroundGeolocation.getCurrentPosition(this.currentPositionRequest)
              .then(() => {
                // position will also be sent to onLocation callback
              });
          }
        } catch (e) {
        }
      }, 5 * 1000);
    }

  }
  protected async onConfigAvailable(_backgroundGeolocationConfig: GeolocationConfigForNative | GeolocationConfigForPWA): Promise<void> {
  }

  protected async startingGeo(): Promise<void> {

    this.logService.info('[NativeBackgroundGeolocationService] startingGeo - starting background geo location plugin');

    if (!this.backgroundGeolocationConfig) {
      throw new AppError(false, 'Internal error. Background geolocation config is missing');
    }

    if (!BackgroundGeolocation || this.serviceIsRunning !== false) {
      return;
    }

    BackgroundGeolocation.onLocation(location => {
      this.logService.debug('[NativeBackgroundGeolocationService] onLocation callback');
      this.logService.trace('[NativeBackgroundGeolocationService] onLocation - Location: ', JSON.stringify(location));
      this.extractAndSend(location);
    }, (errorCode) => {
      // NOTE: This callback will be executed if a location-error occurs.  Eg: this will be called if user disables location-services.
      this.logService.error('[NativeBackgroundGeolocationService] onLocation - error: ', errorCode);
    });

    BackgroundGeolocation.onMotionChange((isMoving) => {
      this.logService.debug('[NativeBackgroundGeolocationService] onMotionChange: ', isMoving);
    });

    BackgroundGeolocation.onActivityChange((event) => {
      this.logService.debug('[NativeBackgroundGeolocationService] onActivityChange: ', event);
      if (!this.providerEnabled && event.activity !== 'still') {
        this.logService.debug('[NativeBackgroundGeolocationService] onActivityChange - resetting last position value to null');
        this._positionSubject.next(null);
      }
    });

    BackgroundGeolocation.onProviderChange((something) => {
      this.logService.debug('[NativeBackgroundGeolocationService] onProviderChange callback', something);
      this.providerEnabled = something.enabled;
    });

    BackgroundGeolocation.onGeofence((geofence) => {
      this.logService.debug('[NativeBackgroundGeolocationService] onGeofence: ', geofence.identifier, geofence.location);
    });

    BackgroundGeolocation.onHttp((response) => {
      if (response.success) {
        this.logService.trace('[NativeBackgroundGeolocationService] onHttp - http success: ', response.responseText);
      } else {
        this.logService.trace('[NativeBackgroundGeolocationService] onHttp - http failure: ', response.status);
      }
    });

    // NOTE: We must call #ready once and only once, each time the app is launched.
    // see - https://transistorsoft.github.io/capacitor-background-geolocation/classes/backgroundgeolocation.html#%E2%9A%A0%EF%B8%8F-warning-you-must-call-ready-once-and-only-once-each-time-your-app-is-launched
    const state = await BackgroundGeolocation.ready(this.backgroundGeolocationConfig as GeolocationConfigForNative);
    // NOTE: This callback is executed when the plugin is ready to use.
    this.logService.info('[NativeBackgroundGeolocationService] is configured and ready to use');
    if (!state.enabled) {
      BackgroundGeolocation.start(() => {
        this.serviceIsRunning = true;
        this.logService.info('[NativeBackgroundGeolocationService] tracking started');
      });
    }
  }

  protected stop() {
    if (!BackgroundGeolocation) {
      return;
    }

    this.logService.info('[NativeBackgroundGeolocationService] stop - stopping background geo location plugin');
    if (this.serviceIsRunning === true) {
      BackgroundGeolocation.stop();
      this.serviceIsRunning = false;
    }
    this._positionSubject.next(null);
  }

  protected createConfig(geotrackingEnabled: boolean): GeolocationConfigForNative {
    // NOTE: One of the below level for the desiredAccuracy is enabled
    // DESIRED_ACCURACY_HIGH --> GPS + Wifi + Cellular | Highest power; highest accuracy.
    // DESIRED_ACCURACY_LOW --> Wifi (low power) + Cellular | Lower power; No GPS. 
    const desiredAccuracy: LocationAccuracy = geotrackingEnabled ?  BackgroundGeolocation.DESIRED_ACCURACY_HIGH : BackgroundGeolocation.DESIRED_ACCURACY_LOW;
    const geolocationConfigBase: GeolocationConfigBase = this.createGeolocationBaseConfig(geotrackingEnabled);
    const geolocationOnlyNativeConfigParams: GeolocationConfigForNative = {
      reset: true,  // <-- true to always apply the supplied config
      desiredAccuracy: desiredAccuracy,
      stationaryRadius: 25,
      // Activity Recognition config
      activityRecognitionInterval: 10 * 1000,
      // Application config
      stopOnTerminate: true,
      startOnBoot: false,
      locationTemplate: '{ "latitude":<%= latitude %>, "longitude":<%= longitude %>, "accuracy":<%= accuracy %>, "heading":<%= heading %>, "speed":<%= speed %>, "altitude":<%= altitude %>, "altitude_accuracy":<%= altitude_accuracy %>, "timestamp":"<%= timestamp %>", "uuid":"<%= uuid %>", "event":"<%= event %>", "odometer":<%= odometer %>, "activityType":"<%= activity.type %>", "activityConfidence":<%= activity.confidence %>, "battery.level":<%= battery.level %>, "batteryIsCharging":<%= battery.is_charging %> }',
      foregroundService: true,
      notification: {
        title: this.translate.instant('geotrackingPlugin.notificationTitle'), // <-- android only, customize the title of the notification
        text: this.translate.instant('geotrackingPlugin.notificationText'), // <-- android only, customize the text of the notification
        smallIcon: 'drawable/notification_icon',
        priority: 1,
        sticky: true
      },
      logLevel: this.deviceInfo.isDebug ? 5 : 1,
      logMaxDays: 2, // <-- days of logs
      disableLocationAuthorizationAlert: true,
      locationAuthorizationRequest: 'Always',
      backgroundPermissionRationale: {
        title: this.translate.instant('geotrackingPlugin.permission.title'),
        message: this.translate.instant('geotrackingPlugin.permission.message'),
        positiveAction: this.translate.instant('geotrackingPlugin.permission.positiveAction'),
        negativeAction: this.translate.instant('geotrackingPlugin.permission.negativeAction')
      },
      useSignificantChangesOnly: false
    };
    const geolocationConfigForNative: GeolocationConfigForNative = { ...geolocationConfigBase, ...geolocationOnlyNativeConfigParams };
    return geolocationConfigForNative;
  };

}
