import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {UserLocationAdderPage} from '@app/pages/setting/user-location-adder/user-location-adder.page';
import {IonicModule} from '@ionic/angular';

const routes: Routes = [
  {
    path: '',
    component: UserLocationAdderPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule
  ],
  declarations: [UserLocationAdderPage]
})
export class UserLocationAdderPageModule {
}
