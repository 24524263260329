import {Injectable} from '@angular/core';
import {LogService} from '@services/log/log.service';
import {TranslateService} from '@ngx-translate/core';

declare const Fingerprint: any;

// Plugin Errors
export enum BIOMETRIC_CONFIG {
  STORAGE_USE_BIOMETRIC_AUTH_KEY = 'USE_BIOMETRIC_AUTH',
  BIOMETRIC_STORAGE_LOGIN_KEY = 'login'
}

// Plugin Errors
export enum BIOMETRIC_ERRORS {

  /**
   * Convenience constant
   */
  BIOMETRIC_UNKNOWN_ERROR = -100,
  /**
   * Convenience constant
   */
  BIOMETRIC_UNAVAILABLE = -101,
  /**
   * Convenience constant
   */
  BIOMETRIC_AUTHENTICATION_FAILED = -102,
  /**
   * Convenience constant
   */
  BIOMETRIC_SDK_NOT_SUPPORTED = -103,
  /**
   * Convenience constant
   */
  BIOMETRIC_HARDWARE_NOT_SUPPORTED = -104,
  /**
   * Convenience constant
   */
  BIOMETRIC_PERMISSION_NOT_GRANTED = -105,
  /**
   * Convenience constant
   */
  BIOMETRIC_NOT_ENROLLED = -106,
  /**
   * Convenience constant
   */
  BIOMETRIC_INTERNAL_PLUGIN_ERROR = -107,
  /**
   * Convenience constant
   */
  BIOMETRIC_DISMISSED = -108,
  /**
   * Convenience constant
   */
  BIOMETRIC_PIN_OR_PATTERN_DISMISSED = -109,
  /**
   * Convenience constant
   */
  BIOMETRIC_SCREEN_GUARD_UNSECURED = -110,
  /**
   * Convenience constant
   */
  BIOMETRIC_LOCKED_OUT = -111,
  /**
   * Convenience constant
   */
  BIOMETRIC_LOCKED_OUT_PERMANENT = -112,

}

// Biometric types
export enum BIOMETRIC_TYPES {
  FINGERPRINT = 'finger',
  FACE = 'face',
  COMMON = 'biometric'
}

// See https://github.com/niklasmerz/cordova-plugin-fingerprint-aio#optional-parameters-1
export interface FingerprintOptions {
  /**
   * Title in biometric prompt (android only)
   *
   * @default {APP_NAME} Biometric Sign On
   */
  title?: string;

  /**
   * Subtitle in biometric Prompt (android only)
   *
   * @default null
   */
  subtitle?: string;

  /**
   * Description in biometric Prompt
   *
   * @default null
   */
  description?: string;

  /**
   * Title of fallback button.
   *
   * @default
   * When disableBackup is true
   * - "Cancel"
   * When disableBackup is false
   * - iOS: "Use PIN"
   * - Android: "Use Backup"
   */
  fallbackButtonTitle?: string;

  /**
   * Title for cancel button on Android
   *
   * @default "Cancel"
   */
  cancelButtonTitle?: string;

  /**
   * If true remove backup option on authentication dialogue. This is useful if you want to implement your own fallback.
   *
   * @default false
   */
  disableBackup?: boolean;

  /**
   * (Android): If false user confirmation is NOT required after a biometric has been authenticated.
   *
   * @default true.
   * See https://developer.android.com/training/sign-in/biometric-auth#no-explicit-user-action
   */
  confirmationRequired?: boolean;

}

export interface FingerprintRegisterSecretOptions {

  title?: string;

  description?: string;

  cancelButtonTitle?: string;

  /**
   * TODO
   */
  secret?: string;


  /**
   * TODO
   */
  invalidateOnEnrollment: boolean;

  /**
   * TODO
   */
  disableBackup: boolean;

}

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  constructor(
    private log: LogService,
    private translateService: TranslateService,
  ) {
  }

  public isAvailable(): Promise<string> {
    return new Promise((resolve: (result: string) => void, reject: (reason: { code: BIOMETRIC_ERRORS; message: string }) => void) => {
      Fingerprint.isAvailable(
        (result: string) => {
          /*
              result depends on device and os.
              iPhone X will return 'face', other Android or iOS devices will return 'finger'
          */
          if (result === BIOMETRIC_TYPES.FINGERPRINT || result === BIOMETRIC_TYPES.FACE || result === BIOMETRIC_TYPES.COMMON) {
            resolve(result);
          } else {
            this.log.error('[FingerPrint] isAvailable - Unknown biometry type', result);
            reject({code: null, message: `Unknown biometry type: ${result}`});
          }
        }, (error: { code: BIOMETRIC_ERRORS; message: string }) => {
          // 'error' will be an object with an error code and message
          this.log.error('[FingerPrint] isAvailable - error occurred', error);
          reject(error);
        }
      );
    });
  }

  public show(): Promise<void> {
    return new Promise(async (resolve: () => void, reject: (reason: { code: BIOMETRIC_ERRORS; message: string }) => void) => {
      const options: FingerprintOptions = {
        title: this.translateService.instant('pages.biometric.show.title'),
        description: this.translateService.instant('pages.biometric.show.description'),
        disableBackup: true,
        cancelButtonTitle: this.translateService.instant('actions.cancel'),
        confirmationRequired: false
      };
      Fingerprint.show(options,
        () => {
          resolve();
        },
        (error: { code: BIOMETRIC_ERRORS; message: string }) => {
          // 'error' will be an object with an error code and message
          this.log.error('[FingerPrint] show - error occurred', error);
          reject(error);
        }
      );
    });

  }

  registerSecret(secret: string, invalidateOnEnrollment: boolean = true, disableBackup: boolean = true) {
    return new Promise((resolve: (success: boolean) => void, reject: (reason: { code: BIOMETRIC_ERRORS; message: string }) => void) => {
      const options: FingerprintRegisterSecretOptions = {
        title: this.translateService.instant('pages.biometric.show.title'),
        description: this.translateService.instant('pages.biometric.show.description'),
        secret: secret,
        cancelButtonTitle: this.translateService.instant('actions.cancel'),
        invalidateOnEnrollment: invalidateOnEnrollment,
        disableBackup: disableBackup,
      };
      Fingerprint.registerBiometricSecret(options,
        () => {
          console.log('success registerBiometricSecret');
          resolve(true);
        }, (error: any) => {
          console.error('cannot registerBiometricSecret', error);
          reject(error);
        }
      );
    });
  }

  loadSecret() {
    return new Promise((resolve: (secret: string) => void, reject: (reason: { code: BIOMETRIC_ERRORS; message: string }) => void) => {
      Fingerprint.loadBiometricSecret({
        description: 'Some biometric description',
        disableBackup: true, // always disabled on Android
      }, (secret: string) => {
        console.log('success loadSecret', secret);
        resolve(secret);
      }, (error: any) => {
        console.error('cannot loadSecret', error);
        reject(error);
      });
    });
  }

}
