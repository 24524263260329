import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';


@Component({
  templateUrl: './remote-command-message-modal.page.html',
  styleUrls: ['./remote-command-message-modal.page.scss'],
})
export class RemoteCommandMessageModalPage {

  title: string;
  body: string;

  constructor(public navParams: NavParams,
              private modalCtrl: ModalController,
  ) {
    this.title = this.navParams.get('title');
    this.body = this.navParams.get('body');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}
