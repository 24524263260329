<ion-header>
  <ion-toolbar>
    <ion-title><span class="ion-text-wrap" translate="pages.biometric-opt-in.title"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>

    <img src="assets/images/biometric/fingerprint1.png" class="biometric-method-image" *ngIf="availableMethod === biometricMethodEnum.FINGERPRINT && !optinSuccessfull">

    <img src="assets/images/biometric/face4.png" class="biometric-method-image" *ngIf="availableMethod === biometricMethodEnum.FACE && !optinSuccessfull">

    <ion-item *ngIf="!optinSuccessfull">
      <span class="centered-message">{{'pages.biometric-opt-in.method.' + availableMethod | translate}}</span>
    </ion-item>

    <ion-icon name="checkmark-circle-outline" class="biometric-opt-in-successfull" *ngIf="optinSuccessfull"></ion-icon>

    <ion-item *ngIf="optinSuccessfull">
      <span class="centered-message">{{'pages.biometric-opt-in.success' | translate}}</span>
    </ion-item>

    <form [formGroup]="loginForm" (submit)="optinWithLogin()" (keyup.enter)="optin()" *ngIf="!credentials && !optinSuccessfull">

      <ion-list class="login-form">
        <ion-item
          [class.invalid]="!loginForm.controls.username.valid && (loginForm.controls.username.dirty || submitAttempt)">
          <ion-input formControlName="username" required type="text" autocorrect="off" autocapitalize="none"
                     autocomplete="on"
                     placeholder="{{ 'pages.login.username' | translate }}"></ion-input>
        </ion-item>

        <ion-item *ngIf="!loginForm.controls.username.valid  && (loginForm.controls.username.dirty || submitAttempt)">
          <p color="danger">{{ 'pages.login.validation.username.required' | translate }}</p>
        </ion-item>

        <ion-item
          [class.invalid]="!loginForm.controls.password.valid && (loginForm.controls.password.dirty || submitAttempt)">
          <ion-input formControlName="password" type="password"
                     placeholder="{{ 'pages.login.password' | translate }}" clearOnEdit="false"></ion-input>
        </ion-item>

        <ion-item *ngIf="!loginForm.controls.password.valid  && (loginForm.controls.password.dirty || submitAttempt)">
          <p>{{ 'pages.login.validation.password.required' | translate }}</p>
        </ion-item>

        <ion-item *ngIf="errorMessage" class="invalid error-message" lines="none">
          <div>{{ errorMessage }}</div>
        </ion-item>

        <ion-button fill="solid" color="primary" type="submit" expand="full" class="login-button"
                    [disabled]="!loginForm.valid || loginInProgress">
          <span translate="pages.biometric-opt-in.activate"></span>
        </ion-button>

      </ion-list>

    </form>

  </ion-list>

  <ion-button fill="solid" expand="full" color="primary" *ngIf="!optinSuccessfull && credentials" (click)="optin()"><span translate="pages.biometric-opt-in.activate"></span></ion-button>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-checkbox labelPlacement="end" justify="start" class="footer-checkbox" [(ngModel)]="stopShowing"
      *ngIf="credentials && !optinSuccessfull">
      <span translate="pages.biometric-opt-in.stop-showing"></span>
    </ion-checkbox>
    <ion-button slot="end" (click)="close()"><span translate="actions.close"></span></ion-button>
  </ion-toolbar>
</ion-footer>
