import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('PhoneNumber')
export class PhoneNumber {

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('number', String)
  number: string = undefined;
}
