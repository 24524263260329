import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { exhaustMapWithTrailing } from 'rxjs-exhaustmap-with-trailing';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

// MODELS
import { AppError, SortDirection, SortedPropertyType } from '@models/base/base';
import { PositionOptions, Position as GeolocationPosition } from '@capacitor/geolocation';
import { DeviceInfo } from '@models/information/device-info.model';
import { GeolocationConfigBase, GeolocationConfigForPWA } from '@models/configuration/background-geolocation.model';
import { Config as GeolocationConfigForNative } from '@transistorsoft/capacitor-background-geolocation';
import { Position } from '@models/business/position.model';

// PROVIDERS
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';
import { GeolocationUtils } from '@services/utils/geolocation-utils';
import { GeoUtils } from '@services/utils/geo-utils';
import { HttpErrorFormatter } from '@services/formatters/http-error-formatter';
import { HttpUtils } from '@services/utils/http-utils';
import { CommonUtils } from '@services/utils/common-utils';
import { DeviceUtils } from '@services/utils/device-utils';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { PersistentStorage } from '@services/persistent-storage/persistent-storage';

export class PWABackgroundGeolocationService extends BackgroundGeolocationService {

    private readonly INTERVAL_TO_CHECK_GEOLOCATION_ITEMS_AGE = 3600000;

    private watchPositionSubscription: Subscription;

    // NOTE: Observable sources
    private uploadGeolocationPositionAnnouncedSource = new Subject<void>();
    // NOTE: Observable streams
    private uploadGeolocationPositionAnnounced$ = this.uploadGeolocationPositionAnnouncedSource.asObservable();

    constructor(
        capacitorPlugins: CapacitorPlugins,
        private commonUtils: CommonUtils,
        deviceInfo: DeviceInfo,
        deviceUtils: DeviceUtils,
        endpointService: EndpointService,
        geolocationUtils: GeolocationUtils,
        private geoUtils: GeoUtils,
        private http: HttpClient,
        private httpErrorFormatter: HttpErrorFormatter,
        private httpUtils: HttpUtils,
        mobileContextService: MobileContextService,
        logService: LogService,
        private persistentStorage: PersistentStorage,
        platform: Platform,
        translate: TranslateService
    ) {
        super(capacitorPlugins, deviceInfo, deviceUtils, endpointService, geolocationUtils, mobileContextService, logService, platform, translate);

        this.watchPositionSubscription = null;
        this.init();
    }

    public async recheckPosition(): Promise<Position | undefined> {
        try {
            const positionOptions: PositionOptions = { maximumAge: 0, timeout: 5000, enableHighAccuracy: true };
            const position: GeolocationPosition = await this.geolocationUtils.getCurrentLocation(true, positionOptions);

            if(!position?.coords) {
                return;
            }

            this.extractAndSend(position);
            await this.storeGeolocationPositionAndTriggerUploadIfRequired(position);

            const currentPosition: Position = new Position(position.coords.latitude, position.coords.longitude, position.coords.accuracy, moment(position.timestamp));
            return currentPosition;

        } catch (error) {
            // NOTE: ignore the error
        }
    }

    protected async init(): Promise<void> {
        await super.init();
        // NOTE: below is to support uploading locations to the server. Since this is itself is a complex process
        // (might require to upload multiple batches sequentially) which takes some time and during the upload execution
        // no additional upload actions should be executed BUT need to make sure that if another upload is queued
        // during upload execution we need to trigger upload again when the current one is finished
        // The below is like exhaustMap (ignoring new uploads while the current is being executed) and concat the last event
        // See https://github.com/ReactiveX/rxjs/issues/1777
        this.uploadGeolocationPositionAnnounced$
            .pipe(
                // QUESTION: skip upload if the connection is not available?
                // filter(_ => this.connectionStatusService.getLastKnownConnectionStatus().isNetworkConnected === true),
                exhaustMapWithTrailing(_ => this.uploadGeolocationPositions())
            ).subscribe();

    }
    protected async onConfigAvailable(backgroundGeolocationConfig: GeolocationConfigForNative | GeolocationConfigForPWA): Promise<void> {
        await this.watchAndRemoveExpiredGeolocationPositions(backgroundGeolocationConfig as GeolocationConfigForPWA);
    }

    /**
     * Method to start background geolocation in PWA mode
     */
    protected async startingGeo(): Promise<void> {

        if (!this.backgroundGeolocationConfig) {
            throw new AppError(false, 'Internal error. Background geolocation config is missing');
        }

        if (this.serviceIsRunning !== false) {
            return;
        }

        const geolocationConfigForPWA: GeolocationConfigForPWA = (this.backgroundGeolocationConfig as GeolocationConfigForPWA);
        this.watchPositionSubscription = this.geolocationUtils.watchPosition(geolocationConfigForPWA.geolocationOptions)
            .subscribe(async (geolocationPosition: GeolocationPosition) => {
                const lastKnownPosition = this.getLastKnownPosition();

                if (lastKnownPosition) {
                    if (moment(geolocationPosition.timestamp).isSameOrBefore(lastKnownPosition.dateEmitted)) return;
                    if (geolocationConfigForPWA.distanceFilter != null && !isNaN(geolocationConfigForPWA.distanceFilter)) {
                        // QUESTION: what about autoSync (see https://mobilus.myjetbrains.com/youtrack/issue/CAPP-1#focus=Comments-4-238.0-0)?
                        const deltaMetres = this.geoUtils.getDistance(lastKnownPosition, geolocationPosition.coords);
                        if (deltaMetres <= geolocationConfigForPWA.distanceFilter) return;
                    }
                }

                this.extractAndSend(geolocationPosition);
                await this.storeGeolocationPositionAndTriggerUploadIfRequired(geolocationPosition);

            });
    }

    protected stop(): void {
        this.logService.info('[PWABackgroundGeolocationService] stop - stopping background geo location');
        if (this.serviceIsRunning === true && this.watchPositionSubscription && this.watchPositionSubscription.closed === false) {
            this.watchPositionSubscription.unsubscribe();
            this.serviceIsRunning = false;
        }
        this._positionSubject.next(null);
    }

    /**
     * Method to create config for background geolocation feature supported in PWA
     * For more details please see https://mobilus.myjetbrains.com/youtrack/issue/CAPP-1
     *
     * Please also see https://transistorsoft.github.io/cordova-background-geolocation-lt/interfaces/config.html
     * (note that this is for a newer plugin version!)
     */
    protected createConfig(geotrackingEnabled: boolean): GeolocationConfigForPWA {

        const geolocationConfigBase: GeolocationConfigBase = this.createGeolocationBaseConfig(geotrackingEnabled);
        // NOTE: background geolocation under web will be limited to support only the subset of the native background geolocation plugin
        if (geolocationConfigBase.method !== 'POST') {
            this.logService.error('[PWABackgroundGeolocationService] createConfig - only POST method is supported in the browser', geolocationConfigBase);
            throw new AppError(false, 'Internal error. Only POST method is supported for background geolocation when running as PWA');
        }

        const geolocationOnlyPWAConfigParams: GeolocationConfigForPWA = {
            geolocationOptions: { maximumAge: 3000, timeout: 5000, enableHighAccuracy: geotrackingEnabled },
            locationTemplate: (val: GeolocationPosition): {
                timestamp: number;
                latitude: number;
                longitude: number;
                accuracy: number;
                heading?: number;
                speed?: number;
                altitude?: number;
                altitude_accuracy?: number;
            } => {
                return {
                    latitude: val.coords.latitude,
                    longitude: val.coords.longitude,
                    accuracy: val.coords.accuracy,
                    timestamp: val.timestamp,
                    heading: val.coords.heading,
                    speed: val.coords.speed,
                    altitude: val.coords.altitude,
                    altitude_accuracy: val.coords.altitudeAccuracy
                };
            }
        }
        const geolocationConfigForPWA: GeolocationConfigForPWA = { ...geolocationConfigBase, ...geolocationOnlyPWAConfigParams };
        return geolocationConfigForPWA;
    }

    /**
     * Method to watch and remove geolocation data based on the config maxRecordsToPersist and maxDaysToPersist settings
     *
     * NOTE: this watch is set up and running regardless of the background geolocation service status (running or not running)
     */
    private async watchAndRemoveExpiredGeolocationPositions(geolocationConfigForPWA: GeolocationConfigForPWA) {
        if (geolocationConfigForPWA.maxRecordsToPersist > 0) {
            const allGeolocationPositions = await this.persistentStorage.getAllGeolocationPositions();
            if (allGeolocationPositions.length > geolocationConfigForPWA.maxRecordsToPersist) {
                await this.trimToMaxRecordsToPersist(geolocationConfigForPWA);
            }
            this.persistentStorage.geolocationPositionsCountObservable
                .pipe(
                    filter(count => {
                        return count > 0;
                    }),
                    distinctUntilChanged()
                )
                .subscribe(async (count) => {
                    if (count > geolocationConfigForPWA.maxRecordsToPersist) {
                        await this.trimToMaxRecordsToPersist(geolocationConfigForPWA);
                    }
                });
        }
        if (geolocationConfigForPWA.maxDaysToPersist > 0) {
            await this.trimToMaxDaysToPersist(geolocationConfigForPWA);
            // NOTE: we may just execute the below trim on the app start up
            setInterval(async () => {
                await this.trimToMaxDaysToPersist(geolocationConfigForPWA);
            }, this.INTERVAL_TO_CHECK_GEOLOCATION_ITEMS_AGE); // NOTE: check geolocations age every hour and delete if necessary
        }
    }

    /**
     * Method to execute remove geolocation data based on the config maxRecordsToPersist setting
     */
    private async trimToMaxRecordsToPersist(geolocationConfigForPWA: GeolocationConfigForPWA) {
        try {
            const sortingFn = (val: Array<GeolocationPosition>) => {
                this.commonUtils.sortArrayByProperty(val, 'timestamp', SortedPropertyType.NUMBER, SortDirection.ASC);
            }
            const allGeolocationPositions: GeolocationPosition[] = await this.persistentStorage.getAllGeolocationPositions(sortingFn);
            // NOTE: remove items if count exceeds maxRecordsToPersist
            if (allGeolocationPositions.length > 0 && geolocationConfigForPWA.maxRecordsToPersist > 0 && allGeolocationPositions.length > geolocationConfigForPWA.maxRecordsToPersist) {
                const GeolocationPositionsToRemove: GeolocationPosition[] = allGeolocationPositions.slice(0, allGeolocationPositions.length - geolocationConfigForPWA.maxRecordsToPersist);
                await this.removeGeolocationPositions(GeolocationPositionsToRemove);
            }
        } catch (error) {
            this.logService.error('[PWABackgroundGeolocationService] trimToMaxDaysToPersist - failed to remove GeolocationPosition items', error);
        }
    }
    /**
    * Method to execute remove geolocation data based on the config maxDaysToPersist setting
    */
    private async trimToMaxDaysToPersist(geolocationConfigForPWA: GeolocationConfigForPWA) {
        try {
            const sortingFn = (val: Array<GeolocationPosition>) => {
                this.commonUtils.sortArrayByProperty(val, 'timestamp', SortedPropertyType.NUMBER, SortDirection.ASC);
            }
            const allGeolocationPositions: GeolocationPosition[] = await this.persistentStorage.getAllGeolocationPositions(sortingFn);
            // NOTE: remove items which exceeds maxDaysToPersist
            if (allGeolocationPositions.length > 0 && geolocationConfigForPWA.maxDaysToPersist > 0) {
                const datetimeBeforeWhichToDelete = moment().subtract(geolocationConfigForPWA.maxDaysToPersist, 'days');
                const geolocationPositionItemsToRemove: GeolocationPosition[] = allGeolocationPositions.filter((val: GeolocationPosition) => {
                    return moment(val.timestamp).isBefore(datetimeBeforeWhichToDelete);
                });
                await this.removeGeolocationPositions(geolocationPositionItemsToRemove);
            }
        } catch (error) {
            // NOTE: we don't throw and just log the error
            this.logService.error('[PWABackgroundGeolocationService] trimToMaxDaysToPersist - failed to remove GeolocationPosition items', error);
        }
    }

    /**
     * Method to remove GeolocationPositions from the persistent storage
     */
    private async removeGeolocationPositions(geolocationPositionsToRemove: GeolocationPosition[]): Promise<void> {
        if (geolocationPositionsToRemove.length === 0) {
            return;
        }
        try {
            await this.persistentStorage.bulkRemoveGeolocationPositions(geolocationPositionsToRemove);
        } catch (error) {
            // QUESTION: should we report this error to the user?
        }
        this.logService.debug('[PWABackgroundGeolocationService] removeGeolocationPositions - removed GeolocationPosition items result', geolocationPositionsToRemove);
    }

    private async storeGeolocationPositionAndTriggerUploadIfRequired(geolocationPosition: GeolocationPosition): Promise<void> {
        // NOTE: persist the location
        try {
            await this.persistentStorage.addGeolocationPosition(geolocationPosition);
        } catch (error) {
            // QUESTION: should we report this error to the user?
        }

        if (!this.backgroundGeolocationConfig) {
            // NOTE: background geolocation config might not be available yet when this method is called from recheckPosition
            // and userSettings has not been provided yet. In this case we just store the position and will upload it later
            // when the config is available
            return;
        }

        const geolocationConfigForPWA: GeolocationConfigForPWA = (this.backgroundGeolocationConfig as GeolocationConfigForPWA);
        // NOTE: trigger upload immediately or later (based on autoSync, autoSyncThreshold config settings)
        if (geolocationConfigForPWA.autoSync === true) {
            if (geolocationConfigForPWA.autoSyncThreshold === 0) {
                // NOTE: trigger upload
                this.uploadGeolocationPositionAnnouncedSource.next();
            } else {
                const allGeolocationPositions: GeolocationPosition[] = await this.persistentStorage.getAllGeolocationPositions();
                if (allGeolocationPositions.length >= geolocationConfigForPWA.autoSyncThreshold) {
                    // NOTE: trigger upload
                    this.uploadGeolocationPositionAnnouncedSource.next();
                }
            }
        }
    }

    /**
    * Method to upload geolocation data to the server
    */
    private async uploadGeolocationPositions(): Promise<void> {
        if (!this.backgroundGeolocationConfig) {
            return;
        }

        const geolocationConfigForPWA: GeolocationConfigForPWA = (this.backgroundGeolocationConfig as GeolocationConfigForPWA);
        try {
            const sortingFn = (val: Array<GeolocationPosition>) => {
                this.commonUtils.sortArrayByProperty(val, 'timestamp', SortedPropertyType.NUMBER, SortDirection.ASC);
            }
            const allGeolocationPositions: GeolocationPosition[] = await this.persistentStorage.getAllGeolocationPositions(sortingFn);
            if (allGeolocationPositions.length === 0) {
                return;
            }

            let batchSize: number = -1;
            if (geolocationConfigForPWA.batchSync !== true) {
                // NOTE: upload all the items one by one
                batchSize = 1;
            } else {
                if (geolocationConfigForPWA.maxBatchSize != null) {
                    batchSize = geolocationConfigForPWA.maxBatchSize;
                }
            }
            await this.executeUploadGeolocationPositions(allGeolocationPositions, batchSize, geolocationConfigForPWA);
        } catch (error) {
            // NOTE: ignore error
        }
    }

    /**
     * Method to prepare the geolocation payload to upload based on config settings
     *
     * NOTE: we support only POST
     */
    private getPayloadToUpload(geolocationPositions: GeolocationPosition[], geolocationConfigForPWA: GeolocationConfigForPWA): object {
        if (!geolocationPositions || geolocationPositions.length === 0) {
            return;
        }

        const locations: Array<{
            timestamp: number;
            latitude: number;
            longitude: number;
            accuracy: number;
            heading?: number;
            speed?: number;
            altitude?: number;
            altitude_accuracy?: number;
        }> = [];
        geolocationPositions.forEach(geolocationPositionDataItem => {
            locations.push(geolocationConfigForPWA.locationTemplate(geolocationPositionDataItem));
        });

        let body: object = {};
        const httpRootProperty = geolocationConfigForPWA.httpRootProperty;
        if (geolocationConfigForPWA.httpRootProperty) {
            body[geolocationConfigForPWA.httpRootProperty] = locations;
        } else {
            body = locations;
        }
        const params = geolocationConfigForPWA.params;
        if (params) {
            body = { ...body, ...params };
        }
        return body;

    }

    /**
     * Method to execute uploading geolocation data to the server
     */
    private async executeUploadGeolocationPositions(geolocationPositions: GeolocationPosition[], batchSize: number, geolocationConfigForPWA: GeolocationConfigForPWA) {
        const url = geolocationConfigForPWA.url;
        const method = geolocationConfigForPWA.method;
        if (
            !url || !method || method !== 'POST' ||
            !geolocationPositions || geolocationPositions.length === 0 || !(batchSize > 0)
        ) {
            return;
        }

        let headers = new HttpHeaders();
        if (geolocationConfigForPWA.headers) {
            Object.keys(geolocationConfigForPWA.headers).forEach(key => {
                headers = headers.append(key, geolocationConfigForPWA.headers[key].toString());
            });
        }

        try {
            // NOTE: split all the locations into chunks to upload them sequentially
            const batchesToUpload: Array<Array<GeolocationPosition>> = [];
            for (let i = 0; i < geolocationPositions.length; i += batchSize) {
                const chunk = geolocationPositions.slice(i, i + batchSize);
                batchesToUpload.push(chunk);
            }
            for (const batchToUpload of batchesToUpload) {
                const geolocationPayloadToUpload = this.getPayloadToUpload(batchToUpload, geolocationConfigForPWA);
                await firstValueFrom(this.http.post(url, geolocationPayloadToUpload, {
                    headers
                }));
                // NOTE: once a batch is uploaded we delete the appropriate locations from the persistent storage
                await this.removeGeolocationPositions(batchToUpload);
            }
        } catch (error) {
            // NOTE: check if this is timeout error and if so don't log the error, otherwise log it
            // we send logs to the server only if this is not http timeout or not WiFi is switched off otherwise we send it to the server
            if (this.httpUtils.isHttpTimeoutError(error) !== true) {
                this.logService.error('[PWABackgroundGeolocationService] executeUploadGeolocationPositions - failed to upload locations', error);
            }
            const errorMessage = this.httpErrorFormatter.getFriendlyMessage(error, true);
            const appError = new AppError(false, errorMessage);
            throw appError;
        }
    }

}
