import {NgModule} from '@angular/core';
import {DebounceClickDirective} from './debounce-click/debounce-click.directive';
import {LongPressDirective} from './long-press/long-press.directive';

@NgModule({
  declarations: [
    DebounceClickDirective,
    LongPressDirective
  ],
  imports: [],
  exports: [
    DebounceClickDirective,
    LongPressDirective
  ]
})
export class DirectivesModule {
}
