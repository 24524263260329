import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ObjectDatabaseChange} from '@models/pouchdb/object-database-change-model';
import {NotificationData} from '@models/push-messages/notification-data.model';
import { ScheduleResult } from '@capacitor/local-notifications';
import {Platform} from '@ionic/angular';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {WrappedToast} from '@services/notification-service/wrapped-toast.model';
import {NotificationStoreService} from '@services/notification-store/notification-store.service';
import * as moment from 'moment';
import {throwError} from 'rxjs';
import {of} from 'rxjs';
import {Subscription} from 'rxjs';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {concat} from 'rxjs';
import {take} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import {retryWhen} from 'rxjs/operators';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

const CONFIG = {
  READ_NOTIFICATION_URL: '/mobile/v2/acknowledgeNotification'
};

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private unreadNotificationCount = 0;

  private _unreadNotificationCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.unreadNotificationCount);
  public unreadNotificationCountObservable: Observable<number> = this._unreadNotificationCountSubject.asObservable();
  private liveChangeSubscription: Subscription;
  private notifications: Array<NotificationData>;
  private isAppInForeground = true;
  private pendingToasts: Array<WrappedToast> = [];

  private localNotificationsIncrement = 1;

  constructor(private notificationStore: NotificationStoreService,
              private toastCtrl: ToastController,
              private platform: Platform,
              private log: LogService,
              private translateService: TranslateService,
              private httpClient: HttpClient,
              private endpointService: EndpointService,
              private capacitorPlugins: CapacitorPlugins
  ) {

    this.notificationStore.changeObservable
      .subscribe((value) => {
        if (value === 'onReset') {
          this.unreadNotificationCount = 0;
        } else if (value === 'retryRegistering') {
          if (this.liveChangeSubscription) {
            this.liveChangeSubscription.unsubscribe();
            this.liveChangeSubscription = null;
          }
          this.fetchFromStoreWhenReady();
        }
      });
    platform.ready().then(() => {
      this.platform.pause.subscribe(() => {
        this.log.info('App going in pause state.');
        this.isAppInForeground = false;
      });
      this.platform.resume.subscribe(() => {
        this.log.info('App going in resume state.');
        this.isAppInForeground = true;
        this.presentPendingToasts();
      });
    });
  }

  manage(notificationData: NotificationData, immediate: boolean = false) {
    // When app is in background, we give 10 seconds for FCM to come in to avoid 2 notifications items for the same notification
    if (notificationData.generator === 'MQTT' && !immediate && !this.isAppInForeground) {
      setTimeout(() => {
        this.manage(notificationData, true);
      }, 10000);
      return;
    }
    // TODO Drop the message if the notification is not for us (add server side the user id to make the check condition)
    // Did we already received this notification
    this.notificationStore.get(notificationData.uuid)
      .subscribe(
        notification => {
          this.log.trace('Notification already exists locally. Nothing to do.');
        },
        () => {
          // Add notification to store
          notificationData.localNotificationID = this.localNotificationsIncrement;
          this.localNotificationsIncrement++;

          this.notificationStore.put(notificationData)
            .subscribe(
              (value: any) => notificationData._rev = value.rev,
              err => this.log.error('Unable to save changed notification', err),
              () => {
                const toast = new WrappedToast(this.toastCtrl, this.translateService, this, notificationData);

                if (this.isAppInForeground) {
                  this.presentNativeNotification(notificationData, toast, false);
                  this.log.info('Presenting toast because in foreground', notificationData.orderId);
                  toast.present();
                } else if (!this.isAppInForeground && notificationData.generator === 'MQTT') {
                  this.log.info('Presenting native notification because in background', notificationData.orderId);
                  this.presentNativeNotification(notificationData, toast, true);
                }
              }
            );
        });
  }

  private presentNativeNotification(notificationData: NotificationData, toast: WrappedToast, addToastToPendingOnFail: boolean = false) {
    try {
      this.capacitorPlugins.getLocalNotificationsPlugin().schedule({
        notifications: [
          {
            id: notificationData.localNotificationID.valueOf(),
            title: notificationData.title,
            body: notificationData.message,
            smallIcon: 'res://notification_icon',
            sound: 'alert', // FIXME : does that work ?
            attachments: null,
            actionTypeId: '',
            extra: null
          }
        ]
      })
        .then((result: ScheduleResult) => {
          if (result.notifications && result.notifications[0]) {
            this.log.info('Native notification has been displayed', result);
          } else {
            this.log.error('Problem trying to display a native notification: ', JSON.stringify(result));
            this.addToPending(toast);
          }
        }, error => {
          this.log.error('Unable to schedule local notifications : ', error);
        });
    } catch (e) {
      this.log.error('Problem trying to display a native notification 2: ', JSON.stringify(e));
      if (addToastToPendingOnFail) {
        this.addToPending(toast);
      }
    }
  }

  public markAsRead(notificationData: NotificationData) {
    notificationData.read = true;
    this.notificationStore.put(notificationData)
      .subscribe(null, err => this.log.error('Unable to save changed notification', err));
    try {
      // We can't use ionic native because it is not compatible (see plugin's README)
      cordova.plugins.notification.local.clear([notificationData.localNotificationID]);
    } catch (e) {
      this.log.error('Problem trying to clear local notification: ', e);
    }
  }

  private presentPendingToasts() {
    while (this.pendingToasts && this.pendingToasts.length && this.pendingToasts.length > 0) {
      this.pendingToasts.shift().present();
    }
  }

  private fetchFromStoreWhenReady() {
    this.notificationStore.ready()
      .then(() => {
        // Fetching all current notifications
        this.notificationStore.allDocs()
          .subscribe((notifications: Array<NotificationData>) => {
            // reset value before counting
            this.unreadNotificationCount = notifications.length;
            this.notifications = notifications;
            this.notifications.forEach(notification => this.decrement(notification));
            this._unreadNotificationCountSubject.next(this.unreadNotificationCount);
          });

        // Managing changed subscriptions
        this.liveChangeSubscription = this.notificationStore.getLiveChanges()
          .subscribe((notificationChange: ObjectDatabaseChange<NotificationData>) => {
            this.count(notificationChange);
          });

      });
  }

  private decrement(notification: NotificationData) {
    if (notification.read) {
      this.unreadNotificationCount--;
    }
  }

  private count(notificationChange: ObjectDatabaseChange<NotificationData>) {
    if (notificationChange.deleted) {
      this.unreadNotificationCount--;
    } else if (notificationChange.doc.read) {
      this.unreadNotificationCount--;
    } else if (!notificationChange.doc.read) {
      this.unreadNotificationCount++;
    }

    // Manage case where they were all deletions
    this.unreadNotificationCount = Math.max(0, this.unreadNotificationCount);

    this._unreadNotificationCountSubject.next(this.unreadNotificationCount);
  }

  private addToPending(toast: WrappedToast) {
    const existing = this.pendingToasts.find((it: WrappedToast) => it.notificationData.uuid === toast.notificationData.uuid);
    if (!existing) {
      this.pendingToasts.push(toast);
    }
  }

  notificationRead(notification: { orderId: string, notificationType: string }): Observable<any> {
    return this.httpClient.post(this.endpointService.currentEndpoint + CONFIG.READ_NOTIFICATION_URL, notification,
      {responseType: 'text'})
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            mergeMap((err: any) => {
              this.log.info('Got err on updating notification', JSON.stringify(err));
              return of(err.status).pipe(
                // @ts-ignore
                delay(1000),
                take(5),
                o => concat(o, throwError({error: '5 retries exceeded.'}))
              );
            })
          ))
      );
  }


  removeOldDocs(): Observable<any> {
    return new Observable(subscriber => {
      this.notificationStore.allDocs()
        .subscribe((notifications: Array<NotificationData>) => {
          notifications.forEach(notification => {
            if (!notification.timestamp.isSame(moment(), 'd')) { // FIXME PM-574 check date and TZ
              this.notificationStore.remove(notification).subscribe();
            }
          });
        }, (error) => {
          this.log.error('Error while removing old docs', error);
          subscriber.error(error);
          subscriber.complete();
        }, () => {
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
