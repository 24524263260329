export class UserReport {
  reportType: string;
  reportText: string;
  reportContactEmail: string;
  reportContactPhone: string;

  constructor(reportType: string, reportText: string, reportContactEmail: string, reportContactPhone: string) {
    this.reportType = reportType;
    this.reportText = reportText;
    this.reportContactEmail = reportContactEmail;
    this.reportContactPhone = reportContactPhone;
  }
}
