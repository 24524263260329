import {Input} from '@angular/core';
import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {Order} from '@models/business/order.model';
import {Traceability} from '@models/business/traceability.model';
import * as moment from 'moment';
import 'moment-duration-format';

@Component({
  selector: 'mobilus-order-countdown-clock',
  templateUrl: './order-countdown-clock.component.html',
  styleUrls: ['./order-countdown-clock.component.scss'],
})
export class OrderCountdownClockComponent implements OnInit {


  @Input() public order: Order;
  @Input() public deliveryOpeningTime: string;
  public countDown: moment.Duration;
  public countDownView: string;
  public countDownClass = 'clock-normal';
  public display = false;

  constructor() {
  }

  public _reference: moment.Moment;
  @Input()
  set reference(ref: moment.Moment) {
    this._reference = ref;
    this.updateView();
  }

  public ngOnInit(): void {
    this.updateView();
  }

  public updateView() {
    this.countDown = this.calculate();
    if (this.countDown) {
      // @ts-ignore
      this.countDownView = this.countDown.format('h:mm', {trim: false});
      this.countDownClass = this.checkClass();
    }
    this.display = true;
  }

  public checkClass() {
    // Warning and error class are disabled until the algorithm is fully implemented
    // if (this.countDown.asMilliseconds() > this.errorDelay.asMilliseconds()) {
    //   return 'clock-error';
    // }
    // else if (this.countDown.asMilliseconds() > this.warnDelay.asMilliseconds()) {
    //   return 'clock-warn';
    // }
    // else {
    return 'clock-normal';
    // }
  }

  public calculate() {
    let candidate: Traceability;
    if (this.order && this.order.traceabilities) {
      candidate = this.findLatestTracaWithStatus('READY_FOR_HAULAGE');
      // TODO, reactivate or move to service when more complex calculation are required
      /*
            switch (this.order.status) {
              case 'ON_THE_WAY_TO_CUSTOMER':
                candidate = this.findLatestTracaWithStatus('READY_FOR_HAULAGE');
                break;
              case 'ON_THE_WAY_TO_PROVIDER':
                candidate = this.findLatestTracaWithStatus('READY_FOR_HAULAGE');
                break;
              case 'READY_FOR_HAULAGE':
                candidate = this.findLatestTracaWithStatus('READY_FOR_HAULAGE');
                break;
            }
      */
      if (candidate && candidate.dateTime) {
        let diff: number;
        const now = moment();
        if (!candidate.dateTime.isSame(now, 'd') && this.deliveryOpeningTime) { // FIXME PM-574 check date and TZ
          diff = now.diff(moment(this.deliveryOpeningTime, 'hh:mm'));
        } else {
          diff = now.diff(candidate.dateTime); // 'h:mm'
        }
        diff = diff < 0 ? 0 : diff;
        return moment.duration(diff);
      } else {
        return null;
      }
    } else {
      return moment.duration('P0M');
    }
  }

  private findLatestTracaWithStatus(status: string): Traceability {
    return this.order.traceabilities.filter((traca: Traceability) => {
      return traca.targetState === status;
    })
      .sort((a: Traceability, b: Traceability) => {
        if (!a || !b || !a.dateTime || !b.dateTime) {
          return 0;
        }
        // return b.dateTime.valueOf() - a.dateTime.valueOf(); alternative
        if (b.dateTime.isAfter(a.dateTime)) {
          return 1;
        } else if (b.dateTime.isBefore(a.dateTime)) {
          return -1;
        } else {
          return 0;
        }
      })[0];
  }

}
