<!-- Adresse atrophiée -->
<ng-container *ngIf="isShortDisplay && orderView">
  <ion-grid [ngClass]="{'even_row' : index % 2 === 0, 'odd_row' : index % 2 !== 0}"
            [class.invalid]="styleInvalidity && !orderView.valid" style="padding: 0"
            [class.assigned]="isAssignedToHauler">
    <ion-row>
      <ion-col size="6" class="mbls-text-ellipsis">
        {{ orderView.order?.client.optimizedName() }}
      </ion-col>
      <ion-col size="6" class="mbls-text-ellipsis mbls-address">
        <span *ngIf="orderView.order?.deliveryAddress.lockerIntegration">
          <fa-icon [icon]="['fas', 'table']"></fa-icon>
        </span>
        {{ orderView.order?.deliveryAddress.addressOneLine }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col [size]="tagSize" class="order-detail">
        <div class="order-external-reference"
             *ngIf="orderView?.order.externalReference && settings.displayOrderReferenceOnOrderList">
          {{orderView.order.externalReference}}</div>
        <ng-container *ngIf="displayProvider">
          <span class="tenant-name">{{ orderView.order.tenantName }}</span>&nbsp;
        </ng-container>
        <ion-badge [hidden]="orderView.order.stateMachineWorkflow !== 'PICKUP'" class="workflow_badge"
                   [translate]="'workflows.abbrev.pickup'"></ion-badge>
        <span [hidden]="!orderView.order.amount" class=""><ion-badge class="collect_badge"><fa-icon
          [icon]="['fas', 'dollar-sign']" size="lg"></fa-icon></ion-badge></span>
        <mobilus-bags [bags]="orderView.order.bags" [metadata]="orderView.order.metadata"></mobilus-bags>
        <mobilus-tags [tags]="orderView.order.tags" [short]="true"></mobilus-tags>
        <mobilus-tags [tags]="orderView.order.client.tags" [short]="true"></mobilus-tags>
        <span [hidden]="!orderView.order.notes"><ion-badge class="order_notes_badge">
        <fa-icon [icon]="['fas', 'comment']"></fa-icon></ion-badge></span>
        <span [hidden]="!orderView.order.client.notes"><ion-badge class="client_notes_badge"><fa-icon
          [icon]="['fas', 'comment']"></fa-icon></ion-badge></span>
      </ion-col>
      <ion-col [size]="detailSize" style="padding-bottom: 10px; padding-top: 1px">
        <p class="order-history-status"
           *ngIf="isforHistory && orderView.order.status !== 'TO_PROCESS_PROVIDER_RECURRING' && orderView.order.status !== 'IN_PREPARATION'" ><fa-icon [icon]="['far', getIcon()]"></fa-icon> {{'workflows.status.' + orderView.order.status.toLowerCase() | translate}}
        </p>
        <mobilus-desired-arrival
          *ngIf="orderView.order.desiredArrivalTime && orderView.order.desiredArrivalTime !== 'ANY'"
          [type]="orderView.order.desiredArrivalTime"
          [start]="orderView.order.desiredArrivalTimeHour"
          [end]="orderView.order.desiredArrivalTimeHourEnd"
        ></mobilus-desired-arrival>
        <mobilus-desired-arrival
          *ngIf="(!orderView.order.desiredArrivalTime || orderView.order.desiredArrivalTime === 'ANY') && orderView.order.client.driverOwnedInfo"
          [type]="orderView.order.client.driverOwnedInfo.desiredArrivalTime"
          [start]="orderView.order.client.driverOwnedInfo.desiredArrivalTimeHour"
          [end]="orderView.order.client.driverOwnedInfo.desiredArrivalTimeHourEnd"
          [constraint]="false"
        ></mobilus-desired-arrival>
        <p *ngIf="!isforHistory && settings.displayDelayCounter" style="margin: 1px">
          <mobilus-order-countdown-clock [order]="orderView.order"
                                 [deliveryOpeningTime]="deliveryOpeningTime"
                                 [reference]="reference"></mobilus-order-countdown-clock>
        </p>
        <mobilus-average-arrival
          *ngIf="orderView.order.averageDeliveryMoment && settings.showAverageDeliveryMoment"
          [time]="orderView.order.averageDeliveryMoment |MobilusDesiredArrivalTime:settings.hourFormat"
        ></mobilus-average-arrival>
        <mobilus-distance-widget [distance]="orderView.order.distance" *ngIf="withDistance"
                         [bearing]="orderView.order.bearing" [unit]="orderView.order.distanceUnit"></mobilus-distance-widget>
      </ion-col>
      <ng-container *ngIf="orderView.order.local">
        <ion-col size="2">
          <mobilus-pending-indicator></mobilus-pending-indicator>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ng-container>
<!-- Adresse complète -->
<ng-container *ngIf="!isShortDisplay && orderView">
  <ion-grid [ngClass]="{'even_row' : index % 2 === 0, 'odd_row' : index % 2 !== 0}"
            [class.invalid]="styleInvalidity && !orderView.valid">
    <ion-row>
      <ion-col [size]="longColSize">
        <ion-row>
          <ion-col class="mbls-text-ellipsis">
            {{ orderView.order?.client.optimizedName() }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="order-external-reference"
                 *ngIf="orderView?.order.externalReference && settings.displayOrderReferenceOnOrderList">
              {{orderView.order.externalReference}}</div>
            <span *ngIf="displayProvider" class="tenant-name">{{ orderView.order.tenantName }}</span>
            <ion-badge [hidden]="orderView.order.stateMachineWorkflow !== 'PICKUP'" class="workflow_badge"
                       [translate]="'workflows.abbrev.pickup'"></ion-badge>
            <span [hidden]="!orderView.order.amount" class="collect_badge"><fa-icon
              [icon]="['fas', 'dollar-sign']"></fa-icon></span>
            <mobilus-bags [bags]="orderView.order.bags" [metadata]="orderView.order.metadata"></mobilus-bags>
            <mobilus-tags [tags]="orderView.order.tags" [short]="true"></mobilus-tags>
            <mobilus-tags [tags]="orderView.order.client.tags" [short]="true"></mobilus-tags>
            <span [hidden]="!orderView.order.notes"><ion-badge class="order_notes_badge"><fa-icon
              [icon]="['fas', 'comment']"></fa-icon></ion-badge></span>
            <span [hidden]="!orderView.order.client.notes"><ion-badge class="client_notes_badge"><fa-icon
              [icon]="['fas', 'comment']"></fa-icon></ion-badge></span>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col [size]="longColSize">
        <ion-row class="mbls-address">
          {{ orderView.order.deliveryAddress.addressOneLine }}
        </ion-row>
        <ion-row *ngIf="orderView.order.desiredArrivalTime && orderView.order.desiredArrivalTime !== 'ANY'">
          <ion-col>
            <mobilus-desired-arrival [type]="orderView.order.desiredArrivalTime"
                             [start]="orderView.order.desiredArrivalTimeHour"
                             [end]="orderView.order.desiredArrivalTimeHourEnd"></mobilus-desired-arrival>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!isforHistory && (!orderView.order.desiredArrivalTimeHour || orderView.order.distance)">
          <ion-col size="7" *ngIf="withDistance && orderView.order.distance" class="ion-no-padding">
            <mobilus-distance-widget [distance]="orderView.order.distance"
                             [unit]="orderView.order.distanceUnit"
                             *ngIf="withDistance"
                             [bearing]="orderView.order.bearing"></mobilus-distance-widget>
          </ion-col>
          <ion-col *ngIf="settings.displayDelayCounter" size="6" class="ion-no-padding">
            <mobilus-order-countdown-clock [order]="orderView.order"
                                   [deliveryOpeningTime]="deliveryOpeningTime"
                                   [reference]="reference"></mobilus-order-countdown-clock>
          </ion-col>
        </ion-row>
      </ion-col>
      <ng-container *ngIf="orderView.order.local">
        <ion-col size="2">
          <mobilus-pending-indicator></mobilus-pending-indicator>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ng-container>
