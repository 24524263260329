import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('DelocalizedDeliveryConfig')
export class DelocalizedDeliveryConfig {

  @JsonProperty('activated', Boolean, true)
  activated: boolean = undefined;
  @JsonProperty('distance', Number, true)
  distance: number = undefined;

}
