<ion-header>

  <ion-toolbar>
    <ion-title>{{reportType | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-no-padding">
  <p class="empty-list-message"
     *ngIf="reportType==='pages.accounting-reports.orders_cancelled' && !report.ret.CANCELLED.length">
    {{'messages.empty_list' | translate}}
  </p>
  <p class="empty-list-message"
     *ngIf="reportType==='pages.accounting-reports.orders_delivered' && !report.ret.DELIVERED.length">
    {{'messages.empty_list' | translate}}
  </p>
  <p class="empty-list-message"
     *ngIf=" reportType==='pages.accounting-reports.payment_report' && !report.ret.DELIVERED.length">
    {{'messages.empty_list' | translate}}
  </p>
  <ion-grid *ngIf="reportType==='pages.accounting-reports.payment_report' && report.ret.DELIVERED.length">
    <div class="rows" *ngFor="let type of report.byPaymentTypes">
      <ion-row>
        <ion-col size="12" class="recap title  col-right ion-text-center">
          {{(type ? 'transition.paymentType.' + type.key.toLowerCase() : '') | translate}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.number_orders' | translate}}
        </ion-col>
        <ion-col size="4" class="recap amount col-right">
          {{type.nbOrders}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.total_asked_amount' | translate}}
        </ion-col>
        <ion-col size="4" class="recap amount col-right">
          {{type.amount?.toFixed(2)}} $
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.total_collected_amount' | translate}}
        </ion-col>
        <ion-col size="4" class="recap amount col-right">
          {{type.collectedAmount?.toFixed(2)}} $
        </ion-col>
      </ion-row>
    </div>
    <div class="total">
      <ion-row>
        <ion-col size="12" class="recap title ion-text-center">
          Total
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.number_orders' | translate}}
        </ion-col>
        <ion-col size="4" class="recap amount">
          {{report.recap.nbOrders}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.total_asked_amount' | translate}}
        </ion-col>
        <ion-col size="4" class="recap amount">
          {{report.recap.totalAmountAsked?.toFixed(2)}} $
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8" class="recap">
          {{'pages.accounting-reports.total_collected_amount' | translate}} $
        </ion-col>
        <ion-col size="4" class="recap amount">
          {{report.recap.totalAmountCollected?.toFixed(2)}} $
        </ion-col>
      </ion-row>
    </div>
    <div class="total" *ngIf="includeTags">
      <ion-row>
        <ion-col size="12" class="recap title ion-text-center">
          Tags
        </ion-col>
      </ion-row>
      <div *ngFor="let tag of tagsLabel">
        <ion-row>
          <ion-col size="8" class="recap">
            {{tag | translate}}
            <mobilus-tag [tag]="getTagIcon(tag)" [short]="true"></mobilus-tag>
          </ion-col>
          <ion-col size="4" class="recap amount">
            {{getNumbersOfTags(tag)}}
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-grid>

  <ion-grid *ngIf="reportType!=='pages.accounting-reports.orders_cancelled' &&
    reportType !== 'pages.accounting-reports.orders_delivered' && reportType!=='pages.accounting-reports.payment_report'">
    <div *ngFor="let haulerReportAccount of haulerReport">
      <p *ngIf="haulerReport?.length > 1" haulerReportAccount?.key>{{ haulerReportAccount?.key }}</p>
      <div class="rows hauler" *ngFor="let type of getAccountPaymentTypes(haulerReportAccount?.key)">
        <ion-row>
          <ion-col size="12" class="recap title  col-right ion-text-center">
            {{(type ? 'transition.paymentType.' + type.toLowerCase() : '') | translate}}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.number_orders' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount col-right">
            {{haulerReportAccount?.value[type]?.nb}}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.total_asked_amount' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount col-right">
            {{ haulerReportAccount?.value[type]?.amount?.toFixed(2) }} $
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.total_collected_amount' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount col-right">
            {{ haulerReportAccount?.value[type]?.collectedAmount?.toFixed(2) }} $
          </ion-col>
        </ion-row>
      </div>
      <div class="total">
        <ion-row>
          <ion-col size="12" class="recap title ion-text-center">
            Total
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.number_orders' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount">
            {{getAmount(haulerReportAccount?.key, 'nb') | number}}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.total_asked_amount' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount">
            {{getAmount(haulerReportAccount?.key, 'amount')}} $
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8" class="recap">
            {{'pages.accounting-reports.total_collected_amount' | translate}}
          </ion-col>
          <ion-col size="4" class="recap amount">
            {{getAmount(haulerReportAccount?.key, 'collectedAmount')}} $
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-grid>

  <div *ngIf="(reportType==='pages.accounting-reports.orders_delivered' && report.ret.DELIVERED.length) ||
                          (reportType==='pages.accounting-reports.orders_cancelled' && report.ret.CANCELLED.length)">
    <ion-grid
      *ngIf="reportType==='pages.accounting-reports.orders_delivered' || reportType==='pages.accounting-reports.orders_cancelled'">
      <ion-row>
        <ion-col size="4">
          <ion-grid class="fixed-grid">
            <ion-row class="header">
              <ion-col class="header-color">
                {{'pages.accounting-reports.customer' | translate}}
              </ion-col>
            </ion-row>
            <ion-row
              *ngFor="let cmd of items; let i = index">
              <ion-col class="hidden-md align-content-left"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.client.lastName?.toUpperCase()}} {{cmd.order.client.firstName}}
                <br>
                <span class="address"> {{cmd.order.deliveryAddress.addressOneLine}}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col style="overflow: auto">
          <ion-grid class="unfixed-grid">
            <ion-row class="header">
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.order_notes' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.prepared_by' | translate}}
              </ion-col>
              <ion-col size="6" class="header-color">
                {{'pages.accounting-reports.delivery_message' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.hauler' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.total_asked_amount' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.total_collected_amount' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.payment_method' | translate}}
              </ion-col>
              <ion-col size="4" class="header-color">
                {{'pages.accounting-reports.reference' | translate}}
              </ion-col>
              <ion-col size="5" class="header-color">
                {{'pages.accounting-reports.drugstore' | translate}}
              </ion-col>
            </ion-row>
            <ion-row
              *ngFor="let cmd of items; let i = index">
              <ion-col size="4" class="hidden-md align-content-left"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.notes}}
              </ion-col>
              <ion-col size="4" class="hidden-md align-content-center"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.creationTraceability?.operatorCode}}
              </ion-col>
              <ion-col size="6" class="hidden-md align-content-center space-evenly"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.traceability.message}}
              </ion-col>
              <ion-col size="4" class="hidden-md align-content-center"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.haulerEmployee?.firstName}} {{cmd.haulerEmployee?.lastName}}
              </ion-col>
              <ion-col size="4" class="hidden-md align-content-right"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.amount}}
              </ion-col>
              <ion-col size="4" class="hidden-md align-content-right"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.payments && cmd.order.payments.length && cmd.order.payments[0].collectedAmount}}
              </ion-col>
              <ion-col size="4" class="hidden-md align-content-center"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.payments && cmd.order.payments.length && cmd.order.payments[0].type &&
              'transition.paymentType.' +
              cmd.order.payments[0].type.toLowerCase() | translate}}
              </ion-col>
              <ion-col size="4" class="hidden-md space-evenly"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.reference}} {{(cmd.order.stateMachineWorkflow === 'PICKUP' ? 'workflows.abbrev.pickup' : '') |
                translate}}
              </ion-col>
              <ion-col size="5" class="hidden-md space-evenly"
                       [ngClass]="{'even_row' : i % 2 === 0, 'odd_row' : i % 2 !== 0}">
                {{cmd.order.tenantName}}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-infinite-scroll *ngIf="reportType !== 'pages.accounting-reports.payment_report'"
                       (ionInfinite)="doInfinite($event)" threshold="100px">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="{{ 'messages.loading' | translate }}">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
