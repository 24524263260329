import {Injectable} from '@angular/core';
import {ProvidedConfiguration} from '@models/configuration/provided-configuration.model';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {TransitionRequest} from '@models/workflow/transition-request.model';
import {classToPlain} from '@utils/json-converter/json-converter';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AwaitingTransitionStoreService} from '@services/awaiting-transition/awaiting-transition-store.service';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {UserReport} from '@services/reporting-service/user-report.model';
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {of} from 'rxjs';
import * as Sentry from '@sentry/browser';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  private count: number;
  private configuration: ProvidedConfiguration;
  private userProfile: UserProfile;
  private userSettings: UserSettings;
  private connectionStatus: ConnectionStatus;

  constructor(private deviceInfo: DeviceInfo,
              private transitionStore: AwaitingTransitionStoreService,
              private orderStore: OrderStoreService,
              private translate: TranslateService,
              private mobileContextService: MobileContextService,
              private toaster: ToastController,
              private log: LogService,
              private connectionStatusService: ConnectionStatusService,
              private capacitorPlugins: CapacitorPlugins) {
    transitionStore.countObservable
      .subscribe((count) => this.count = count);
    this.mobileContextService.providedConfigurationObservable
      .subscribe(configuration => {
        this.configuration = configuration;
      });
    this.mobileContextService.userProfileObservable
      .subscribe(userProfile => {
        this.userProfile = userProfile;
      });
    this.mobileContextService.userSettingsObservable
      .subscribe(userSettings => {
        this.userSettings = userSettings;
      });
    this.connectionStatusService.connectionStatusSubscription
      .subscribe(connectionStatus => {
        this.connectionStatus = connectionStatus;
      });
  }

  public sendSnapshotReport(userReport?: UserReport) {
    const context: any = {};
    let message = 'Complete report';
    const profile = this.userProfile;
    if (profile) {
      message += ` ${profile.username} (${profile.firstName} ${profile.lastName})`;
    }

    if (this.configuration) {
      context.configuration = this.configuration;
    }

    if (userReport) {
      context.userReport = userReport;
      context.userMessage = userReport.reportText;
    }

    const observables: Array<Observable<any>> = [];

    observables.push(this.fillDeviceInfo(context));
    observables.push(this.fillNetworkStatus(context));
    observables.push(this.fillTransitionStore(context));
    observables.push(this.fillOrderStore(context));

    if (this.userSettings) {
      context.userSettings = this.userSettings;
    }

    combineLatest(observables)
      .subscribe(() => {
        this.sendReport(message, context, true);
      }, error => {
        this.log.error('Promises rejected', error);
      });
  }

  public async sendReport(title: string, context: any, withToast: boolean = false) {
    try {
      Sentry.withScope(scope => {
        if (context) {
          scope.setContext('extra', context);
        }
        Sentry.captureMessage(title);
        scope.clear();

        if (withToast) {
          this.toaster.create({
            message: this.translate.instant('diagnostic.debugReport.sent'),
            duration: 2000
          })
            .then(toast => toast.present());
        }
      });
    } catch (error) {
      this.log.error('Unable to send report to Sentry : ', error);
    }
  }

  private fillDeviceInfo(context): Observable<any> {
    context.deviceInfo = classToPlain(this.deviceInfo);
    context.deviceInfo.connectionStatus = this.connectionStatus;
    context.deviceInfo.permissions = this.deviceInfo.permissions;
    return of(this.deviceInfo);
  }

  private fillOrderStore(context): Observable<any> {
    return new Observable(subscriber => {
      try {
        this.orderStore.getInfo()
          .then(
            (info) => {
              context.orderStoreInfo = info;
              subscriber.next(info);
              subscriber.complete();
            },
            () => {
              subscriber.complete();
            });
      } catch (exception) {
        context.orderStoreInfo = 'Exception fetching order store info: ' + exception;
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private fillTransitionStore(context): Observable<any> {
    return new Observable(subscriber => {
      try {
        this.transitionStore.getAllDocs()
          .subscribe((requests: Array<TransitionRequest>) => {
            context.transitionCount = this.count || requests.length;
            context.transitionStore = classToPlain(requests);
          }, (error) => {
            this.log.error('Error on transition store data', error);
          }, () => {
            this.transitionStore.getInfo()
              .then(
                (info) => {
                  context.transitionStoreInfo = info;
                  subscriber.next(info);
                  subscriber.complete();
                },
                (error) => {
                  this.log.error('Error on transition store info', error);
                  subscriber.complete();
                });
          });
      } catch (exception) {
        context.transitionStore = 'Exception fetching transition store info: ' + exception;
      }
    });

  }

  private fillNetworkStatus(context): Observable<any> {
    return new Observable(subscriber => {
      try {
        this.capacitorPlugins.getNetworkPlugin().getStatus()
          .then(
            (status) => {
              context.registeredIsConnected = status.connected;
              context.networkType = status.connectionType;
              subscriber.next(status);
              subscriber.complete();
            },
            () => {
              subscriber.complete();
            });
      } catch (exception) {
        context.orderStoreInfo = 'Exception fetching network status: ' + exception;
        subscriber.next();
        subscriber.complete();
      }
    });
  }

/*
    private showSentryDialog() {
      try {
        Sentry.showReportDialog({dsn: 'https://4052662060534bf2bde6ef4a648a0f10@sentry.io/5186798\''});
      } catch (e) {
        console.log('Can not show sentry dialog.');
      }

    }
*/
}
