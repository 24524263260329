import {Order} from '@models/business/order.model';
import {RouteSummary} from '@models/optimize-route/route-summary.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('OptimizedResponse')
export class OptimizedResponse {

  @JsonProperty('summary', RouteSummary, true)
  summary: RouteSummary = null;

  @JsonProperty('orders', [Order], true)
  orders: Array<Order> = [];
}
