import {HttpResponse} from '@angular/common/http';
import {HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthToken} from '@models/oauth-token.model';
import {plainToClass} from '@utils/json-converter/json-converter';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const CONFIG = {
  ACCESS_TOKEN_PATH: '/oauth/token',
  REFRESH_TOKEN_PATH: '/oauth/token',
  OAUTH: {
    SCOPE: 'read+write',
    CLIENT_ID: 'mobilusandroid'
  }
};

@Injectable({
  providedIn: 'root'
})
export class OAuth2Service {

  constructor(private httpClient: HttpClient,
              private endpointService: EndpointService,
              private log: LogService,
  ) {
  }

  public getAccessTokenViaRefreshGrant(refreshToken: string) {
    const url = this.endpointService.currentEndpoint + CONFIG.REFRESH_TOKEN_PATH;
    this.log.info('Attempting refresh the token with refresh_token : ' + refreshToken + ' on URL ' + url);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let formBody = new HttpParams();
    formBody = formBody.append('grant_type', 'refresh_token');
    formBody = formBody.append('refresh_token', refreshToken);
    formBody = formBody.append('scope', CONFIG.OAUTH.SCOPE);
    formBody = formBody.append('client_id', CONFIG.OAUTH.CLIENT_ID);

    return this.httpClient.post(url, formBody, {
      observe: 'response',
      headers
    })
      .pipe(
        map((response: HttpResponse<object>) => plainToClass(OAuthToken, response.body))
      );
  }

  public getAccessTokenViaPasswordGrant(username: string, password: string): Observable<OAuthToken> {
    const url = this.endpointService.currentEndpoint + CONFIG.ACCESS_TOKEN_PATH;
    this.log.info('Attempting login with username : ' + username + ' on URL ' + url);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let formBody = new HttpParams();
    formBody = formBody.append('grant_type', 'password');
    formBody = formBody.append('password', password);
    formBody = formBody.append('username', username);
    formBody = formBody.append('scope', CONFIG.OAUTH.SCOPE);
    formBody = formBody.append('client_id', CONFIG.OAUTH.CLIENT_ID);

    return this.httpClient.post(url, formBody, {
      observe: 'response',
      headers
    })
      .pipe(
        map((response: HttpResponse<object>) => plainToClass(OAuthToken, response.body))
      );
  }

}
