import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {PipesModule} from '@app/pipes/pipes.module';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {AccountingReportsPage} from './accounting-reports.page';

const routes: Routes = [
  {
    path: '',
    component: AccountingReportsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ComponentsModule,
    PipesModule
  ],
  declarations: [AccountingReportsPage]
})
export class AccountingReportsPageModule {}
