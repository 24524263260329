import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {Order} from '@models/business/order.model';


@Component({
  selector: 'mobilus-sequence-number',
  templateUrl: './sequence-number.component.html',
  styleUrls: ['./sequence-number.component.scss'],
})
export class SequenceNumberComponent {

  @Input() order: Order;

  constructor() {
  }

}
