import {ManifestConfirmationText} from '@models/business/manifest-confirmation-text.model';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('OrderManifest')
export class OrderManifest {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('confirmationText', ManifestConfirmationText, true)
  confirmationText: ManifestConfirmationText = undefined;

  @JsonProperty('items', [OrderManifestItem], true)
  items: Array<OrderManifestItem> = [];

}
