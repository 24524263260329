import {Hauler} from '@models/business/hauler.model';
import {ServerContext} from '@models/check-in/server-context.model';
import {Versions} from '@models/check-in/versions';
import {ProvidedConfiguration} from '@models/configuration/provided-configuration.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {JsonObject} from 'json2typescript';
import {JsonProperty} from 'json2typescript';

@JsonObject('MobileConfiguration')
export class MobileConfiguration {

  @JsonProperty('userProfile', UserProfile)
  userProfile: UserProfile = undefined;

  @JsonProperty('hauler', Hauler, true)
  hauler: Hauler = undefined;

  @JsonProperty('providedConfiguration', ProvidedConfiguration, true)
  providedConfiguration: ProvidedConfiguration = undefined;

  @JsonProperty('userSettings', UserSettings, true)
  userSettings: UserSettings = undefined;

  @JsonProperty('versions', Versions, true)
  versions: Versions = undefined;

  @JsonProperty('serverContext', ServerContext, true)
  serverContext: ServerContext = undefined;

}
