<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'pages.transition-detail-debug.header' | translate }}
    </ion-title>
    <ion-buttons slot="end" (click)="close()">
      <ion-button outline>
        {{ 'actions.close' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <h2 class="ion-text-center">
    {{ 'pages.transition-detail-debug.labels.order-number' | translate }}: {{ awaitingTransitionAndOrder?.order?.reference }}
  </h2>

  <div *ngIf="awaitingTransitionAndOrder?.transitionRequest?.transitionRequestLogs && awaitingTransitionAndOrder?.transitionRequest?.transitionRequestLogs.length > 0; else noItems" class="list-view-container">

    <ion-card detail-none *ngFor="let transitionRequestLog of awaitingTransitionAndOrder.transitionRequest.transitionRequestLogs; let i = index;">
      <ion-card-header>
        <ion-card-title [ngClass]="{ 'error-message': transitionRequestLog.iserror === true }">{{ 'pages.transition-detail-debug.labels.date' | translate }}: {{ transitionRequestLog.date.format('HH:mm:ss.SSS MMM DD, YYYY') }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p *ngIf="transitionRequestLog.message" class="message" [ngClass]="{ 'error-message': transitionRequestLog.iserror === true }"> {{ transitionRequestLog.message }}</p>
      </ion-card-content>
    </ion-card>

  </div>

  <ng-template #noItems>
    <div class="no-data">
      <p>{{ 'pages.transition-detail-debug.messages.no-transaction-detail-text' | translate }}</p>
    </div>
  </ng-template>

</ion-content>
