import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TransitionDetailPage } from './transition-detail.page';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '@app/components/components.module';
import {PipesModule} from '@app/pipes/pipes.module';
import {CurrencyMaskModule} from 'ng2-currency-mask';
const routes: Routes = [
  {
    path: '',
    component: TransitionDetailPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ComponentsModule,
    PipesModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
  ],
  declarations: [TransitionDetailPage]
})
export class TransitionDetailPageModule {}
