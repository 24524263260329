import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {DriverOwnedInfo} from '@models/business/driver-owned-info.model';
import {PhoneNumber} from '@models/business/phone-number.model';
import {Tag} from '@models/business/tag.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Client')
export class Client {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('firstName', String)
  firstName: string = undefined;

  @JsonProperty('lastName', String)
  lastName: string = undefined;

  @JsonProperty('notes', String, true)
  notes: string = undefined;

  @JsonProperty('organisation', String, true)
  organisation: string = undefined;

  @JsonProperty('phoneNumbers', [PhoneNumber], true)
  phoneNumbers: Array<PhoneNumber> = [];

  @JsonProperty('authorizedRecipients', [DeliveryRecipient], true)
  authorizedRecipients: Array<DeliveryRecipient> = [];

  @JsonProperty('tags', [Tag], true)
  tags: Array<Tag> = [];

  @JsonProperty('driverOwnedInfo', DriverOwnedInfo, true)
  driverOwnedInfo: DriverOwnedInfo = undefined;

  @JsonProperty('externalReference', String, true)
  externalReference: string = undefined;

  simplifiedName() {
    return this.firstName.charAt(0).toUpperCase() + '. ' + this.lastName.toUpperCase();
  }

  fullName() {
    return this.firstName + ' ' + this.lastName.toUpperCase();
  }

  optimizedName() {
    return this.lastName.toLocaleUpperCase() + ', ' + this.firstName;
  }

}
