import {Injectable} from '@angular/core';
import {UrlTree} from '@angular/router';
import {RouterStateSnapshot} from '@angular/router';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Router} from '@angular/router';

import {AuthenticationService} from '@services/auth/authentication.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard  {
  constructor(private authService: AuthenticationService,
              private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.authService.getAccessToken()) {
      return this.router.parseUrl('/login');
    } else {
      return true;
    }
  }

}
