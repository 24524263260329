import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import {Features} from './features.model';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private features = new Features();
  private _featuresSubject: BehaviorSubject<Features> = new BehaviorSubject<Features>(this.features);
  public featuresObservable: Observable<Features> = this._featuresSubject.asObservable();

  constructor() {

  }

  public featureChanged() {
    this._featuresSubject.next(this.features);
  }
}
