<ion-header>
  <ion-toolbar color="danger">
    <ion-title><span translate="pages.position-entry.header-text"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <form [formGroup]="form" *ngIf="form" novalidate>
    <ion-item>
      <ion-button slot="end" [disabled]="form.pristine || (!form.valid && form.dirty)" (click)="save()">
        {{ 'actions.save' | translate }}
      </ion-button>
    </ion-item>
    <ion-item-group>
      <ion-item-divider>Latitude</ion-item-divider>
      <ion-item>
        <ion-input type="number" maxlength="10" formControlName="latitude"></ion-input>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>Longitude</ion-item-divider>
      <ion-item>
        <ion-input type="number" maxlength="10" formControlName="longitude"></ion-input>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>
        {{ 'pages.position-entry.address-label' | translate }}
      </ion-item-divider>
      <ion-item>
        <ion-input id="address" type="text" maxlength="100" formControlName="address" name="address" (ionFocus)="writing = true" (ionBlur)="writing = false" placeholder="{{ 'location-settings.address' | translate }}"></ion-input>
      </ion-item>
    </ion-item-group>
  </form>

  <ion-item>
    <ion-label>
      {{ 'pages.position-entry.prompt-to-double-click-on-map' | translate }}
    </ion-label>
  </ion-item>

</ion-content>

<ion-footer style="height: 300px" [ngStyle]="{ 'visibility': (!writing? 'visible': 'hidden') }">
  <div id="position_entry_page_map_canvas" [ngStyle]="{ 'visibility': (connected && !writing? 'visible': 'hidden') }"></div>
  <div id="errorPanel" *ngIf="(!connected || mapLoadFailed)">
    <p *ngIf="!connected">
      {{ 'messages.mapNotAvailableOffline' | translate }}
    </p>
    <p *ngIf="connected && mapLoadFailed">
      {{ 'messages.failedToLoadGoogleMapsSDK' | translate }}
    </p>
  </div>
</ion-footer>
