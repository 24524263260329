import {Injectable, Injector} from '@angular/core';
import {LogService} from '@services/log/log.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

export const endpoints = {
  production: 'https://pharma.mobilus.com',
  staging: 'https://staging.mobilus.com',
  qa: 'https://qa.mobilus.com',
  testing: 'https://testing.mobilus.com',
  local_proxy: '/proxy',
  dev_paul: 'https://paul.mbls.io/mobilus-webapp',
  dev_patrick: 'https://patrek.mbls.io/mobilus-webapp',
  emulator_local: 'http://10.0.2.2:8080/mobilus-webapp'
};

@Injectable({
  providedIn: 'root'
})

export class EndpointService {

  static readonly ENDPOINT_STORAGE_KEY: string = 'ENDPOINT_URL';

  private endpointBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject(endpoints.production);
  public observableEndpoint: Observable<string> = this.endpointBehaviorSubject.asObservable();
  private log: LogService;

  constructor(
    private capacitorPlugins: CapacitorPlugins,
    private injector: Injector
  ) {
    setTimeout(() => this.log = this.injector.get(LogService));
  }

  private _currentEndpoint: string;

  get currentEndpoint() {
    return this._currentEndpoint;
  }

  set currentEndpoint(endpoint: string) {
    if (endpoint.endsWith('/')) {
      endpoint = endpoint.substr(0, endpoint.length - 1);
    }
    this.capacitorPlugins.getPreferencesPlugin().set({key: EndpointService.ENDPOINT_STORAGE_KEY, value: endpoint})
      .then(
        data => this.log.info('Endpoint saved to native storage.'),
        error => this.log.error('Unable to save endpoint to native storage : ', error)
      );
    this._currentEndpoint = endpoint;
    this.endpointBehaviorSubject.next(this._currentEndpoint);
  }

  init(): Promise<any> {
    return this.capacitorPlugins.getPreferencesPlugin().get({key: EndpointService.ENDPOINT_STORAGE_KEY})
      .then(
        data => {
          this.log.trace('Endpoint restored from storage : ', data);
          if (data && data.value) {
            this.currentEndpoint = data.value;
            return this.currentEndpoint;
          } else {
            this.setEndpointToProduction();
            return this.currentEndpoint;
          }
        },
        error => {
          this.log.error('Unable to restore endpoint to storage, defaulting to production.', error);
          console.error('Endpoint not found, using production', error);
          this.setEndpointToProduction();
          return this.currentEndpoint;
        }
      );
  }

  setEndpointToProduction() {
    this.currentEndpoint = endpoints.production;
  }

  setEndpointToStaging() {
    this.currentEndpoint = endpoints.staging;
  }

  setEndpointToQa() {
    this.currentEndpoint = endpoints.qa;
  }

  setEndpointToTesting() {
    this.currentEndpoint = endpoints.testing;
  }

  setEndpointToLocalDev() {
    this.currentEndpoint = endpoints.local_proxy;
  }

  setEndpointToEmulatorLocal() {
    this.currentEndpoint = endpoints.emulator_local;
  }

  setEndpointToDevPaul() {
    this.currentEndpoint = endpoints.dev_paul;
  }

  setEndpointToDevPatrick() {
    this.currentEndpoint = endpoints.dev_patrick;
  }

}
