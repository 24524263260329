import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {Input} from '@angular/core';
import {Component} from '@angular/core';

@Component({
  selector: 'mobilus-tri-state-toggle',
  templateUrl: './tri-state-toggle.component.html',
  styleUrls: ['./tri-state-toggle.component.scss'],
})
export class TriStateToggleComponent {

  modelValue: boolean;

  @Input()
  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
    if (value === true) {
      this.state = 'true';
    } else if (value === false) {
      this.state = 'false';
    } else {
      this.state = 'null';
    }
  }

  @Output()
  modelChange = new EventEmitter<boolean>();

  wrapStyle = {
    width: '50px',
    height: '25px'
  };

  state = 'null';

  constructor() {

  }

  getStyle() {
    return ['horizontal', this.state];
  }

  toggle() {
    if (this.model === undefined || this.model === null) {
      this.model = true;
      return;
    } else if (this.model === true) {
      this.model = false;
      return;
    } else if (this.model === false) {
      this.model = true;
      return;
    }
  }

}
