import {Order} from '@models/business/order.model';
import {Traceability} from '@models/business/traceability.model';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('AccountingReport')
export class AccountingReport {

  @JsonProperty('ret', Object)
  ret: Ret = undefined;
  @JsonProperty('recap', Object)
  recap: Recap = undefined;
  @JsonProperty('byPaymentTypes', Object)
  byPaymentTypes: Array<Recap> = undefined;
  @JsonProperty('byHaulerEmployeeIDAndByPaymentTypes', Object)
  byHaulerEmployeeIDAndByPaymentTypes: Array<any> = undefined;
}

interface Ret {
  CANCELLED: Array<ReportAccountingItem>;
  DELIVERED: Array<ReportAccountingItem>;
}

interface Recap {
  key: string;
  nbOrders: number;
  totalAmountAsked: number;
  totalAmountCollected: number;
  amount: number;
  collectedAmount: number;
}

interface ReportAccountingItem {
  creationTraceability: Traceability;
  haulerEmployee: HaulerEmployee;
  order: Order;
  provider: string;
  traceability: Traceability;
}
