<ion-button *ngIf="!emitting"
  expand="full"
  size="large"
  fill="solid"
  color="{{ transition.event.style }}"
  class="transition-button"
  [disabled]="isDisabled"
  (click)="transit()">
  <fa-icon [icon]="['fas', $any(transition).event.icon]"></fa-icon><span
  class="transitions-buttons-text" [translate]="transition.event.labelKey"></span>
</ion-button>
<ion-button *ngIf="emitting"
  expand="full"
  size="large"
  fill="solid"
  color="{{ transition.event.style }}"
  class="transition-button"
  [disabled]="true">
  <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
</ion-button>
