import {Order} from '@models/business/order.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('HttpTransitionResponse')
export class HttpTransitionResponse {

  @JsonProperty('status', Number, true)
  status: number = undefined;

  @JsonProperty('order', Order, true)
  order: Order = undefined;

  @JsonProperty('message', String, true)
  message: string = undefined;

  @JsonProperty('ok', Boolean, true)
  ok: boolean = undefined;
}
