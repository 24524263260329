import { Injectable } from "@angular/core";
declare let gtag: Function;

@Injectable()
export class MblsAnalyticsService {
  public eventEmitter(
    page_title: string,
    eventName: string,
    eventAction: string,
    eventCategory: string = null,
    eventValue: number = null,
    page_path: string = null,
    eventLabel: string = null,
  ) {

  }

  public trackView(title: string, campaignUrl: string = null, newSession: boolean = false): Promise<any> {
    return new Promise<any>(resolve => {

    });
  }

  public trackEvent(category: string, action: string = null, label: string = null, value: number = null, newSession: boolean = false): Promise<any> {
    return new Promise<any>(resolve => {

    });
  }

  public setUserId(id: string, user_id: string): Promise<any> {
    return new Promise<any>(resolve => {

    });
  }

  public setAppVersion(appVersion: string): Promise<any> {
    return new Promise<any>(resolve => {

    });
  }
}
