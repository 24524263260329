import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Hauler} from '@models/business/hauler.model';
import {Provider} from '@models/business/provider.model';
import {AppInfoCheckin} from '@models/check-in/app-info.model';
import {DeviceInfoCheckin} from '@models/check-in/device-info.model';
import {MobileCheckIn} from '@models/check-in/mobile-checkin.model';
import {MobileConfiguration} from '@models/check-in/mobile-configuration.model';
import {Policies} from '@models/check-in/policies.model';
import {ServerContext} from '@models/check-in/server-context.model';
import {Versions} from '@models/check-in/versions';
import {ProvidedConfiguration} from '@models/configuration/provided-configuration.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {OAuthToken} from '@models/oauth-token.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {RemoteManagementCommandData} from '@models/push-messages/remote-management-command.data.model';
import {SelectedProvider} from '@models/selected-provider.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {classToClass, classToPlain, plainToClass} from '@utils/json-converter/json-converter';
import {TranslateService} from '@ngx-translate/core';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, mergeMap, map} from 'rxjs/operators';
import * as moment from 'moment';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

const CONFIG = {
  CONFIGURATION_KEY: 'mobilus_configuration',
  CHECKIN_PATH: '/mobile/v2/checkin',
  CHECKOUT_PATH: '/mobile/v2/checkout',
  CONTEXT_PATH: '/mobile/v2/context',
  UPDATE_USER_PROFILE: '/mobile/v2/updateHaulerEmployeeProfile',
  SERVER_STARTUP_CONTEXT_PATH: '/mobile/v2/startup',
};

@Injectable({
  providedIn: 'root'
})
export class MobileContextService {

  protected _userProfileSubject: BehaviorSubject<UserProfile> = new BehaviorSubject(null);
  public userProfileObservable: Observable<UserProfile> = this._userProfileSubject.asObservable();

  protected _haulerSubject: BehaviorSubject<Hauler> = new BehaviorSubject(null);
  public haulerObservable: Observable<Hauler> = this._haulerSubject.asObservable();

  protected _userSettingsSubject: BehaviorSubject<UserSettings> = new BehaviorSubject(new UserSettings(true));
  public userSettingsObservable: Observable<UserSettings> = this._userSettingsSubject.asObservable();

  protected _orderStatusSubject: BehaviorSubject<OrderStatus> = new BehaviorSubject(OrderStatus.ON_THE_WAY_TO_CUSTOMER);
  public orderStatusObservable: Observable<OrderStatus> = this._orderStatusSubject.asObservable();

  protected _providedConfigurationSubject: BehaviorSubject<ProvidedConfiguration> = new BehaviorSubject(null);
  public providedConfigurationObservable: Observable<ProvidedConfiguration> = this._providedConfigurationSubject.asObservable();

  protected _oauthTokenSubject: BehaviorSubject<OAuthToken> = new BehaviorSubject(null);
  public oauthTokenObservable: Observable<OAuthToken> = this._oauthTokenSubject.asObservable();

  protected _versionsSubject: BehaviorSubject<Versions> = new BehaviorSubject(null);
  public versionsObservable: Observable<Versions> = this._versionsSubject.asObservable();

  protected _policiesSubject: BehaviorSubject<Policies> = new BehaviorSubject<Policies>(null);
  public policiesObservable: Observable<Policies> = this._policiesSubject.asObservable();

  private context: MobileConfiguration;
  private endpointService: EndpointService;
  private log: LogService;

  private initialContextSubmit = null;
  private regularContextSubmit = null;
  private command: RemoteManagementCommandData;

  constructor(private http: HttpClient,
              private injector: Injector,
              private deviceInfo: DeviceInfo,
              private translateService: TranslateService,
              private capacitorPlugins: CapacitorPlugins) {
    setTimeout(() => this.endpointService = this.injector.get(EndpointService));
    setTimeout(() => this.log = this.injector.get(LogService));
  }

  public getConfigurationAndBootstrapApplication(oauthToken?: OAuthToken): Observable<MobileConfiguration> {
    return this.getConfiguration(oauthToken.access_token)
      .pipe(
        mergeMap(mobileConfiguration => {
          return this.bootstrapApplication(mobileConfiguration, oauthToken);
        })
      );
  }

  public bootstrapApplication(mobileConfiguration: MobileConfiguration, oauthToken?: OAuthToken): Observable<MobileConfiguration> {
    this.log.trace('Bootstrapping application with configuration : ', mobileConfiguration);

    if (this.initialContextSubmit) {
      clearTimeout(this.initialContextSubmit);
    }
    // TODO : Move this to a retained MQTT topic and make the webapp listen to it.
    this.initialContextSubmit = setTimeout(() => {
      if (this.context) {
        this.log.info('Submitting mobile context (once 1 minute after login)');
        this.submitContext()
          .subscribe({
            error: (error) => {
              this.log.error('Context submission after login failed with error', error);
            }
          });
      }
    }, 1 * 60 * 1000);

    if (this.regularContextSubmit) {
      clearInterval(this.regularContextSubmit);
    }
    this.regularContextSubmit = setInterval(() => {
      if (this.context) {
        this.log.info('Submitting mobile context (every 15 minutes)');
        this.submitContext()
          .subscribe({
              next: (serverContext) => {
                this.updatePolicies(serverContext.policies);
                this.executeServerContextCommands(serverContext);
              },
              error: (error) => {
                this.log.error('Context submission by interval failed with error', error);
              }
            }
          );
      }
    }, 15 * 60 * 1000);

    return new Observable<MobileConfiguration>(observer => {
      this.newContext(mobileConfiguration);
      this.newOauthToken(oauthToken);
      observer.next(mobileConfiguration);
      observer.complete();
    });
  }

  public newContext(mobileConfiguration: MobileConfiguration) {
    if (mobileConfiguration) {
      this.context = mobileConfiguration;
      if (!this.context.userSettings) {
        this.context.userSettings = new UserSettings();
      }
      this.persistContextToStorage(mobileConfiguration);
      this.newUserProfile(mobileConfiguration.userProfile);
      this.newUserSettings(mobileConfiguration.userSettings);
      this.newProvidedConfiguration(mobileConfiguration.providedConfiguration);
      this.newHauler(mobileConfiguration.hauler);
      this._versionsSubject.next(mobileConfiguration.versions);
      this.executeServerContextCommands(mobileConfiguration.serverContext);
    } else {
      this.context = null;
      this.persistContextToStorage(null);
      this.newUserProfile(null);
      this.newUserSettings(null);
      this.newProvidedConfiguration(null);
      this.newHauler(null);

      if (this.regularContextSubmit) {
        clearInterval(this.regularContextSubmit);
      }
      if (this.initialContextSubmit) {
        clearTimeout(this.initialContextSubmit);
      }
    }
    return this.context;
  }

  public newUserSettings(userSettings: UserSettings) {
    if (userSettings) {
      const permissiveHauler: boolean = this.context && this.context.hauler && this.context.hauler.employeeControlsSubscriptions;
      // Enriching already saved selected providers
      userSettings.selectedProviders.forEach(provider => {
        const providerCandidate = this.context.hauler.linkedProviders.find(providerData =>
          providerData.id.toString() === provider.id.toString()
        );
        if (providerCandidate) {
          provider.name = providerCandidate.name;
          provider.tags = providerCandidate.tags;
          provider.position = providerCandidate.position;
          provider.address = providerCandidate.address;
          if (provider.visible === undefined) {
            provider.visible = permissiveHauler;
          }
          if (provider.enabled === undefined) {
            provider.enabled = permissiveHauler;
          }
        } else {
          // Removing already selected providers which are not available anymore
          const index = userSettings.selectedProviders.indexOf(provider, 0);
          if (index > -1) {
            userSettings.selectedProviders.splice(index, 1);
          }
        }
      });

      userSettings.selectedProviders = userSettings.selectedProviders.sort((a: Provider, b: Provider) => {
        return !a.name ? 0 : a.name.localeCompare(b.name);
      });

      // Adding new provider to list of selected providers
      this.context.hauler.linkedProviders.forEach(provider => {
        const providerCandidate = userSettings.selectedProviders.find(providerData =>
          providerData.id.toString() === provider.id.toString()
        );
        if (!providerCandidate) {
          const selectedProvider = new SelectedProvider();
          selectedProvider.id = provider.id;
          selectedProvider.selected = permissiveHauler;
          selectedProvider.enabled = permissiveHauler;
          selectedProvider.visible = permissiveHauler;
          selectedProvider.hidden = false;
          selectedProvider.name = provider.name;
          selectedProvider.tags = provider.tags;
          selectedProvider.position = provider.position;
          selectedProvider.address = provider.address;
          userSettings.selectedProviders.push(selectedProvider);
        }
      });

      userSettings.canDoRouteOptimization = this.context.hauler.canDoRouteOptimization;
      userSettings.routeEtaActivated = this.context.hauler.routeEtaActivated;
    } else {
      userSettings = new UserSettings(true);
      userSettings.language = this.translateService.getBrowserLang();
    }

    this.translateService.use(userSettings.language).subscribe();
    this._userSettingsSubject.next(userSettings);
    this.log.setUserSettings(userSettings);
  }

  public loadContextFromStorage(): Observable<MobileConfiguration> {
    return from(this.capacitorPlugins.getPreferencesPlugin().get({key: CONFIG.CONFIGURATION_KEY})
      .then(mobileConfigurationString => plainToClass(MobileConfiguration, JSON.parse(mobileConfigurationString.value))));
  }

  public clearContext() {
    this.newContext(null);
  }

  // User settings
  public settingsHaveChanged(persistChangeImmediately: boolean) {
    if (this.context) {
      this.newUserSettings(this.context.userSettings);
    }

    if (persistChangeImmediately) {
      this.submitContext() // FIXME send the context in a retained mqtt message on a topic
        .subscribe(() => {
          this.log.info('Context successfully submitted');
        }, error => {
          this.log.info('Error while submitting context : ', error);
        });
    }
  }

  public statusHasChanged(status: OrderStatus) {
    if (this.context && this.context.userSettings) {
      this._orderStatusSubject.next(status);
    }
  }

  /**
   * FOR TESTS ONLY !
   */
  public getUserProfile_FOR_TESTS_ONLY(): UserProfile {
    return this.context.userProfile;
  }

  private newUserProfile(userProfile: UserProfile) {
    let object = null;
    if (userProfile) {
      object = classToClass(UserProfile, userProfile);
    }
    this._userProfileSubject.next(object);
    this.log.setUserProfile(object);
  }

  private newHauler(hauler: Hauler) {
    let object = null;
    if (hauler) {
      object = classToClass(Hauler, hauler);
    }
    this._haulerSubject.next(object);
    this.log.setHauler(object);
  }

  private newProvidedConfiguration(providedConfiguration: ProvidedConfiguration) {
    let object = null;
    if (providedConfiguration) {
      object = classToClass(ProvidedConfiguration, providedConfiguration);
    }
    this._providedConfigurationSubject.next(object);
  }

  private newOauthToken(oauthToken: OAuthToken) {
    this._oauthTokenSubject.next(classToClass(OAuthToken, oauthToken));
  }

  private getConfiguration(accessToken: string): Observable<MobileConfiguration> {
    const url = this.endpointService.currentEndpoint + CONFIG.CHECKIN_PATH;
    const headers = new HttpHeaders();
    if (accessToken) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    }
    this.log.trace('Fetching user configuration and context data.');
    return this.http.post(url, classToPlain(this.buildMobileCheckInRequest()), {headers: headers})
      .pipe(
        map((body: object) => plainToClass(MobileConfiguration, body))
      );
  }

  private persistContextToStorage(mobileConfiguration: MobileConfiguration) {
    if (mobileConfiguration) {
      this.capacitorPlugins.getPreferencesPlugin().set({key: CONFIG.CONFIGURATION_KEY, value: JSON.stringify(classToPlain(mobileConfiguration))});
    } else {
      this.capacitorPlugins.getPreferencesPlugin().remove({key: CONFIG.CONFIGURATION_KEY});
    }
  }

  private buildMobileCheckInRequest(): MobileCheckIn {
    const mobileCheckin = new MobileCheckIn();

    mobileCheckin.deviceInfo = new DeviceInfoCheckin();
    mobileCheckin.deviceInfo.uuid = this.deviceInfo.uuid;
    mobileCheckin.deviceInfo.manufacturer = this.deviceInfo.manufacturer;
    mobileCheckin.deviceInfo.model = this.deviceInfo.model;
    mobileCheckin.deviceInfo.osVersion = this.deviceInfo.osVersion;
    mobileCheckin.deviceInfo.platform = this.deviceInfo.platform;
    if (this.context && this.context.userSettings) {
      mobileCheckin.deviceInfo.selectedLanguage = (this.context
        && this.context.userSettings
        && this.context.userSettings.language)
        || this.deviceInfo.preferredLanguage;
    }
    mobileCheckin.deviceInfo.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    mobileCheckin.deviceInfo.localDateTime = moment().toISOString();
    mobileCheckin.deviceInfo.deviceLanguage = this.deviceInfo.preferredLanguage;
    mobileCheckin.deviceInfo.batteryCharge = (this.deviceInfo && this.deviceInfo.batteryLevel) ? this.deviceInfo.batteryLevel.toString() : 'n/a';

    mobileCheckin.appInfo = new AppInfoCheckin();
    mobileCheckin.appInfo.name = 'ca.mobilus.mobile.hauler#V2';
    mobileCheckin.appInfo.unifiedVersion = this.deviceInfo.unifiedVersion;
    mobileCheckin.appInfo.nativeVersion = this.deviceInfo.nativeVersion || 'unknown';
    mobileCheckin.appInfo.ionicVersion = this.deviceInfo.ionicVersion || 'unknown';

    // mobileCheckin.simInfo = new SimInfoCheckin();
    // mobileCheckin.simInfo.phoneNumber = this.deviceInfo.phoneNumber();
    // mobileCheckin.simInfo.carrierName = this.deviceInfo.carrierName();
    // mobileCheckin.simInfo.countryCode = this.deviceInfo.countryCode();
    // mobileCheckin.simInfo.networkType = this.deviceInfo.networkType();

    mobileCheckin.permissions = JSON.stringify(classToPlain(this.deviceInfo.permissions));

    // FIXME : push token should be sent when checking or context
    // mobileCheckin.pushTokens = [];
    //
    // if (this.deviceInfo.firebaseToken) {
    //   mobileCheckin.pushTokens.push(new PushTokenInfoCheckin('FCM', this.deviceInfo.firebaseToken))
    // }
    if (this.context && this.context.userSettings) {
      mobileCheckin.userSettings = JSON.stringify(classToPlain(this.context.userSettings));
    }

    return mobileCheckin;
  }

  checkout() {
    const url = this.endpointService.currentEndpoint + CONFIG.CHECKOUT_PATH;
    this.log.debug('Submitting logout.');
    this.http.post(url, classToPlain(this.buildMobileCheckInRequest()))
      .pipe(
        map((body: object) => plainToClass(ServerContext, body)),
        catchError(() => of(null))
      )
      // Before, this post wasn't returning any result but now it does
      // so we catch the json parsing error to continue
      .subscribe((serverContext: ServerContext) => {
        this.log.info('Logout successfully submitted');
        this.executeServerContextCommands(serverContext);
      }, error => {
        this.log.info('Error while logout : ', error);
      }, () => {
        this.log.info('Clearing local context.');
        this.clearContext();
        this.log.info('Logout completed.');
      });
  }


  updateUserProfile(firstName, lastName, phoneNumber, email) {
    const url = this.endpointService.currentEndpoint + CONFIG.UPDATE_USER_PROFILE;
    return this.http.post(url, {firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, email: email}).pipe(
      map(data => {
        const userProfile = plainToClass(UserProfile, data);
        this.newUserProfile(userProfile);
      })
    );
  }

  submitContext() {
    const url = this.endpointService.currentEndpoint + CONFIG.CONTEXT_PATH;
    this.log.debug('Submitting context.');
    return this.http.post(url, classToPlain(this.buildMobileCheckInRequest()))
      .pipe(
        map((body: object) => plainToClass(ServerContext, body))
      );
  }

  public getServerStartupContext() {
    const url = this.endpointService.currentEndpoint + CONFIG.SERVER_STARTUP_CONTEXT_PATH;
    this.log.debug('getting server context.');
    try {
      return this.http.post(url, classToPlain(this.buildMobileCheckInRequest()))
        .pipe(
          map((body: object) => plainToClass(ServerContext, body))
        );
    } catch (error) {
      this.log.error('error while getting server context', error);
    }

  }

  private executeServerContextCommands(serverContext: ServerContext) {
    if (serverContext) {
      serverContext.commands.forEach(command => {
        this.command = command;
        this.sendCommandToManagement();
      });
    }
  }

  sendCommandToManagement(): Observable<any> {
    return new Observable<any>(subscriber => {
      subscriber.next(this.command);
      subscriber.complete();
    });
  }

  updatePolicies(policies: Policies) {
    this._policiesSubject.next(policies);
  }

}
