import {Injectable, Injector} from '@angular/core';
import {RemoteManagementCommandData} from '@models/push-messages/remote-management-command.data.model';
import {RemoteManagementCommandEnum} from '@models/push-messages/remote-management-command.enum.model';
import {RemoteCommandMessageModalPage} from '@app/pages/remote-command-message-modal/remote-command-message-modal.page';
import {Platform} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {ToastController} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {DeviceInfoService} from '@services/device-info/device-info.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import {ReportingService} from '@services/reporting-service/reporting.service';
import {SettingsManager} from '@services/settings-providers/settings.service';

const CONFIG = {
  GA: {
    EVENT: {
      FORCING_SYNC: {
        NAME: 'force-sync',
        ACTION: 'on-remote-command'
      }
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class RemoteManagementService {

  private authenticationService: AuthenticationService;
  private orderSync: OrderSyncService;
  private ionicDeploy: IonicDeploy;
  private reportingService: ReportingService;

  constructor(private platform: Platform,
              private deviceInfoService: DeviceInfoService,
              private injector: Injector,
              private log: LogService,
              private settingsManager: SettingsManager,
              private alertCtrl: AlertController,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              private modalCtrl: ModalController,
              private ga: MblsAnalyticsService,
              private navController: NavController,
              private mobileContext: MobileContextService,
  ) {
    this.mobileContext.sendCommandToManagement()
      .subscribe(command => {
        this.executeCommand(command);
      });
  }


  async executeCommand(remoteManagementCommand: RemoteManagementCommandData) {
    if (remoteManagementCommand && remoteManagementCommand.remoteManagementCommand) {
      switch (remoteManagementCommand.remoteManagementCommand) {
        case RemoteManagementCommandEnum.PERFORM_FULL_REFRESH: {
          this.log.info('Remote management : performing full refresh.');
          this.ga.trackEvent(CONFIG.GA.EVENT.FORCING_SYNC.NAME, CONFIG.GA.EVENT.FORCING_SYNC.ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.FORCING_SYNC} with GA`, error));
          this.orderSync.fullSync()
            .subscribe();
          break;
        }
        case RemoteManagementCommandEnum.FORCE_CLOSE: {
          this.log.info('Remote management : exiting app.');
          // @ts-ignore
          this.platform.app.exitApp();
          break;
        }
        case RemoteManagementCommandEnum.FORCE_IONIC_CHANNEL: {
          this.log.info('Setting ionic channel');
          this.setChannelName(remoteManagementCommand.payload.get('channelName'));
          break;
        }
        case RemoteManagementCommandEnum.FORCE_IONIC_UPDATE: {
          this.log.info('Forcing ionic update');
          this.setChannelName(remoteManagementCommand.payload.get('channelName'));
          this.forceIonicUpdate();
          break;
        }
        case RemoteManagementCommandEnum.FORCE_LOGOUT: {
          this.log.info('Remote management : logging out the user.');
          this.authenticationService.doLogout();
          this.navController.navigateRoot('/login');
          break;
        }
        case RemoteManagementCommandEnum.FORCE_REGISTRATION: {
          this.log.info('Remote management : forcing registration of user.');
          this.authenticationService.onlineLogin().subscribe();
          break;
        }
        case RemoteManagementCommandEnum.SEND_COMPLETE_REPORT: {
          this.log.info('Remote management: Sending report');
          this.reportingService.sendSnapshotReport();
          break;
        }
        case RemoteManagementCommandEnum.RELOAD_SERVER_CONFIGURATION: {
          this.log.info('Remote management: Reloading server configuration');
          this.authenticationService.onlineLogin().subscribe();
          break;
        }
        case RemoteManagementCommandEnum.SEND_PERMISSIONS: {
          this.log.info('Remote management: sending app permissions');
          Promise.all(this.deviceInfoService.updatePermissions())
            .then(results => {
              this.deviceInfoService.submitPermissions();
            });
          break;
        }
        case RemoteManagementCommandEnum.RESET_LOCAL_CHANNEL: {
          this.log.info('Remote management: resetting local channel');
          this.ionicDeploy.resetLocalChannel();
          break;
        }
        case RemoteManagementCommandEnum.RESET_USER_CHANNEL: {
          this.log.info('Remote management: resetting user channel');
          this.settingsManager.setIonicChannel(null);
          break;
        }
        case RemoteManagementCommandEnum.RESET_LOCAL_AND_USER_CHANNEL: {
          this.log.info('Remote management: resetting local and user channel');
          this.resetLocalAndUserChannel();
          break;
        }
        case RemoteManagementCommandEnum.DISPLAY_ALERT: {
          this.log.info('Remote management: displaying alert');
          try {
            const alert = await this.alertCtrl.create({
              header: this.translateService.instant(remoteManagementCommand.payload.get('title')),
              subHeader: this.translateService.instant(remoteManagementCommand.payload.get('message')),
              buttons: [this.translateService.instant('actions.ok')]
            });
            alert.present();
          } catch (e) {
          }
          break;
        }
        case RemoteManagementCommandEnum.DISPLAY_MODAL: {
          this.log.info('Remote management: displaying message');
          const content = {
            body: remoteManagementCommand.payload.get('message'),
            title: remoteManagementCommand.payload.get('title')
          };
          const modal = await this.modalCtrl.create({
              component: RemoteCommandMessageModalPage,
              componentProps: content
            },
          );
          modal.present();
          break;
        }
        case RemoteManagementCommandEnum.DISPLAY_TOAST: {
          this.log.info('Remote management: displaying toast');
          const toast = await this.toastCtrl.create({
            message: this.translateService.instant(remoteManagementCommand.payload.get('message')),
            duration: Number(remoteManagementCommand.payload.get('duration')) || 3 * 1000,
            cssClass: 'processing-toast'
          });
          toast.present();
          break;
        }
        case RemoteManagementCommandEnum.LOAD_NEW_USER_SETTINGS: {
          if (remoteManagementCommand.userSettings) {
            this.log.info('Remote management: load new user settings');
            this.mobileContext.newUserSettings(remoteManagementCommand.userSettings);
          }
          break;
        }
        default: {
          this.log.error('remoteManagementCommand just fell through for ', remoteManagementCommand.remoteManagementCommand);
        }
      }
    }
  }

  private setChannelName(channelName?: string) {
    if (channelName) {
      this.log.info('Channel set to', channelName);
      this.ionicDeploy.forceChannelForUser(channelName);
    } else {
      this.log.error('Missing channelName');
    }
  }

  private forceIonicUpdate() {
    this.log.info('Ionic update starting');
    this.ionicDeploy.installNewVersion();
  }

  private resetLocalAndUserChannel() {
    this.ionicDeploy.removeChannelFromStorage()
      .then(() => this.settingsManager.setIonicChannel(null)
      );
  }

  injectServices(authenticationService: AuthenticationService, ionicDeploy: IonicDeploy, reportingService: ReportingService) {
    this.authenticationService = authenticationService;
    this.ionicDeploy = ionicDeploy;
    this.reportingService = reportingService;
  }
}
