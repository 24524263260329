import { Platform } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

// MODELS
import { Position as GeolocationPosition } from '@capacitor/geolocation';
import { GeolocationConfigBase, GeolocationConfigForPWA } from '@models/configuration/background-geolocation.model';
import { Config as GeolocationConfigForNative, Location } from '@transistorsoft/capacitor-background-geolocation';
import { DeviceInfo } from '@models/information/device-info.model';
import { OAuthToken } from '@models/oauth-token.model';
import { Position } from '@models/business/position.model';
import { ProvidedConfiguration } from '@models/configuration/provided-configuration.model';
import { UserSettings } from '@models/settings/settings.model';
import { UserProfile } from '@models/user-profile.model';

// PROVIDERS
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';
import { DeviceUtils } from '@services/utils/device-utils';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { GeolocationUtils } from '@services/utils/geolocation-utils';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { LogService } from '@services/log/log.service';

export abstract class BackgroundGeolocationService {

    protected readonly CONFIG = {
        BACKGROUND_GEOLOCATION_ENDPOINT: '/mobile/v2/submitPositions'
    };

    protected serviceIsRunning: boolean;
    protected oauthToken: OAuthToken;
    protected userSettings: UserSettings;
    protected userProfile: UserProfile;

    protected _positionSubject: BehaviorSubject<Position> = new BehaviorSubject<Position>(null);
    public positionObservable: Observable<Position> = this._positionSubject.asObservable();

    protected readonly defaultPosition: Position = new Position(45.400529, -71.896220); // Espace-inc Sherbrooke
    protected fallBackPosition: Position;
    protected providedConfiguration: ProvidedConfiguration;

    protected backgroundGeolocationConfig: GeolocationConfigForNative | GeolocationConfigForPWA;

    constructor(
        protected capacitorPlugins: CapacitorPlugins,
        protected deviceInfo: DeviceInfo,
        protected deviceUtils: DeviceUtils,
        protected endpointService: EndpointService,
        protected geolocationUtils: GeolocationUtils,
        protected mobileContextService: MobileContextService,
        protected logService: LogService,
        protected platform: Platform,
        protected translate: TranslateService
    ) {
        this.serviceIsRunning = false;
        this.oauthToken = null;
        this.userSettings = null;
        this.userProfile = null;

        this.fallBackPosition = new Position(this.defaultPosition.latitude, this.defaultPosition.longitude);
        this.providedConfiguration = null;

        this.backgroundGeolocationConfig = null;
    }

    public getLastKnownPosition(): Position {
        return this._positionSubject.value;
    }

    public isActive(): boolean {
        return this.serviceIsRunning;
    }

    /**
     * Method to retrieve the current Location.
     *
     * This method fetches exactly one location using maximum power & accuracy.
     * The code will persist the fetched location to its persistent database just as any other location
     * in addition to POSTing to your configured Config.url.
     */
    public abstract recheckPosition(): Promise<Position | undefined>;

    protected async init(): Promise<void> {
        await this.platform.ready();

        combineLatest([
            this.mobileContextService.oauthTokenObservable,
            this.mobileContextService.userSettingsObservable,
            this.mobileContextService.providedConfigurationObservable,
        ])
        .pipe(
            filter(([oauthToken, userSettings, providedConfiguration]) => {
                return oauthToken != null && userSettings != null && providedConfiguration != null;
            })
        )
            .subscribe(async ([oauthToken, userSettings, providedConfiguration]) => {
                this.oauthToken = oauthToken;
                this.userSettings = userSettings;
                this.providedConfiguration = providedConfiguration;
                this.backgroundGeolocationConfig = this.createConfig(this.userSettings.geotrackingEnabled);
                await this.onConfigAvailable(this.backgroundGeolocationConfig);
                this.toggleService();
            });

        this.mobileContextService.userProfileObservable
            .subscribe(userProfile => {
                this.userProfile = userProfile;
            });

    }
    protected abstract onConfigAvailable(backgroundGeolocationConfig: GeolocationConfigForNative | GeolocationConfigForPWA): Promise<void>;

    /**
     * Method to construct url where to publish positions
     */
    private getPublishUrl(): string {
        let publishUrl = this.endpointService.currentEndpoint + this.CONFIG.BACKGROUND_GEOLOCATION_ENDPOINT;
        if (this.providedConfiguration && this.providedConfiguration.geolocationEndpoint) {
            if (this.providedConfiguration.geolocationEndpoint.startsWith('http')) {
                publishUrl = this.providedConfiguration.geolocationEndpoint;
            } else {
                publishUrl = this.endpointService.currentEndpoint + this.providedConfiguration.geolocationEndpoint;
            }
        }
        return publishUrl;
    }

    protected toggleService(): void {
        if (this.serviceIsRunning === true) {
            this.stop();
        }

        if (this.oauthToken && this.oauthToken.access_token) {
            const isAndroid = this.platform.is('android');
            if (this.userSettings && !this.userSettings.dummy && (!isAndroid || (isAndroid && (this.userSettings.hideLocationDisclosure || this.userSettings.geotrackingConsentPopupHasBeenSeen)))) {
                // NOTE: Start as full config
                this.start();
            }
        }
    }

    protected async start(): Promise<void> {
        this.logService.debug('[BackgroundGeolocationService] start - checking permission before starting background geolocation');
        try {
            await this.geolocationUtils.isGeolocationAuthorized();
            this.logService.debug('[BackgroundGeolocationService] start - location authorized, startingGeo');
            this.startingGeo();
        } catch (error) {
            this.logService.warn('[BackgroundGeolocationService] start - the user refused geolocation. Will work without it');
        }
    }

    protected abstract startingGeo(): Promise<void>;

    protected abstract stop(): void;

    /**
     * Method to create background geolocation config for native or PWA
     *
     * @param {boolean} geotrackingEnabled flag to indicate if geotrackingEnabled is enabled or not
     */
    protected abstract createConfig(geotrackingEnabled: boolean): GeolocationConfigForNative | GeolocationConfigForPWA;

    /**
     * Method to create base config with all the commom params/settings working for both, the native and PWA
     *
     * NOTE: easier to configure for the both platforms and also make sure that the same values are used for the both native and PWA
     * therefore we get the similar behaviour (where applicable)
     */
    protected createGeolocationBaseConfig(geotrackingEnabled: boolean): GeolocationConfigBase {
        const publishUrl = this.getPublishUrl();
        const geolocationConfigBase: GeolocationConfigBase = {
            url: publishUrl,
            method: 'POST',
            autoSync: geotrackingEnabled != null ? geotrackingEnabled : true, // use default value of true if autoSync not present
            autoSyncThreshold: 0, // use default value of 0 (no threshold)
            distanceFilter: 10,
            batchSync: true,
            maxBatchSize: 500,
            maxDaysToPersist: 1,
            maxRecordsToPersist: -1, // default is -1
            headers: {
                Authorization: 'Bearer ' + this.oauthToken.access_token
            },
            httpRootProperty: 'location',
            params: {},
            debug: false,
            extras: {
                courierUuid: this.userProfile.haulerEmployeeUuid
            }
        }
        return geolocationConfigBase;
    }

    protected extractAndSend(location: Location | GeolocationPosition): void {
        if (location.coords) {
            const lastKnownPosition = new Position(location.coords.latitude, location.coords.longitude, location.coords.accuracy, moment(location.timestamp));
            this._positionSubject.next(lastKnownPosition);
        } else {
            this.logService.info('[BackgroundGeolocationService] extractAndSend - unable to extract positions from input', location);
        }
    }

    public setFallbackPosition(position: Position) {
        this.fallBackPosition = position;
        this._positionSubject.next(position);
    }

}

