import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {OrderManifestPage} from './order-manifest.page';

const routes: Routes = [
  {
    path: '',
    component: OrderManifestPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FontAwesomeModule,
    ComponentsModule,
  ],
  exports: [
    OrderManifestPage
  ],
  declarations: [OrderManifestPage]
})
export class OrderManifestPageModule {
}
