import {Input, OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {Hauler} from '@models/business/hauler.model';
import {Payment} from '@models/business/transition-data.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {Transition} from '@models/workflow/transition.model';
import * as moment from 'moment';

@Component({
  selector: 'mobilus-order-item-transition',
  templateUrl: './order-item-transition.component.html',
  styleUrls: ['./order-item-transition.component.scss'],
})
export class OrderItemTransitionComponent implements OnInit {

  @Input() forceLongAddress = true;
  @Input() reference: moment.Moment;
  @Input() status: string;
  @Input() orderView: OrderView;
  @Input() index: number;
  @Input() isforHistory = false;
  @Input() styleInvalidity = false;
  @Input() transition: Transition;
  @Input() hauler: Hauler;
  @Input() showDoorCode = false;

  withDoorCode: boolean = false;

  constructor() {
  }

  get displayProvider(): boolean {
    if (!this.orderView) {
      return false;
    }
    return this.orderView.order.stateMachineWorkflow === 'PICKUP';
  }

  ngOnInit(): void {
    if (this.showDoorCode && this.orderView.order.lockerData) {
      this.withDoorCode = true;
    }

    if (this.transition.to.name === 'DELIVERED' && this.hauler && this.orderView.order.amount === 0) {
      const payment = new Payment(this.orderView.order.amount, null);
      this.orderView.prepareForData();
      this.orderView.transitionData.addPayment(payment);
      this.orderView.updateValidity(this.transition.event.transitionDetail, this.hauler);
    }
  }

}

