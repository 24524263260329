import {State} from '@models/workflow/state.model';
import {Event} from '@models/workflow/event.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Transition')
export class Transition {

  // Using string to avoid circular dependency when stringifyng
  @JsonProperty('workflowName', String)
  workflowName: string = undefined;

  @JsonProperty('from', State)
  from: State = undefined;

  @JsonProperty('to', State)
  to: State = undefined;

  @JsonProperty('event', Event)
  event: Event = undefined;

  @JsonProperty('secondary', Boolean, true)
  secondary = false;

  @JsonProperty('requireOnline', Boolean, true)
  requireOnline = false;

  @JsonProperty('needsCompletion', Boolean, true)
  needsCompletion = false;

  @JsonProperty('impersonate', Boolean, true)
  impersonate = false;

  constructor(workflowName: string, from: State, to: State, event: Event, secondary: boolean = false, requireOnline: boolean = false, needsCompletion: boolean = false, impersonate: boolean = false) {
    if (workflowName) {
      this.workflowName = workflowName;
    }
    this.from = from;
    this.to = to;
    this.event = event;
    this.secondary = secondary;
    this.requireOnline = requireOnline;
    this.needsCompletion = needsCompletion;
    this.impersonate = impersonate;
  }
}
