import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// MODELS
import { SortDirection, SortedPropertyType } from '@models/base/base';
import { Order } from '@models/business/order.model';
import { TransitionRequest } from '@models/workflow/transition-request.model';

// PROVIDERS
import { CommonUtils } from '@services/utils/common-utils';

@Component({
  templateUrl: './transition-detail-debug.component.html',
  styleUrls: ['./transition-detail-debug.component.scss'],
})
export class TransitionDetailDebugComponent implements OnInit {

  @Input() awaitingTransitionAndOrder: {
    transitionRequest: TransitionRequest,
    order: Order
  };

  constructor(
    private commonUtils: CommonUtils,
    private modalController: ModalController
  ) {
    this.awaitingTransitionAndOrder = null;
  }

  public async ngOnInit() {
    if (this.awaitingTransitionAndOrder && this.awaitingTransitionAndOrder.transitionRequest && this.awaitingTransitionAndOrder.transitionRequest.transitionRequestLogs) {
      this.commonUtils.sortArrayByProperty(this.awaitingTransitionAndOrder.transitionRequest.transitionRequestLogs, 'date', SortedPropertyType.DATETIME, SortDirection.DESC);
    }
  }

  public close() {
    this.modalController.dismiss();
  }

}
