import {RemoteManagementCommandEnum} from '@models/push-messages/remote-management-command.enum.model';
import {UserSettings} from '@models/settings/settings.model';
import {JsonMapConverter} from '@utils/json-converter/json-converter';
import {JsonCustomConvert} from 'json2typescript';
import {JsonConverter} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';


@JsonConverter
export class RemoteManagementCommandEnumConverter implements JsonCustomConvert<RemoteManagementCommandEnum> {
  serialize(enumValue: RemoteManagementCommandEnum): string {
    return RemoteManagementCommandEnum[enumValue];
  }

  deserialize(enumValue: string): RemoteManagementCommandEnum {
    return RemoteManagementCommandEnum[enumValue.toUpperCase()];
  }
}

@JsonObject('RemoteManagementCommandData')
export class RemoteManagementCommandData {
  @JsonProperty('remoteManagementCommand', RemoteManagementCommandEnumConverter, true)
  remoteManagementCommand: RemoteManagementCommandEnum;
  @JsonProperty('payload', JsonMapConverter, true)
  payload: Map<string, string>;
  @JsonProperty('userSettings', UserSettings, true)
  userSettings: UserSettings;

  constructor(remoteManagementCommand: RemoteManagementCommandEnum, payload: Map<string, string>, userSettings?: UserSettings) {
    this.remoteManagementCommand = remoteManagementCommand;
    this.payload = payload;
    this.userSettings = userSettings;
  }
}
