import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('OAuthToken')
export class OAuthToken {

  @JsonProperty('access_token', String)
  access_token: string = undefined;

  @JsonProperty('expires_in', Number)
  expires_in: number = undefined;

  @JsonProperty('expiration_date', MomentDateConverter, true)
  expiration_date: moment.Moment = undefined;

  @JsonProperty('refresh_token', String)
  refresh_token: string = undefined;

  @JsonProperty('scope', String)
  scope: string = undefined;

  @JsonProperty('token_type', String)
  token_type: string = undefined;

  setExpiration_date() {
    if (this.expires_in) {
      this.expiration_date = moment().add(this.expires_in, 'second');
    }
  }
}
