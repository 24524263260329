import { Injectable } from '@angular/core';
import { UserSettings } from '@models/settings/settings.model';
import { DateFormatEnum } from '@models/units-and-format/date-format.enum';
import { DistanceSystemEnum } from '@models/units-and-format/distance-system.enum';
import { TimeFormatEnum } from '@models/units-and-format/time-format.enum';
import { TranslateService } from '@ngx-translate/core';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { SettingsManager } from '@services/settings-providers/settings.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private userSettings: UserSettings;

  public browserLang;

  constructor(private translateService: TranslateService,
    private mobileContextService: MobileContextService,
    private settingsManager: SettingsManager,
  ) {
    if (this.mobileContextService) {
      this.mobileContextService.userSettingsObservable
        .subscribe((userSettings) => {
          this.userSettings = userSettings;
          this.browserLang = this.translateService.getBrowserCultureLang();
        });
    }
  }

  public initDefaultSettings() {
    this.translateService.setDefaultLang('fr');
    this.translateService.addLangs(['fr', 'en']);
    moment.locale('fr');
  }

  public findAndApplyMostSuitableLanguage(): Promise<string> {
    return this.findMostSuitableLanguage(this.userSettings)
      .then(lang => this.applyLanguage(lang));
  }

  public async findMostSuitableLanguage(userSettings: UserSettings): Promise<string> {
    if (this.browserLang === 'en-US') {
      this.settingsManager.setDistanceUnit(DistanceSystemEnum.IMPERIAL, false);
      this.settingsManager.setHourFormat(TimeFormatEnum.STANDARD, false);
      this.settingsManager.setDateFormat(DateFormatEnum.dateMDY, false);
      return 'en';
    }
    const browserLang = this.translateService.getBrowserLang();
    const defaultLang = this.translateService.getDefaultLang();
    this.settingsManager.setDistanceUnit(DistanceSystemEnum.SI, false);
    this.settingsManager.setHourFormat(TimeFormatEnum.MILITARY, false);
    this.settingsManager.setDateFormat(DateFormatEnum.dateISO, false);
    if (userSettings && userSettings.language && !userSettings.dummy) {
      return userSettings.language;
    } else if (browserLang) {
      return browserLang;
    } else if (defaultLang) {
      return defaultLang;
    } else {
      return 'fr';
    }
  }

  public applyLanguage(lang: string): Promise<string> {
    this.translateService.use(lang).subscribe();
    moment.locale(lang);
    if (this.userSettings) {
      if (this.userSettings.language !== lang) {
        this.settingsManager.setLanguage(lang, true);
      }
    }
    return Promise.resolve(lang);
  }
}
