import {OrderStatus} from '@models/order-helper/order-status.enum';
import {
  ColorModeEnumColorModeEnumConverter,
  MapOptionEnumConverter,
  MapQueryOptionEnumConverter
} from '@models/push-messages/converters.model';
import {DateFormatEnumConverter} from '@models/push-messages/converters.model';
import {TimeFormatEnumConverter} from '@models/push-messages/converters.model';
import {DistanceSystemEnumConverter} from '@models/push-messages/converters.model';
import {SelectedProvider} from '@models/selected-provider.model';
import {ActionButtons} from '@models/settings/action-buttons.model';
import {ListActions} from '@models/settings/list-actions.model';
import {MapOptionEnum, MapSearchOptionEnum} from '@models/settings/map-option.enum';
import {StatusSettings} from '@models/settings/status-settings.model';
import {UserLocation} from '@models/settings/user-location.model';
import {DateFormatEnum} from '@models/units-and-format/date-format.enum';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import {ColorModeEnum} from '@models/settings/ColorModeEnum.model';

@JsonObject('UserSettings')
export class UserSettings {

  dummy = false;

  constructor(_default: boolean = false) {
    this.dummy = _default;
  }

  @JsonProperty('selectedProviders', [SelectedProvider], true)
  selectedProviders: Array<SelectedProvider> = [];

  @JsonProperty('geotrackingEnabled', Boolean, true)
  geotrackingEnabled = true;

  @JsonProperty('keepScreenOn', Boolean, true)
  keepScreenOn = false;

  @JsonProperty('displayType', Boolean, true)
  displayType = true;

  @JsonProperty('sortOrder', String, true)
  sortOrder = 'lastName';

  @JsonProperty('previousSortOrder', String, true)
  previousSortOrder = 'lastName';

  @JsonProperty('channel', String, true)
  channel: string = undefined;

  // The channel where the user is currently. Changing it server side does nothing (use channel instead)
  @JsonProperty('currentChannel', String, true)
  currentChannel: string = undefined;

  @JsonProperty('logLevel', String, true)
  logLevel = 'INF';

  @JsonProperty('language', String, true)
  language = 'fr';

  @JsonProperty('firstRun', Boolean, true)
  firstRun = true;

  @JsonProperty('logRocketEnabled', Boolean, true)
  logRocketEnabled = false;

  @JsonProperty('seenIntros', [String], true)
  seenIntros: Array<string> = [];

  @JsonProperty('sequenceOnSelect', Boolean, true)
  sequenceOnSelect = false;

  @JsonProperty('showAverageDeliveryMoment', Boolean, true)
  showAverageDeliveryMoment = false;

  @JsonProperty('confirmReturnOrderBackToStore', Boolean, true)
  confirmReturnOrderBackToStore = false;

  @JsonProperty('forStatus', [StatusSettings], true)
  forStatus: Array<StatusSettings> = [];

  @JsonProperty('buttons', ActionButtons, true)
  buttons: ActionButtons = new ActionButtons();

  @JsonProperty('userLocations', [UserLocation], true)
  userLocations: Array<UserLocation> = [];

  @JsonProperty('distanceUnit', DistanceSystemEnumConverter, true)
  distanceUnit: DistanceSystemEnum = DistanceSystemEnum.SI;

  @JsonProperty('hourFormat', TimeFormatEnumConverter, true)
  hourFormat: TimeFormatEnum = TimeFormatEnum.MILITARY;

  @JsonProperty('dateFormat', DateFormatEnumConverter, true)
  dateFormat: DateFormatEnum = DateFormatEnum.dateISO;

  @JsonProperty('mapOption', MapOptionEnumConverter, true)
  mapOption: MapOptionEnum = null;

  @JsonProperty('mapQueryOption', MapQueryOptionEnumConverter, true)
  mapQueryOption: MapSearchOptionEnum = MapSearchOptionEnum.ADDRESS_AND_COORDINATES;

  @JsonProperty('colorMode', ColorModeEnumColorModeEnumConverter, true)
  colorMode: ColorModeEnum = ColorModeEnum.AUTO;

  @JsonProperty('signaturePinActivated', Boolean, true)
  signaturePinActivated = false;

  @JsonProperty('signaturePinValue', String, true)
  signaturePinValue = '';

  @JsonProperty('signatureLockedOnLandscape', Boolean, true)
  signatureLockedOnLandscape = true;

  @JsonProperty('displayOrderReferenceOnOrderList', Boolean, true)
  displayOrderReferenceOnOrderList = true;

  @JsonProperty('displayProviderFooter', Boolean, true)
  displayProviderFooter = false;

  @JsonProperty('displayDelayCounter', Boolean, true)
  displayDelayCounter = true;

  @JsonProperty('hideLocationDisclosure', Boolean, true)
  hideLocationDisclosure = false;

  @JsonProperty('promptForGalleryWhenTakingPicture', Boolean, true)
  promptForGalleryWhenTakingPicture = false;

  @JsonProperty('hideBiometricOptInPopup', Boolean, true)
  hideBiometricOptInPopup = false;

  geotrackingConsentPopupHasBeenSeen = false;

  actions: ListActions = new ListActions();

  // Comes from hauler properties.
  canDoRouteOptimization = false;
  routeEtaActivated = false;
  nextSequenceNumber = 0;

  get providers(): Array<SelectedProvider> {
    return this.selectedProviders;
  }

  providersToString(): string {
    let out = '';
    this.selectedProviders.forEach(provider => {
      if (out && out !== '') {
        out += ', ';
      }
      out = out + provider.name + ' (' + provider.id + '/' + (provider.selected ? 'subscribed' : 'unsubscribed') + ')';
    });
    return out;
  }

  settingsForStatus(status: OrderStatus) {
    let settings: StatusSettings = this.forStatus.find(value => value.status === status);
    if (!settings) {
      settings = new StatusSettings();
      settings.status = status;
      settings.sortOrder = this.sortOrder ? this.sortOrder : settings.sortOrder;
      settings.previousSortOrder = this.previousSortOrder ? this.previousSortOrder : settings.previousSortOrder;
      this.forStatus.push(settings);
    }

    return settings;
  }

  getLocate() {
    return this.language === 'fr' ? 'fr-CA' : 'en-CA';
  }

}
