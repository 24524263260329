<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="order.reference && order.id" class="order-detail-tabs-title">
      <ion-row class="ion-align-items-center">
        <span class="reference_number">{{ order.reference }}</span>
        <ion-badge [hidden]="order.stateMachineWorkflow !== 'PICKUP'" class="workflow_badge"
                   [translate]="'workflows.abbrev.pickup'"></ion-badge>
        <span class="status">
          ( {{ 'workflows.status.' + order.status.toLowerCase() | translate }} )
        </span>
      </ion-row>
    </ion-title>
    <ion-title *ngIf="!order.reference && !order.id">
      <ion-row class="ion-align-items-center">
        <span *ngIf="!order.reference && !order.id">{{ 'workflows.status.not_activated' | translate }}</span>
        <ion-badge class="recurrence_badge"
                   [translate]="'workflows.abbrev.recurrence'"></ion-badge>
        <span class="status">
          ( {{ 'workflows.status.' + order.status.toLowerCase() | translate }} )
        </span>
      </ion-row>
    </ion-title>
    <ion-buttons slot="end">
      <mobilus-pending-indicator *ngIf="order.local"></mobilus-pending-indicator>
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="my-tabs">
    <!-- Impossible with @ViewChild('my_profile_tabs', {read: IonTabs}) tabRef: IonTabs; and tabRef.select('tabName') without using router in angular+ionic4 -->
    <div>
      <ion-tab-bar slot="top">
        <ion-tab-button (click)="tabChange('detail')" [ngClass]="tab === 'detail' ? 'tab-selected' : ''">
          <ion-label>
            <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
            <span translate="pages.order-detail-tabs.order_detail_title"></span>
          </ion-label>
        </ion-tab-button>
        <ion-tab-button (click)="tabChange('manifest')" [ngClass]="tab === 'manifest' ? 'tab-selected' : ''">
          <ion-label>
            <fa-icon [icon]="['fas', 'check']"></fa-icon>
            <span translate="pages.order-detail-tabs.order_manifest_title"></span>
          </ion-label>
        </ion-tab-button>
        <ion-tab-button (click)="tabChange('traceability')" [ngClass]="tab === 'traceability' ? 'tab-selected' : ''">
          <ion-label>
            <fa-icon [icon]="['fas', 'history']"></fa-icon>
            <span translate="pages.order-detail-tabs.order_history_title"></span>
          </ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </div>


    <div class="my-tabs-inner">
      <ng-container *ngIf="tab === 'detail'">
        <mobilus-order-detail></mobilus-order-detail>
      </ng-container>

      <ng-container *ngIf="tab === 'manifest'">
        <mobilus-order-manifest></mobilus-order-manifest>
      </ng-container>

      <ng-container *ngIf="tab === 'traceability'">
        <mobilus-order-traceability></mobilus-order-traceability>
      </ng-container>
    </div>

  </div>
</ion-content>

<ion-footer class="actions-footer">
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row *ngIf="transitions.length > 1">
      <ion-col size="6" *ngFor="let transition of transitions" class="transitions-buttons-col">
        <mobilus-transition-button [transition]="transition" [connected]="connected" [order]="order" [disableOnClick]="true"
                           (onClick)="transit(transition, $event)"></mobilus-transition-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="transitions.length === 1">
      <ion-col size="12" *ngFor="let transition of transitions" class="transitions-buttons-col">
        <mobilus-transition-button [transition]="transition" [connected]="connected" [order]="order" [disableOnClick]="true"
                           (onClick)="transit(transition, $event)"></mobilus-transition-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
