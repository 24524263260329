import {Injectable} from '@angular/core';
import {PushMessage} from '@models/push-messages/push-message.model';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import {PushServiceInterface} from './push-interface.services';
import {RegisterToken} from './register-token.model';

@Injectable({
  providedIn: 'root'
})
export abstract class PushAbstractService implements PushServiceInterface {

  serviceName: string;

  private pushMessageSubject: BehaviorSubject<PushMessage> = new BehaviorSubject<PushMessage>(null);
  public observablePushMessage: Observable<PushMessage> = this.pushMessageSubject.asObservable();

  protected registerTokenSubject: BehaviorSubject<RegisterToken> = new BehaviorSubject<RegisterToken>(null);
  public observableRegisterToken: Observable<RegisterToken> = this.registerTokenSubject.asObservable();

  public register() {
    this.init()
      // NOTE: init has always resolved with undefined (even though the method signagure says that it can return RegisterToken)
      .then(_ => this.registerTokenSubject.next(null), error => {
        console.error(`Initialization of service ${this.serviceName} failed : `, error);
      });
  }

  public unregister(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cleanup()
        .then(() => {
          this.pushMessageSubject.next(null);
          resolve();
        }, () => {
          resolve();
        });
    });
  }

  abstract init(): Promise<void>;

  abstract cleanup(): Promise<void>;

  abstract sendRefreshConnectedStatus();

  abstract convert(source: any): PushMessage;

  protected handlePush(pushMessage: any) {
    const message = this.convert(pushMessage);
    if (message) {
      this.notifyObservers(message);
    }
  }

  private notifyObservers(pushMessage: PushMessage) {
    this.pushMessageSubject.next(pushMessage);
  }

}
