import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// MODELS
import { UserProfile } from '@models/user-profile.model';

@Injectable({
    providedIn: 'root'
})
export class AppEvents {

    // Observable sources
    private authorizationSuccessAnnouncedSource = new BehaviorSubject<UserProfile>(null);
    private logoutSuccessAnnouncedSource = new Subject<void>();

    // Observable streams
    public authorizationSuccessAnnounced$ = this.authorizationSuccessAnnouncedSource.asObservable();
    public logoutSuccessAnnounced$ = this.logoutSuccessAnnouncedSource.asObservable();

    constructor() { }

    /**
     * Method returning if the user is authenticated or not.
     * @returns true if authenticated, false otherwise.
     */
    public isUserAuthorized(): boolean {
        return this.authorizationSuccessAnnouncedSource.value !== null;
    }

    public notifyAuthorizationSuccess(userProfile: UserProfile) {
        this.authorizationSuccessAnnouncedSource.next(userProfile);
    }
    public notifyLogoutSuccess() {
        this.authorizationSuccessAnnouncedSource.next(null);
        this.logoutSuccessAnnouncedSource.next();
    }

}
