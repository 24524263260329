export enum NetworkType {
  UNKNOWN,
  ETHERNET,
  WIFI,
  SECOND_GENERATION,
  THIRD_GENERATION,
  FOURTH_GENERATION,
  CELLULAR,
  NONE
}

