import {UntypedFormArray} from '@angular/forms';
import {Hauler} from '@models/business/hauler.model';
import {Order} from '@models/business/order.model';
import {TransitionData} from '@models/business/transition-data.model';
import {TransitionDetail} from '@models/business/transition-detail.model';
import {Transition} from '@models/workflow/transition.model';
import {TransitionDataFormConverter} from '@app/pages/transition-detail/transition-data-form.converter';
import {jsonClone} from '@utils/json-converter/json-converter';

export class OrderView {

  order: Order;
  transitions: Array<Transition>;
  selected = false;
  hidden = false;
  valid = true;
  transitionData: TransitionData;
  moved = false;

  constructor(order: Order, transitions: Array<Transition>) {
    this.order = order;
    this.transitions = transitions;
  }

  prepareForData() {
    if (!this.transitionData) {
      this.transitionData = new TransitionData();
    }
  }

  /**
   * To clean up data associated with an upcoming transition which is now cancelled
   */
  resetTransitionStatus() {
    this.transitionData = undefined;
    this.order.local = false;
  }

  updateValidity(config: TransitionDetail, hauler?: Hauler) {
    const conv = new TransitionDataFormConverter(config, this.order, hauler);
    const form = conv.toForm();
    if (this.transitionData) {
      conv.setData(this.transitionData);
    }
    form.updateValueAndValidity();
    this.valid = form.valid;
    const payments = form.get('payments') as UntypedFormArray;
    const paymentType = payments && payments.at(0).get('paymentType');
    const provider = hauler && paymentType && hauler.linkedProviders.find(instance => instance.id === this.order.tenantId);
    const valid = provider && provider.configuration.paymentModes.find(paymentMode => paymentMode.mode.endsWith(paymentType.value));
    if (valid) {
      this.valid = form.valid && valid.enabled;
    }
  }

  withImpersonation() {
    const clonedTransitions: Transition[] = [];
    this.transitions.forEach(value => {
      const cloned = jsonClone(Transition, value);
      cloned.impersonate = true;
      clonedTransitions.push(cloned);
    });
    this.transitions = clonedTransitions;
  }
}
