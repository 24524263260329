import {HttpInterceptor} from '@angular/common/http';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {Injector} from '@angular/core';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {Observable} from 'rxjs/';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const endpointService = this.injector.get(EndpointService);
    const logService = this.injector.get(LogService);
    return next.handle(req).pipe(
      tap(evt => {
      }, errorResponse => {
        if (errorResponse instanceof HttpErrorResponse) {
          logService.error('HTTP request failed with error : ', errorResponse);
          if (errorResponse.status === 401 && req.url.startsWith(endpointService.currentEndpoint)) {
            const router = this.injector.get(Router);
            router.navigateByUrl('/login');
          }
        }
      })
    );
  }
}
