import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('LockerDataDetails')
export class LockerDataDetails {

  @JsonProperty('lockerId', String, true)
  lockerId: string = undefined;

  @JsonProperty('lockerReservationId', String, true)
  lockerReservationId: string = undefined;

  @JsonProperty('clientDoorCode', String, true)
  clientDoorCode: string = undefined;

  @JsonProperty('courierDoorCode', String, true)
  courierDoorCode: string = undefined;

}
