import {Policies} from '@models/check-in/policies.model';
import {RemoteManagementCommandData} from '@models/push-messages/remote-management-command.data.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ServerContext')
export class ServerContext {

  @JsonProperty('versions', Object, true)
  versions: object = undefined;

  @JsonProperty('commands', [RemoteManagementCommandData], true)
  commands: RemoteManagementCommandData[] = undefined;

  @JsonProperty('policies', Policies, true)
  policies: Policies = undefined;
}
