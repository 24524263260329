import {Position} from '@models/business/position.model';
import {TransitionData} from '@models/business/transition-data.model';
import {Transition} from '@models/workflow/transition.model';
import {TransitionRequestLog} from '@models/workflow/transition-request-log.model';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {Any} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

@JsonObject('TransitionRequest')
export class TransitionRequest {

  @JsonProperty('date', MomentDateConverter, true)
  date: moment.Moment = undefined;

  @JsonProperty('transition', Transition, true)
  transition: Transition = undefined;

  @JsonProperty('error', Any, true)
  error: any = undefined;

  @JsonProperty('errorDate', MomentDateConverter, true)
  errorDate: moment.Moment = undefined;

  @JsonProperty('orderID', String, true)
  orderID: string = undefined;

  @JsonProperty('_id', String, true)
  _id: string = undefined;

  @JsonProperty('_rev', String, true)
  _rev: string = undefined;

  @JsonProperty('_rev_tree', Any, true)
  _rev_tree: any = undefined;

  @JsonProperty('_deleted', Boolean, true)
  _deleted = false;

  @JsonProperty('transitionData', TransitionData, true)
  transitionData: TransitionData = undefined;

  @JsonProperty('retryCount', Number, true)
  retryCount = 0;

  @JsonProperty('timeoutCount', Number, true)
  timeoutCount = 0;

  @JsonProperty('sequenceNumber', Number, true)
  sequenceNumber: number = undefined;

  @JsonProperty('position', Position, true)
  position: Position = undefined;

  @JsonProperty('transitionRequestLogs', [TransitionRequestLog], true)
  transitionRequestLogs: TransitionRequestLog[] = [];

  constructor(orderID: string, date: moment.Moment, transition: Transition, increment: number, transitionData?: TransitionData, sequenceNumber?: number, position?: Position) {
    this.orderID = orderID;
    this.date = date;
    this.transition = transition;
    this.transitionData = transitionData;
    this.sequenceNumber = sequenceNumber;
    this.position = position;
    // PM-193
    // this._id = new Date().toJSON() + '_' + increment.toString();
    this._id = 'tr_ ' + uuidv4();
  }

  public incrementRetryCount() {
    // if (!this.retryCount) { NOTE: this will always return "true" when the value is 0 so the 0 will never be incremented
    if (this.retryCount == null) {
      this.retryCount = 0;
    }
    this.retryCount++;
  }

  public incrementTimeoutCount() {
    if (this.timeoutCount == null) {
      this.timeoutCount = 0;
    }
    this.timeoutCount++;
  }

}
