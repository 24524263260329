<ion-header>

  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">
  <div [innerHTML]="body"></div>
</ion-content>
