import {EventEmitter} from '@angular/core';
import {Output} from '@angular/core';
import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {SimpleChanges} from '@angular/core';
import {OnChanges} from '@angular/core';
import {Order} from '@models/business/order.model';
import {Transition} from '@models/workflow/transition.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'mobilus-transition-button',
  templateUrl: './transition-button.component.html',
  styleUrls: ['./transition-button.component.scss'],
})
export class TransitionButtonComponent implements OnInit, OnChanges {

  @Input() transition: Transition;
  @Input() connected: boolean;
  @Input() order: Order;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<Subject<boolean>> = new EventEmitter();
  @Input() disableOnClick = false;
  public isDisabled = true;
  public emitting = false;
  private callBack: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  ngOnInit() {
    this.setDisabledState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connected) {
      if (changes.connected.currentValue !== changes.connected.previousValue) {
        this.setDisabledState();
      }
    } else if (changes.order) {
      this.setDisabledState();
    }
  }

  setDisabledState() {
    this.isDisabled = (!this.connected && this.transition.requireOnline) || (this.order && this.order.blocked);
  }

  transit() {
    if (this.disableOnClick) {
      this.emitting = true;
    }

    this.callBack
      .subscribe(() => {
        this.emitting = false;
      });

    setTimeout(() => {
      this.onClick.emit(this.callBack);
    });
  }

}
