import {JsonCustomConvert} from 'json2typescript';
import {JsonConverter} from 'json2typescript';
import {ValueCheckingMode} from 'json2typescript';
import {OperationMode} from 'json2typescript';
import {JsonConvert} from 'json2typescript';
import * as moment from 'moment';

export type ClassType<T> = new(...args: any[]) => T;

function constructJsonConverter(debug?: boolean): JsonConvert {
  const jsonConvert: JsonConvert = new JsonConvert();
  if (debug) {
    jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
  } else {
    jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
  }
  jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // don't allow null
  return jsonConvert;
}

export function plainToArray<T>(cls: ClassType<T>, plain: Array<object>, debug?: boolean): Array<T> {
  return constructJsonConverter(debug).deserializeArray(plain, cls as new () => object) as Array<T>;
}

export function plainToClass<T>(cls: ClassType<T>, plain: object, debug?: boolean): T {
  return constructJsonConverter(debug).deserialize(plain, cls as new () => object) as T;
}

export function classToPlain(plain: object, debug?: boolean): object {
  return constructJsonConverter(debug).serialize(plain) as object;
}

export function jsonClone<T>(cls: ClassType<T>, source: object) {
  return plainToClass(cls, Object.assign({}, source));
}

export function classToClass<T>(cls: ClassType<T>, plain: object, debug?: boolean): T {
  const jsonConverter = constructJsonConverter(debug);
  const serializedObject = jsonConverter.serialize(plain);
  // @ts-ignore
  return jsonConverter.deserialize(serializedObject, cls) as T;
}

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return date.toISOString();
  }

  deserialize(date: any): Date {
    return new Date(date);
  }
}

@JsonConverter
export class MomentDateConverter implements JsonCustomConvert<moment.Moment> {
  serialize(date: moment.Moment): any {
    if (date) {
      return date.toISOString();
    } else {
      return undefined;
    }
  }

  deserialize(date: string): moment.Moment {
    if (date) {
      const momentDate = moment(date);
      if (momentDate && momentDate.isValid()) {
        return momentDate;
      } else {
        return undefined;
      }
    }
    return undefined;
  }
}

@JsonConverter
export class JsonMapConverter implements JsonCustomConvert<Map<string, string>> {

  serialize(data: Map<string, string>): Array<any> {
    const object = [];
    data.forEach((v, k) => {
      object.push({key: k, value: v});
    });
    return object;
  }

  deserialize(data: Array<any>): Map<string, string> {
    const map = new Map<string, string>();
    data.forEach(item => {
      map.set(item.key, item.value);
    });
    return map;
  }
}

@JsonConverter
export class ObjectArrayConverter implements JsonCustomConvert<any[]> {
  serialize(objects: any[]): string {
    return JSON.stringify(objects);
  }

  deserialize(objects: string): any[] {
    return JSON.parse(objects);
  }
}
