import {Component} from '@angular/core';
import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';

@Component({
  templateUrl: './select-recipient.page.html',
  styleUrls: ['./select-recipient.page.scss'],
})
export class SelectRecipientPage {
  title = 'selectRecipient.title';
  recipients: Array<DeliveryRecipient>;
  selectedRecipient: DeliveryRecipient;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
  ) {
    this.recipients = this.navParams.get('recipients');
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  activate() {
    this.modalCtrl.dismiss({recipient: this.selectedRecipient});
  }

}
