export enum LogLevelEnum {
  TRA,
  DBG,
  INF,
  WRN,
  ERR,
  FTL,
  OFF
}

