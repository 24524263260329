import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {SortPopoverPage} from '@app/pages/sort-popover/sort-popover.page';
import {PipesModule} from '@app/pipes/pipes.module';
import {IonicModule} from '@ionic/angular';

const routes: Routes = [
  {
    path: '',
    component: SortPopoverPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    PipesModule,
    ComponentsModule
  ],
  declarations: [SortPopoverPage]
})
export class SortPopoverPageModule {
}
