import {Component} from '@angular/core';
import {RouteSummaryDisplayComponent} from '@app/components/route-summary-display/route-summary-display.component';
import {UserLocationAdderPage} from '@app/pages/setting/user-location-adder/user-location-adder.page';
import {ModalController, NavParams, Platform, PopoverController} from '@ionic/angular';
import {Position} from '@models/business/position.model';
import {Provider} from '@models/business/provider.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {PositionTypeEnum} from '@models/information/position-type.enum';
import {RouteSummary} from '@models/optimize-route/route-summary.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserLocation} from '@models/settings/user-location.model';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import {DeviceInfoService} from '@services/device-info/device-info.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// MODELS
import { TimeFormatEnum } from '@models/units-and-format/time-format.enum';

const CONFIG = {
  GA: {
    PAGE_NAME: 'OptimizeOptionsPage',
  }
};

@Component({
  templateUrl: './optimize-options.page.html',
  styleUrls: ['./optimize-options.page.scss'],
})
export class OptimizeOptionsPage {

  routeSummary: RouteSummary;
  private unsubscribe: Subject<void> = new Subject<void>();
  providers: Array<Provider>;
  userLocations: Array<UserLocation>;
  locationOrigin = '0';
  locationEnd = 'false';
  startTime: any;
  public readyForCurrentLocation: boolean;
  public isCurrentPositionFound: boolean;
  public employeeControlsSubscriptions = true;
  withTimeConstraints = true;
  settings: UserSettings = new UserSettings();
  private position: Position;

  public TimeFormatEnum = TimeFormatEnum;

  constructor(private platform: Platform,
              private modalCtrl: ModalController,
              private deviceInfo: DeviceInfo,
              private deviceInfoService: DeviceInfoService,
              private navParams: NavParams,
              private backgroundGeolocationService: BackgroundGeolocationService,
              private mobileContextService: MobileContextService,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private popoverController: PopoverController,
  ) {
    this.routeSummary = this.navParams.get('routeSummary');
    platform.ready()
      .then(() => {
        this.deviceInfoService.updatePermissions();
        this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
      });
    this.readyForCurrentLocation = this.deviceInfo.permissions.location.isAuthorized &&
      this.deviceInfo.permissions.location.isAvailable && this.deviceInfo.permissions.location.isEnabled;
  }

  ionViewWillEnter(): void {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((options) => {
        this.providers = options.selectedProviders;
        this.userLocations = options.userLocations || [];
        this.settings = options;
      });

    this.mobileContextService.haulerObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(hauler => {
        this.employeeControlsSubscriptions = hauler && hauler.employeeControlsSubscriptions;
      });

    this.backgroundGeolocationService.positionObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((position: Position) => {
        if (position) {
          this.position = position;
          this.isCurrentPositionFound = true;
        } else {
          this.position = null;
          this.isCurrentPositionFound = false;
        }
      });

    this.startTime = moment().format('HH:mm');
  }

  public onStartTimeChanged(time: string | string[]) {
    if (Array.isArray(time)) {
      this.log.error('[OptimizeOptionsPage] onStartTimeChanged - multiple selection is not supported in this component', time);
      if (time[0]) {
        this.startTime = time[0];
      } else {
        this.startTime = undefined;
      }
    } else {
      this.startTime = time;
    }
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  optimize() {
    let startPosition: Position;
    let endPosition: Position;
    let positionType: PositionTypeEnum;

    if (this.locationOrigin === '0' && this.isCurrentPositionFound) {
      // Fetch location from system
      startPosition = this.position;
      positionType = PositionTypeEnum.HAULER_EMPLOYEE_LOCATION;
    } else {
      if (this.locationOrigin.startsWith('p_')) {
        const reference = this.locationOrigin.substring(2);
        const provider: Provider = this.providers.find((value: Provider) => value.id.toString() === reference.toString());
        if (provider) {
          // Fetch location from provider
          startPosition = provider.position;
          positionType = PositionTypeEnum.PROVIDER_LOCATION;
        }
      } else if (this.locationOrigin.startsWith('l_')) {
        const reference = this.locationOrigin.substring(2);
        const location: UserLocation = this.userLocations.find((value: UserLocation) => {
          return value.position.id.toString() === reference.toString();
        });
        if (location) {
          startPosition = location.position;
          positionType = PositionTypeEnum.CUSTOM_LOCATION;
        }
      }
    }

    if (this.locationEnd === '0') {
      endPosition = this.position;
    } else if (this.locationEnd !== 'false') {
      if (this.locationEnd.startsWith('p_')) {
        const reference = this.locationEnd.substring(2);
        const provider: Provider = this.providers.find((value: Provider) => value.id.toString() === reference.toString());
        if (provider) {
          endPosition = provider.position;
        }
      } else if (this.locationEnd.startsWith('l_')) {
        const reference = this.locationEnd.substring(2);
        const location: UserLocation = this.userLocations.find((value: UserLocation) => {
          return value.position.id.toString() === reference.toString();
        });
        if (location) {
          endPosition = location.position;
        }
      }
    }

    const time = this.startTime.split(':');
    const when = moment().hour(time[0]).minute(time[1]).second(0).millisecond(0);
    this.log.debug('About to optimize', startPosition, endPosition, positionType, when);
    this.conclude(startPosition, endPosition, positionType, when);
  }

  private conclude(startPosition: Position, endPosition: Position, positionType: PositionTypeEnum, startAt: moment.Moment) {
    this.modalCtrl.dismiss({
      startPosition,
      endPosition,
      positionType,
      startAt,
      withTimeConstraints: this.withTimeConstraints
    });
  }

  async addLocation(where: string) {
    const modal = await this.modalCtrl.create({
      component: UserLocationAdderPage,
      componentProps: {}
    });
    modal.onDidDismiss().then(
      data => {
        if (data && data.data && data.data.location) {
          this.userLocations.push(data.data.location);
          this.mobileContextService.settingsHaveChanged(true);
          if (where === 'start') {
            this.locationOrigin = 'l_' + data.data.location.position.id;
          } else if (where === 'end') {
            this.locationEnd = 'l_' + data.data.location.position.id;
          }
        }
      }
    );
    await modal.present();
  }

  async openRouteSummary() {
    const popover = await this.popoverController.create({
      component: RouteSummaryDisplayComponent,
      componentProps: {
        summary: this.routeSummary
      },
      cssClass: 'route-optimization-popover',
      translucent: true,
    });
    return await popover.present();
  }
}
