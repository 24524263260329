import {Input} from '@angular/core';
import {OnDestroy} from '@angular/core';
import {Component} from '@angular/core';
import {UserSettings} from '@models/settings/settings.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'mobilus-average-arrival',
  templateUrl: './average-arrival.component.html',
  styleUrls: ['./average-arrival.component.scss'],
})
export class AverageArrivalComponent implements OnDestroy {

  @Input() time: string;
  settings: UserSettings = new UserSettings();
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(private mobileContextService: MobileContextService,) {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userSettings => {
        this.settings = userSettings;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
