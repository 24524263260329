import {Component, ViewChild} from '@angular/core';
import {Position} from '@models/business/position.model';
import {Provider} from '@models/business/provider.model';
import {PositionTypeEnum} from '@models/information/position-type.enum';
import {RouteSummary} from '@models/optimize-route/route-summary.model';
import {OrderGroup} from '@models/order-helper/order-group.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {PositionTypeEnumConverter} from '@models/push-messages/converters.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserLocation} from '@models/settings/user-location.model';
import {IonNav, LoadingController, ModalController, NavParams, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderService} from '@services/order-service/order.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DeviceInfoService} from '@services/device-info/device-info.service';

// MODELS
import { TimeFormatEnum } from '@models/units-and-format/time-format.enum';

const CONFIG = {
  GA: {
    PAGE_NAME: 'ReorderModalPage',
    EVENT: {
      REORDER_ORDERS: {
        NAME: 'item_order_changed',
        ACTION: 'reordering'
      }
    }
  }
};

@Component({
  templateUrl: './reorder-modal.page.html',
  styleUrls: ['./reorder-modal.page.scss'],
})
export class ReorderModalPage {

  @ViewChild(IonNav) nav: IonNav;
  orderGroups: Array<OrderGroup> = [];
  allOrders: Array<OrderView> = [];
  timeReference: moment.Moment = moment();
  isOrderButtonDisabled = false;
  withDistance = false;
  providers: Array<Provider>;
  reorderStartPoint = '-1';
  userLocations: Array<UserLocation>;
  withTimeConstraints = true;
  startTime: any;
  settings: UserSettings = new UserSettings();
  public isCurrentPositionFound: boolean;
  private timer: NodeJS.Timeout;
  private unsubscribe: Subject<void> = new Subject<void>();
  private position: Position;
  private selectedPosition: Position;
  private positionTypeConverter = new PositionTypeEnumConverter();
  private positionType: string = this.positionTypeConverter.serialize(PositionTypeEnum.HAULER_EMPLOYEE_LOCATION);

  public TimeFormatEnum = TimeFormatEnum;

  constructor(public navParams: NavParams,
              private modalCtrl: ModalController,
              private orderService: OrderService,
              private orderStoreService: OrderStoreService,
              private orderSyncService: OrderSyncService,
              private loadingCtrl: LoadingController,
              private backgroundGeolocationService: BackgroundGeolocationService,
              private mobileContextService: MobileContextService,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              private log: LogService,
              private ga: MblsAnalyticsService,
              private deviceInfoService: DeviceInfoService,
              private platform: Platform
  ) {
    this.orderGroups = this.navParams.get('orderGroups');
    this.withDistance = this.navParams.get('withDistance') || false;
    this.allOrders = this.reorderBySequence(this.navParams.get('allOrders'));
    platform.ready()
      .then(() => {
        this.deviceInfoService.updatePermissions();
        this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
      });
  }

  ionViewWillEnter() {
    this.timer = setInterval(() => {
      this.timeReference = moment();
    }, 5 * 1000);

    this.backgroundGeolocationService.positionObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((position: Position) => {
        if (position) {
          this.position = position;
          this.isCurrentPositionFound = true;
        } else {
          this.position = null;
          this.isCurrentPositionFound = false;
        }
      });

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((settings) => {
        this.settings = settings;
        this.providers = settings.selectedProviders;
        this.userLocations = settings.userLocations || [];
      });

    this.startTime = moment().format('HH:mm');
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  public onStartTimeChanged(time: string | string[]) {
    if (Array.isArray(time)) {
      this.log.error('[ReorderModalPage] onStartTimeChanged - multiple selection is not supported in this component', time);
      if (time[0]) {
        this.startTime = time[0];
      } else {
        this.startTime = undefined;
      }
    } else {
      this.startTime = time;
    }
  }

  ionViewDidLeave(): void {
    clearInterval(this.timer);
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  reorderBySequence(orders: Array<OrderView>) {
    return orders.concat().sort((orderA, orderB) => {
      if (orderA.order.sequence === null && orderB.order.sequence !== null) {
        return 1;
      } else if (orderB.order.sequence === null && orderA.order.sequence !== null) {
        return -1;
      } else if (orderA.order.sequence === orderB.order.sequence) {
        return 0;
      }
      return orderA.order.sequence < orderB.order.sequence ? -1 : 1;
    });
  }

  initialPositionSelect() {
    if (this.reorderStartPoint === '-1') {
      this.positionType = this.positionTypeConverter.serialize(PositionTypeEnum.HAULER_EMPLOYEE_LOCATION);
      this.selectedPosition = null;
    } else {
      if (this.reorderStartPoint.startsWith('p_')) {
        const reference = this.reorderStartPoint.substring(2);
        this.positionType = this.positionTypeConverter.serialize(PositionTypeEnum.PROVIDER_LOCATION);
        const provider = this.providers.find(p => p.id.toString() === reference.toString());
        if (provider) {
          this.selectedPosition = provider.position;
        }
      } else if (this.reorderStartPoint.startsWith('l_')) {
        const reference = this.reorderStartPoint.substring(2);
        this.positionType = this.positionTypeConverter.serialize(PositionTypeEnum.CUSTOM_LOCATION);
        const location: UserLocation = this.userLocations.find((value: UserLocation) => {
          return value.position.id.toString() === reference.toString();
        });
        if (location) {
          this.selectedPosition = location.position;
        }
      }
    }
  }

  async reorder() {
    this.ga.trackEvent(CONFIG.GA.EVENT.REORDER_ORDERS.NAME, CONFIG.GA.EVENT.REORDER_ORDERS.ACTION, 'ReorderModal')
      .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.REORDER_ORDERS} with GA`, error));

    const time = this.startTime.split(':');
    const when = moment().hour(time[0]).minute(time[1]).second(0).millisecond(0);

    const ordersSequences = this.allOrders.reduce((acc, orderView, index) => [...acc, {
      orderId: orderView.order.id,
      sequence: index + 1
    }], []);

    const position = this.selectedPosition ? this.selectedPosition : this.position;
    if (position) {
      const loading = await this.loadingCtrl.create();
      await loading.present();
      this.orderSyncService.updateOrdersSequences(position, this.positionType, when, ordersSequences, this.withTimeConstraints)
        .subscribe((summary: RouteSummary) => {
          this.orderService.updateSummary(summary);
          loading.dismiss();

          let maxSequence = 0;
          ordersSequences.forEach(value => {
            if (value.sequence) {
              maxSequence = Math.max(maxSequence, value.sequence);
            }
          });

          this.allOrders.forEach(orderView => {
            this.orderStoreService.put(orderView.order).subscribe();
          });

          this.modalCtrl.dismiss({success: true, maxSequence});
        }, async err => {
          await loading.dismiss();
          const toast = await this.toastCtrl
            .create({
              message: this.translateService.instant('messages.errors.reordering_error'),
              duration: 3 * 1000,
              cssClass: 'processing-toast'
            });
          await toast.present()
          ;
          this.log.debug('unable to reorder orders', err);
        });
    } else {
      const toast = await this.toastCtrl
        .create({
          message: this.translateService.instant('messages.errors.reordering_no_position'),
          duration: 3 * 1000,
          cssClass: 'processing-toast'
        });
      await toast.present()
      ;

    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  reorderItems(indexes) {
    const element = this.allOrders[indexes.detail.from];
    this.allOrders.splice(indexes.detail.from, 1);
    this.allOrders.splice(indexes.detail.to, 0, element);
    this.isOrderButtonDisabled = false;
    indexes.detail.complete();
  }

}
