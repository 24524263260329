import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AccountingReport} from '@models/reports/accountingReport.model';
import {ReportingRequest} from '@models/reports/reporting-request.model';
import {TripReport} from '@models/reports/tripReport.model';
import {plainToClass} from '@utils/json-converter/json-converter';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const CONFIG = {
  PATH_TRIPS_REPORT: '/mobile/v2/tripsReport',
  PATH_ACCOUNTING_REPORT: '/mobile/v2/accountingReport',
};

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private httpClient: HttpClient,
              private endpointService: EndpointService,
              private log: LogService) {
  }

  getAccountingReport(reportRequest: ReportingRequest): Observable<any> {
    this.log.info('getting accounting reports');
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_ACCOUNTING_REPORT;
    return this.httpClient.post(url, reportRequest.forPost())
      .pipe(
        map(body => plainToClass(AccountingReport, body))
      );

  }

  getTripsReport(reportRequest: ReportingRequest): Observable<any> {
    this.log.info('getting accounting reports');
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_TRIPS_REPORT;
    return this.httpClient.post(url, reportRequest.forPost())
      .pipe(
        map(body => plainToClass(TripReport, body))
      );
  }
}
