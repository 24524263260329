import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {SignaturePinModalPage} from './signature-pin-modal.page';

const routes: Routes = [
  {
    path: '',
    component: SignaturePinModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    TranslateModule,
    ComponentsModule,
  ],
  declarations: [SignaturePinModalPage]
})
export class SignaturePinModalPageModule {
}
