import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('BagType')
export class BagType {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('shortName', String)
  shortName: string = undefined;

  @JsonProperty('archived', Boolean)
  archived: boolean = undefined;
}

@JsonObject('Bag')
export class Bag {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('number', Number)
  number: number = undefined;

  @JsonProperty('bagType', BagType)
  bagType: BagType = undefined;
}
