<ion-header>

  <ion-toolbar>
    <ion-title>{{ title | translate }}</ion-title>
    <ion-buttons slot="right">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content class="ion-no-padding">
  <ion-item>
    <ion-label><span translate="pages.driver-owned-info.notes"></span></ion-label>
  </ion-item>
  <ion-item>
    <ion-textarea [(ngModel)]="notes" placeholder="{{ 'pages.driver-owned-info.notes_instructions' | translate }}"
      clearInput="true" rows="3"></ion-textarea>
  </ion-item>

  <ion-item>
    <ion-toggle [(ngModel)]="withTimePreference">
      <span translate="pages.driver-owned-info.timeConstraint"></span>
    </ion-toggle>
  </ion-item>

  <ng-container *ngIf="withTimePreference">
    <ion-list>
      <ion-item lines="none" class="time-preference-container">
        <ion-radio-group [(ngModel)]="desiredArrivalTime" class="time-preference-radio-group">
            <ion-radio value="BEFORE">
              <span translate="pages.driver-owned-info.before"></span>
            </ion-radio>
            <ion-radio value="AFTER">
              <span translate="pages.driver-owned-info.after"></span>
            </ion-radio>
            <ion-radio value="BETWEEN">
              <span translate="pages.driver-owned-info.between"></span>
            </ion-radio>
        </ion-radio-group>
      </ion-item>
    </ion-list>

    <ion-item *ngIf="desiredArrivalTime" class="date-time-container" button="true" id="open-arrival-time-picker">
      <ion-label *ngIf="desiredArrivalTime === 'BEFORE'"><span translate="pages.driver-owned-info.before"></span>
      </ion-label>
      <ion-label *ngIf="desiredArrivalTime === 'AFTER'"><span translate="pages.driver-owned-info.after"></span>
      </ion-label>
      <ion-label *ngIf="desiredArrivalTime === 'BETWEEN'"><span translate="pages.driver-owned-info.between"></span>
      </ion-label>
      <ion-text slot="end">
        {{ desiredArrivalTimeHour | Time: displayFormat }}
      </ion-text>
      <ion-modal trigger="open-arrival-time-picker" #modalDesiredArrivalTimeHour class="datetime-modal time-only-picker">
        <ng-template>
          <ion-content force-overscroll="false" show-backdrop="true">
            <ion-datetime
              #desiredArrivalTimeHourControl [value]="desiredArrivalTimeHour" [hourCycle]="displayFormat === TimeFormatEnum.STANDARD ? 'h12': 'h23'"
              presentation="time" show-default-buttons="true" [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
              [multiple]="false" (ionChange)="onArrivalTimeHourChange(desiredArrivalTimeHourControl.value)">
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ion-item>

    <ion-item *ngIf="desiredArrivalTime === 'BETWEEN'" class="date-time-container" button="true" id="open-arrival-time-end-picker">
      <ion-label><span translate="pages.driver-owned-info.and"></span></ion-label>
      <ion-text slot="end">
        {{ desiredArrivalTimeHourEnd | Time: displayFormat }}
      </ion-text>
      <ion-modal trigger="open-arrival-time-end-picker" #modalDesiredArrivalTimeHourEnd class="datetime-modal time-only-picker">
        <ng-template>
          <ion-content force-overscroll="false" show-backdrop="true">
            <ion-datetime
              #desiredArrivalTimeHourEndControl [value]="desiredArrivalTimeHourEnd" [hourCycle]="displayFormat === TimeFormatEnum.STANDARD ? 'h12': 'h23'"
              presentation="time" show-default-buttons="true" [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
              [multiple]="false" (ionChange)="onArrivalTimeHourEndChange(desiredArrivalTimeHourEndControl.value)">
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ion-item>
  </ng-container>

  <ion-item class="transitions-buttons-col">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="6" class="transitions-buttons-col">
          <ion-button expand="block" size="large" (click)="dismiss()" color="secondary">
            <fa-icon [icon]="['fal', 'times']"></fa-icon>
            <span class="transitions-buttons-text">{{ 'actions.cancel' | translate }}</span>
          </ion-button>
        </ion-col>
        <ion-col size="6" class="transitions-buttons-col">
          <ion-button expand="block" size="large" (click)="save()"
            [disabled]="withTimePreference && !desiredArrivalTime" color="primary">
            <fa-icon slot="start" [icon]="['fas', 'check']"></fa-icon>
            <span class="transitions-buttons-text">{{ 'actions.save' | translate }}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>

</ion-content>
