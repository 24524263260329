import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// NATIVE
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// MODELS
import { AppError } from '@models/base/base';
import { Position as GeolocationPosition } from '@capacitor/geolocation';
import { UserProfile } from '@models/user-profile.model';

// PROVIDERS
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';

/**
 * Provider to support persistent storage for locations data to be uploaded by PWA geolocation background plugin to the backend.
 * All these are stored as JSON with the appropriate key/value pairs.
 */
@Injectable({
    providedIn: 'root'
})
export class PersistentStorage {

    private userProfile: UserProfile;

    private readonly LOCATIONS_TO_UPLOAD: string = '_locations_to_upload_';

    private _geolocationPositionsCountTrigger: BehaviorSubject<number> = new BehaviorSubject(null);
    public geolocationPositionsCountObservable: Observable<number> = this._geolocationPositionsCountTrigger.asObservable();

    private _storage: Storage | null = null;

    constructor(
        private mobileContextService: MobileContextService,
        private logService: LogService,
        private storage: Storage,
        private translateService: TranslateService
    ) {
    }

    public async init(): Promise<void> {
        try {
            await this.storage.defineDriver(CordovaSQLiteDriver);
            const storage = await this.storage.create();
            this._storage = storage;
        } catch (error) {
            this.logService.error('[PersistentStorage] init - failed to initialize local storage', error);
        }

        this.mobileContextService.userProfileObservable
            .pipe(
                filter((userProfile) => userProfile !== null)
            )
            .subscribe(userProfile => {
                this.userProfile = userProfile;
            });
    }

    /**
     * Method to get a storage key for the specific item for the currently logged user
     */
    public getStorageKey(val: string): string {
        if (this.userProfile) {
            return `_user_id_${this.userProfile.id}_${val}`;
        } else {
            return `_anonymous_user_${val}`;
        }
    }

    // #region MANAGE LOCATIONS TO UPLOAD
    public async getAllGeolocationPositions(sortingFn?: (val: Array<GeolocationPosition>) => void): Promise<GeolocationPosition[]> {
        let allGeolocationPositions: GeolocationPosition[] = null;
        const geolocationPositionsStorageKey = this.getStorageKey(this.LOCATIONS_TO_UPLOAD);
        try {
            const geolocationPositions = await this._storage.get(geolocationPositionsStorageKey);
            if (geolocationPositions) {
                allGeolocationPositions = geolocationPositions;
            } else {
                allGeolocationPositions = [];
            }
        } catch (error) {
            this.logService.error('[PersistentStorage] getAllGeolocationPositions - failed', error);
            const errorMessageTranslated = this.translateService.instant('messages.errors.persistent_storage.failed_get_all_geolocation');
            const appError = new AppError(false, errorMessageTranslated);
            throw appError;
        }
        if (sortingFn) {
            sortingFn(allGeolocationPositions);
        }
        return allGeolocationPositions;
    }
    public async saveAllGeolocationPositions(allGeolocationPositions: GeolocationPosition[]): Promise<void> {
        const geolocationPositionsStorageKey = this.getStorageKey(this.LOCATIONS_TO_UPLOAD);
        try {
            await this._storage.set(geolocationPositionsStorageKey, allGeolocationPositions);
            this._geolocationPositionsCountTrigger.next(allGeolocationPositions.length);
        } catch (error) {
            this.logService.error('[PersistentStorage]: saveAllGeolocationPositions - failed', error);
            const errorMessageTranslated = this.translateService.instant('messages.errors.persistent_storage.failed_save_all_geolocation');
            const appError = new AppError(false, errorMessageTranslated);
            throw appError;
        }
    }
    public async addGeolocationPosition(geolocationPosition: GeolocationPosition): Promise<void> {
        const allGeolocationPositions: GeolocationPosition[] = await this.getAllGeolocationPositions();
        allGeolocationPositions.push(geolocationPosition);
        await this.saveAllGeolocationPositions(allGeolocationPositions);
    }

    public async removeGeolocationPosition(geolocationPosition: GeolocationPosition): Promise<void> {
        const allGeolocationPositions: GeolocationPosition[] = await this.getAllGeolocationPositions();
        // NOTE: may be to use timestamp as a unique key
        const index = allGeolocationPositions.findIndex(_geolocationPosition => JSON.stringify(_geolocationPosition) === JSON.stringify(geolocationPosition));
        if (index > -1) {
            allGeolocationPositions.splice(index, 1);
        } else {
            this.logService.error(`[PersistentStorage] removeGeolocationPosition - failed to remove geolocation position: ${JSON.stringify(geolocationPosition)} because it has not been found`);
            const errorMessageTranslated = this.translateService.instant('messages.errors.persistent_storage.failed_remove_geolocation');
            const appError = new AppError(false, errorMessageTranslated);
            throw appError;
        }
        await this.saveAllGeolocationPositions(allGeolocationPositions);
    }
    public async bulkRemoveGeolocationPositions(geolocationPositionsToRemove: GeolocationPosition[]): Promise<void> {
        const allGeolocationPositions: GeolocationPosition[] = await this.getAllGeolocationPositions();
        const geolocationPositionsToKeep = allGeolocationPositions.filter(_geolocationPosition => {
            // NOTE: may be to use timestamp as a unique key
            return geolocationPositionsToRemove.some(geolocationPositionToRemove => JSON.stringify(_geolocationPosition) === JSON.stringify(geolocationPositionToRemove)) === false;
        });
        await this.saveAllGeolocationPositions(geolocationPositionsToKeep);
    }
    public async removeAllGeolocationPosition(): Promise<void> {
        const allGeolocationPositions: GeolocationPosition[] = new Array<GeolocationPosition>();
        await this.saveAllGeolocationPositions(allGeolocationPositions);
    }
    // #endregion

}
