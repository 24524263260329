import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {Policies} from '@models/check-in/policies.model';
import {UserSettings} from '@models/settings/settings.model';
import {DateTimeCombinationEnum} from '@models/units-and-format/date-format.enum';

@Component({
  selector: 'mobilus-usage-policies',
  templateUrl: './usage-policies.component.html',
  styleUrls: ['./usage-policies.component.scss'],
})
export class UsagePoliciesComponent {

  @Input() policies: Policies;
  @Input() userSettings: UserSettings = new UserSettings();
  dateType: DateTimeCombinationEnum = DateTimeCombinationEnum.dateOnly;

  constructor() {
  }
}
