import {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {

  constructor(public translateService: TranslateService, ) {
  }

  transform(value: number, ...args) {

    if (!value || !args || !args[0]) {
      return '';
    }

    let out = '';
    const duration = moment.duration(value, args[0]);

    if (duration.days() > 0) {
      out += duration.days() + ' ' + this.translateService.instant('messages.days');
    }
    if (duration.hours() > 0) {
      if (out) {
        out += ' ' + this.translateService.instant('messages.and') + ' ';
      }
      out += duration.hours() + this.translateService.instant('messages.hours');
    }
    if (duration.minutes() > 0) {
      out += duration.minutes() + this.translateService.instant('messages.minutes');
    }
    return out;
  }
}
