<ion-header>
  <ion-toolbar>
    <ion-title (click)="close()"><span translate="options.title"></span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-toggle labelPlacement="start" justify="space-between" [(ngModel)]="searching" (ionChange)="searchToggle()">
        <span translate="options.search.label"></span>
      </ion-toggle>
    </ion-item>

    <ion-item>
      <ion-toggle labelPlacement="start" justify="space-between" [(ngModel)]="statusSettings.activeFilter.withTimeConstraint" (ionChange)="timeConstraintFilterChanged()">
        <span translate="options.filter.withTimeConstraint"></span>
      </ion-toggle>
    </ion-item>

    <ion-item *ngFor="let tag of filterableTags">
      <ion-toggle labelPlacement="start" justify="space-between" [checked]="selectedTag(tag.label)" (ionChange)="tagFilterChanged(tag.label, $any($event).detail.checked)">
        <mobilus-tag [tag]="tag" [short]="false"></mobilus-tag>
      </ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>
