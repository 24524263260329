<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button (click)="close()">
        <img src="assets/images/logo_menu.png"/>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>
      <span translate="userProfile.profile"></span>
    </ion-title>
    <ion-button (click)="close()" slot="end" fill="clear" color="light" style="margin-right:5px;">
      <fa-icon [icon]="['fas','times']" style="color:#424242 !important;"></fa-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="margin-right:18px;">
    <ion-item class="ion-margin-top">
      <ion-avatar style="margin: 8px 16px 8px 0;">
        <img src="assets/images/user.png" height="40" width="auto">
      </ion-avatar>
      <span style="white-space: normal">{{ userProfile?.firstName }} {{ userProfile?.lastName }}</span>
      <ion-button (click)="openProfileEdit()" slot="end" fill="clear">
        <fa-icon [icon]="['fas','pencil']"></fa-icon>
      </ion-button>
    </ion-item>

    <ion-item class="ion-margin-top">
      <fa-icon [icon]="['fas', 'phone-alt']" size="lg" slot="start"></fa-icon>
      <ion-label class="ion-text-wrap">
        <span>{{ userProfile?.phoneNumber }}</span>
      </ion-label>
    </ion-item>

    <ion-item class="ion-margin-top">
      <fa-icon [icon]="['fas', 'envelope']" size="lg" slot="start"></fa-icon>
      <ion-label class="ion-text-wrap">
        <span>{{ userProfile?.email }}</span>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
