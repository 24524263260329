import {DeliveryConstraints} from '@models/business/delivery-constraints.model';
import {PaymentMode} from '@models/business/payment-mode.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ProviderConfig')
export class ProviderConfig {

  @JsonProperty('allowFutureViewForHauler', Boolean, true)
  allowFutureViewForHauler: boolean = undefined;

  @JsonProperty('paymentModes', [PaymentMode], true)
  paymentModes: Array<PaymentMode> = undefined;

  @JsonProperty('deliveryOpeningTime', String, true)
  deliveryOpeningTime: string = undefined;

  @JsonProperty('deliveryConstraints', DeliveryConstraints, true)
  deliveryConstraints: DeliveryConstraints = undefined;

}
