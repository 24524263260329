<ion-badge [style.background-color]="tag.color" *ngIf="tag.onMobile" class="tag">
  <span [ngSwitch]="short">
    <span *ngSwitchCase="true">
      <span [ngSwitch]="tag.displayOption">
        <span *ngSwitchCase="'ICON'"><fa-icon [icon]="['fal', $any(icon)]" *ngIf="tag.icon"></fa-icon></span>
        <span *ngSwitchCase="'SHORT'"><span> </span><span [translate]="tag.shortLabel"></span></span>
        <span *ngSwitchDefault><fa-icon [icon]="['fal', $any(icon)]" *ngIf="tag.icon"></fa-icon><span> </span><span
          [translate]="tag.shortLabel"></span></span>
      </span>
    </span>

    <span *ngSwitchCase="false">
      <fa-icon [icon]="['fal', $any(icon)]" *ngIf="tag.icon"></fa-icon><span> </span><span [translate]="tag.label"></span>
    </span>
  </span>
</ion-badge>
