import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HttpUtils {

    /**
     * Utility method to determine if this is a timeout error
     * 
     */
    public isHttpTimeoutError(error: any): boolean {
        if(!error) {
            // NOTE: if the user is not defined just consider this as NOT timeout error
            return false;
        }
        if (error instanceof ProgressEvent) {
            // NOTE: this is an XMLHttpRequest error and it this is a timeout error if its type prop is 'timeout'
            return error.type === 'timeout';
        }
        if ((error.status == null && (error.message === 'timeout' || error.message === 'Timeout has occurred')) || error.status === 0) {
            // NOTE: checking if this an http error and caused by timeout. Note that CORS error might be considered as timeout error
            return true;
        }
        return false;
    }
  
    /**
     * Method to check if error is an http error
     */
    public isHttpError(error: any): boolean {
        return error != null && (this.isHttpTimeoutError(error) === true || error.hasOwnProperty('status'));
    }

}