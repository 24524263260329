export enum DateFormatEnum {
  dateDMY,
  dateMDY,
  dateISO,
}

export namespace DateFormatEnum {
  export function getFormat(format: DateFormatEnum) {
    switch (+format) {
      case DateFormatEnum.dateDMY:
        return 'DD-MM-YYYY';
      case DateFormatEnum.dateMDY:
        return 'MM-DD-YYYY';
      case DateFormatEnum.dateISO:
        return 'YYYY-MM-DD';
      default:
        return 'YYYY-MM-DD';
    }
  }
}

export enum DateTimeCombinationEnum {
  dateOnly,
  dateAndTime,
  timeAndDate
}
