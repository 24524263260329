export enum OrderStatus {
  UNKNOWN, // use index 0 for no op status
  READY_FOR_HAULAGE,
  ON_THE_WAY_TO_PROVIDER,
  ON_THE_WAY_TO_CUSTOMER
}


export class OrderStatusConverter {
  static convert(status: string): OrderStatus {
    switch (status) {
      case 'UNKNOWN':
        return OrderStatus.UNKNOWN;
      case 'READY_FOR_HAULAGE' :
        return OrderStatus.READY_FOR_HAULAGE;
      case 'ON_THE_WAY_TO_PROVIDER' :
        return OrderStatus.ON_THE_WAY_TO_PROVIDER;
      case 'ON_THE_WAY_TO_CUSTOMER':
        return OrderStatus.ON_THE_WAY_TO_CUSTOMER;
    }

  }
}
