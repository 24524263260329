import {NotificationData} from '@models/push-messages/notification-data.model';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from './notification.service';

export class WrappedToast {

  constructor(private toastCtrl: ToastController,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              public notificationData: NotificationData) {
  }

  async present(): Promise<void> {
    // Create self contained something so you can refer to notification data to mark as read
    const message = (this.notificationData.title ? this.notificationData.title : '') + '\n'
      + (this.notificationData.message ? this.notificationData.message : '');
    const toast = await this.toastCtrl.create({
      message: message,
      // dismissOnPageChange: true,
      buttons: [{
        text: this.translateService.instant('actions.seen'),
        role: 'cancel'
      }],
      position: 'middle',
      duration: 3 * 1000,
      cssClass: 'processing-toast'
    });

    toast.onDidDismiss().then((data) => {
      if (data.role === 'close') {
        this.notificationService.markAsRead(this.notificationData);
      }
    });

    await toast.present();
  }

}
