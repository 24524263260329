import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('HaulerEmployee')
export class HaulerEmployee {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('firstName', String)
  firstName: string = undefined;

  @JsonProperty('lastName', String)
  lastName: string = undefined;

  @JsonProperty('secUserId', Number)
  secUserId: number = undefined;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
