import {Transition} from '@models/workflow/transition.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Workflow')
export class Workflow {

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('transitions', [Transition])
  transitions: Array<Transition> = [];

  constructor(name: string) {
    this.name = name;
    this.transitions = [];
  }
}
