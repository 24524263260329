import {OrderStatus} from '@models/order-helper/order-status.enum';

export class StatusInfo {
  name: OrderStatus;
  byOrderType: boolean;

  constructor(name: OrderStatus, byOrderType: boolean = false) {
    this.name = name;
    this.byOrderType = byOrderType;
  }
}
