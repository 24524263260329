import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountingReport} from '@models/reports/accountingReport.model';
import {AccountingReportModalPage} from '@app/pages/accounting-report-modal/accounting-report-modal.page';
import {ModalController} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'AccountingReportsPage',
    EVENT: {
      GENERATING_REPORT: {
        NAME: 'accounting_report',
        ACTION: 'generate'
      }
    }
  },
};

@Component({
  templateUrl: './accounting-reports.page.html',
  styleUrls: ['./accounting-reports.page.scss'],
})
export class AccountingReportsPage {

  public report: AccountingReport;
  public isHaulerManager: boolean;
  public includeTags: boolean;

  constructor(private modalCtrl: ModalController,
              private route: ActivatedRoute,
              private router: Router,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.report = this.router.getCurrentNavigation().extras.state.report;
        this.isHaulerManager = this.router.getCurrentNavigation().extras.state.isHaulerManager;
        this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
        this.includeTags = this.router.getCurrentNavigation().extras.state.includeTags;
      }
    });
  }

  async onShowReport(reportType: string, haulerReport?: any) {
    this.ga.trackEvent(CONFIG.GA.EVENT.GENERATING_REPORT.NAME, CONFIG.GA.EVENT.GENERATING_REPORT.ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.GENERATING_REPORT} with GA`, error));
    const modalPage = await this.modalCtrl.create(
      {
        component: AccountingReportModalPage,
        componentProps: {
          report: this.report, reportType, haulerReport,
          includeTags: this.includeTags
        }
      }
    );
    await modalPage.present();
  }
}
