import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('PreSignedURLResponse')
export class PreSignedURLResponse {

  @JsonProperty('url', String)
  url: string = undefined;

  @JsonProperty('filename', String)
  filename: string = undefined;

  @JsonProperty('path', String)
  path: string = undefined;

  @JsonProperty('issuedDate', MomentDateConverter)
  uploadUrlIssuedDate: moment.Moment = undefined;
}
