import {ManifestItem} from '@models/business/manifest-item.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('OrderManifestItem')
export class OrderManifestItem {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('acceptedOnDelivery', Boolean, true)
  acceptedOnDelivery: boolean = undefined;

  @JsonProperty('item', ManifestItem, true)
  item: ManifestItem = undefined;

  _status: boolean = null;

  get status() {
    if (this.isInvalid()) {
      this._status = null;
    }

    return this._status;
  }

  set status(value: boolean) {  // FIXME remove status
    this._status = value;
    if (value === null) {
      this.acceptedOnDelivery = null;
      return;
    } else if (value === true) {
      this.acceptedOnDelivery = true;
      return;
    } else if (value === false) {
      this.acceptedOnDelivery = false;
      return;
    }
  }

  get label() {
    return this.item.label;
  }

  isInvalid(): boolean {
    return (this.acceptedOnDelivery === undefined || this.acceptedOnDelivery === null);
  }

}
