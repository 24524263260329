import {Component} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'MqttLostDetailPage',
  }
};


@Component({
  templateUrl: './mqtt-lost-detail.page.html',
  styleUrls: ['./mqtt-lost-detail.page.scss'],
})
export class MqttLostDetailPage {

  message: { title: string, subTitle: string };

  constructor(private modalCtrl: ModalController,
              public navParams: NavParams,
              private translateService: TranslateService,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
    this.message = this.navParams.get('connectionDetailMessage');
    this.message.subTitle = this.translateService.instant(this.message.subTitle);
    this.message.title = this.translateService.instant(this.message.title);
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
