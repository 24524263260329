import {HttpParams} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {plainToArray} from '@utils/json-converter/json-converter';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {map} from 'rxjs/operators';

const CONFIG = {
  PATH_HAULER: '/mobile/v2/listOtherHaulerEmployees',
};

@Injectable({
  providedIn: 'root'
})
export class HaulerEmployeeProvider {

  constructor(private httpClient: HttpClient,
              private endpointService: EndpointService,
  ) {
  }

  getAvailableEmployees(includeCurrentUser?: boolean) {
    const params = new HttpParams().set('includeCurrentUser', 'true');
    return this.httpClient.get(this.endpointService.currentEndpoint + CONFIG.PATH_HAULER, includeCurrentUser && {params})
      .pipe(
        map((body: object[]) => plainToArray(HaulerEmployee, body))
      );
  }

}
