import {HttpEvent} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpInterceptor} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap, timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TimeoutInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/mobile/v2/getOrdersForHaulerEmployee')) {
      return next.handle(req).pipe(
        timeout(60 * 1000),
        tap(event => {
        }, err => {
          console.log('Got timeout in interceptor for getOrdersForHaulerEmployee', err);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
