import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouteSummary} from '@models/optimize-route/route-summary.model';
import {UserSettings} from '@models/settings/settings.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Provider} from '@models/business/provider.model';
import {UserLocation} from '@models/settings/user-location.model';
import {MapService} from '@services/map/map.service';
import {Position} from '@models/business/position.model';
import {NavParams, PopoverController, ToastController} from '@ionic/angular';
import {OrdersAccessor} from '@models/order-helper/orders-accessor.model';
import {OrderService} from '@services/order-service/order.service';
import {OrderGroup} from '@models/order-helper/order-group.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {OrderView} from '@models/order-helper/order-view.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mobilus-route-summary-display',
  templateUrl: './route-summary-display.component.html',
  styleUrls: ['./route-summary-display.component.scss'],
})
export class RouteSummaryDisplayComponent implements OnInit, OnDestroy {

  summary: RouteSummary = new RouteSummary();
  settings: UserSettings = new UserSettings(true);
  unsubscribe: Subject<void> = new Subject<void>();
  private providers: Array<Provider>;
  private userLocations: Array<UserLocation>;
  endLocationName: string;
  endLocationAddress: string;
  endLocationPosition: Position;
  orders: Array<OrderView>;
  status = OrderStatus.ON_THE_WAY_TO_CUSTOMER;

  constructor(private mobileContextService: MobileContextService,
              private mapService: MapService,
              private navParams: NavParams,
              private popoverController: PopoverController,
              private orderService: OrderService,
              private translate: TranslateService,
              public toastController: ToastController,
  ) {
    this.summary = this.navParams.get('summary');
  }

  ngOnInit(): void {

    this.orderService.ordersAccessor
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((accessor: OrdersAccessor) => this.manageNewOrders(accessor));


    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userSettings => {
        this.settings = userSettings;
        if (this.settings && this.summary && this.summary.routeEnd) {
          this.getEndLocationInfos(this.settings);
        }
      });
  }


  private manageNewOrders(accessor: OrdersAccessor) {
    this.orders = [];
    accessor.getList(this.status).groups
      .forEach((group: OrderGroup) => {
        this.orders = this.orders.concat(group.orders);
      });
  }

  getEndLocationInfos(userSettings: UserSettings) {
    this.providers = userSettings.selectedProviders;
    this.userLocations = userSettings.userLocations || [];

    this.providers.forEach(provider => {
      if (provider.position.latitude.toString() === this.summary.routeEnd.latitude.toString()
        && provider.position.longitude.toString() === this.summary.routeEnd.longitude.toString()) {
        this.endLocationAddress = provider.address.addressOneLine;
        this.endLocationName = provider.name;
        this.endLocationPosition = provider.position;
      }
    });

    if (!this.endLocationAddress && !this.endLocationName) {
      this.userLocations.forEach(userLocation => {
        if (userLocation.position.latitude.toString() === this.summary.routeEnd.latitude.toString()
          && userLocation.position.longitude.toString() === this.summary.routeEnd.longitude.toString()) {
          this.endLocationAddress = userLocation.address;
          this.endLocationName = userLocation.name;
          this.endLocationPosition = userLocation.position;
        }
      });
    }


  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openMap() {
    this.mapService.openMap(null, this.endLocationPosition, this.endLocationAddress, this.endLocationName);
  }

  async closePopover() {
    await this.popoverController.dismiss();
  }

  async openDirectionsForOptimizedOrders() {
    if (this.orders.length > 10) {
      const toast = await this.toastController.create({
        message: this.translate.instant('pages.map.tooManyWaypoints'),
        duration: 5 * 1000
      });
      await toast.present();
    }
    this.mapService.openDirectionsForOptimizedOrders(this.orders, this.summary.routeStart, this.summary.routeEnd);
  }
}
