import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Policies} from '@models/check-in/policies.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {Platform} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Features} from '@services/features/features.model';
import {FeaturesService} from '@services/features/features.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {SettingsManager} from '@services/settings-providers/settings.service';
import introJs from 'intro.js/intro';
import {Subject} from 'rxjs';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const CONFIG = {
  GA: {
    PAGE_NAME: 'HelpPage',
  },
  REPLAY_INTRO: 'replayIntro'
};

@Component({
  templateUrl: './help.page.html',
  styleUrls: ['./help.page.scss'],
})
export class HelpPage {

  public language = 'en';
  public userLocaleLang: string;
  public helpUrl: string;
  private unsubscribe: Subject<void> = new Subject<void>();
  protected seenV2_4_x = false;
  phoneNumber = '+18884506257';
  phoneNumberText = '+18884506257';
  private canPlayIntro = true;
  settings: UserSettings = new UserSettings(true);
  public isCordova = true;
  private features: Features = new Features();
  public userProfile: UserProfile;
  policies: Policies;

  constructor(private modalCtrl: ModalController,
              private mobileContextService: MobileContextService,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private translateService: TranslateService,
              private sanitizer: DomSanitizer,
              private featuresService: FeaturesService,
              private settingsManager: SettingsManager,
              private platform: Platform,
  ) {

    this.platform.ready()
      .then(() => {
        this.isCordova = this.platform.is('cordova');
      });

    this.userLocaleLang = this.translateService.getBrowserCultureLang();
    if (this.userLocaleLang === 'en-US') {
      this.phoneNumber = '+18885659149';
      this.phoneNumberText = '+18885659149';
    }
  }

  ionViewWillEnter(): void {
    combineLatest([
      this.mobileContextService.userProfileObservable,
      this.mobileContextService.userSettingsObservable
    ])
      .subscribe(([userProfile, userSettings]) => {
        this.userProfile = userProfile;
        if (userProfile && userSettings && userSettings.firstRun) {
          if (userSettings.language) {
            this.language = userSettings.language;
          } else {
            this.language = 'fr';
          }
        }

        if (this.language === 'fr') {
          this.helpUrl = 'https://support.mobilus.ca/hc/fr-ca/categories/360001960793';
        } else {
          this.helpUrl = 'https://support.mobilus.ca/hc/en-ca/categories/360001960793';
        }

      });
    if (this.language === 'fr') {
      this.helpUrl = 'https://support.mobilus.ca/hc/fr-ca/categories/360001960793';
    } else {
      this.helpUrl = 'https://support.mobilus.ca/hc/en-ca/categories/360001960793';
    }

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userSettings => {
        this.settings = userSettings;
      });

    this.mobileContextService.policiesObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(policies => {
        this.policies = policies;
      });

    this.featuresService.featuresObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(features => this.features = features);

  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  changeIntroSeen() {
    this.modalCtrl.dismiss()
      .then(() => {
        setTimeout(() => {
          this.playIntro();
        });
      });
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  private playIntro() {
    if (this.canPlayIntro) {
      this.canPlayIntro = false;
      const steps: any = [];

      steps.push({intro: this.translateService.instant('walkthrough.v2_4_x.intro')});

      if (this.settings.buttons.multipleSelection) {
        steps.push({
          element: '.selection-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.multipleSelection')
        });
      }

      if (this.settings.buttons.map && (this.isCordova || this.features.nativeMapButton)) {
        steps.push({
          element: '.native-map-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.map')
        });
      }

      if (this.settings.buttons.reordering) {
        steps.push({
          element: '.ordering-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.reorder')
        });
      }

      if (this.settings.buttons.optimization && this.settings.canDoRouteOptimization) {
        steps.push({
          element: '.optimize-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.optimize')
        });
      }

      if (this.settings.buttons.grouping) {
        steps.push({
          element: '.grouping-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.grouping')
        });
      }

      if (this.settings.buttons.sorting) {
        steps.push({
          element: '.sort-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.sorting')
        });
      }

      if (this.settings.buttons.filter) {
        steps.push({
          element: '.filter-intro',
          intro: this.translateService.instant('walkthrough.v2_4_x.filter')
        });
      }

      steps.push({intro: this.translateService.instant('walkthrough.v2_4_x.byStatus')});

      steps.push({intro: this.translateService.instant('walkthrough.v2_4_x.availableInHelp')});

      const intro = introJs();
      intro.setOptions({
        nextLabel: this.translateService.instant('walkthrough.nextLabel'),
        prevLabel: this.translateService.instant('walkthrough.prevLabel'),
        skipLabel: this.translateService.instant('walkthrough.skipLabel'),
        doneLabel: this.translateService.instant('walkthrough.doneLabel'),
        exitOnOverlayClick: false,
        disableInteraction: true,
        showStepNumbers: false,
        hidePrev: false,
        hideNext: false,
        tooltipPosition: 'auto',
        positionPrecedence: ['bottom-middle-aligned', 'bottom-right-aligned', 'bottom-left-aligned', 'left', 'right'],
        steps: steps
      });

      intro.oncomplete(() => {
        this.settingsManager.markIntroSeen('v2_4_x');
        this.canPlayIntro = true;
      });

      intro.onexit(() => {
        this.settingsManager.markIntroSeen('v2_4_x');
        this.canPlayIntro = true;
      });

      intro.start();
    }
  }
}
