import {DelocalizedDeliveryConfig} from '@models/business/delocalized-delivery-config.model';
import {Provider} from '@models/business/provider.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Hauler')
export class Hauler {

  @JsonProperty('id', Number)
  id: number = undefined;
  @JsonProperty('name', String)
  name: string = undefined;
  @JsonProperty('address', String, true)
  address: string = undefined;
  @JsonProperty('notes', String, true)
  notes: string = undefined;
  @JsonProperty('phone', String, true)
  phone: string = undefined;
  @JsonProperty('managerFirstName', String, true)
  managerFirstName: string = undefined;
  @JsonProperty('managerLastName', String, true)
  managerLastName: string = undefined;
  @JsonProperty('code', String)
  code: string = undefined;
  @JsonProperty('employeesCount', Number, true)
  employeesCount: number = undefined;
  @JsonProperty('canDoRouteOptimization', Boolean, true)
  canDoRouteOptimization: boolean = undefined;
  @JsonProperty('employeeControlsSubscriptions', Boolean, true)
  employeeControlsSubscriptions: boolean = undefined;
  @JsonProperty('calculateOptimizedRouteOnEta', Boolean, true)
  calculateOptimizedRouteOnEta: boolean = undefined;
  @JsonProperty('routeEtaActivated', Boolean, true)
  routeEtaActivated: boolean = undefined;
  @JsonProperty('linkedProviders', [Provider], true)
  linkedProviders: Array<Provider> = undefined;
  @JsonProperty('warningOnDelocalizedDelivery', DelocalizedDeliveryConfig, true)
  warningOnDelocalizedDelivery: DelocalizedDeliveryConfig = undefined;

}
