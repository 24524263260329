<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'pages.transitions-debug.header' | translate }}
    </ion-title>
    <ion-buttons slot="end" (click)="close()">
      <ion-button outline>
        {{ 'actions.close' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar *ngIf="explanation">
    <h3 class="ion-text-center">{{ explanation }}</h3>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh()">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="list-view-container">

    <ion-card detail-none (click)="openTransitionDetailDebugView(item)" [ngClass]="{ 'transition-error': item.transitionRequest?.retryCount }"
      *ngFor="let item of awaitingTransitionAndOrderList; let i = index;">
      <ion-list lines="none">
        <ion-item>
          <ion-label>
            <ion-avatar class="pending-indicator" *ngIf="transitionService.retryingTransitions === true && i === 0 ">
              <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
            </ion-avatar>
            <h2>
              {{ 'pages.transitions-debug.labels.order-number' | translate }}: {{ item.order?.reference }}
            </h2>
            <p class="ion-padding-end"> {{ 'pages.transitions-debug.labels.order-client-name' | translate }}: {{
              item.order?.client?.fullName() }}</p>
            <p class="ion-text-wrap">{{ 'pages.transitions-debug.labels.order-address' | translate }}: {{
              item.order?.deliveryAddress?.addressOneLine }}</p>
            <p>{{ 'pages.transitions-debug.labels.transition-name' | translate }}: {{
              item.transitionRequest?.transition?.workflowName }}</p>
            <p>{{ 'pages.transitions-debug.labels.number-of-attachments' | translate }}: {{
              item.transitionRequest?.transitionData?.pictures?.length || 0 }}</p>
            <p class="number-of-retries-container">
              <ion-icon *ngIf="item.transitionRequest?.retryCount" name="alert-circle"></ion-icon>
              {{ 'pages.transitions-debug.labels.number-of-retries' | translate }}: {{ item.transitionRequest?.retryCount || 0 }}
            </p>
            <p>{{ 'pages.transitions-debug.labels.number-of-timeouts' | translate }}: {{
              item.transitionRequest?.timeoutCount || 0 }}</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-button expand="full" color="primary" [disabled]="manuallyRetryingTransition" mobilusDebounceClick
            (debounceClick)="retryTransition(item)" [debounceTime]="500">
            {{ 'pages.transitions-debug.labels.retry' | translate }}
          </ion-button>
          <ion-button expand="full" color="danger" mobilusDebounceClick
            (debounceClick)="deletePendingTransition(item?.transitionRequest)" [debounceTime]="500">
            {{ 'pages.transitions-debug.labels.delete' | translate }}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-item *ngIf="awaitingTransitionAndOrderList?.length" class="delete-all-transitions-container">
      <ion-button expand="full" color="danger" mobilusDebounceClick
        (debounceClick)="deleteAllPendingTransitions()" [debounceTime]="500">
        {{ 'pages.transitions-debug.labels.deleteAll' | translate }}
      </ion-button>
    </ion-item>

    <div class="no-data" [hidden]="!(awaitingTransitionAndOrderList && awaitingTransitionAndOrderList.length === 0)">
      <p>{{ 'pages.transitions-debug.messages.no-pending-transactions-text' | translate }}</p>
    </div>

    <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
    <mobilus-reloader (onReload)="init()" [promptToNavigateBack]="false" [hidden]="!(awaitingTransitionAndOrderList == null && errorShowReloadUI === true)">
    </mobilus-reloader>

  </div>

</ion-content>