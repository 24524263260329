import {Component, Input} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {BIOMETRIC_CONFIG, BIOMETRIC_TYPES, BiometricService} from '@services/biometric/biometric.service';
import {StorageService} from '@services/storage-service/storage.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@services/auth/authentication.service';
import {LogService} from '@services/log/log.service';

@Component({
  templateUrl: './biometric-opt-in.page.html',
  styleUrls: ['./biometric-opt-in.page.scss'],
})
export class BiometricOptInPage {

  @Input() availableMethod: BIOMETRIC_TYPES;
  @Input() credentials: string;
  @Input() storageKey: string;

  public loginForm: UntypedFormGroup;

  biometricMethodEnum: typeof BIOMETRIC_TYPES = BIOMETRIC_TYPES;

  stopShowing: boolean = false;

  submitAttempt: boolean = false;
  loginInProgress: boolean = false;
  public errorMessage: string;

  optinSuccessfull: boolean = false;

  constructor(private modalCtrl: ModalController,
              private biometricService: BiometricService,
              private storageService: StorageService,
              private formBuilder: UntypedFormBuilder,
              private loadingController: LoadingController,
              private authenticationService: AuthenticationService,
              private log: LogService,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  optinWithLogin() {
    this.loginInProgress = true;
    this.errorMessage = null;
    this.submitAttempt = true;
    if (this.loginForm.valid) {
      this.loadingController.create()
        .then(
          res => {
            res.present()
              .then(() => {
                this.authenticationService.doLoginOnly(this.loginForm.value.username.trim(), this.loginForm.value.password)
                  .subscribe(
                    mobileConfiguration => {
                      if (mobileConfiguration) {
                        this.loginInProgress = false;
                        this.optin(this.loginForm.value.username.trim() + ':' + this.loginForm.value.password);
                        this.storageService.persistString(BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY, 'true', false);
                        this.optinSuccessfull = true;
                        res.dismiss()
                          .catch(() => {
                          });
                      }
                    },
                    error => {
                      this.log.error('Unable to log in ', error);
                      this.loginInProgress = false;
                      res.dismiss()
                        .catch(() => {
                        });
                      this.errorMessage = error;
                    }
                  );
              });
          });
    }
  }

  optin(credentials: string = null) {
    const credentialsToSave = credentials ? credentials : this.credentials;
    if (credentialsToSave) {
      this.biometricService.registerSecret(credentialsToSave)
        .then(() => {
          this.optinSuccessfull = true;
          this.storageService.persistString(BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY, 'true', false);
        });
    }
  }


  close() {
    this.modalCtrl.dismiss({stopShowing: this.stopShowing, success: this.optinSuccessfull});
  }

}
