// ENUMS
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortedPropertyType {
  DATETIME = 10,
  MOMENT = 20,
  STRING = 30,
  STRING_CASE_INSENSITIVE = 35,
  NUMBER = 40,
  BOOLEAN = 50,
  OTHER = 9999
}

export enum DBListenChangesMode {
    SINCE_NOW = 10,
    SINCE_BEGINNIG = 20
}

export class AppError {
  constructor(public handled?: boolean, public message?: string, public innerError?: any) {
  }
}
