import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ManifestItem')
export class ManifestItem {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('label', String, true)
  label: string = undefined;

  @JsonProperty('origin', String, true)
  origin: string = undefined;


}
