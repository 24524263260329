import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

// NATIVE
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

// MODELS
import { AppError } from '@models/base/base';
import { PositionOptions, Position as GeolocationPosition } from '@capacitor/geolocation';

@Injectable({
    providedIn: 'root'
})
export class GeolocationUtils {

    constructor(
        private capacitorPlugins: CapacitorPlugins,
        private translateService: TranslateService
    ) {
    }

    public async isGeolocationAuthorized(): Promise<void> {
        const geolocationPlugin = this.capacitorPlugins.getGeolocationPlugin();
        // NOTE: we check (and request if not granted) coarse location permission
        const permissionStatus = await geolocationPlugin.checkPermissions();
        if (permissionStatus.coarseLocation === 'granted') {
            return;
        } else {
            const permissionStatus = await geolocationPlugin.requestPermissions({
                permissions: ['coarseLocation']
            });
            if (permissionStatus.coarseLocation === 'granted') {
                // NOTE: check the returned value?
                return;
            } else {
                const errorMessageTranslated = this.translateService.instant('messages.errors.geolocation_utils.no_permission_to_access_location');
                throw new AppError(false, errorMessageTranslated);
            }
        }
    }

    /**
     * Method to round and truncate the latitude or longitude to have required decimal precision (number of digits after decimal point)
     * Note: see https://stackoverflow.com/a/11832950/5151355
     *
     * @param {number} val latitude or longitude value
     * @param {number} decimalPrecision number of significant digits after decimal point to round to
     */
    public formatLatLng(val: number, decimalPrecision: number = 6): number {
        if (val != null) {
            const roundingValue = Math.pow(10, decimalPrecision);
            const truncated = Math.round((val + Number.EPSILON) * roundingValue) / roundingValue;
            return truncated;
        }
    }

    /**
     * Method to get the current user location
     * 
     * @param {boolean} formatLatLng whether to round and truncate the latitude or longitude
     * NOTE: the geolocation plugin might returns the latitude and longitude values with extremely high precision which is not desirable
     * @param {GeolocationOptions} options geolocation options
     */
    public async getCurrentLocation(formatLatLng: boolean = true, options?: PositionOptions): Promise<GeolocationPosition> {

        try {
            await this.isGeolocationAuthorized();
            const geolocationPlugin = this.capacitorPlugins.getGeolocationPlugin();
            const geolocationOptions: PositionOptions = options || { maximumAge: 0, timeout: 5000, enableHighAccuracy: true };
            const position: GeolocationPosition = await geolocationPlugin.getCurrentPosition(geolocationOptions);
            if (formatLatLng === true) {
                position.coords.latitude = this.formatLatLng(position.coords.latitude);
                position.coords.longitude = this.formatLatLng(position.coords.longitude);
            }
            return position;
        } catch (error) {
            const errorMessageTranslated = this.translateService.instant('messages.errors.geolocation_utils.failed_obtain_current_location');
            throw new AppError(false, errorMessageTranslated);
        }

    }

    /**
     * Method to watch the device locations
     */
    public watchPosition(options?: PositionOptions): Observable<GeolocationPosition> {

        const geolocationPlugin = this.capacitorPlugins.getGeolocationPlugin();
        const positionOptions: PositionOptions = options || { maximumAge: 0, timeout: 5000, enableHighAccuracy: true };

        const observable = new Observable<GeolocationPosition>((observer) => {

            let watchID: string = null;
            geolocationPlugin.watchPosition(positionOptions, (position: GeolocationPosition, error?: any): void => {
                if (!error && position && position.coords && position.coords.latitude && position.coords.longitude) {
                    observer.next(position);
                }
            })
                .then(callbackID => watchID = callbackID)
                .catch(error => observer.error(error));

            // NOTE: Provide a way of canceling and disposing the interval resource
            return () => {
                geolocationPlugin.clearWatch({ id: watchID });
            };

        });

        return observable;

    }

}
