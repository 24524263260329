<ion-badge class="bag" (click)="setOpen($event, true)">
  <fa-icon [icon]="['fas', 'archive']"></fa-icon>
  <span class="bagText">{{ bag.bagType.shortName }}:{{ bag.number }}</span>
</ion-badge>
<ion-modal [isOpen]="isModalOpen">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ 'pages.bag.labels.bag-name' | translate }}: {{ bag.bagType.name }} ({{ bag.number }}) </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="setOpen($event, false)" class="text-xxlarge" style="margin-left: auto;">
            <fa-icon [icon]="['fal', 'times']"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-text class="header">
        <p>{{ 'pages.bag.labels.bag-ids' | translate }}</p>
      </ion-text>
      <ion-text *ngFor="let manifestItem of manifestItems" class="manifest-item">
        <p>{{ 'pages.bag.labels.id' | translate }}: {{ manifestItem.id }}</p>
      </ion-text>
    </ion-content>
  </ng-template>
</ion-modal>
