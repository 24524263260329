import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {Tag} from '@models/business/tag.model';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {IconName} from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'mobilus-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {

  @Input() tag: Tag;
  @Input() short: boolean;
  iconNotFound: boolean = false;

  constructor(private faIconLibrary: FaIconLibrary, ) {
  }

  get icon() {
    const icon = this.tag.icon;
    let result = this.tag.icon;

    if (!this.faIconLibrary.getIconDefinition('fal', icon as any)) {
      // NOTE: Some icons are not available for the prefix 'fal'. Checking is the icon exists, otherwise we catch that case
      result = 'question';
      this.iconNotFound = true;
    }

    switch (icon) {
      case 'area-chart': {
        result = 'chart-area';
        break;
      }
      case 'automobile': {
        return 'car';
      }
      case 'bank': {
        result = 'university';
        break;
      }
      case 'bar-chart': {
        result = 'chart-bar';
        break;
      }
      case 'bell-o': {
        result = 'bell';
        break;
      }
      case 'bell-slash-o': {
        result = 'bell-slash';
        break;
      }
      case 'building-o': {
        result = 'building';
        break;
      }
      case 'calendar-o': {
        result = 'calendar';
        break;
      }
      case 'caret-square-o-down': {
        result = 'caret-square-down';
        break;
      }
      case 'caret-square-o-left': {
        result = 'caret-square-left';
        break;
      }
      case 'caret-square-o-right': {
        result = 'caret-square-right';
        break;
      }
      case 'caret-square-o-up': {
        result = 'caret-square-up';
        break;
      }
      case 'circle-o': {
        result = 'circle';
        break;
      }
      case 'circle-o-notch': {
        result = 'circle-notch';
        break;
      }
      case 'circle-thin': {
        result = 'circle';
        break;
      }
      case 'clock-o': {
        result = 'clock';
        break;
      }
      case 'close': {
        result = 'times';
        break;
      }
      case 'code-fork': {
        result = 'code-branch';
        break;
      }
      case 'cutlery': {
        result = 'utensils';
        break;
      }
      case 'dashboard': {
        result = 'tachometer';
        break;
      }
      case 'envelope-o': {
        result = 'envelope';
        break;
      }
      case 'eyedropper': {
        result = 'eye-dropper';
        break;
      }
      case 'file-image-o': {
        result = 'file-image';
        break;
      }
      case 'file-pdf-o': {
        result = 'file-pdf';
        break;
      }
      case 'file-photo-o': {
        result = 'file-image';
        break;
      }
      case 'flag-o': {
        result = 'flag';
        break;
      }
      case 'frown-o': {
        result = 'frown';
        break;
      }
      case 'futbol-o': {
        result = 'futbol';
        break;
      }
      case 'gear': {
        result = 'cogs';
        break;
      }
      case 'glass': {
        result = 'glass-martini';
        break;
      }
      case 'group': {
        result = 'users';
        break;
      }
      case 'hdd-o': {
        result = 'hdd';
        break;
      }
      case 'heart-o': {
        result = 'heart';
        break;
      }
      case 'hospital-o': {
        result = 'hospital';
        break;
      }
      case 'hotel': {
        result = 'h-square';
        break;
      }
      case 'institution': {
        result = 'university';
        break;
      }
      case 'keyboard-o': {
        result = 'keyboard';
        break;
      }
      case 'life-saver': {
        result = 'life-ring';
        break;
      }
      case 'lightbulb-o': {
        result = 'lightbulb';
        break;
      }
      case 'line-chart': {
        result = 'chart-line';
        break;
      }
      case 'money': {
        result = 'money-bill';
        break;
      }
      case 'moon-o': {
        result = 'moon';
        break;
      }
      case 'newspaper-o': {
        result = 'newspaper';
        break;
      }
      case 'pencil-square-o': {
        result = 'pen-square';
        break;
      }
      case 'photo': {
        result = 'image';
        break;
      }
      case 'pie-chart': {
        result = 'chart-pie';
        break;
      }
      case 'refresh': {
        result = 'sync';
        break;
      }
      case 'sliders': {
        result = 'sliders-h';
        break;
      }
      case 'spoon': {
        result = 'utensil-spoon';
        break;
      }
      case 'square-o': {
        result = 'square';
        break;
      }
      case 'star-o': {
        result = 'star';
        break;
      }
      case 'sun-o': {
        result = 'sun';
        break;
      }
      case 'thumb-tack': {
        result = 'thumbtack';
        break;
      }
      case 'video-camera': {
        result = 'video';
        break;
      }
    }
    return result;

  }

}
