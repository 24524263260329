import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Client} from '@models/business/client.model';
import {DriverOwnedInfo} from '@models/business/driver-owned-info.model';
import {EndpointService} from '@services/endpoint/endpoint.service';

const CONFIG = {
  PATH_UPDATE: '/mobile/v2/updateDriverOwnedInfo',
};

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient,
              private endpointService: EndpointService,
  ) {
  }

  updateClient(client: Client, info: DriverOwnedInfo) {
    return this.http.post(this.endpointService.currentEndpoint + CONFIG.PATH_UPDATE, {
      clientId: client.id.toString(),
      notes: info.notes,
      desiredArrivalTime: info.desiredArrivalTime,
      desiredArrivalTimeHour: info.desiredArrivalTimeHour,
      desiredArrivalTimeHourEnd: info.desiredArrivalTimeHourEnd,
    }).subscribe();
  }

}
