import {Injectable} from '@angular/core';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {SettingsManager} from '@services/settings-providers/settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userProfile: UserProfile;
  private userSettings: UserSettings;

  constructor(private mobileContextService: MobileContextService,
              private settingsManager: SettingsManager,
  ) {
    this.mobileContextService.userProfileObservable
      .subscribe(userProfile => {
        this.userProfile = userProfile;
      });
    this.mobileContextService.userSettingsObservable
      .subscribe(userSettings => {
        this.userSettings = userSettings;
      });
  }

  setIonicChannel(channelName: string) {
    this.settingsManager.setIonicChannel(channelName);
  }

  setCurrentIonicChannel(channelName: string) {
    if (this.userProfile && this.userSettings && this.userSettings.currentChannel !== channelName) {
      this.settingsManager.setCurrentIonicChannel(channelName);
    }
  }

}
