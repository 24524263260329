import {Pipe} from '@angular/core';
import {PipeTransform} from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string, ...args) {

    if (!value) {
      return value;
    }
    value = value.replace('-', '');
    // TODO take COUNTRY and FORMAT as arguments
    try {
      const parsed = parsePhoneNumber(value, 'CA');
      return parsed.formatNational();
    } catch (e) {
      console.error('Unparsable phone number: ', value);
      return value;
    }


  }
}
