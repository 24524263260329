import {OrderListForStatus} from '@models/order-helper/order-list-for-status.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {UserSettings} from '@models/settings/settings.model';

export class OrdersAccessor {
  private _orderList: Map<OrderStatus, OrderListForStatus>;
  private _settings: UserSettings;

  constructor(orderList: Map<OrderStatus, OrderListForStatus>) {
    this._orderList = orderList;
  }

  setOptions(settings: UserSettings) {
    this._settings = settings;
  }

  getList(status: OrderStatus): OrderListForStatus {
    const orderList = this._orderList.get(status);
    if (orderList) {
      if (status !== undefined) {
        const statusSettings = this._settings.settingsForStatus(status);
        orderList.serveRaw(!statusSettings.groupByProvider);
      }
    }
    return orderList;
  }

}
