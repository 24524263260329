import {ViewChild} from '@angular/core';
import {Component} from '@angular/core';
import {ElementRef} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Validators} from '@angular/forms';
import {UntypedFormControl} from '@angular/forms';
import {UntypedFormArray} from '@angular/forms';
import {UntypedFormGroup} from '@angular/forms';
import {ManifestConfirmationPage} from '@app/pages/manifest-confirmation/manifest-confirmation.page';
import {SelectRecipientPage} from '@app/pages/select-recipient/select-recipient.page';
import {SignaturePage} from '@app/pages/signature/signature.page';
import {TransitionDataFormConverter} from '@app/pages/transition-detail/transition-data-form.converter';
import {TransitionParameters} from '@app/pages/transition-detail/transition-parameters.model';
import {ToastController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {Platform} from '@ionic/angular';
import {Hauler} from '@models/business/hauler.model';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';
import {Order} from '@models/business/order.model';
import {PaymentMode} from '@models/business/payment-mode.model';
import {Picture, TransitionData} from '@models/business/transition-data.model';
import {TransitionDetail} from '@models/business/transition-detail.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {Transition} from '@models/workflow/transition.model';
import {TranslateService} from '@ngx-translate/core';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {HaulerEmployeeProvider} from '@services/hauler-employee/hauler-employee-provider.service';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {PictureService} from '@services/picture/picture.service';
import {jsonClone} from '@utils/json-converter/json-converter';
import {throwError} from 'rxjs';
import {Subject} from 'rxjs';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {takeUntil} from 'rxjs/operators';
import {delay} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import {retryWhen} from 'rxjs/operators';

const CONFIG = {
  GA: {
    PAGE_NAME: 'TransitionDetailPage',
    EVENT: {
      ADD_PAYMENT: {
        NAME: 'payment_clicked',
        ACTION: 'add'
      },
      REMOVE_PAYMENT: {
        NAME: 'payment_clicked',
        ACTION: 'remove'
      },
      SELECT_RELATION_TYPE: {
        NAME: 'relation_type_clicked',
        ACTION: 'select'
      },
      OPEN_MANIFEST: {
        NAME: 'manifest_clicked',
        ACTION: 'open'
      },
      OPEN_SIGNATURE_MODEL: {
        NAME: 'signature_clicked',
        ACTION: 'open'
      },
      TAKE_PICTURE: {
        NAME: 'picture_clicked',
        ACTION: 'take'
      },
      REMOVE_PICTURE: {
        NAME: 'picture_clicked',
        ACTION: 'remove'
      },
      REMOVE_SIGNATURE: {
        NAME: 'signature_clicked',
        ACTION: 'remove'
      },
    }
  }
};

@Component({
  templateUrl: './transition-detail.page.html',
  styleUrls: ['./transition-detail.page.scss'],
})
export class TransitionDetailPage {

  @ViewChild('relationWithClientSelect') relationWithClientSelect: ElementRef;

  title: string;
  order: Order;
  orderViews: Array<OrderView>;
  transition: Transition;
  config: TransitionDetail;
  private data: TransitionData;
  private transitionIsNotRunning = true;
  submitAttempt = false;
  isForEdition: boolean;
  hauler: Hauler;
  paymentModes: Array<PaymentMode> = [];
  gettingAvailableEmployees: boolean;

  public connected = true;
  private unsubscribe: Subject<void> = new Subject<void>();

  public width = 300;
  public height = 300;
  transitionForm: UntypedFormGroup;
  fdc: TransitionDataFormConverter;
  employees: Array<HaulerEmployee> = [];
  parameters: TransitionParameters = TransitionParameters.allDeactivated();
  private inFront = true;
  private toast: any = null;
  private picturesLocation: string;

  relations = [
    {value: 'self', label: 'transition.relationWithClient.relations.self'},
    {value: 'spouse', label: 'transition.relationWithClient.relations.spouse'},
    {value: 'nurse', label: 'transition.relationWithClient.relations.nurse'},
    {value: 'reception', label: 'transition.relationWithClient.relations.reception'},
    {value: 'friend', label: 'transition.relationWithClient.relations.friend'},
    {value: 'parent', label: 'transition.relationWithClient.relations.parent'},
    {value: 'familyChild', label: 'transition.relationWithClient.relations.familyChild'},
  ];
  authorizedRecipientsEnabled = false;
  withAuthorizedRecipients = false;
  relationType = 'other';
  public isIos: boolean = false;
  public isAndroid: boolean = false;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private deviceInfo: DeviceInfo,
              private employeesService: HaulerEmployeeProvider,
              private picture: PictureService,
              private orderStoreService: OrderStoreService,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              private log: LogService,
              private mobileContextService: MobileContextService,
              private ga: MblsAnalyticsService,
              private platform: Platform,
  ) {
    this.order = this.navParams.get('order');
    this.orderViews = this.navParams.get('orderViews');
    this.config = this.navParams.get('formConfig');
    this.data = this.navParams.get('formData');
    this.parameters = this.navParams.get('parameters');
    this.transition = this.navParams.get('transition');
    this.inFront = true;
    this.isForEdition = this.navParams.get('update') || false;
    this.hauler = this.navParams.get('hauler');
    this.title = this.navParams.get('title') ? this.navParams.get('title') : 'transitions.titles.' + this.transition.event.name;
    this.fdc = new TransitionDataFormConverter(this.config, this.order, this.hauler, this.parameters);
    this.transitionForm = this.fdc.toForm();
    // set previous data
    if (this.data) {
      this.fdc.setData(this.data);
    }
    // filter payment mode for a single order
    if (this.order) {
      const provider = this.hauler && this.hauler.linkedProviders.find(p => p.id === this.order.tenantId);
      this.paymentModes = provider && provider.configuration.paymentModes;
      this.authorizedRecipientsEnabled = provider && provider.configuration && provider.configuration.deliveryConstraints && provider.configuration.deliveryConstraints.authorizedRecipientsEnabled;
      this.withAuthorizedRecipients = this.order.client && this.order.client.authorizedRecipients && this.order.client.authorizedRecipients.length > 0;
    }
    // filter payment mode for a multiple orders
    if (this.orderViews) {
      const modes = [];
      this.orderViews.forEach(orderView => {
        const aProvider = this.hauler.linkedProviders.find(provider => provider.id === orderView.order.tenantId);
        if (aProvider) {
          aProvider.configuration.paymentModes.forEach(mode => {
            if (mode.enabled && !modes.find(m => m.mode === mode.mode)) {
              modes.push(mode);
            }
          });
        }
      });
      this.paymentModes = modes;
    }

    if (this.isForEdition) {
      const paymentsControls = this.transitionForm.get('payments') as UntypedFormArray;
      this.order.payments.forEach((payment, index) => {
        paymentsControls.at(index).get('collectedAmount').setValue(payment.collectedAmount);
        paymentsControls.at(index).get('paymentType').setValue(payment.type && payment.type.toString());
      });
      this.order.traceabilities.sort((traceA, traceB) =>
        Number(traceB.dateTime.format('X')) - Number(traceA.dateTime.format('X')));
      const traceLatest = this.order.traceabilities.find(trace => (trace.event === 'CUSTOMER_DELIVERY' || trace.event === 'PICKUP')); // FIXME we should not hardcode event like this but use the WorkflowService instead
      this.transitionForm.removeControl('comment');
      this.transitionForm.setControl('comment',
        new UntypedFormControl(traceLatest ? traceLatest.message : '', Validators.required));
      const unbalancedPaymentComment = this.transitionForm.get('unbalancedPaymentComment') as UntypedFormControl;
      unbalancedPaymentComment.setValue(traceLatest.unbalancedPaymentComment);
    }

    if (this.config.withHaulerChoice) {
      this.gettingAvailableEmployees = true;
      this.employeesService.getAvailableEmployees(this.transition.impersonate)
        .pipe(
          retryWhen(error => {
            return error.pipe(
              mergeMap((err: any) => {
                this.log.error('Got error when getting hauler employees ', JSON.stringify(err));
                if (err.status === 0 || err.status === 500) {
                  this.log.error('Retrying after a 0 or 500', err);
                  // @ts-ignore
                  return of(err.status).pipe(delay(2 * 1000));
                }
                this.log.warn('Not retrying (error status different than 0)');
                return throwError({error: 'No retry', status: err.status});
              })
            );
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => {
          this.gettingAvailableEmployees = false;
          this.employees = data;
        });
    }
    this.platform.ready()
      .then(() => {
        this.isIos = this.platform.is('ios');
        this.isAndroid = this.platform.is('android');
      });
  }

  ionViewDidEnter() {
    this.inFront = true;
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ionViewWillEnter() {
    this.orderStoreService.getLiveChanges()
      .pipe(
        takeUntil(this.unsubscribe),
        filter(Boolean),
        filter((orderChange: any) => orderChange.doc.id?.toString() === this.order.id?.toString()),
        filter(() => this.transitionIsNotRunning)
      )
      .subscribe((orderChange: any) => {
        if (orderChange.deleted || (this.order.status !== orderChange.doc.status)) {
          // nothing to do here
        } else {
          this.order = orderChange.doc;
        }
      });

    this.deviceInfo.networkStatus.networkChange
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(next => {
        this.connected = next;
      });

    this.mobileContextService.userProfileObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userProfile => {
        this.picturesLocation = 'mbls_tp_' + userProfile.haulerEmployeeUuid;
      });

    this.sameAmount();
  }

  ionViewWillLeave() {
    this.unsubscribe.next();
    this.inFront = false;
    if (this.toast) {
      try {
        this.toast.dismiss();
      } catch (e) {
      }
      this.toast = null;
    }
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  /**
   * check whether the payment mode is authorized by the provider or not
   *
   */
  isPaymentModeAuthorized(mode: string) {
    if (this.paymentModes) {
      const paymentMode = this.paymentModes.find(pM => pM.mode.endsWith(mode));
      return paymentMode && paymentMode.enabled;
    }
    return true;
  }

  sameAmount() {
    const payments = this.transitionForm.get('payments') as UntypedFormArray;
    if (payments) {
      payments.at(0).get('collectedAmount').setValue(this.order.amount);
    }
  }

  addPayment() {
    this.ga.trackEvent(CONFIG.GA.EVENT.ADD_PAYMENT.NAME, CONFIG.GA.EVENT.ADD_PAYMENT.ACTION, 'payment').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.ADD_PAYMENT} with GA`, error));
    this.fdc.addPayment();
  }

  removePayment(index) {
    this.ga.trackEvent(CONFIG.GA.EVENT.REMOVE_PAYMENT.NAME, CONFIG.GA.EVENT.REMOVE_PAYMENT.ACTION, 'payment').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.REMOVE_PAYMENT} with GA`, error));
    this.fdc.removePayment(index);
  }

  get payments(): UntypedFormArray {
    return this.transitionForm.get('payments') as UntypedFormArray;
  }

  get reasonChoicesOther() {
    return this.transitionForm.get('reasonChoicesOther') as UntypedFormGroup;
  }

  paymentClicked(control: UntypedFormGroup, value?: string) {
    // @ts-ignore
    control.paymentType.setValue(value);
  }

  get signatureImage() {
    const field = this.transitionForm.get('signatureImage');
    return field || null;
  }

  isRequiredField(control: AbstractControl): boolean {
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  async selectRelationType(type: string) {
    this.ga.trackEvent(CONFIG.GA.EVENT.SELECT_RELATION_TYPE.NAME, CONFIG.GA.EVENT.SELECT_RELATION_TYPE.ACTION, 'relation').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.SELECT_RELATION_TYPE} with GA`, error));
    const previousRelationType = this.relationType;
    this.relationType = type;
    if (type === 'self') {
      if (this.recipientIdentity) {
        this.recipientIdentity.setValue(this.order.client.fullName());
        this.recipientIdentity.updateValueAndValidity();
      }
      if (this.relationWithClient) {
        this.relationWithClient.setValue('self');
        this.relationWithClient.disable();
        this.relationWithClient.updateValueAndValidity();
      }
      if (this.relationWithClientOther) {
        this.relationWithClientOther.setValue(null);
        this.relationWithClientOther.updateValueAndValidity();
      }
    } else if (type === 'authorizedRecipients') {
      // Pop to select authorized recipient
      const modal = await this.modalCtrl.create({
        component: SelectRecipientPage,
        componentProps: {recipients: this.order.client.authorizedRecipients}
      });
      modal.onDidDismiss().then(
        (data) => {
          if (data && data.data && data.data.recipient) {
            if (this.recipientIdentity) {
              // this.recipientIdentity.reset();
              this.recipientIdentity.setValue(data.data.recipient.identity);
              this.recipientIdentity.updateValueAndValidity();
            }
            if (this.relationWithClient) {
              this.relationWithClient.setValue(data.data.recipient.relation);
              this.relationWithClient.disable();
              this.relationWithClient.updateValueAndValidity();
            }
            if (this.relationWithClientOther) {
              this.relationWithClientOther.setValue(data.data.recipient.relationMore);
              this.relationWithClientOther.updateValueAndValidity();
            }
          } else {
            this.relationType = previousRelationType;
          }
        }
      );
      await modal.present();
    } else {
      if (previousRelationType !== 'other') {
        if (this.recipientIdentity) {
          this.recipientIdentity.enable();
          this.recipientIdentity.reset();
          this.recipientIdentity.updateValueAndValidity();
        }

        if (this.relationWithClient) {
          this.relationWithClient.enable();
          this.relationWithClient.reset();
          this.relationWithClient.updateValueAndValidity();
        }

        if (this.relationWithClientOther) {
          this.relationWithClientOther.setValue(null);
          this.relationWithClientOther.enable();
          this.relationWithClientOther.updateValueAndValidity();
        }
      }

      if (this.relationWithClientSelect) {
        // @ts-ignore
        this.relationWithClientSelect.open();
      }
    }
  }

  get recipientIdentity(): AbstractControl {
    const field = this.transitionForm.get('recipientIdentity');
    return field || null;
  }

  get relationWithClientGroup() {
    const field = this.transitionForm.get('relationWithClientGroup');
    return field || null;
  }

  get relationWithClient() {
    const field = this.transitionForm.get('relationWithClientGroup') ? this.transitionForm.get('relationWithClientGroup').get('relationWithClient') : null;
    return field || null;
  }

  get relationWithClientOther() {
    const field = this.transitionForm.get('relationWithClientGroup') ? this.transitionForm.get('relationWithClientGroup').get('relationWithClientOther') : null;
    return field || null;
  }

  get manifest() {
    const field = this.transitionForm.get('manifest');
    return field || null;
  }

  get doorCode() {
    const field = this.transitionForm.get('doorCode');
    return field || null;
  }

  async openManifest() {
    // FIXME manage multiple orders
    this.ga.trackEvent(CONFIG.GA.EVENT.OPEN_MANIFEST.NAME, CONFIG.GA.EVENT.OPEN_MANIFEST.ACTION, 'manifest').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.OPEN_MANIFEST} with GA`, error));
    const manifest = [];
    this.order.manifest.items.forEach(item => {
      manifest.push(jsonClone(OrderManifestItem, item));
    });
    const modal = await this.modalCtrl.create({
      component: ManifestConfirmationPage,
      componentProps: {
        manifestId: this.order.manifest.id,
        confirmationText: this.order.manifest.confirmationText,
        manifestItems: manifest
      }
    });
    modal.onDidDismiss().then(
      data => {
        if (data) {
          // Bind values to a form array
          const formGroup = this.manifest as UntypedFormGroup;
          const confirmation = formGroup.controls.confirmation as UntypedFormGroup;
          confirmation.get('id').setValue(data.data.confirmation.id);
          confirmation.get('text').setValue(data.data.confirmation.text);
          confirmation.get('value').setValue(data.data.confirmation.value);
          confirmation.updateValueAndValidity();

          const items = formGroup.controls.items as UntypedFormArray;
          data.data.manifestItems.forEach((item: OrderManifestItem) => {
            items.push(new UntypedFormGroup({id: new UntypedFormControl(item.id), value: new UntypedFormControl(item.acceptedOnDelivery)}));
          });
        }
      }
    );
    await modal.present();
  }

  get unbalancedPaymentComment() {
    return this.transitionForm.get('unbalancedPaymentComment');
  }

  get pictures() {
    return this.transitionForm.get('pictures') as UntypedFormArray;
  }

  get multiplePayments() {
    return false;
  }

  async openSignatureModel() {
    this.ga.trackEvent(CONFIG.GA.EVENT.OPEN_SIGNATURE_MODEL.NAME, CONFIG.GA.EVENT.OPEN_SIGNATURE_MODEL.ACTION, 'signature').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.OPEN_SIGNATURE_MODEL} with GA`, error));
    const modal = await this.modalCtrl.create({
      component: SignaturePage
    });
    modal.onDidDismiss().then(
      data => {
        if (data) {
          this.signatureImage.setValue(data.data.signature);
        }
      }
    );
    modal.present();
  }

  takePicture() {
    this.ga.trackEvent(CONFIG.GA.EVENT.TAKE_PICTURE.NAME, CONFIG.GA.EVENT.TAKE_PICTURE.ACTION, 'picture').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.TAKE_PICTURE} with GA`, error));
    this.picture.initPictureChooser()
      .then((picture: Picture) => {
        this.fdc.addPicture(picture);
      }, () => {
        // nothing to do, taking picture cancelled
      });
  }

  removePicture(index: number) {
    this.ga.trackEvent(CONFIG.GA.EVENT.REMOVE_PICTURE.NAME, CONFIG.GA.EVENT.REMOVE_PICTURE.ACTION, 'picture').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.REMOVE_PICTURE} with GA`, error));
    this.fdc.removePicture(index);
  }

  removeSignature() {
    this.ga.trackEvent(CONFIG.GA.EVENT.REMOVE_SIGNATURE.NAME, CONFIG.GA.EVENT.REMOVE_SIGNATURE.ACTION, 'picture').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.REMOVE_SIGNATURE} with GA`, error));
    this.signatureImage.setValue(null);
  }

  dismiss() {
    this.order.local = false;
    this.modalCtrl.dismiss();
  }

  confirm(callback: Subject<boolean>) {
    this.submitAttempt = true;
    this.transitionIsNotRunning = false;

    // because, sometime, we disable this field
    if (this.relationWithClient) {
      this.relationWithClient.enable();
    }

    this.transitionForm.updateValueAndValidity();

    if (this.transitionForm.valid) {
      const data: TransitionData = this.fdc.toData();
      if (callback) {
        callback.next(true);
      }
      this.modalCtrl.dismiss({transitionData: data, transitionForm: this.transitionForm});
    }
  }

}
