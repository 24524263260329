import {SafeUrl} from '@angular/platform-browser';
import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {OrderManifest} from '@models/business/order-manifest.model';
import {Any} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Payment')
export class Payment {

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('collectedAmount', Number)
  collectedAmount: number = undefined;

  constructor(collectedAmount: number, type: string) {
    this.collectedAmount = collectedAmount;
    this.type = type;
  }
}

@JsonObject('Picture')
export class Picture {

  @JsonProperty('base64String', String, true)
  base64String: string = undefined;

  safeUrl: SafeUrl = undefined;

  // Path in S3 Bucket
  @JsonProperty('path', String, true)
  path: string = undefined;

  @JsonProperty('filename', String, true)
  filename: string = undefined;

  @JsonProperty('extension', String, true)
  extension: string = undefined;

  @JsonProperty('mimeType', String, true)
  mimeType: string = undefined;

  @JsonProperty('uploaded', Boolean, true)
  uploaded = false;

}

@JsonObject('TransitionData')
export class TransitionData {

  @JsonProperty('payments', [Payment], true)
  payments: Array<Payment> = [];

  @JsonProperty('unbalancedPaymentComment', String, true)
  unbalancedPaymentComment: string = undefined;

  @JsonProperty('comment', String, true)
  comment: string = undefined;

  @JsonProperty('reason', String, true)
  reason: string = undefined;

  @JsonProperty('selectedReason', String, true)
  selectedReason: string = undefined;

  @JsonProperty('signatureImage', Any, true)
  signatureImage: string | ArrayBuffer | any = undefined;

  @JsonProperty('pictures', [Picture], true)
  pictures: Array<Picture> = [];

  @JsonProperty('haulerChoice', String, true)
  haulerChoice: string = undefined;

  @JsonProperty('impersonate', Boolean, true)
  impersonate = false;

  @JsonProperty('deliveryRecipient', DeliveryRecipient, true)
  deliveryRecipient: DeliveryRecipient = undefined;

  @JsonProperty('manifest', OrderManifest, true)
  manifest: OrderManifest = undefined;

  // Transient for display only
  haulerChoiceName: string;

  constructor() {
  }

  addPicture(picture: Picture) {
    this.pictures.push(picture);
  }

  addPayment(payment: Payment) {
    this.payments.push(payment);
  }

}
