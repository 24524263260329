import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[mobilusDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {

    @Input() debounceTime: number;
    @Output() debounceClick = new EventEmitter();
    private clicks = new Subject<Event>();
    private subscription: Subscription;

    constructor() { }

    public ngOnInit() {
        this.subscription = this.clicks
            .pipe(debounceTime(this.debounceTime || 500))
            .subscribe(e => {
                this.debounceClick.emit(e)
            });
    }

    public ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    @HostListener('click', ['$event'])
    public clickEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }

}