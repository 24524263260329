export enum RemoteManagementCommandEnum {
  EMPTY_COMMAND,
  PERFORM_FULL_REFRESH,
  FORCE_LOGOUT,
  FORCE_CLOSE,
  FORCE_IONIC_UPDATE,
  FORCE_IONIC_CHANNEL,
  FORCE_REGISTRATION,
  SEND_COMPLETE_REPORT,
  RELOAD_SERVER_CONFIGURATION,
  SEND_PERMISSIONS,
  RESET_LOCAL_CHANNEL,
  RESET_USER_CHANNEL,
  RESET_LOCAL_AND_USER_CHANNEL,
  DISPLAY_ALERT,
  DISPLAY_MODAL,
  DISPLAY_TOAST,
  LOAD_NEW_USER_SETTINGS,
}
