import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AdminPage} from '@app/pages/admin/admin.page';
import {DiagnosticPage} from '@app/pages/diagnostic/diagnostic.page';
import {AlertController, LoadingController, ModalController, NavController, Platform} from '@ionic/angular';
import {MobileConfiguration} from '@models/check-in/mobile-configuration.model';
import {Policies} from '@models/check-in/policies.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {UserSettings} from '@models/settings/settings.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {endpoints, EndpointService} from '@services/endpoint/endpoint.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {IonicDeployStatus} from '@services/ionic-deploy/ionic-deploy-status';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LanguageService} from '@services/language-service/language.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  BIOMETRIC_CONFIG,
  BIOMETRIC_ERRORS,
  BIOMETRIC_TYPES,
  BiometricService
} from '@services/biometric/biometric.service';
import {BiometricOptInPage} from '@app/pages/biometric-opt-in/biometric-opt-in.page';
import {StorageService} from '@services/storage-service/storage.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SettingsManager} from '@services/settings-providers/settings.service';
import { AppEvents } from '@services/app-events/app-events';

const CONFIG = {
  GA: {
    PAGE_NAME: 'LoginPage',
    EVENT: {
      LOGIN_IN: {
        NAME: 'login',
        ACTION: 'login-in'
      },
      BIOMETRIC_LOGIN_IN: {
        NAME: 'biometric-login',
        ACTION: 'biometric-login-in'
      }
    }
  }
};

@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  public loginForm: UntypedFormGroup;
  public submitAttempt = false;
  public loginInProgress = false;
  public errorMessage: string;
  public type = 'password';
  public showPass = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private tapCount = 0;
  private timeoutFunction = false;

  private loading: any;
  public isConnected: boolean = undefined;
  public endpointHint: string;
  private lightLogoFile = 'logo_full_text.png';
  private darkLogoFile = 'logo_full_text_dark.png';
  public logoFile: string = this.lightLogoFile;

  public userLocaleLang;
  public defaultLang;
  public currentLang: string;

  deployStatus: IonicDeployStatus = new IonicDeployStatus();

  userSettings: UserSettings;
  policies: Policies;

  public isMobileWeb = false;
  public isAndroidOrIos = false;

  public biometricAuthenticationAvailable: boolean;
  public isUsingBiometricAuthentication: boolean;
  public biometricAuthenticationMethod: string;

  biometricMethodEnum: typeof BIOMETRIC_TYPES = BIOMETRIC_TYPES;

  public biometricError: string;

  constructor(private navController: NavController,
              private authenticationService: AuthenticationService,
              private formBuilder: UntypedFormBuilder,
              private modalCtrl: ModalController,
              private loadingCtrl: LoadingController,
              private endpointService: EndpointService,
              public deviceInfo: DeviceInfo,
              private languageService: LanguageService,
              private log: LogService,
              private ionicDeploy: IonicDeploy,
              private ga: MblsAnalyticsService,
              private mobileContext: MobileContextService,
              private translateService: TranslateService,
              private biometricService: BiometricService,
              private platform: Platform,
              private alertController: AlertController,
              private modalController: ModalController,
              private storageService: StorageService,
              private settingsManager: SettingsManager,
              private appEvents: AppEvents
  ) {
    this.biometricAuthenticationAvailable = undefined;
    this.biometricAuthenticationMethod = undefined;
    const userLocaleLang = this.translateService.getBrowserCultureLang();
    if (userLocaleLang?.length > 2) {
      this.userLocaleLang = userLocaleLang.substring(0, 2);
    } else {
      this.userLocaleLang = userLocaleLang;
    }
    this.defaultLang = this.translateService.getBrowserLang();
    this.currentLang = this.translateService.currentLang;
    this.loading = this.loadingCtrl.create();

    this.platform.ready()
      .then(() => {
        this.isMobileWeb = this.platform.is('mobileweb');
        this.isAndroidOrIos = this.platform.is('android') || this.platform.is('ios');

        this.biometricError = this.translateService.instant('pages.login.errors.biometricExpired');
      });
    this.prepareLoginForm();
  }

  ionViewWillEnter(): void {
    const darkSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const isDarkThemed: boolean = darkSchemeQuery.matches || document.documentElement.classList.contains('ion-palette-dark');
    if (isDarkThemed) {
      this.logoFile = this.darkLogoFile;
    } else {
      this.logoFile = this.lightLogoFile;
    }

    darkSchemeQuery.addEventListener('change', (mediaQuery) => {
      if (mediaQuery.matches) {
        this.logoFile = this.darkLogoFile;
      } else {
        this.logoFile = this.lightLogoFile;
      }
    });

    this.languageService.findAndApplyMostSuitableLanguage().then(lang => {
      this.log.trace('suitable language is', lang);
      this.currentLang = lang;
    });

    this.deviceInfo.networkStatus.networkChange
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(next => {
        this.isConnected = next;
      });

    this.ionicDeploy.observableStatus
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((status: IonicDeployStatus) => this.deployStatus = status);

    this.endpointService.observableEndpoint
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((url: string) => {
        if (url && url !== endpoints.production) {
          this.endpointHint = url;
        } else {
          this.endpointHint = null;
        }
      });

    this.mobileContext.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(settings => {
        this.userSettings = settings;
      });

    this.mobileContext.policiesObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(policies => {
        this.policies = policies;
      });


    this.platform.ready()
      .then(() => {
        if (this.platform.is('android') || this.platform.is('ios')) {
          this.storageService.loadString(BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY, false)
            .then(result => {
              if (result && result === 'true') {
                this.isUsingBiometricAuthentication = true;
              }
            });
          this.biometricService.isAvailable().then(result => {
            if (result === BIOMETRIC_TYPES.FINGERPRINT || result === BIOMETRIC_TYPES.FACE || result === BIOMETRIC_TYPES.COMMON) {
              this.biometricAuthenticationAvailable = true;
              this.biometricAuthenticationMethod = result;
            } else {
              this.log.error('[LoginPage] ionViewWillEnter - fingerPrint.isAvailable returned unknown result', result);
              this.biometricAuthenticationAvailable = false;
              this.biometricAuthenticationMethod = null;
            }
          }, error => {
            if (!error || error.code !== BIOMETRIC_ERRORS.BIOMETRIC_NOT_ENROLLED) {
              this.log.error('[LoginPage] ionViewWillEnter - fingerPrint.isAvailable failed', error);
            }
            this.biometricAuthenticationAvailable = false;
            this.biometricAuthenticationMethod = null;
          });
        }
      });
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  prepareLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      language: [this.userLocaleLang]
    });
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  biometricLogin() {
    this.ga.trackEvent(CONFIG.GA.EVENT.BIOMETRIC_LOGIN_IN.NAME, CONFIG.GA.EVENT.BIOMETRIC_LOGIN_IN.ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.BIOMETRIC_LOGIN_IN} with GA`, error));

    this.loginInProgress = true;
    this.errorMessage = null;

    this.loadingCtrl.create()
      .then(
        res => {
          this.loading = res;
          res.present()
            .then(() => {
              if (this.biometricAuthenticationAvailable && this.isUsingBiometricAuthentication) {
                this.biometricService.loadSecret()
                  .then((secret: string) => {
                    const splits = secret.split(':');
                    this.authenticationService.doLogin(splits[0], splits[1])
                      .subscribe(mobileConfiguration => {
                        this.log.info('Login successful via biometric.');
                        return this.afterLoginSuccessful(mobileConfiguration);
                      }, error => {
                        this.log.error('Unable to log in ', error);
                        this.loginInProgress = false;
                        res.dismiss().catch(() => {
                        });
                        if (error && error instanceof HttpErrorResponse && error.status === 0) {
                          // Unable to contact the backend, trying an offline login
                          this.errorMessage = error.message;
                        } else {
                          this.errorMessage = this.biometricError;
                          this.storageService.persistString(BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY, 'false', false);
                        }
                      });
                  }, error => {
                    this.log.error('Unable to load secret ', error);
                    this.loginInProgress = false;
                    this.storageService.persistString(BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY, 'false', false);
                    res.dismiss().catch(() => {
                    });
                    this.errorMessage = this.biometricError + '  -- ' + error;
                  });
              }
            });
        });
  }

  login() {
    this.ga.trackEvent(CONFIG.GA.EVENT.LOGIN_IN.NAME, CONFIG.GA.EVENT.LOGIN_IN.ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.LOGIN_IN} with GA`, error));
    this.type = 'password';
    this.showPass = false;

    this.loginInProgress = true;
    this.errorMessage = null;
    this.submitAttempt = true;

    const credentials = this.loginForm.value.username.trim() + ':' + this.loginForm.value.password;

    this.loadingCtrl.create().then(
      res => {
        this.loading = res;
        res.present()
          .then(() => {
            if (this.loginForm.valid) {
              this.authenticationService.doLogin(this.loginForm.value.username.trim(), this.loginForm.value.password)
                .subscribe(
                  mobileConfiguration => {

                    if (this.biometricAuthenticationAvailable && !this.isUsingBiometricAuthentication && mobileConfiguration && !mobileConfiguration.userSettings.hideBiometricOptInPopup) {
                      setTimeout(() => {
                        this.modalController.create({
                          component: BiometricOptInPage,
                          componentProps: {
                            availableMethod: this.biometricAuthenticationMethod,
                            credentials: credentials,
                            storageKey: BIOMETRIC_CONFIG.STORAGE_USE_BIOMETRIC_AUTH_KEY
                          }
                        }).then(modal => {
                          modal.present();
                          modal.onDidDismiss()
                            .then((result: any) => {
                              if (result && result.data && result.data.stopShowing) {
                                this.settingsManager.setDontAskForBiometric(true);
                              }
                            });
                        });
                      }, 3000);
                    }

                    return this.afterLoginSuccessful(mobileConfiguration);
                  },
                  error => {
                    this.log.error('Unable to log in ', error);
                    this.loginInProgress = false;
                    res.dismiss().catch(() => {
                    });
                    this.errorMessage = error;
                  }
                );
            }
          });
      }
    );
  }

  public languageChange() {
    // locally change the language
    this.translateService.use(this.loginForm.value.language || this.defaultLang || 'fr').subscribe();
  }

  async afterLoginSuccessful(mobileConfiguration: MobileConfiguration) {
    this.loginInProgress = false;
    await this.loading.dismiss();
    if (this.loginForm.value.language && this.loginForm.value.language !== this.settingsManager.getLanguage()) {
      await this.languageService.applyLanguage(this.loginForm.value.language);
      this.ga.trackEvent(CONFIG.GA.EVENT['LANGUAGE-CHANGED'].NAME, CONFIG.GA.EVENT['LANGUAGE-CHANGED'].ACTION, 'LanguageSetting').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT['LANGUAGE-CHANGED']} with GA`, error));
    }
    this.appEvents.notifyAuthorizationSuccess(mobileConfiguration.userProfile);
    this.navController.navigateRoot('/status-tabs');
  }

  async tap() {
    this.tapCount++;
    if (this.tapCount > 5) {
      const modal = await this.modalCtrl.create({
        component: AdminPage,
      });
      modal.present();
    }
    if (!this.timeoutFunction) {
      this.timeoutFunction = true;
      setTimeout(() => {
        this.tapCount = 0;
        this.timeoutFunction = false;
      }, 5000);
    }
  }

  showPassword() {
    this.showPass = !this.showPass;

    if (this.showPass) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  async showDiagnostic() {
    const modal = await this.modalCtrl.create({
      component: DiagnosticPage,
      componentProps: {withCloseButton: true}
    });
    modal.present().then(() => {
    });
  }

  installNewVersion() {
    if (this.isMobileWeb) {
      window.location.reload();
    } else {
      this.ionicDeploy.installNewVersion();
    }
  }

}
