<ion-list class="accordion-list">
  <ion-list-header lines="none"  class="item-no-padding ion-no-padding">
    <ion-item detail="false"
              [style.background]="headerColor"
              (click)="toggleAccordion()"
              class="accordion-header item-no-padding"
              [class.active]="expanded">
      <fa-icon [icon]="['fas', $any('carret-right')]"></fa-icon>
      {{ title }}
    </ion-item>
    <section #accordionContent
             [style.background]="contentColor"
             [class.active]="expanded"
             class="accordion-content">
      <ng-content></ng-content>
    </section>
  </ion-list-header>
</ion-list>
