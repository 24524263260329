<ion-header>

  <ion-toolbar>
    <ion-title>{{ title | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="dismiss()"
        class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>
  <form [formGroup]="transitionForm" novalidate>
    <ion-list>
      <ng-container *ngIf="config.withPayment && parameters.withPayment && !doorCode" formArrayName="payments">
        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center">
              <ion-col>
                <span translate="transition.amount.label"></span>
              </ion-col>
              <ion-col>
                <ion-button
                  color="secondary"
                  style="float:right" (click)="sameAmount()">$ {{ order.amount | number:'1.2' }}</ion-button>
              </ion-col>
              <ion-col
                size="3" *ngIf="multiplePayments">
                <ion-button (click)="addPayment()" *ngIf="multiplePayments">Add payment
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ng-container *ngFor="let payment of payments.controls; let i = index" [formGroupName]="i">
            <ion-item
            [class.invalid]="!payment['controls'].collectedAmount.valid && (payment['controls'].collectedAmount.dirty || submitAttempt)">
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <span translate="transition.collectedAmount.label"></span>
                  <span *ngIf="isRequiredField(payment['controls'].collectedAmount)">*</span>
                </ion-col>
                <ion-col>
                  <input
                    type="tel"
                    currencyMask
                    class="native-input"
                    [ngClass]="{'sc-ion-input-md': isAndroid, 'sc-ion-input-ios': isIos}"
                    placeholder="{{ 'transition.collectedAmount.placeholder' | translate }}"
                    formControlName="collectedAmount"
                    style="border: 0;"/>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="multiplePayments && payments.controls.length > 1">
                <ion-col size="1">
                  <ion-button
                    size="small" (click)="removePayment(i)">
                    <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item
            [class.invalid]="!payment.valid && (payment.dirty || submitAttempt)"
            *ngIf="payment['controls'].collectedAmount.value && payment['controls'].collectedAmount.value !== 0 && payment['controls'].collectedAmount.value !== '0'">
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-align-items-center">
                <ion-col
                  class="ion-no-padding"
                  formControlName="paymentType">
                  <div>
                    <span translate="transition.paymentMode.selected"></span>
                    <span
                      class="selected-payment-type"
                      *ngIf="payment['controls'].paymentType.value">
                      &nbsp;: {{ 'transition.paymentType.' + payment['controls'].paymentType.value.toLowerCase() | translate}}</span>
                  </div>
                  <ion-row
                    class="payment_type_selection ion-no-padding">
                    <ion-col
                      size="2"
                      (click)="paymentClicked(payment['controls'], 'CASH')"
                      *ngIf="isPaymentModeAuthorized('CASH')">
                      <fa-icon
                        [icon]="['fal', 'money-bill']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CASH'"></fa-icon>
                      <!-- cash -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CREDIT_FILES')"
                      *ngIf="isPaymentModeAuthorized('CREDIT_FILES')">
                      <fa-icon
                        [icon]="['fal', 'address-card']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CREDIT_FILES'"></fa-icon>
                      <!-- credit_files -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CREDIT')"
                      *ngIf="isPaymentModeAuthorized('CREDIT')">
                      <fa-icon
                        [icon]="['fal', 'credit-card']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CREDIT'"></fa-icon>
                      <!-- credit -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CLIENT_ACCOUNT')"
                      *ngIf="isPaymentModeAuthorized('CLIENT_ACCOUNT')">
                      <fa-icon
                        [icon]="['fal', 'address-book']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CLIENT_ACCOUNT'"></fa-icon>
                      <!-- client_account -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CHECK')"
                      *ngIf="isPaymentModeAuthorized('CHECK')">
                      <fa-icon
                        [icon]="['fal', 'pen-square']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CHECK'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'E_TRANSFER')"
                      *ngIf="isPaymentModeAuthorized('E_TRANSFER')">
                      <fa-icon
                        [icon]="['fal', 'file-invoice-dollar']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'E_TRANSFER'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CREDIT_VISA')"
                      *ngIf="isPaymentModeAuthorized('CREDIT_VISA')">
                      <fa-icon
                        [icon]="['fab', 'cc-visa']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CREDIT_VISA'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CREDIT_MASTERCARD')"
                      *ngIf="isPaymentModeAuthorized('CREDIT_MASTERCARD')">
                      <fa-icon
                        [icon]="['fab', 'cc-mastercard']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CREDIT_MASTERCARD'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'CREDIT_AMEX')"
                      *ngIf="isPaymentModeAuthorized('CREDIT_AMEX')">
                      <fa-icon
                        [icon]="['fab', 'cc-amex']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'CREDIT_AMEX'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'], 'DEBIT')"
                      *ngIf="isPaymentModeAuthorized('DEBIT')">
                      <fa-icon
                        [icon]="['fal', 'credit-card']"
                        [ngClass]="['fa-2x']"
                        [class.payment_selected]="payment['controls'].paymentType.value === 'DEBIT'"></fa-icon>
                      <!-- check -->
                    </ion-col>
                    <ion-col
                      size="2" (click)="paymentClicked(payment['controls'])">
                      <fa-icon
                        [icon]="['fal', 'ban']"
                        [class.payment_selected]="!payment['controls'].paymentType.value"
                        [ngClass]="['fa-2x']"></fa-icon>
                      <!-- none -->
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ng-container>
      </ng-container>
      <ion-item
        [class.invalid]="!transitionForm.controls.unbalancedPaymentComment.valid && (transitionForm.controls.unbalancedPaymentComment.dirty || submitAttempt)"
        *ngIf="parameters.withPayment && (payments && payments.getError('needComment')) || (unbalancedPaymentComment !== null && unbalancedPaymentComment.value)">
        <ion-textarea labelPlacement="floating"  formControlName="unbalancedPaymentComment" autocomplete="on" rows="2">
          <div slot="label">
            {{ 'transition.unbalancedPayment.label' | translate }}
          </div>
        </ion-textarea>
      </ion-item>

      <ng-container *ngIf="order.id && (recipientIdentity || relationWithClientGroup)">
        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="12">
                <span translate="transition.relationWithClient.receptionGroup.label"></span>
              </ion-col>
            </ion-row>
            <ion-row class="reception-group-row">
              <ion-col>
                <ion-button expand="full" [color]="relationType === 'self' ? 'success' : 'secondary'"
                  (click)="selectRelationType('self')">
                  <span translate="transition.relationWithClient.receptionGroup.self"></span>
                </ion-button>
              </ion-col>
              <ion-col *ngIf="authorizedRecipientsEnabled">
                <ion-button expand="full"
                  [color]="relationType === 'authorizedRecipients' ? 'success' : 'secondary'"
                  (click)="selectRelationType('authorizedRecipients')" [disabled]="!withAuthorizedRecipients">
                  <span translate="transition.relationWithClient.receptionGroup.authorizedRecipients"></span>
                </ion-button>
              </ion-col>
              <ion-col>
                <ion-button expand="full" color="secondary"
                  (click)="selectRelationType('other')">
                  <span translate="transition.relationWithClient.receptionGroup.other"></span>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ng-container>

      <ion-item-group
        formGroupName="relationWithClientGroup" *ngIf="relationWithClientGroup">
        <ng-container *ngIf="relationWithClient">
          <ion-item [class.invalid]="relationWithClient.invalid && (relationWithClient.dirty || submitAttempt)">
            <ion-select class="ion-text-wrap" formControlName="relationWithClient" #relationWithClientSelect>
              <div slot="label">
                <span translate="transition.relationWithClient.label"></span>
                <span *ngIf="isRequiredField(relationWithClient)">*</span>
              </div>
              <!-- [disabled]="relationType !== 'other'" -->
              <ion-select-option [value]="relation.value" *ngFor="let relation of relations">{{ relation.label | translate}}</ion-select-option>
              <ion-select-option value="other">{{ 'transition.relationWithClient.relations.other' | translate }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ng-container>

        <ng-container *ngIf="relationWithClientOther">
          <ion-item *ngIf="relationWithClient.value === 'other'"
            [class.invalid]="relationWithClientOther.invalid && (relationWithClientOther.dirty || submitAttempt)" lines="none">
            <ion-input labelPlacement="floating" type="text" [readonly]="relationType !== 'other'" formControlName="relationWithClientOther">
              <div slot="label">
                <span translate="transition.relationWithClient.other"></span>
                <span *ngIf="isRequiredField(relationWithClientOther)">*</span>
              </div>
            </ion-input>
          </ion-item>
        </ng-container>
      </ion-item-group>

      <ng-container *ngIf="doorCode">
        <ion-item>
          <ion-label>
            <span translate="transition.doorCode.label"></span>
          </ion-label>
        </ion-item>
        <ion-item class="doorCode">
          <fa-icon slot="start" [icon]="['fas', 'key']"></fa-icon>
          <ion-label>{{ order.lockerData.details.courierDoorCode }}</ion-label>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="recipientIdentity">
        <ion-item
          [class.invalid]="transitionForm.controls.recipientIdentity.invalid && (transitionForm.controls.recipientIdentity.dirty || submitAttempt)">
          <ion-input labelPlacement="floating" type="text" [readonly]="relationType !== 'other'" formControlName="recipientIdentity">
            <div slot="label">
              <span translate="transition.recipientIdentity.label"></span>
              <span *ngIf="isRequiredField(relationWithClient)">*</span>
            </div>
          </ion-input>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="manifest">
        <ion-item [class.invalid]="manifest.invalid && submitAttempt">
          <ion-button (click)="openManifest()">
            <fa-icon
              slot="start" [icon]="['fas', 'clipboard-list']"></fa-icon>
            <span translate="manifest.open.button"></span>
          </ion-button>
          <span>*</span>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="signatureImage">
        <ion-item *ngIf="!signatureImage.value" [class.invalid]="signatureImage.invalid && submitAttempt">
          <ion-button (click)="openSignatureModel()">
            <fa-icon
              slot="start" [icon]="['fas', 'signature']"></fa-icon>
            <span translate="signature.open.button"></span>
          </ion-button>
          <span *ngIf="isRequiredField(signatureImage)">*</span>
        </ion-item>
        <ion-item *ngIf="signatureImage && signatureImage.value">
          <ion-button
            slot="end"
            (click)="removeSignature()" *ngIf="signatureImage && signatureImage.value">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </ion-button>
          <img [src]="signatureImage.value" *ngIf="signatureImage && signatureImage.value" class="signature_thumb" />
        </ion-item>
      </ng-container>

      <ion-item *ngIf="config.withComment && parameters.withComment"
        [class.invalid]="!transitionForm.controls.comment.valid && (transitionForm.controls.comment.dirty || submitAttempt)">
        <ion-textarea labelPlacement="floating" formControlName="comment" autocomplete="on" rows="2">
          <div slot="label">
            {{ 'transition.comment.label' | translate }}
          </div>
        </ion-textarea>
      </ion-item>

      <ion-radio-group
        *ngIf="config.withReasonChoices && parameters.withReasonChoices && config.withReasonChoices.length > 0"
        formControlName="reasonChoices"
        [class.invalid]="!transitionForm.controls.reasonChoices.valid && (transitionForm.controls.reasonChoices.dirty || submitAttempt)">
        <ion-item *ngFor="let choice of config.withReasonChoices">
          <ion-radio [value]="choice">
            {{ choice | translate }}
          </ion-radio>
        </ion-item>
      </ion-radio-group>

      <ng-container *ngIf="config.withReason && parameters.withReason">
        <ion-item>
          <ion-label>
            <span translate="transition.reason.label"></span>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-textarea
            formControlName="reason"
            placeholder="{{ 'transition.reason.placeholder' | translate }}"
            [class.invalid]="!transitionForm.controls.reason.valid && (transitionForm.controls.reason.dirty || submitAttempt)"
            autocomplete="on"
            rows="2"></ion-textarea>
        </ion-item>
      </ng-container>

      <ion-item-group
        *ngIf="config.withReasonChoicesOther && parameters.withReasonChoicesOther && config.withReasonChoicesOther.length > 0"
        formGroupName="reasonChoicesOther">

        <ion-radio-group
          formControlName="reasonChoices"
          [class.invalid]="!reasonChoicesOther.controls.reasonChoices.valid && (reasonChoicesOther.controls.reasonChoices.dirty || submitAttempt)">
          <ion-item *ngFor="let choice of config.withReasonChoicesOther">
            <ion-radio [value]="choice">
              {{ choice | translate }}
            </ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-textarea
          formControlName="reason"
          placeholder="{{ 'transition.reason.placeholder' | translate }}"
          [class.invalid]="(!reasonChoicesOther.controls.reason.valid || (reasonChoicesOther.controls.reasonChoices.valid && !reasonChoicesOther.valid)) && (reasonChoicesOther.controls.reason.dirty || submitAttempt)"
          autocomplete="on"
          rows="2">
        </ion-textarea>
      </ion-item-group>

      <ng-container *ngIf="config.withHaulerChoice && parameters.withHaulerChoice">
        <ion-list-header *ngIf="!gettingAvailableEmployees">
          <ion-label translate="transition.hauler.header"></ion-label>
        </ion-list-header>
        <ion-spinner *ngIf="gettingAvailableEmployees" name="bubbles"></ion-spinner>
        <ion-radio-group
          formControlName="haulerChoice">
          <ion-item *ngFor="let employee of employees">
            <ion-radio [value]="employee.id">
              {{ employee.fullName }}
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </ng-container>

      <ng-container *ngIf="config.withPicture && parameters.withPicture" formArrayName="pictures">
        <ion-item [class.invalid]="!transitionForm.controls.pictures.valid && submitAttempt">
          <ion-button (click)="takePicture()">
            <fa-icon
              slot="start" [icon]="['fas', 'camera']"></fa-icon>
            {{ 'picture.take.button' | translate }}
          </ion-button>
          <span *ngIf="isRequiredField(pictures)">*</span>
        </ion-item>
        <ion-item *ngFor="let picture of pictures.controls; let i = index" [formGroupName]="i">
          <ion-grid>
            <ion-row>
              <ion-col>
                <img [src]="picture.value.picture.safeUrl" *ngIf="picture.value.picture"/>
              </ion-col>
              <ion-col size="2">
                <ion-button (click)="removePicture(i)">
                  <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ng-container>
    </ion-list>
  </form>
</ion-content>

<ion-footer class="actions-footer">
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row>
      <ion-col
        size="6"
        class="transitions-buttons-col">
        <ion-button
          expand="full"
          size="large"
          class="transition-button"
          fill="solid"
          color="secondary"
          (click)="dismiss()">
          <fa-icon [icon]="['fal', 'times']"></fa-icon>
          <span class="transitions-buttons-text">{{ 'actions.cancel' | translate }}</span>
        </ion-button>
      </ion-col>

      <ion-col
        size="6"
        class="transitions-buttons-col" *ngIf="!parameters.transitionFooter">
        <ion-button
          expand="full"
          size="large"
          fill="solid"
          class="transition-button"
          color="primary"
          [disabled]="gettingAvailableEmployees"
          (click)="confirm(null)">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
          <span class="transitions-buttons-text">{{ 'actions.save' | translate }}</span>
        </ion-button>
      </ion-col>

      <ion-col
        class="transitions-buttons-col"
        size="6" *ngIf="parameters.transitionFooter">
        <mobilus-transition-button
          [transition]="transition"
          [connected]="connected"
          [order]="order"
          (onClick)="confirm($event)"></mobilus-transition-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
