import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {BiometricOptInPage} from '@app/pages/biometric-opt-in/biometric-opt-in.page';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FontAwesomeModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
  declarations: [BiometricOptInPage]
})
export class BiometricOptInPageModule {
}
