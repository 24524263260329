import {OrderView} from '@models/order-helper/order-view.model';

export class OrderGroup {
  name: string;
  subtitle: string;
  hidden = false;
  orders: Array<OrderView> = [];
  selected = false;
  viewClass = 'provider_group';
  canHaulerSeeFutureOrders = false;
  hasNoOrder = false;

  constructor(name: string) {
    this.name = name;
  }

  get size(): number {
    return this.orders.length || 0;
  }

  isWorkflowGroup(): boolean {
    return this.name && this.name.startsWith('workflows.names.');
  }

  get visible(): boolean {
    return !this.hidden;
  }

  set visible(value: boolean) {
    this.hidden = !value;
  }

}
