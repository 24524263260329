<ion-header>

  <ion-toolbar>
    <ion-title><span translate="pages.help.title"></span></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">

  <ion-button expand="full" [href]="helpUrl">
    <fa-icon [icon]="['fas', 'external-link']"></fa-icon>
    <span translate="pages.help.online_support"></span>
  </ion-button>

  <ion-button expand="full" [href]="'tel:' + phoneNumber">
    <fa-icon [icon]="['fas', 'phone-alt']"></fa-icon>
    <span translate="pages.help.call_us"></span>
  </ion-button>
  <ion-button expand="full" [href]="'sms:' + phoneNumberText">
    <fa-icon [icon]="['fas', 'comment-alt']"></fa-icon>
    <span translate="pages.help.text_us"></span>
  </ion-button>

  <!--<ion-item>
    <ion-toggle [(ngModel)]="seenV2_4_x" (ionChange)="changeIntroSeen()">
      <span translate="pages.help.intro_seen">Rejouer l'introduction</span>
    </ion-toggle>
  </ion-item>-->

  <ion-item *ngIf="policies">
    <mobilus-usage-policies [policies]="policies" [userSettings]="settings"></mobilus-usage-policies>
  </ion-item>
</ion-content>
