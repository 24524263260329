import { Component } from '@angular/core';
import { UserSettings } from '@models/settings/settings.model';
import { StatusSettings } from '@models/settings/status-settings.model';
import { PopoverController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { MblsAnalyticsService } from '@services/mbls-analytics-service/MblsAnalyticsService';
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { SettingsManager } from '@services/settings-providers/settings.service';
import { Subject } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeolocationUtils } from '@services/utils/geolocation-utils';

const CONFIG = {
  GA: {
    PAGE_NAME: 'SortPopoverPage',
    EVENT: {
      SORT_CHANGED: {
        NAME: 'sort_changed',
        ACTION: 'sort'
      },
    }
  }
};

@Component({
  templateUrl: './sort-popover.page.html',
  styleUrls: ['./sort-popover.page.scss'],
})
export class SortPopoverPage {

  settings: UserSettings = new UserSettings(true);
  private unsubscribe: Subject<void> = new Subject<void>();
  statusSettings: StatusSettings;

  constructor(private popoverCtrl: PopoverController,
    private settingsManager: SettingsManager,
    private backgroundGeolocationService: BackgroundGeolocationService,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private mobileContextService: MobileContextService,
    private ga: MblsAnalyticsService,
    private log: LogService,
    private geolocationUtils: GeolocationUtils
  ) {
    combineLatest([
      this.mobileContextService.userSettingsObservable,
      this.mobileContextService.orderStatusObservable
    ])
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(([userSettings, status]) => {
        this.settings = userSettings;
        this.statusSettings = this.settings.settingsForStatus(status);
      });
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  public async sortChanged(sortType: Event) {

    const newSortOrder = (sortType as CustomEvent).detail.value;
    if(newSortOrder === this.statusSettings.sortOrder) {
      // NOTE: we can ignore this event as this is fired on the initial popup display
      return;
    }

    if (newSortOrder === 'distance') {
      try {
        await this.geolocationUtils.isGeolocationAuthorized();
      } catch (error) {
        // NOTE: show the toast with the error message
        const toast = await this.toastCtrl
          .create({
            message: this.translateService.instant('messages.errors.gps-deactivated'),
            duration: 3 * 1000,
            cssClass: 'processing-toast'
          });
        await toast.present();
        this.close();
        return;
    }
    }

    // NOTE: all good, we can update the sorting order
    this.ga.trackEvent(CONFIG.GA.EVENT.SORT_CHANGED.NAME, CONFIG.GA.EVENT.SORT_CHANGED.ACTION, 'SortChanged').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.SORT_CHANGED} with GA`, error));
    if (this.statusSettings) {
      this.statusSettings.sortOrder = newSortOrder;
      this.settingsManager.sortChanged();
      this.close();
    }
  
  }

  close() {
    this.popoverCtrl.dismiss();
  }

}
