import {Component, ViewChild} from '@angular/core';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {OrderGroup} from '@models/order-helper/order-group.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {Transition} from '@models/workflow/transition.model';
import {OrderDetailTabsPage} from '@app/pages/order-detail-tabs/order-detail-tabs.page';
import {TransitionOrders} from '@app/pages/orders-list/transition-orders.model';
import {APPROX_ITEM_HEIGHT} from '@utils/order-height-calculator';
import {DEFAULT_HEADER_HEIGHT} from '@utils/order-height-calculator';
import {orderViewHeight} from '@utils/order-height-calculator';
import {headerHeight} from '@utils/order-height-calculator';
import {Platform} from '@ionic/angular';
import {IonItemSliding} from '@ionic/angular';
import {ToastController} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {HaulerEmployeeProvider} from '@services/hauler-employee/hauler-employee-provider.service';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderService} from '@services/order-service/order.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import {TransitionService} from '@services/transition/transition.service';
import {Subject} from 'rxjs';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { SortDirection, SortedPropertyType } from '@models/base/base'

const CONFIG = {
  GA: {
    PAGE_NAME: 'HaulerEmployeesPage',
    EVENT: {
      'HAULER_ORDER_LIST-MULTIPLE_SELECT': {
        NAME: 'hauler_order_list',
        ACTION: 'multiple_select'
      },
      MULTIPLE_SELECT_TRANSITION: {
        NAME: 'hauler_order_list_transition',
        ACTION: 'multiple_select_transition'
      }
    },
  }
};


@Component({
  templateUrl: './hauler-employees.page.html',
  styleUrls: ['./hauler-employees.page.scss'],
})
export class HaulerEmployeesPage {

  public readonly SortDirection = SortDirection;
  public readonly SortedPropertyType = SortedPropertyType;

  public allOrders: Array<OrderView> = [];
  public user: UserProfile;
  public orderGroups: Array<OrderGroup> = [];
  public activeOrders: Array<OrderView> = null;
  public employees: Array<HaulerEmployee> = [];
  protected _component: HaulerEmployeesPage;
  public loading: boolean;
  connectionStatus: ConnectionStatus = new ConnectionStatus();
  private unsubscribe: Subject<void> = new Subject<void>();
  settings: UserSettings = new UserSettings(true);
  public allSelected = false;
  private sendSequence = false;
  private dummySequenceNumber = 0;
  public transitionsSelection: Array<Transition> = [];
  public hasOneSelected = false;

  approxHeaderHeight = DEFAULT_HEADER_HEIGHT;
  approxItemHeight = APPROX_ITEM_HEIGHT;
  isIos: boolean | undefined = undefined;

  constructor(private modalCtrl: ModalController,
              private orderService: OrderService,
              private orderSyncService: OrderSyncService,
              private log: LogService,
              private mobileContextService: MobileContextService,
              private haulerEmployeeProvider: HaulerEmployeeProvider,
              private connectionStatusService: ConnectionStatusService,
              protected transitionService: TransitionService,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              private ga: MblsAnalyticsService,
              private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.isIos = this.platform.is('ios');
    });
  }

  // noinspection JSUnusedGlobalSymbols
  ionViewDidEnter() {
    this.fetchOrders();
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ionViewWillEnter(): void {
    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.connectionStatus = next;
      });
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  fetchOrders(withLoadingIcon: boolean = true) {
    this.loading = withLoadingIcon;
    combineLatest([
      this.orderSyncService.getOrdersForAllHaulerEmployees(),
      this.mobileContextService.userProfileObservable,
      this.haulerEmployeeProvider.getAvailableEmployees(),
      this.mobileContextService.userSettingsObservable
    ])
      .subscribe(([orders, user, employees, settings]) => {
          if (user) {
            const selectedOrders: Array<OrderView> = [];
            orders.forEach(order => {
              if (order.haulerEmployeeId !== user.haulerEmployeeId) {
                selectedOrders.push(this.orderService.constructOrderView(order, true));
              }
            });
            this.allOrders = selectedOrders;
            this.employees = employees;
            this.user = user;
            this.activeOrders = this.sortOrders(this.allOrders, 'haulerEmployeeId');
            this.manageGroups(this.activeOrders);
          }
          if (settings) {
            this.settings = settings;
          }
          this.loading = false;
        }, err => {
          this.loading = false;
          this.activeOrders = [];
          this.log.error(err);
        }
      );
  }

  getEmployeeById(id: number): HaulerEmployee {
    if (this.employees) {
      return this.employees.find(employee => employee.id === id);
    }
  }

  identify(index, orderView: OrderView) {
    return orderView.order.id;
  }

  toggleGroupView(groupName: string) {
    const orderGroup = this.orderGroups.find(oG => oG.name === groupName);
    if (orderGroup) {
      if (orderGroup.hidden) {
        this.activeOrders = this.filterOrdersByGroupName(this.activeOrders, groupName, false);
      } else {
        this.activeOrders = this.activeOrders.concat(this.filterOrdersByGroupName(this.allOrders, groupName, true));
      }
      orderGroup.hidden = !orderGroup.hidden;
      orderGroup.visible = !orderGroup.visible;
    }
    this.orderGroups = [...this.orderGroups]
  }

  filterOrdersByGroupName(orders: Array<OrderView>, groupName: string, fill: boolean) {
    return orders.filter(orderView => {
      const group = this.getEmployeeById(orderView.order.haulerEmployeeId);
      if (group) {
        if (fill) {
          return group.fullName === groupName;
        } else {
          return group.fullName !== groupName;
        }
      }
    });
  }

  transitionDisabled(view: OrderView, transition: Transition): boolean {
    return (!this.connectionStatus.isNetworkConnectedForUser && transition.requireOnline) || (view.order && view.order.blocked);
  }

  transitionOrder(view: OrderView, transition: Transition, slidingItem: IonItemSliding) {
    this.transitionService.transitionOrder(view.order, transition)
      .subscribe((data) => {
        if (slidingItem) {
          slidingItem.close();
        }
        if (data) {
          view.order.local = true;
          this.fetchOrders();
        }
        setTimeout(() => {
          this.fetchOrders(false);
        }, 1 * 1000);
      }, async err => {
        this.log.debug('Error transit impersonate transition', err);
        const toast = await this.toastCtrl.create({
          message: this.translateService.instant('messages.errors.generic_error'),
          duration: 2 * 1000,
          cssClass: 'processing-toast'
        });
        await toast.present();
      });
  }

  async selectItem(event, orderView: OrderView) {
    orderView.withImpersonation();
    const itemModal = await this.modalCtrl.create({
      id: 'order-detail-tabs-page',
      component: OrderDetailTabsPage,
      componentProps: {
        orderView: orderView,
        isforHistory: true
      }
    });

    itemModal.onDidDismiss().then(() => {
      setTimeout(() => {
        this.fetchOrders(false);
      }, 0.5 * 1000);
    });

    await itemModal.present();
  }

  sortOrders(orders: Array<OrderView>, prop: string): Array<OrderView> {
    const result: Array<OrderView> = [];
    const ordersSorted = orders.reduce((groups, item) => {
      const val = item.order[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
    Object.keys(ordersSorted).forEach(item => {
        result.push(...ordersSorted[item]);
      }
    );
    return result;
  }

  /**
   * create groups based on employees
   *
   */
  private manageGroups(orders: Array<OrderView>) {
    if (this.employees) {
      this.orderGroups = [];
      this.employees.forEach(employee => {
        const aEmployee = this.getEmployeeById(employee.id);
        const orderGroup = new OrderGroup(aEmployee.fullName);
        this.orderGroups.push(orderGroup);
      });
      orders.forEach(orderView => {
        const aEmployee = this.getEmployeeById(orderView.order.haulerEmployeeId);
        if (aEmployee) {
          const orderGroup = this.orderGroups.find(oG => oG.name === aEmployee.fullName);
          if (orderGroup) {
            orderGroup.orders.push(orderView);
          }
        }
      });
      this.showGroupsWithNoOrders();
    }
  }

  /**
   * show groups that don't have any orders
   */
  private showGroupsWithNoOrders() {
    this.orderGroups.forEach(group => {
      if (!group.orders.length) {
        group.hidden = true;
        group.visible = false;
        group.hasNoOrder = true;
      }
    });
  }

  changeAllSelection(option) {
    this.orderGroups.forEach((orderGroup: OrderGroup) => {
      orderGroup.selected = this.allSelected;
      orderGroup.orders.forEach((order: OrderView) => {
        if (!order.hidden) {
          order.selected = this.allSelected;
          if (order.selected && this.sendSequence) {
            order.order.nextSequenceNumber = ++this.dummySequenceNumber;
          } else {
            order.order.nextSequenceNumber = null;
          }
        }
      });
    });
    this.updateSelection();
  }

  changeGroupSelection(group: OrderGroup, option) {
    group.orders.forEach((order: OrderView) => {
      if (!order.hidden) {
        order.selected = group.selected;
        if (order.selected && this.sendSequence) {
          order.order.nextSequenceNumber = ++this.dummySequenceNumber;
        } else {
          order.order.nextSequenceNumber = null;
        }
      }
    });
    this.updateSelection();
  }

  toggleSelect(): void {
    this.allSelected = false;
    this.settings.actions.canReorder = false;
    this.settings.actions.canSelect = !this.settings.actions.canSelect;
    if (!this.settings.actions.canSelect) {
      // Reset selection
      this.orderGroups
        .forEach((orderGroup: OrderGroup) => {
          orderGroup.selected = false;
          orderGroup.orders
            .forEach((order: OrderView) => {
              order.selected = false;
              order.order.nextSequenceNumber = null;
            });
        });

    }

    this.updateSelection();

    this.ga.trackEvent(CONFIG.GA.EVENT['ORDER_LIST-MULTIPLE_SELECT'].NAME, CONFIG.GA.EVENT['ORDER_LIST-MULTIPLE_SELECT'].ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT['ORDER_LIST-MULTIPLE_SELECT']} with GA`, error));
  }

  updateSelection() {
    let hasAtLeastOneSelected = false;
    this.transitionsSelection = [];
    this.orderGroups.forEach((orderGroup: OrderGroup) => {
      orderGroup.orders.forEach((order: OrderView) => {
        if (order.selected && !order.hidden) {
          hasAtLeastOneSelected = true;
          order.transitions.forEach((transition) => {
            if (!this.containsTransition(transition, this.transitionsSelection)) {
              this.transitionsSelection.push(transition);
            }
          });
        }
      });
    });

    this.transitionsSelection.sort((a, b): number => {
      if (a.event.rank < b.event.rank) {
        return -1;
      }
      if (a.event.rank > b.event.rank) {
        return 1;
      }
      return 0;
    });

    if (this.hasOneSelected !== hasAtLeastOneSelected) {
      this.hasOneSelected = hasAtLeastOneSelected;
    }
  }

  private containsTransition(transition: Transition, transitions: Array<Transition>): boolean {
    let contains = false;
    for (let k = 0, len = transitions.length; k < len; k++) {
      const existingTransition = transitions[k];
      if (existingTransition.event.labelKey === transition.event.labelKey) {
        contains = true;
      }
    }
    return contains;
  }

  markItem(orderView: OrderView) {
    if (orderView.selected && this.sendSequence) {
      orderView.order.nextSequenceNumber = ++this.dummySequenceNumber;
    } else {
      orderView.order.nextSequenceNumber = null;
    }

    this.updateSelection();
  }

  transitionSelection(transition: Transition, callback: Subject<boolean>) {
    const selectedOrders: Array<OrderView> = [];
    this.orderGroups.forEach((orderGroup: OrderGroup) => {
      orderGroup.orders.forEach((order: OrderView) => {
        orderGroup.selected = false;
        if (order.selected) {
          if (this.containsTransition(transition, order.transitions)) {
            selectedOrders.push(order);
            order.selected = false;
          }
        }
      });
    });

    this.updateSelection();
    this.settings.actions.canSelect = this.hasOneSelected;
    this.ga.trackEvent(CONFIG.GA.EVENT.MULTIPLE_SELECT_TRANSITION.NAME, CONFIG.GA.EVENT.MULTIPLE_SELECT_TRANSITION.ACTION, 'OrdersSelected', selectedOrders.length).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.MULTIPLE_SELECT_TRANSITION} with GA`, error));

    // Put orders in right basket according to workflow and event name
    const groupedByWorkflowEvent: Array<TransitionOrders> = [];
    selectedOrders
      .sort((a: OrderView, b: OrderView) => {
        if (a.order.nextSequenceNumber && b.order.nextSequenceNumber) {
          return a.order.nextSequenceNumber - b.order.nextSequenceNumber;
        } else if (a.order.nextSequenceNumber && !b.order.nextSequenceNumber) {
          return 1;
        } else if (!a.order.nextSequenceNumber && b.order.nextSequenceNumber) {
          return -1;
        } else {
          return 0;
        }
      })
      .forEach((view: OrderView) => {
        const activatedTransition = view.transitions.find((value: Transition) => transition.event.labelKey === value.event.labelKey);
        let matchingTransition = groupedByWorkflowEvent.find((group: TransitionOrders) => group.matches(activatedTransition));
        if (!matchingTransition) {
          matchingTransition = new TransitionOrders(activatedTransition);
          groupedByWorkflowEvent.push(matchingTransition);
        }
        if (this.sendSequence && transition.to.name === 'ON_THE_WAY_TO_CUSTOMER') {
          view.order.nextSequenceNumber = ++this.settings.nextSequenceNumber;
        }
        matchingTransition.orders.push(view);
      });

    groupedByWorkflowEvent.forEach((transitionOrders: TransitionOrders) => {
      this.transitionService.transitionOrders(transitionOrders.orders, transitionOrders.transition, true);
    });

    callback.next(true);
  }

  itemHeightFn = (item: OrderView, index) => {
    return orderViewHeight(item, null, this.settings, this.isIos);
  }

  headerHeight(header, index) {
    return headerHeight(header);
  }
}

