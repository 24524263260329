import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {PipesModule} from '@app/pipes/pipes.module';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {MqttLostDetailPage} from './mqtt-lost-detail.page';

const routes: Routes = [
  {
    path: '',
    component: MqttLostDetailPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    PipesModule,
    ComponentsModule,
  ],
  declarations: [MqttLostDetailPage]
})
export class MqttLostDetailPageModule {}
