import { Pipe, PipeTransform } from '@angular/core';

// MODELS
import { SortDirection, SortedPropertyType } from '@models/base/base';

// PROVIDERS
import { CommonUtils } from '@services/utils/common-utils';

@Pipe({ name: 'mobilusOrderBy' })
export class OrderByPipe implements PipeTransform {

    constructor(
        private commonUtils: CommonUtils
    ) {
    }

    public transform(
        data: any[],
        options: { compareFn?: (a: any, b: any) => number, sortedPropertyName?: string, sortedPropertyType?: SortedPropertyType, direction?: SortDirection }
    ): any {

        if (!data || Array.isArray(data) !== true || !options) {
            return data;
        }

        if (options.compareFn && typeof options.compareFn === 'function') {
            return data.sort(options.compareFn);
        } else if (options.sortedPropertyName && typeof options.sortedPropertyName === 'string') {
            return this.commonUtils.sortArrayByProperty(data, options.sortedPropertyName, options.sortedPropertyType, options.direction);
        } else {
            return data;
        }

    }

}
