import {Component} from '@angular/core';
import {Input} from '@angular/core';

@Component({
  selector: 'mobilus-pending-indicator',
  templateUrl: './pending-indicator.component.html',
  styleUrls: ['./pending-indicator.component.scss'],
})
export class PendingIndicatorComponent {

  constructor() {
  }

}
