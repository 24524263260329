import {Component} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'ConnectionLostDetailPage',
  }
};

@Component({
  templateUrl: './connection-lost-detail.page.html',
  styleUrls: ['./connection-lost-detail.page.scss'],
})
export class ConnectionLostDetailPage {

  message: { title: string, subTitle: string };
  transitionsRequireInternet = [
    'take_in_charge',
    'back_to_provider',
    'transfer_to_hauler',
    'send_back_ready_for_haulage',
    'edit_payment'
  ];

  constructor(private modalCtrl: ModalController,
              public navParams: NavParams,
              private translateService: TranslateService,
              private ga: MblsAnalyticsService,
              private log: LogService,
  ) {
    this.message = this.navParams.get('connectionDetailMessage');
    this.message.subTitle = this.translateService.instant(this.message.subTitle);
    this.message.title = this.translateService.instant(this.message.title);
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
