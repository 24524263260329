import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('Position')
export class Position {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('accuracy', Number, true)
  accuracy: number = undefined;

  @JsonProperty('latitude', Number, true)
  latitude: number = undefined;

  @JsonProperty('longitude', Number, true)
  longitude: number = undefined;

  @JsonProperty('dateEmitted', MomentDateConverter, true)
  dateEmitted: moment.Moment = undefined;

  constructor(latitude?: number, longitude?: number, accuracy?: number, dateEmitted?: moment.Moment) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.accuracy = accuracy;
    this.dateEmitted = dateEmitted;
  }

  clone(): Position {
    return new Position(this.latitude, this.longitude, this.accuracy, this.dateEmitted);
  }

}
