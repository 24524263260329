import { Component } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Any } from 'json2typescript';
import { JsonProperty } from 'json2typescript';
import { JsonObject } from 'json2typescript';

// MODELS
import { ConnectionStatus } from '@models/information/connection-status.model';
import { ObjectDatabaseChange } from '@models/pouchdb/object-database-change-model';

// COMPONENTS
import { MapSettingsPage } from '@pages/map-settings/map-settings.page';

// PROVIDERS
import { CommonUtils } from '@services/utils/common-utils';
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { DbService } from '@utils/abstract/db-service';
import { DialogsService } from '@services/dialogs/dialogs';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { LogService } from '@services/log/log.service';

@JsonObject("TestData")
class TestData {

  @JsonProperty('_id', String, true)
  _id: string = undefined;

  @JsonProperty('_rev', String, true)
  _rev: string = undefined;

  @JsonProperty('_rev_tree', Any, true)
  _rev_tree: any = undefined;

  @JsonProperty('_deleted', Boolean, true)
  _deleted = false;

  @JsonProperty('name', String, true)
  public name: string;

  @JsonProperty('description', String, true)
  public description: string;

  constructor(name: string, description: string) {
    this.name = name;
    this.description = description;
    this._id = 'td_' + uuidv4();
  }
}

class TestDataStoreService extends DbService<TestData> {

  constructor(
    mobileContextService: MobileContextService,
    log: LogService,
    toastCtrl: ToastController,
    translateService: TranslateService
  ) {
    super('testdata', TestData, mobileContextService, log, toastCtrl, translateService);
  }

  getAllDocs(): Observable<Array<TestData>> {
    return new Observable(subscriber => {
      super.getAll()
        .subscribe(transitions => {
          subscriber.next(transitions);
          subscriber.complete();
        });
    });
  }

  getLiveChanges(): Observable<ObjectDatabaseChange<TestData>> {
    return super.getLiveChanges();
  }
}


@Component({
  templateUrl: 'sample.page.html',
  styleUrls: ['./sample.page.scss']
})
export class SamplePage {

  public executeDBOperationsInProgress = false;

  constructor(
    private commonUtils: CommonUtils,
    private connectionStatusService: ConnectionStatusService,
    public dialogsService: DialogsService,
    private mobileContextService: MobileContextService,
    private modalController: ModalController,
    private log: LogService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
  }

  //#region MODAL DIALOGS
  public showErrorDialog() {
    this.dialogsService.showErrorDialog('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum facilisis nisi, volutpat fermentum leo iaculis posuere. Praesent convallis efficitur tortor, ut lacinia orci semper ac.'); // eslint-disable-line max-len
  }
  public showSuccessDialog() {
    this.dialogsService.showSuccessDialog('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum facilisis nisi, volutpat fermentum leo iaculis posuere. Praesent convallis efficitur tortor, ut lacinia orci semper ac.'); // eslint-disable-line max-len
  }
  public async showConfirmationDialogOnMapViewFailed(errorMessage?: string): Promise<void> {
    // NOTE: if the map view load failed we prompt to retry
    const confirmDialogMessage = this.translateService.instant('pages.map.messages.confirm-retry-loading-map-view');
    const loadingMapViewFailedMessage = this.translateService.instant('pages.map.messages.loading-map-view-failed');
    const cancelButtonText = this.translateService.instant('actions.cancel');
    const retryButtonText = this.translateService.instant('actions.retry');
    const confirmDialogMessageWithError = errorMessage ? `${errorMessage}. ${confirmDialogMessage}` : confirmDialogMessage;
    const confirmed: boolean = await this.dialogsService.showConfirmDialog(confirmDialogMessageWithError, loadingMapViewFailedMessage, null, 'confirm-warning-dialog map-view-load-failed-dialog', cancelButtonText, retryButtonText);
    await this.dialogsService.showSuccessDialog(`confirmed: ${confirmed}`);
  }
  public async showConfirmationPutOrderBackToStoreDialog(): Promise<void> {
    // NOTE: if the order tries to return order back to the store we show the confirmation dialog (see https://mobilus.myjetbrains.com/youtrack/issue/CAPP-28)
    const confirmPutOrderBackToStoreMessage = this.translateService.instant('pages.order-detail-tabs.confirm-put-order-back-to-store');
    const confirmButtonText = this.translateService.instant('actions.seen');
    const confirmed: boolean = await this.dialogsService.showConfirmDialog(confirmPutOrderBackToStoreMessage, null, null, 'confirm-warning-dialog', null, confirmButtonText);
    await this.dialogsService.showSuccessDialog(`confirmed: ${confirmed}`);
  }
  public async showMapSettings(): Promise<void> {
    const modal = await this.modalController.create({
      component: MapSettingsPage,
      componentProps: { sequence: 100 }
    });
    await modal.present();
  }
  //#endregion

  //#region ONLINE/OFFLINE MODE
  public async goOffline() {
    const connectionStatus: ConnectionStatus = new ConnectionStatus();
    connectionStatus.isNetworkConnected = false;
    (this.connectionStatusService as any)._connectionStatusTrigger.next(connectionStatus);

    const connectivityLostMessage = 'Connectivity Lost.';
    const toast = await this.toastController.create({
      message: connectivityLostMessage,
      duration: 2000,
      position: 'middle'
    });
    toast.present();
  }
  public async goOnline() {
    const connectionStatus: ConnectionStatus = new ConnectionStatus();
    connectionStatus.isNetworkConnected = true;
    (this.connectionStatusService as any)._connectionStatusTrigger.next(connectionStatus);

    const connectivityGainedMessage = 'Connectivity Gained.';
    const toast = await this.toastController.create({
      message: connectivityGainedMessage,
      duration: 2000,
      position: 'middle'
    });
    toast.present();

  }
  //#endregion

  //#region EMULATE ERROR (to test Sentry reporting)
  public triggerError() {
    // @ts-ignore
    myUndefinedFunction();
  }
  //#endregion

  //#region EMULATE INDEXEDDB ERROR (to test https://mobilus.myjetbrains.com/youtrack/issue/CAPP-17)
  /**
   * Method to write/read from IndexedDB
   */
  public async executeDBOperations(): Promise<void> {
    this.executeDBOperationsInProgress = true;
    try {
      const testDataStoreService = new TestDataStoreService(this.mobileContextService, this.log, this.toastController, this.translateService);
      await testDataStoreService.ready();
      while (true) {
        for (let i = 0; i < 10000; i++) {
          await this.addTestData(testDataStoreService);
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        console.info('[SAMPLE] startPopulatingIndexedDB - added 100 test data items');
        await new Promise(resolve => setTimeout(resolve, 2000));
        const allDocs: TestData[] = await firstValueFrom(testDataStoreService.getAllDocs());
        for (const doc of allDocs) {
          await firstValueFrom(testDataStoreService.remove(doc));
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        console.info('[SAMPLE] startPopulatingIndexedDB - removed all test data items');
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } catch (error) {
      this.dialogsService.showErrorDialog(`Error populating indexedDB: ${this.commonUtils.safeStringify(error)}`);
    }
  }

  private async addTestData(testDataStoreService: TestDataStoreService): Promise<void> {
    const name = 'Test Data';
    const description = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget dolor in dolor molestie sagittis. Morbi cursus tempor mi sed ultricies. In sollicitudin faucibus nulla porta commodo. Vestibulum iaculis nibh vitae erat imperdiet, vehicula mollis quam commodo. Ut nunc est, aliquam et mi egestas, hendrerit vestibulum purus. Nunc nec orci in sapien venenatis iaculis vel eget arcu. Nulla id convallis augue. Etiam tortor ante, tincidunt id neque vitae, mollis facilisis diam. Vestibulum eget nulla augue. Sed gravida mi eget turpis dapibus, sed viverra nibh feugiat.
Mauris tincidunt pellentesque varius. Duis finibus tempor felis, ac posuere risus pretium vel. Donec sapien lacus, suscipit ut est non, euismod ultricies ipsum. In eu rhoncus turpis. Sed id velit sit amet nulla malesuada sagittis. Nulla convallis vitae arcu eget mollis. In accumsan urna diam, a lacinia augue varius non. Fusce varius lectus nec tempus venenatis. Vivamus in odio ut lacus porta viverra. Duis lobortis, lorem sed aliquam vestibulum, orci enim efficitur nisi, sed egestas ex felis in nisl. Praesent vel luctus mi. Praesent facilisis ultricies maximus. Mauris a rhoncus nibh, eu lobortis purus. Nam ut bibendum est, dignissim sollicitudin ligula. Nunc feugiat velit sit amet eros posuere, tincidunt molestie velit finibus. Fusce maximus dignissim libero nec pretium.
Nulla quam tellus, finibus efficitur ultricies et, euismod at nunc. Pellentesque iaculis, nisl malesuada mattis tincidunt, dui tellus blandit mauris, in varius arcu felis non dui. Maecenas eleifend sagittis fringilla. Donec fringilla eros vel nisi commodo mollis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam id turpis ullamcorper, auctor leo ac, malesuada libero. Cras sagittis porta tortor, at vestibulum lectus consectetur eu. Suspendisse arcu magna, dignissim at neque id, imperdiet consectetur velit. Aliquam id scelerisque dui. Etiam quis justo non felis vestibulum posuere. Donec sagittis in elit eget tristique. Phasellus non ligula nec lacus porta finibus et vitae risus. Aenean rutrum, dui sit amet consequat consectetur, sem justo vulputate arcu, quis aliquet odio urna at sem. Duis interdum velit nec est sagittis, eu blandit ex tincidunt.
Morbi volutpat risus eu ipsum commodo, eget pretium risus sollicitudin. Vestibulum sollicitudin mauris mi, vel porta tellus commodo eget. In nunc nunc, bibendum et fermentum in, sodales non lacus. Phasellus aliquam, lectus mollis facilisis dapibus, libero ipsum accumsan dolor, vel porta libero erat non augue. Praesent imperdiet leo nulla, vitae varius mi vulputate nec. Aliquam consequat turpis nulla, fermentum mattis tellus egestas interdum. Quisque fermentum velit sem, id pellentesque elit dignissim ac. Ut ante est, viverra eget purus a, consequat viverra leo. Suspendisse a porttitor mi. Pellentesque interdum tortor vel diam efficitur rutrum. Duis ornare viverra turpis, id convallis lorem varius in.
Cras commodo efficitur ipsum, quis lobortis eros dignissim ac. Nulla sagittis ultricies arcu non euismod. Mauris nisi ipsum, ultrices ac velit sit amet, mollis sagittis dolor. Integer eu ullamcorper urna. Vivamus nibh ante, pretium id lectus id, tristique feugiat justo. Mauris vulputate ante eu neque porta congue. In semper lectus luctus leo tincidunt fermentum. Maecenas fermentum urna mi, non pretium lacus congue et.`;
    const testData = new TestData(name, description);
    await firstValueFrom(testDataStoreService.put(testData));
  }
  //#endregion

  public close() {
    this.modalController.dismiss();
  }

}
