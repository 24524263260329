import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MobileConfiguration} from '@models/check-in/mobile-configuration.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {OAuthToken} from '@models/oauth-token.model';
import {TranslateService} from '@ngx-translate/core';
import {OAuth2Service} from '@services/auth/oauth2.service';
import {TokenService} from '@services/auth/token.service';
import {LanguageService} from '@services/language-service/language.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as moment from 'moment';
import {throwError} from 'rxjs';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {filter} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import { AppEvents } from '@services/app-events/app-events';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private activeRefreshCounter: NodeJS.Timeout;
  private isOfflineLogin = false;

  constructor(private oauth2Service: OAuth2Service,
              private tokenService: TokenService,
              private translateService: TranslateService,
              private deviceInfo: DeviceInfo,
              private log: LogService,
              private mobileConfigurationService: MobileContextService,
              private languageService: LanguageService,
              private appEvents: AppEvents) {
    this.deviceInfo.networkStatus.networkChange
      .pipe(
        filter(connected => connected === true),
        filter(() => this.isOfflineLogin === true)
      )
      .subscribe(() => {
        this.log.info('App has been reconnected to internet after an offline login : reloading profile.');
        this.onlineLogin()
          .subscribe(
            () => {
              this.log.info('Auto-login successful after offline login.');
              this.isOfflineLogin = false;
            },
            error => {
              this.log.error('Auto-login failed after offline login : ', error);
            }
          );
      });
  }

  public onlineLogin(accessToken?: OAuthToken): Observable<MobileConfiguration> {
    if (!accessToken) {
      accessToken = this.getAccessToken();
    }
    if (!accessToken) {
      return throwError('No access token found.');
    }
    return this.mobileConfigurationService.getConfigurationAndBootstrapApplication(accessToken);
  }

  public offlineLogin(): Observable<MobileConfiguration> {
    return new Observable<MobileConfiguration>(observer => {
      this.mobileConfigurationService.loadContextFromStorage()
        .subscribe(configuration => {
          const token = this.tokenService.oauthToken;
          if (this.tokenService.isOfflineConnectionAllowed()) {
            this.mobileConfigurationService.bootstrapApplication(configuration, token)
              .subscribe(conf => {
                this.isOfflineLogin = true;
                observer.next(conf);
                observer.complete();
              });
          }
        }, error => {
          this.log.error('Unable to load context for offline login', error);
          observer.error('Unable to load context for offline login');
          observer.complete();
        });
    });
  }

  public getAccessToken(): OAuthToken {
    if (this.tokenService.oauthToken && this.tokenService.oauthToken.access_token) {
      return this.tokenService.oauthToken;
    }
    return null;
  }

  public doLogout() {
    this.mobileConfigurationService.checkout();
    this.appEvents.notifyLogoutSuccess();
    // Using start new session here cause a webview crash when we log in again
    // LogRocket.startNewSession();
  }

  public doRefreshToken(): Observable<OAuthToken> {
    const oauthToken = this.tokenService.oauthToken;
    if (oauthToken) {
      this.tokenService.clearAuth();
      return this.oauth2Service.getAccessTokenViaRefreshGrant(oauthToken.refresh_token)
        .pipe(
          catchError((error: Response) => {
            this.log.error('Unable to get token via refresh token grant : ', error);
            return throwError(error);
          }),
          map(oauthResponse => this.recordAuth(oauthResponse))
        );
    } else {
      return of(null);
    }
  }

  public doLoginOnly(username: string, password: string): Observable<OAuthToken> {
    this.log.info('Doing login');
    return this.oauth2Service.getAccessTokenViaPasswordGrant(username, password)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 500) {
            this.log.error('Login got a HTTP 500.');
            return throwError(this.translateService.instant('pages.login.errors.server_unavailable'));
          }
          const error = response.error;
          if (error) {
            if (error.error_description === 'Bad credentials') {
              this.log.error('Login failed : Bad credentials.');
              return throwError(this.translateService.instant('pages.login.errors.bad_credentials'));
            } else {
              this.log.error('Login failed : unknown error.', error);
              return throwError(this.translateService.instant('messages.errors.generic_error'));
            }
          } else {
            this.log.error('Login failed : unknown error.', error);
            return throwError(this.translateService.instant('messages.errors.generic_error'));
          }
        }),
        map(oauthResponse => this.recordAuth(oauthResponse))
      );
  }

  public doLogin(username: string, password: string): Observable<MobileConfiguration> {
    this.log.info('Doing login');
    return this.doLoginOnly(username, password).pipe(
        mergeMap(oAuthToken => this.onlineLogin(oAuthToken))
      );
  }

  public scheduleRefreshToken() {
    this.log.debug('JWT Scheduling refresh token', this.activeRefreshCounter);
    if (this.activeRefreshCounter) {
      clearInterval(this.activeRefreshCounter);
    }
    this.activeRefreshCounter = setInterval(() => {
      if (this.tokenService.oauthToken) {
        // Are we going to be expired in 15 minutes?
        const acceptableDelay = moment().add(15, 'minutes');
        if (acceptableDelay.isSameOrAfter(this.tokenService.oauthToken.expiration_date)) {
          this.log.info('Refreshing auth token');
          clearInterval(this.activeRefreshCounter);
          this.doRefreshToken()
            .subscribe(() => {
              this.log.info('token refreshed');
            });
        }
      } else {
        // no more available token, stop the schedule
        this.log.trace('Removing refresh token schedule');
        clearInterval(this.activeRefreshCounter);
      }
    }, 10 * 60 * 1000); // every 10 minutes  10 * 60 * 1000

  }

  private recordAuth(oauthToken: OAuthToken): OAuthToken {
    const storedToken = this.tokenService.recordAuth(oauthToken);
    this.log.trace('oauthToken recorded', oauthToken);
    this.scheduleRefreshToken();
    return storedToken;
  }

}
