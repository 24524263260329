import {ElementRef} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {Output} from '@angular/core';
import {OnDestroy} from '@angular/core';
import {OnInit} from '@angular/core';
import {Directive} from '@angular/core';
import {Gesture} from '@ionic/core';
import {createGesture} from '@ionic/core';
import {GestureDetail} from '@ionic/core';

@Directive({
  selector: '[mobilusLongPress]'
})
export class LongPressDirective implements OnInit, OnDestroy {

  el: HTMLElement;
  pressGesture: Gesture;
  timerId: any;
  
  /* eslint-disable @angular-eslint/no-output-rename */
  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output('long-press') onPressRelease: EventEmitter<any> = new EventEmitter(); 
  /* eslint-enable @angular-eslint/no-output-rename */
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit() {
    this.pressGesture = createGesture({
      el: this.el,
      gestureName: 'longpress',
      threshold: 0,
      canStart: () => true,
      onStart: (gestureEv: GestureDetail) => {
        gestureEv.event.preventDefault();
        this.timerId = setTimeout(() => {
          this.onPressRelease.emit(gestureEv.event);
        }, 500);
      },
      onEnd: () => {
        clearTimeout(this.timerId);
      }
    });
    this.pressGesture.enable(true);
  }

  ngOnDestroy() {
    this.pressGesture.destroy();
  }

}
