import {Position} from '@models/business/position.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('UserLocation')
export class UserLocation {

  @JsonProperty('id', String, true)
  id: string = undefined;

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('address', String, true)
  address: string = undefined;

  @JsonProperty('position', Position, true)
  position: Position = undefined;
}
