import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// PROVIDERS
import { HttpUtils } from '@services/utils/http-utils';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorFormatter {

    constructor(
        private httpUtils: HttpUtils,
        private translateService: TranslateService
    ) {
    }

    /**
     * Method to format http error to user friendly error message
     * There are two modes support:
     * - regular (default), with the error text and hint
     * - short, with the error text only. This could be used when displaying a list of errors
     */
    public getFriendlyMessage(httpError: { status: number; message?: string; error?: any }, shortFormat = false, prefix?: string): string {

        let httpErrorMessage: string = null;
        let httpErrorMessageTranslationKey: string = null;

        if (this.httpUtils.isHttpTimeoutError(httpError) === true) {
            // NOTE: when there is no network
            if (shortFormat === true) {
                httpErrorMessageTranslationKey = 'messages.errors.http.timeout.short_format';
            } else {
                httpErrorMessageTranslationKey = 'messages.errors.http.timeout.long_format';
            }
        } else if (httpError.status === 400) {
            httpErrorMessageTranslationKey = 'messages.errors.http.400';
        } else if (httpError.status === 401) {
            if (shortFormat === true) {
                httpErrorMessageTranslationKey = 'messages.errors.http.401.short_format';
            } else {
                httpErrorMessageTranslationKey = 'messages.errors.http.401.long_format';
            }
        } else if (httpError.status === 403) {
            if (shortFormat === true) {
                httpErrorMessageTranslationKey = 'messages.errors.http.403.short_format';
            } else {
                httpErrorMessageTranslationKey = 'messages.errors.http.403.long_format';
            }
        } else if (httpError.status === 404) {
            httpErrorMessageTranslationKey = 'messages.errors.http.404';
        } else if (httpError.status === 405) {
            httpErrorMessageTranslationKey = 'messages.errors.http.405';
        } else if (httpError.status === 500) {
            httpErrorMessageTranslationKey = 'messages.errors.http.500';
        } else {
            httpErrorMessageTranslationKey = 'messages.errors.http.unexpected';
        }

        httpErrorMessage = this.translateService.instant(httpErrorMessageTranslationKey);
        if (prefix) {
            return `${prefix} ${httpErrorMessage}`;
        } else {
            return httpErrorMessage;
        }

    }

}
