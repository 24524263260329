import {MqttServer} from '@models/configuration/mqtt-server.model';
import {MqttTopics} from '@models/configuration/mqtt-topics.model';
import {MQTTStatusEnum} from '@models/push-messages/mqtt-status-enums.model';
import {MqttStatusMessage} from '@models/push-messages/mqtt-status-message.model';
import {classToPlain} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('MqttConfiguration')
export class MqttConfiguration {

  @JsonProperty('brokerUrl', String, true)
  brokerUrl: string = undefined;

  @JsonProperty('brokerUsername', String, true)
  username: string = undefined;

  @JsonProperty('brokerPassword', String, true)
  password: string = undefined;

  @JsonProperty('keepAlive', Number, true)
  keepAlive: number = undefined;

  @JsonProperty('protocolId', String, true)
  protocolId: string = undefined;

  @JsonProperty('protocolVersion', Number, true)
  protocolVersion: number = undefined;

  @JsonProperty('reconnectPeriod', Number, true)
  reconnectPeriod: number = undefined;

  @JsonProperty('connectTimeout', Number, true)
  connectTimeout: number = undefined;

  @JsonProperty('qos', Number, true)
  qos: number = undefined;

  @JsonProperty('useWillAndTestament', Boolean, true)
  useWillAndTestament: Boolean = undefined;

  @JsonProperty('uris', [String], true)
  uris: Array<string> = [];

  @JsonProperty('brokerPath', String, true)
  brokerPath: string = undefined;

  @JsonProperty('protocol', String, true)
  protocol: string = undefined;

  @JsonProperty('servers', [MqttServer], true)
  servers: Array<MqttServer> = [];

  @JsonProperty('topics', MqttTopics, true)
  topics: MqttTopics = undefined;

  asOptions(haulerEmployeeUuid: string, clientId: string): any {
    const options: any = {
      clientId: clientId + '_' + haulerEmployeeUuid, // PM-396 il est préférable rendre le id unique par device + usager
      protocolId: this.protocolId || 'MQTT',
      protocolVersion: this.protocolVersion || 4,
      clean: false,
      reconnectPeriod: this.reconnectPeriod || 1000,
      connectTimeout: this.connectTimeout || 15 * 1000,
      rejectUnauthorized: false,
      resubscribe: true,
      protocol: this.protocol || 'wss',
      path: this.brokerPath
    };

    if (this.useWillAndTestament) {
      options.will = {
        topic: this.topics.haulerEmployeeStatuses,
        payload: JSON.stringify(classToPlain(new MqttStatusMessage(MQTTStatusEnum.DISCONNECTED_UNGRACEFULLY, haulerEmployeeUuid, clientId))),
        retain: true
      };
      if (this.qos && this.qos >= 0 && this.qos <= 2) {
        options.will.qos = this.qos;
      } else {
        options.will.qos = 2;
      }
    }

    if (this.keepAlive && this.keepAlive > 0) {
      options.keepAlive = this.keepAlive; // https://www.hivemq.com/blog/mqtt-essentials-part-10-alive-client-take-over
    }

    if (this.username && this.password) {
      options.username = this.username;
      options.password = this.password;
    }

    if (this.servers) {
      options.servers = this.servers;
    }

    return options;
  }
}
