import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';
import {Position} from '@models/business/position.model';

@JsonObject('RouteSummary')
export class RouteSummary {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('stopCount', Number, true)
  stopCount: number = undefined;

  @JsonProperty('totalDistanceKm', Number, true)
  totalDistanceKm: number = undefined;

  @JsonProperty('totalTravelTimeMin', Number, true)
  totalTravelTimeMin: number = undefined;

  @JsonProperty('withTimeConstraints', Boolean, true)
  withTimeConstraints: boolean = undefined;

  @JsonProperty('startAt', MomentDateConverter, true)
  startAt: moment.Moment = undefined;

  @JsonProperty('routeEnd', Position, true)
  routeEnd: Position = undefined;

  @JsonProperty('routeStart', Position, true)
  routeStart: Position = undefined;
}
