import {Injectable} from '@angular/core';
import {ObjectDatabaseChange} from '@models/pouchdb/object-database-change-model';
import {TransitionRequest} from '@models/workflow/transition-request.model';
import {DbService} from '@utils/abstract/db-service';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Observable} from 'rxjs';
import {DBListenChangesMode} from '@models/base/base';

@Injectable({
  providedIn: 'root'
})
export class AwaitingTransitionStoreService extends DbService<TransitionRequest> {

  constructor(mobileContextService: MobileContextService,
              log: LogService,
              toastCtrl: ToastController,
              translateService: TranslateService
  ) {
    super('transition', TransitionRequest, mobileContextService, log, toastCtrl, translateService, DBListenChangesMode.SINCE_BEGINNIG);
  }

  getAllDocs(): Observable<Array<TransitionRequest>> {
    return new Observable(subscriber => {
      super.getAll()
        .subscribe(transitions => {
          transitions.sort((a: TransitionRequest, b: TransitionRequest) => {
            if (a.date.isAfter(b.date)) {
              return 1;
            } else if (a.date.isBefore(b.date)) {
              return -1;
            } else {
              return 0;
            }
          });
          subscriber.next(transitions);
          subscriber.complete();
        });
    });
  }

  getLiveChanges(): Observable<ObjectDatabaseChange<TransitionRequest>> {
    return super.getLiveChanges();
  }
}
