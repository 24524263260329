import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {Any} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('TransitionRequestLog')
export class TransitionRequestLog {

  @JsonProperty('date', MomentDateConverter, true)
  date: moment.Moment = undefined;

  @JsonProperty('message', String, true)
  message: string = undefined;

  @JsonProperty('iserror', Boolean, true)
  iserror?: boolean = undefined;

  @JsonProperty('_id', String, true)
  _id: string = undefined;

  @JsonProperty('_rev', String, true)
  _rev: string = undefined;

  @JsonProperty('_rev_tree', Any, true)
  _rev_tree: any = undefined;

  @JsonProperty('_deleted', Boolean, true)
  _deleted = false;

  constructor(message: string, isError?: boolean) {
    this.date = moment();
    this.message = message;
    if(isError) {
      this.iserror = isError;
    }
  }

}
