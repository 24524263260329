<ion-header>
  <ion-toolbar>
    <ion-title><span translate="pages.drawer-menu.bug_report"></span></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-margin-top">
  <form [formGroup]="sendReportForm" (ngSubmit)="sendReport()" [noValidate]="false">
    <ion-list class="ion-no-margin">
      <!-- Report type -->
      <ion-list-header lines="full">
        <ion-label translate="diagnostic.debugReport.reportType"></ion-label>
      </ion-list-header>
      <ion-item lines="none" class="report-type-container">
        <ion-radio-group class="report-type-radio-group" formControlName="reportType" value="bug">
          <ion-radio value="bug">
            {{ 'diagnostic.debugReport.bugType' | translate }}
          </ion-radio>
          <ion-radio value="suggestion">
            {{ 'diagnostic.debugReport.suggestionType' | translate }}
          </ion-radio>
        </ion-radio-group>
      </ion-item>
      <!-- Description -->
      <ion-list-header lines="full">
        <ion-label translate="diagnostic.debugReport.bugType"></ion-label>
      </ion-list-header>
      <ion-item>
        <ion-textarea area-label="Report Text" formControlName="reportText" type="text"
                      placeholder="{{ 'diagnostic.debugReport.issue_description' | translate }}"
                      clearInput="true" rows="5"></ion-textarea>
      </ion-item>
      <!-- Contact info -->
      <ion-list-header lines="full">
        <ion-label translate="diagnostic.debugReport.contactTitle"></ion-label>
      </ion-list-header>
      <ion-item>
        <ion-input formControlName="reportContactEmail" type="email"
                   placeholder="{{ 'diagnostic.debugReport.contactEmail' | translate }}">
        </ion-input>

        <ion-input formControlName="reportContactPhone" type="text"
                   placeholder="{{ 'diagnostic.debugReport.contactPhoneNumber' | translate }}"></ion-input>
      </ion-item>
    </ion-list>
    <!-- Submit -->
    <section class="ion-margin send-button-container">
      <ion-button expand="block" type="submit">
        <fa-icon [icon]="['fas', 'envelope']" slot="end"></fa-icon>
        <span class="button-text" translate="diagnostic.debugReport.action"></span>
      </ion-button>
    </section>
  </form>
</ion-content>
