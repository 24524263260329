import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DialogsService {

    constructor(
        private alertController: AlertController,
        private translateService: TranslateService
    ) {
    }

    public showErrorDialog(message: string, header?: string, subHeader?: string): Promise<void> {
        return new Promise(async (resolve: () => void) => {
            const options = {
                header: header || this.translateService.instant('dialogs.headers.error').toUpperCase(),
                subHeader,
                cssClass: 'error-dialog',
                message,
                buttons: [
                    {
                        text: this.translateService.instant('actions.ok').toUpperCase(),
                        role: 'cancel',
                        handler: () => {
                            resolve();
                        }
                    }
                ]
            };
            const alert = await this.alertController.create(options);
            await alert.present();
        });
    }

    public showSuccessDialog(message: string, header?: string, subHeader?: string): Promise<void> {
        return new Promise(async (resolve: () => void) => {
            const options = {
                header: header || this.translateService.instant('dialogs.headers.success').toUpperCase(),
                subHeader,
                cssClass: 'success-dialog',
                message,
                buttons: [
                    {
                        text: this.translateService.instant('actions.ok').toUpperCase(),
                        role: 'cancel',
                        handler: () => {
                            resolve();
                        }
                    }
                ]
            };
            const alert = await this.alertController.create(options);
            await alert.present();
        });
    }

    /**
     * Method to show confirmation dialog. Returns Promise<boolean> and resolves with true if confirmed, otherwise false
     */
    public showConfirmDialog(
        message: string,
        header?: string,
        subHeader?: string,
        cssClass?: string,
        cancelButtonText?: string,
        okButtonText?: string
    ): Promise<boolean> {
        return new Promise(async (resolve: (value: boolean) => void) => {
            const options = {
                header: header || this.translateService.instant('dialogs.headers.confirm').toUpperCase(),
                subHeader,
                message,
                cssClass: cssClass || 'confirm-dialog',
                buttons: [
                    {
                        text: cancelButtonText || this.translateService.instant('actions.cancel').toUpperCase(),
                        role: 'cancel',
                        cssClass: 'cancel-button',
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: okButtonText || this.translateService.instant('actions.ok').toUpperCase(),
                        cssClass: 'ok-button',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            };
            const alert = await this.alertController.create(options);
            alert.present();
        });
    }

}
