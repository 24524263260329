<ion-list>
  <ion-radio-group [value]="statusSettings.sortOrder" (ionChange)="sortChanged($event)">

    <ion-list-header>
      <span translate="options.sort.label"></span>
    </ion-list-header>

    <ion-item>
      <ion-radio labelPlacement="start" value="lastName">
        <div class="radio-inner-wrapper">
          <fa-icon [icon]="['far','sort-alpha-down']" size="lg"></fa-icon>
          <span translate="options.sort.lastName"></span>
        </div>
      </ion-radio>
    </ion-item>
    <ion-item>
      <ion-radio labelPlacement="start" value="sequence">
          <div class="radio-inner-wrapper">
            <fa-icon [icon]="['far','sort-numeric-down']" size="lg" slot="start"></fa-icon>
              <span translate="options.sort.rank"></span>
          </div>
      </ion-radio>
    </ion-item>
    <ion-item>
      <ion-radio labelPlacement="start" value="distance">
        <div class="radio-inner-wrapper">
          <fa-layers class="fa-fw fa-1x" slot="start">
            <fa-icon [icon]="['far','arrow-down']" transform="grow-5 left-5"></fa-icon>
            <fa-layers-text transform="shrink-6 right-5 up-4" style="font-weight: bold;"
              content="{{'km' | DistanceUnit:settings.distanceUnit}}"></fa-layers-text>
          </fa-layers>
          <span translate="options.sort.distance"></span>
        </div>
      </ion-radio>
    </ion-item>
    <ion-item *ngIf="settings.showAverageDeliveryMoment">
      <ion-radio labelPlacement="start" value="avgtime">
        <div class="radio-inner-wrapper">
          <fa-layers class="fa-fw fa-1x" slot="start">
            <fa-icon [icon]="['far','arrow-down']" transform="grow-5 left-5"></fa-icon>
            <fa-icon [icon]="['far', 'clock']" transform="shrink-3 right-5 up-3"></fa-icon>
          </fa-layers>
          <span translate="options.sort.avgtime"></span>
        </div>
      </ion-radio>
    </ion-item>

  </ion-radio-group>

</ion-list>