import {Position} from '@models/business/position.model';
import {ProviderConfig} from '@models/business/provider-config.model';
import {Tag} from '@models/business/tag.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {JsonObject, JsonProperty} from 'json2typescript';
import {Address} from "@models/business/address.model";

@JsonObject('SelectedProvider')
export class SelectedProvider {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('selected', Boolean, true)
  selected: boolean = undefined;

  @JsonProperty('hidden', Boolean, true)
  hidden: boolean = undefined;

  @JsonProperty('visible', Boolean, true)
  visible: boolean = undefined;

  @JsonProperty('enabled', Boolean, true)
  enabled: boolean = undefined;

  name: string = undefined;
  tags: Array<Tag> = [];
  position: Position = undefined;
  hiddenForPage: Map<OrderStatus, boolean> = undefined;

  @JsonProperty('configuration', ProviderConfig, true)
  configuration: ProviderConfig = undefined;
  address: Address = undefined;
  @JsonProperty('phoneNumber', String, true)
  phoneNumber: string = undefined;
}
