import {Injectable} from '@angular/core';
import {Order} from '@models/business/order.model';
import {ObjectDatabaseChange} from '@models/pouchdb/object-database-change-model';
import {SUPPORTED_STATUS} from '@models/push-messages/order-message.model';
import {UserSettings} from '@models/settings/settings.model';
import {DbService} from '@utils/abstract/db-service';
import {plainToClass} from '@utils/json-converter/json-converter';
import {classToPlain} from '@utils/json-converter/json-converter';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as moment from 'moment';
import 'moment-timezone';
import {EMPTY} from 'rxjs';
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {forkJoin} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderStoreService extends DbService<Order> {
  private settings: UserSettings;

  constructor(mobileContextService: MobileContextService,
              log: LogService,
              toastCtrl: ToastController,
              translateService: TranslateService
  ) {
    super('order', Order, mobileContextService, log, toastCtrl, translateService);

    this.mobileContextService.userSettingsObservable
      .subscribe(settings => {
        this.settings = settings;
      });
  }

  flagOrdersForDeletion(): Observable<string> {
    const flag = new Date().getTime().toString();
    this.log.trace('Flagging orders with full sync flag : ', flag);
    return new Observable(subscriber => {
      this.allDocs()
        .subscribe(orders => {
          if (orders.length === 0) {
            subscriber.next(flag);
            subscriber.complete();
            return;
          }
          const observables = [];
          orders.forEach(order => {
            this.log.trace('Flagging order with full sync flag : ', order, flag);
            order.fullSyncFlag = flag;
            observables.push(this.put(order));
          });
          combineLatest(observables)
            .subscribe(
              () => {
                subscriber.next(flag);
              },
              (error) => {
                this.log.error('Flagging orders for deletion : error on promise', error);
                subscriber.next(flag);
              },
              () => {
                subscriber.complete();
              });
        });
    });
  }

  deleteflaggedOrdersForDeletion(flag: string): Observable<any> {
    return new Observable(subscriber => {
      this.allDocs()
        .subscribe(orders => {
          if (orders.length === 0) {
            subscriber.next();
            subscriber.complete();
            return;
          }
          const observables = [];
          orders.forEach(order => {
            if (order.fullSyncFlag && order.fullSyncFlag === flag) {
              this.log.trace('Removing order flagged before full sync : ', flag, order);
              observables.push(this.remove(order));
            }
          });
          forkJoin(observables).subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
        }, () => {
          subscriber.complete();
        }, () => {
          subscriber.complete();
        });
    });
  }

  getLiveChanges(sinceBegining: boolean = true, live: boolean = true, includeDocs: boolean = false, statusFilter?: string): Observable<ObjectDatabaseChange<Order>> {
    return super.getLiveChanges()
      .pipe(
        filter(orderChange => !statusFilter || (statusFilter && statusFilter === orderChange.doc.status))
      );
  }

  allDocs(...statusFilter: Array<string>): Observable<Array<Order>> {
    return new Observable(subscriber => {
      this.getAll().subscribe({
        next: (allOrders) => {
          const orders: Array<Order> = [];
          allOrders.forEach(order => {
            if ((statusFilter && statusFilter.length > 0 && statusFilter.indexOf(order.status) > -1) || !statusFilter || statusFilter.length === 0) {
              orders.push(order);
            }
          });
          subscriber.next(orders);
        },
        complete: () => {
          subscriber.complete();
        }
      });
    });
  }

  /**
   * This method only works with pouchdb entities (order with _id and _rev).
   * DO NOT use it with raw order.
   */
  batchPersistOrders(orders: Array<Order>, source?: string): Observable<Array<Order>> {
    this.log.debug('Persisting batch order from : ' + source);
    const plainOrders = [];
    orders.forEach((order: Order) => {
      plainOrders.push(classToPlain(order));
    });
    return new Observable(subscriber => {
      this.bulkDocs(plainOrders).subscribe({
        next: (result) => {
          const ordersPersisted = [];
          result.forEach(resultResponse => {
            const orderCandidate = orders.find(it => it.id.toString() === resultResponse.id.toString());
            if (orderCandidate) {
              orderCandidate._rev = resultResponse._rev;
              ordersPersisted.push(orderCandidate);
            }
          });
          subscriber.next(ordersPersisted);
        },
        complete: () => {
          subscriber.complete();
        }
      });
    });
  }

  /**
   * Persist one order while taking care of the sync logic.
   * TODO: implement the syncing logic / conflicts
   */
  persistOrder(order: Order, source?: string): Observable<Order> {
    // console.log("Persisting order from : " + source, order);
    const fetchObservable = new Observable(subscriber => {
      if (order && order._id) {
        subscriber.next(order);
        subscriber.complete();
      } else {
        this.get(order.id.toString())
          .subscribe(
            (orderDb: Order) => {
              this.log.trace('Order already exist in database : ', orderDb);
              if (orderDb && order.version > orderDb.version) {
                // TODO : logic for syncing
              }
              order._id = orderDb._id;
              order._rev = orderDb._rev;
              subscriber.next(order);
              subscriber.complete();
            }, error => {
              // console.log("Catching in get reject", error);
              if (error.status === 404) {
                this.log.trace('Unable to find order in database : ', error, order);
                order._id = order.id.toString();
                subscriber.next(order);
                subscriber.complete();
              } else {
                this.log.error('Fetch order failed', error);
                subscriber.error('Database operation error.');
                subscriber.complete();
              }
            }
          );
      }
    });

    const replace = new Observable(subscriber => {
      fetchObservable.subscribe(
        (preparedOrder: Order) => {
          this.log.trace('Put order in database : ', preparedOrder);
          const dateOrder = order.deliveryDate.clone().utc();
          // let dateOrder = order.deliveryDate.clone().tz('UTC');
          const reference = moment().startOf('day').utc();
          // console.log('checking order', dateOrder, reference, dateOrder.isSame(reference, 'day'), reference.isSame(dateOrder, 'day'));
          // Remove order if not owned by me or too old
          if (
            (order.haulerEmployeeId
              && this.userProfile
              && this.userProfile.haulerEmployeeId
              && order.haulerEmployeeId.toString() !== this.userProfile.haulerEmployeeId.toString()) // order for someone else
            || (!dateOrder.isSame(reference, 'day')) // order for another day
            || (order.tags.filter(tag => tag.invisibleToHauler).length > 0 || order.client.tags.filter(tag => tag.invisibleToHauler).length > 0) // order is invisibleToHauler by tag
            || (SUPPORTED_STATUS.indexOf(order.status) < 0) // unsupported status
            || (order.tenantWithAssignation && !order.haulerEmployeeId) // with assignation, null id are not for us
          ) {
            this.remove(preparedOrder)
              .subscribe(
                (result) => {
                  subscriber.next(result);
                },
                error => {
                  this.log.error('Catch reject remove : ', error, preparedOrder);
                },
                () => {
                  subscriber.complete();
                });
          } else {
            this.put(preparedOrder)
              .subscribe(
                (result) => {
                  subscriber.next(result);
                },
                error => {
                  this.log.error('Catch reject put : ', error, preparedOrder);
                },
                () => {
                  subscriber.complete();
                });
          }
        },
        error => {
          this.log.error('Put order failed from reject', error, order);
        }
      );
    });

    return new Observable(subscriber => {
      replace.subscribe(
        (response: any) => {
          // console.log("Order has been persisted: " + JSON.stringify(response), response);
          subscriber.next(plainToClass(Order, order));
        },
        error => {
          this.log.error('persistOrder operations failed : ', error, source);
          subscriber.error('Persisting failed');
        },
        () => {
          subscriber.complete();
        }
      );
    });
  }

  persistOrders(orders: Array<Order>, source?: string): Observable<Order> {
    if (orders.length === 0) {
      return EMPTY;
    }
    const promises = [];
    let maxSequence = 0;
    for (const order of orders) {
      if (order.sequence) {
        maxSequence = Math.max(maxSequence, order.sequence);
      }
      promises.push(this.persistOrder(order, source));
    }
    if (this.settings) {
      this.settings.nextSequenceNumber = maxSequence;
    }

    return new Observable(subscriber => {
      combineLatest(promises)
        .subscribe(
          () => {
            subscriber.complete();
          },
          error => {
            this.log.error('persistOrders operations failed : ', error, source);
            subscriber.error('persistOrders failed');
          }
        );
    });
  }

}
