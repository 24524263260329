import {Component} from '@angular/core';
import {MenuController, ModalController} from '@ionic/angular';
import {ProfileEditPage} from '@app/pages/profile/profile-edit/profile-edit.page';
import {UserProfile} from '@models/user-profile.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {TranslateService} from '@ngx-translate/core';

const CONFIG = {
  MAIN_MENU_ID: 'MAIN_MENU',
  GA: {
    EVENT: {
      DRAWER_FORCING_SYNC: {
        NAME: 'force-sync',
        ACTION: 'on-drawer-menu'
      }
    }
  }
};

@Component({
  templateUrl: './profile-view.page.html',
  styleUrls: ['./profile-view.page.scss'],
})
export class ProfileViewPage {
  public userProfile: UserProfile;

  constructor(private menuCtrl: MenuController,
              private mobileContextService: MobileContextService,
              private modalCtrl: ModalController,
              private translateService: TranslateService) {
    this.mobileContextService.userProfileObservable.subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
  }

  async openProfileEdit() {
    const modal = await this.modalCtrl.create({
      component: ProfileEditPage,
      componentProps: {userProfile: this.userProfile}
    });
    await modal.present();
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
