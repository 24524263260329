import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {PipesModule} from '@app/pipes/pipes.module';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
/* NOTE: ngx-signaturepad seems to be not compatible with Angular 12. The component fails to render and the entire project fails
to build in production configuration due to the below error
==========================================================
./src/main.ts - Error: Module build failed (from ./node_modules/@ngtools/webpack/src/ivy/index.js):
Error: Symbol SignaturePad declared in /Users/alexryltsov/Git/COURIER-MOBILE-APP/chore-CAPP-8-angular-upgrade/node_modules/ngx-signaturepad/signature-pad.d.ts is not exported from ngx-signaturepad (import into /Users/alexryltsov/Git/COURIER-MOBILE-APP/chore-CAPP-8-angular-upgrade/src/app/pages/signature/signature.page.ts)
......
./src/polyfills.ts - Error: Module build failed (from ./node_modules/@ngtools/webpack/src/ivy/index.js):
Error: Symbol SignaturePad declared in /Users/alexryltsov/Git/COURIER-MOBILE-APP/chore-CAPP-8-angular-upgrade/node_modules/ngx-signaturepad/signature-pad.d.ts is not exported from ngx-signaturepad (import into /Users/alexryltsov/Git/COURIER-MOBILE-APP/chore-CAPP-8-angular-upgrade/src/app/pages/signature/signature.page.ts)
.......
==========================================================
The fix is to change to angular2-signaturepad
*/
import {SignaturePage} from './signature.page';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

const routes: Routes = [
  {
    path: '',
    component: SignaturePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    AngularSignaturePadModule,
    ComponentsModule,
    PipesModule,
  ],
  declarations: [SignaturePage]
})
export class SignaturePageModule {
}
