import {Injectable} from '@angular/core';
import {UserProfile} from '@models/user-profile.model';
import {NavController} from '@ionic/angular';
import {Platform} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {IonicDeployStatus} from '@services/ionic-deploy/ionic-deploy-status';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {combineLatest} from 'rxjs';

const CONFIG = {
  IOS_APPLE_STORE_URL: 'itms-apps://itunes.apple.com/app/id1322109097',
  ANDROID_PLAY_STORE_URL: 'market://details?id=ca.mobilus.mobile.hauler'
};

@Injectable({
  providedIn: 'root'
})
export class VersionsListenerService {

  public userProfile: UserProfile;
  private deployStatus: IonicDeployStatus;
  private alert: any;
  private isVersionOutdatedAlertDisplayed = false;
  private isNativeInstallButtonDisplayed = false;
  private isNewVersionAvailable = false;
  private logOutbutton;
  private nativeButton;
  private ionicButton;

  constructor(private authenticationService: AuthenticationService,
              private mobileConfigurationService: MobileContextService,
              private alertCtrl: AlertController,
              private translateService: TranslateService,
              private ionicDeploy: IonicDeploy,
              private platform: Platform,
              private navController: NavController,
  ) {
    combineLatest([
      this.ionicDeploy.observableStatus,
      this.mobileConfigurationService.versionsObservable,
      this.mobileConfigurationService.userProfileObservable
    ])
      .subscribe(([status, versions, userProfile]) => {
        this.deployStatus = status;
        this.userProfile = userProfile;
        if (userProfile && versions && versions.isOutdated) {
          this.checkIfUserVersionIsOutdated();
        }
      });
  }

  private checkIfUserVersionIsOutdated() {
    if (!this.logOutbutton) {
      this.logOutbutton = {
        text: this.translateService.instant('logout.label'),
        handler: () => this.goToLoginPage()
      };
    }
    if (!this.ionicButton) {
      this.ionicButton = {
        text: this.translateService.instant('deploy.newVersionInstallLong'),
        handler: () => this.installNewVersion()
      };
    }
    if (!this.nativeButton) {
      this.nativeButton = {
        text: this.translateService.instant('deploy.openAppStore'),
        handler: () => {
          if (this.platform.is('ios')) {
            window.location.href = CONFIG.IOS_APPLE_STORE_URL;
          } else {
            window.open(CONFIG.ANDROID_PLAY_STORE_URL);
          }
          return false;
        }
      };
    }

    this.isNativeInstallButtonDisplayed = !(this.deployStatus && this.deployStatus.newVersionAvailable);
    this.isNewVersionAvailable = this.deployStatus && this.deployStatus.newVersionAvailable;
    const buttons = [this.logOutbutton, this.isNewVersionAvailable ? this.ionicButton : this.nativeButton];
    const title = this.translateService.instant('deploy.versionIsOutdated');
    const subTitle = this.platform.is('ios') ? 'deploy.checkAppStore' : 'deploy.checkPlayStore';
    if (this.isNewVersionAvailable && this.isNativeInstallButtonDisplayed && this.alert) {
      this.alert.dismiss();
    }
    this.alert = this.alertCtrl.create({
      header: title,
      subHeader: !this.isNewVersionAvailable ? this.translateService.instant(subTitle) : '',
      buttons: buttons,
      // enableBackdropDismiss: false
    });
    this.alert.onDidDismiss(() => {
      if (this.userProfile) {
        this.alert.present();
      } else {
        this.isVersionOutdatedAlertDisplayed = false;
      }
    });
    if (!this.isVersionOutdatedAlertDisplayed) {
      this.alert.present();
      this.isVersionOutdatedAlertDisplayed = true;
    }
  }

  private goToLoginPage() {
    this.authenticationService.doLogout();
    this.navController.navigateRoot('/login');
  }

  private installNewVersion() {
    this.ionicDeploy.installNewVersion();
  }

}
