import { Inject, Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';

declare const window: any;

// SETTINGS
import { AppSettings, APP_SETTINGS } from '@app/app.settings';

// MODELS
import { AppError } from '@models/base/base';
import { Order } from '@models/business/order.model';
import { Position } from '@models/business/position.model';
import { ConnectionStatus } from '@models/information/connection-status.model';
import { MapDestination } from '@models/map/map-destination.model';
import { OrderView } from '@models/order-helper/order-view.model';
import { MapOptionEnum, MapSearchOptionEnum } from '@models/settings/map-option.enum';
import { UserSettings } from '@models/settings/settings.model';

// PROVIDERS
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { CommonUtils } from '@services/utils/common-utils';
import { DialogsService } from '@services/dialogs/dialogs';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { Loading } from '@services/loading/loading';
import { LogService } from '@services/log/log.service';
import { SettingsManager } from '@services/settings-providers/settings.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  userSettings: UserSettings;

  public GOOGLE_API_KEY: string;
  public TIMEOUT: number;
  public isGoogleMapsApiLoaded: boolean;
  public gmAuthFailureWasInvoked: boolean;
  public mapSDKLoadFailed: boolean;

  private isNetworkConnected = true;

  constructor(
    private alertController: AlertController,
    @Inject(APP_SETTINGS) appSettings: AppSettings,
    private connectionStatusService: ConnectionStatusService,
    private commonUtils: CommonUtils,
    private dialogsService: DialogsService,
    private mobileContextService: MobileContextService,
    private loading: Loading,
    private log: LogService,
    private platform: Platform,
    private settings: SettingsManager,
    private translateService: TranslateService
  ) {

    this.GOOGLE_API_KEY = appSettings.GOOGLE_API_KEY;
    this.TIMEOUT = appSettings.TIMEOUT;
    this.isGoogleMapsApiLoaded = false;
    this.gmAuthFailureWasInvoked = false;
    this.mapSDKLoadFailed = false;

    this.connectionStatusService.connectionStatusSubscription
      .subscribe((status: ConnectionStatus) => {
        this.isNetworkConnected = status.isNetworkConnected;
      });

    this.mobileContextService.userSettingsObservable
      .subscribe(
        userSettings => {
          if (userSettings) {
            this.userSettings = userSettings;
          }
        }
      );
  }

  /**
   * Open map application to a specific place. If no map application has been choosen in the past by the user, a chooser will be prompted.
   * @param order order for which the request is made
   * @param position override order's position
   * @param addressOneLine override order's address one line string address
   * @param addressLabel override order's address label
   */
  openMap(order: Order, position: Position = null, addressOneLine: string = null, addressLabel: string = null) {
    let coordinate = null;
    let address = null;
    let label = null;
    if (position != null && addressOneLine != null) {
      coordinate = position.latitude + ',' + position.longitude;
      address = addressOneLine;
      label = addressLabel;
    } else if (order) {
      coordinate = order.deliveryAddress.position.latitude + ',' + order.deliveryAddress.position.longitude;
      address = order.deliveryAddress.addressOneLine;
      label = order.client.optimizedName();
    }
    coordinate = encodeURIComponent(coordinate);
    address = encodeURIComponent(address);
    label = encodeURIComponent(label);

    this.mapProviderSelectionPopup()
      .subscribe(() => {
        this.openMapApplicationForPlace(this.userSettings.mapOption, this.userSettings.mapQueryOption, coordinate, address, label);
      });
  }

  /**
   * Launch the map app in direction mode. Ask for an application if none where previously choosen.
   * @param order order to navigate to
   */
  openDirectionToOrderClient(order: Order) {
    let coordinate = order.deliveryAddress.position.latitude + ',' + order.deliveryAddress.position.longitude;
    coordinate = encodeURIComponent(coordinate);
    const address = encodeURIComponent(order.deliveryAddress.addressOneLine);

    this.mapProviderSelectionPopup()
      .subscribe(() => {
        this.openMapApplicationForDirection(this.userSettings.mapOption, this.userSettings.mapQueryOption, coordinate, address);
      });
  }

  openDirectionToSelectedMapDestinations(mapDestinations: Array<MapDestination>) {
    const numberOfItems = mapDestinations.length;
    const lastMapItemIndex = numberOfItems - 1;
    const lastMapItem = mapDestinations[lastMapItemIndex];
    const destination = lastMapItem.address.position.latitude + ',' + lastMapItem.address.position.longitude;
    let url = 'https://www.google.com/maps/dir/?api=1&destination=' + destination + '&waypoints=';
    for (let i = 0; i < mapDestinations.length - 1; i++) {
      const currentMapItem = mapDestinations[i];
      const itemCoordinates = currentMapItem.address.position.latitude + ',' + currentMapItem.address.position.longitude;
      url += itemCoordinates;
      if (i !== mapDestinations.length - 2) {
        url += '|';
      }
    }
    if (this.platform.is('android') || (!this.platform.is('capacitor') && !this.platform.is('cordova'))) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }

  openDirectionsForOptimizedOrders(orders: Array<OrderView>, start: Position, end: Position) {
    let url = 'https://www.google.com/maps/dir/?api=1';
    if (start) {
      const origin = start.latitude + ',' + start.longitude;
      url += '&origin=' + origin;
    }

    if (end) {
      const destination = end.latitude + ',' + end.longitude;
      url += '&destination=' + destination;
    } else {
      const numberOfOrders = orders.length;
      const lastOrdersIndex = numberOfOrders - 1;
      const lastOrder = orders[lastOrdersIndex].order;
      const destination = lastOrder.deliveryAddress.position.latitude + ',' + lastOrder.deliveryAddress.position.longitude;
      url += '&destination=' + destination;
    }

    url += '&waypoints=';

    for (let i = 0; i < orders.length - 1; i++) {
      const currentOrder = orders[i].order;
      const orderCoordinates = currentOrder.deliveryAddress.position.latitude + ',' + currentOrder.deliveryAddress.position.longitude;
      url += orderCoordinates;
      if (i !== orders.length - 2) {
        url += '|';
      }
    }
    if (this.platform.is('android') || (!this.platform.is('capacitor') && !this.platform.is('cordova'))) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }

  /**
   * Map application provider seleection popup.
   * If an app has already been choosen in the past, no chooser is displayed.
   */
  mapProviderSelectionPopup() {
    const options: Array<any> =
      [{
        type: 'radio',
        label: this.translateService.instant('pages.order-detail.google_maps'),
        value: MapOptionEnum.getMap(MapOptionEnum.GOOGLE)
      }, {
        type: 'radio',
        label: this.translateService.instant('pages.order-detail.waze_maps'),
        value: MapOptionEnum.getMap(MapOptionEnum.WAZE)
      }, {
        type: 'radio',
        label: this.translateService.instant('pages.order-detail.system_maps'),
        value: MapOptionEnum.getMap(MapOptionEnum.SYSTEM)
      }];

    if (this.platform.is('ios')) {
      options.push({
        type: 'radio',
        label: this.translateService.instant('pages.order-detail.apple_maps'),
        value: MapOptionEnum.getMap(MapOptionEnum.APPLE)
      });
    }

    return new Observable<any>(subscriber => {
      if (this.userSettings.mapOption === null || (!this.platform.is('ios') && this.userSettings.mapOption === MapOptionEnum.APPLE)) {
        this.alertController.create({
          header: this.translateService.instant('pages.order-detail.apps_to_select'),
          inputs: options,
          buttons: [
            {
              text: this.translateService.instant('pages.order-detail.order_detail_alert_cancel'),
              role: 'cancel',
              handler: () => {
                subscriber.error();
                subscriber.complete();
              }
            },
            {
              text: this.translateService.instant('pages.order-detail.order_detail_alert_ok'),
              handler: data => {
                this.settings.setMapOption(+MapOptionEnum[data]);
                subscriber.next(this.userSettings.mapOption);
                subscriber.complete();
              }
            }
          ]
        }
        ).then(r => {
          r.present();
        });
      } else {
        subscriber.next(this.userSettings.mapOption);
        subscriber.complete();
      }
    });
  }

  /**
   * Open the selected map application to the specified place.
   * @param map choosen map app
   * @param mapQueryOption search criteria (address, coordinates, both)
   * @param coordinate lat/long
   * @param address address text format
   * @param label human readable label
   */
  openMapApplicationForPlace(map: MapOptionEnum, mapQueryOption: MapSearchOptionEnum, coordinate, address, label) {
    let url = '';
    if (+map === MapOptionEnum.GOOGLE) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'https://www.google.com/maps/search/?api=1&query=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'https://www.google.com/maps/search/?api=1&query=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        // No option to have both query address + coordinates, using coordinates by default
        url = 'https://www.google.com/maps/search/?api=1&query=' + coordinate;
      }
    } else if (+map === MapOptionEnum.APPLE || (+map === MapOptionEnum.SYSTEM && this.platform.is('ios'))) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'maps://maps.apple.com/?q=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'maps://maps.apple.com/?ll=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'maps://maps.apple.com/?q=' + address + '&sll=' + coordinate;
      }
    } else if (+map === MapOptionEnum.WAZE) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'https://www.waze.com/ul?q=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'https://www.waze.com/ul?q=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'https://www.waze.com/ul?q=' + address + '&ll=' + coordinate;
      }
    } else if (+map === MapOptionEnum.SYSTEM) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'geo:0,0?q=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'geo:' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'geo:' + coordinate + '&q=' + address;
      }
    }

    if (url) {
      window.location.href = url;
    }
  }

  /**
   * Open the selected map application to the specified place.
   * @param map choosen map app
   * @param mapQueryOption search criteria (address, coordinates, both)
   * @param coordinate lat/long
   * @param address address text format
   */
  openMapApplicationForDirection(map: MapOptionEnum, mapQueryOption: MapSearchOptionEnum, coordinate, address) {
    let url = '';
    if (+map === MapOptionEnum.GOOGLE) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'https://www.google.com/maps/dir/?api=1&q=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'https://www.google.com/maps/dir/?api=1&q=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        // No option to have both query address + coordinates, using coordinates by default
        url = 'https://www.google.com/maps/dir/?api=1&q=' + coordinate;
      }
    } else if (+map === MapOptionEnum.APPLE || (+map === MapOptionEnum.SYSTEM && this.platform.is('ios'))) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'maps://maps.apple.com/?daddr=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'maps://maps.apple.com/?daddr=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'maps://maps.apple.com/?daddr=' + coordinate;
      }
    } else if (+map === MapOptionEnum.WAZE) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'https://www.waze.com/ul?q=' + address + '&navigate=yes';
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'https://www.waze.com/ul?q=' + coordinate + '&navigate=yes';
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'https://www.waze.com/ul?q=' + address + '&ll=' + coordinate + '&navigate=yes';
      }
    } else if (+map === MapOptionEnum.SYSTEM) {
      if (mapQueryOption === MapSearchOptionEnum.ADDRESS) {
        url = 'google.navigation:q=' + address;
      } else if (mapQueryOption === MapSearchOptionEnum.COORDINATES) {
        url = 'google.navigation:q=' + coordinate;
      } else if (mapQueryOption === MapSearchOptionEnum.ADDRESS_AND_COORDINATES) {
        url = 'google.navigation:q=' + coordinate;
      }
    }

    if (url) {
      window.location.href = url;
    }
  }

  //#region MAP LIBS METHODS
  /**
   * Method to load all the required google maps and other mapping libraries
   * This method uses jquery to support and detect timeout however this is not optimal to use this approach
   * because jquery does not support tree shaking
   * NOTE: keep this in case the alternative loadGoogleMapsApiAndLibs does not work well
   */
  // public loadGoogleMapsApiAndLibs(): Promise<void> {

  //   const promise = new Promise(async (resolve: (val?: any) => void, reject: (reason: AppError) => void) => {
  //     if (this.isGoogleMapsApiLoaded) {
  //       resolve();
  //     } else {
  //       // NOTE: if online and Google Maps SDK has not been loaded yet then load maps api
  //       if (this.isNetworkConnected === true) {
  //         if (this.GOOGLE_API_KEY) {

  //           this.gmAuthFailureWasInvoked = false;
  //           this.mapSDKLoadFailed = false;

  //           window.gm_authFailure = () => {
  //             this.gmAuthFailureWasInvoked = true;
  //             const appError = new AppError(false, 'Google Maps SDK authentication failed');
  //             reject(appError);
  //           };
  //           window.onGoogleMapsApiLoaded = async () => {
  //             // NOTE: Google Maps API loaded and ready to be used
  //             if (this.gmAuthFailureWasInvoked === false && this.mapSDKLoadFailed === false) {
  //               delete window.gm_authFailure;
  //               delete window.onGoogleMapsApiLoaded;

  //               this.isGoogleMapsApiLoaded = true;
  //               resolve();
  //             }
  //           };

  //           // NOTE: we may want to replace jquery with some light weight library since we use it only to load the script
  //           // and jquery does not support tree shaking yet :(
  //           $.get({
  //             // NOTE: For most applications, Google recommends the weekly channel - see https://developers.google.com/maps/documentation/javascript/versions#choosing-the-weekly-channel
  //             url: `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_API_KEY}&v=weekly&callback=onGoogleMapsApiLoaded&libraries=places`,
  //             timeout: this.TIMEOUT * 2,
  //             dataType: 'script'
  //           })
  //             .fail(() => {
  //               if (this.gmAuthFailureWasInvoked === false) {
  //                 this.mapSDKLoadFailed = true;
  //                 const appError = new AppError(false, 'Google Maps SDK loading failed due to the problem with connectivity');
  //                 reject(appError);
  //               }
  //             });
  //         } else {
  //           const appError = new AppError(false, 'Google Maps SDK API key is missing');
  //           reject(appError);
  //         }
  //       } else {
  //         const appError = new AppError(false, 'Google Maps SDK loading failed due to the problem with connectivity');
  //         reject(appError);
  //       }
  //     }
  //   });

  //   return this.commonUtils.promiseTimeout((this.TIMEOUT * 3), promise)
  //     .catch((appError) => {
  //       delete window.gm_authFailure;
  //       delete window.onGoogleMapsApiLoaded;

  //       this.isGoogleMapsApiLoaded = false;

  //       throw appError;
  //     });
  // }

  /**
  * Method to load all the required google maps and other mapping libraries
  * This method uses @googlemaps/js-api-loader and not require jquery
  */
  public async loadGoogleMapsApiAndLibs(): Promise<void> {

    if (this.isGoogleMapsApiLoaded === true) {
      return;
    } else {
      // NOTE: if online and Google Maps SDK has not been loaded yet then load maps api
      if (this.isNetworkConnected === true) {
        if (this.GOOGLE_API_KEY) {

          const loader = new Loader({
            apiKey: this.GOOGLE_API_KEY,
            version: "weekly",
            libraries: ["places"]
          });
          try {
            await loader.load();
            this.isGoogleMapsApiLoaded = true;
          } catch (error) {
            const googleMapsSDKAuthenticationFailedMessage = this.translateService.instant('messages.googleMapsSDKAuthenticationFailed');
            const appError = new AppError(false, googleMapsSDKAuthenticationFailedMessage);
            throw appError;
          }

        } else {
          const googleMapsSDKApiKeyIsMissingMessage = this.translateService.instant('messages.googleMapsSDKApiKeyIsMissing');
          const appError = new AppError(false, googleMapsSDKApiKeyIsMissingMessage);
          throw appError;
        }
      } else {
        const googleMapsSDKLoadingFailedDueToProblemWithConnectivityMessage = this.translateService.instant('messages.googleMapsSDKLoadingFailedDueToProblemWithConnectivity');
        const appError = new AppError(false, googleMapsSDKLoadingFailedDueToProblemWithConnectivityMessage);
        throw appError;
      }
    }

  }

  /**
   * Method to check if google maps lib is loaded/available and load it if not
   */
  public async makeSureGoogleMapsAPILoaded(): Promise<void> {
    if (!this.isGoogleMapsApiLoaded) {
      // NOTE: Google SDK and libs have not been loaded yet
      try {
        await this.loadGoogleMapsApiAndLibs();
      } catch (error) {
        this.log.error('[MappingUtils] Failed to load and initialize Google Maps SDK', error);
        if (error && error instanceof AppError) {
          throw error;
        } else {
          const failedToLoadAndInitializeGoogleMapsSDKMessage = this.translateService.instant('messages.failedToLoadAndInitializeGoogleMapsSDK');
          const appError = new AppError(false, failedToLoadAndInitializeGoogleMapsSDKMessage);
          throw appError;
        }
      }
    }
  }

  /**
   * Method to show the confirmation dialog when map view load/initialization failed
   * It prompts the user to retry to reload the map or cancel
   */
  public async showConfirmationDialogOnMapViewFailed(retryMapLoadFn: () => Promise<void>, errorMessage?: string): Promise<void> {
    const confirmDialogMessage = this.translateService.instant('pages.map.messages.confirm-retry-loading-map-view');
    const loadingMapViewFailedMessage = this.translateService.instant('pages.map.messages.loading-map-view-failed');
    const cancelButtonText = this.translateService.instant('actions.cancel');
    const retryButtonText = this.translateService.instant('actions.retry');
    const confirmDialogMessageWithError = errorMessage ? `${errorMessage}. ${confirmDialogMessage}` : confirmDialogMessage;
    const confirmed: boolean = await this.dialogsService.showConfirmDialog(confirmDialogMessageWithError, loadingMapViewFailedMessage, null, 'confirm-warning-dialog map-view-load-failed-dialog', cancelButtonText, retryButtonText);
    if (confirmed === true) {
      if (this.isNetworkConnected === true) {
        await retryMapLoadFn();
      }
    }
  }
  // #endregion

  //#region MARKERS METHODS
  /**
   * Sets the map on all markers in the array.
   */
  public setMapOnAllMarkers(map: google.maps.Map, markers: Array<google.maps.Marker | google.maps.OverlayView>): void {
    if (markers) {
      for (const marker of markers) {
        marker.setMap(map);
      }
    }
  }
  /**
   * Shows all the markers on the map
   */
  public showTicketMarkers(map: google.maps.Map, markers: Array<google.maps.Marker | google.maps.OverlayView>): void {
    if (markers) {
      this.setMapOnAllMarkers(map, markers);
    }
  }
  /**
   * Deletes one marker from the map
   */
  public deleteMarker(marker: google.maps.Marker | google.maps.OverlayView): void {
    if (marker) {
      marker.setMap(null);
    }
  }
  /**
   * Deletes all markers from the map and also remove all markers from the array
   */
  public deleteAllMarkers(markers: Array<google.maps.Marker | google.maps.OverlayView>): void {
    if (markers) {
      this.setMapOnAllMarkers(null, markers);
      markers.length = 0;
    }
  }
  // #endregion

}
