import {Component} from '@angular/core';
import {TripReport} from '@models/reports/tripReport.model';
import {UserSettings} from '@models/settings/settings.model';
import {DateTimeCombinationEnum} from '@models/units-and-format/date-format.enum';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {AlertController, ModalController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const CONFIG = {
  GA: {
    PAGE_NAME: 'TripReportModalPage',
  }
};

@Component({
  templateUrl: './trip-report-modal.page.html',
  styleUrls: ['./trip-report-modal.page.scss'],
})
export class TripReportModalPage {

  public reportType: string;
  public report: TripReport;
  public items = [];

  public count = 0;

  userSettings: UserSettings = new UserSettings();
  private unsubscribe: Subject<void> = new Subject<void>();

  timeDateType: DateTimeCombinationEnum = DateTimeCombinationEnum.timeAndDate;
  dateTimeType: DateTimeCombinationEnum = DateTimeCombinationEnum.dateAndTime;

  constructor(public navParams: NavParams,
              private modalCtrl: ModalController,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private translate: TranslateService,
              private mobileContextService: MobileContextService,
              private alertCtrl: AlertController,
  ) {
    this.reportType = this.navParams.get('reportType');
    this.report = this.navParams.get('report');

    for (let i = 0; i < 30; i++) {
      if (i >= this.report.trips.length) {
        break;
      }
      this.items.push(this.report.trips[i]);
      this.count = i;
    }
    combineLatest([this.mobileContextService.userSettingsObservable])
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(([userSettings]) => {
        this.userSettings = userSettings;
      });


  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  doInfinite(infiniteScroll) {
    for (let i = this.count; i < 30 + this.count; i++) {
      if (i >= this.report.trips.length) {
        break;
      }
      this.items.push(this.report.trips[i]);
    }
    infiniteScroll.target.complete();
    if (this.items.length > this.report.trips.length) {
      infiniteScroll.target.disabled = true;
    }
  }

  translateDistanceText(key): string {
    if (this.userSettings.distanceUnit === DistanceSystemEnum.SI) {
      return this.translate.instant(key, {unit: this.translate.instant('distanceUnits.km')});
    } else {
      return this.translate.instant(key, {unit: this.translate.instant('distanceUnits.miles')});
    }
  }

  async openLegend() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('pages.trips-reports.legend'),
      message: this.translate.instant('pages.trips-reports.delivery_copies')
        + this.translate.instant('pages.trips-reports.extra_distance'),
      buttons: [this.translate.instant('actions.ok')]
    });
    await alert.present();
  }
}

