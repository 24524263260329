import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('MqttTopics')
export class MqttTopics {

  @JsonProperty('haulerEmployeeMessages', String, true)
  haulerEmployeeMessages: string = undefined;

  @JsonProperty('haulerMessages', String, true)
  haulerMessages: string = undefined;

  @JsonProperty('haulerEmployeeStatuses', String, true)
  haulerEmployeeStatuses: string = undefined;

}
