import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {DurationPipe} from '@app/pipes/duration/duration.pipe';
import {PipesModule} from '@app/pipes/pipes.module';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {ReorderModalPage} from './reorder-modal.page';

const routes: Routes = [
  {
    path: '',
    component: ReorderModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    PipesModule,
    ComponentsModule,
  ],
  declarations: [ReorderModalPage],
  providers: [
    DurationPipe,
  ]
})
export class ReorderModalPageModule {}
