import {Pipe} from '@angular/core';
import {PipeTransform} from '@angular/core';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';
import * as moment from 'moment';

@Pipe({
  name: 'Time',
})
export class TimePipe implements PipeTransform {

  constructor() {

  }

  /**
   * value, string : time
   * hourFormat, string: hour format (standard or military)
   * isTimePicker, Boolean: is it for a time picker ?
   * formatOnly, Boolean: return the format only, or the formatted time
   */
  transform(value: any, hourFormat: TimeFormatEnum, isTimePicker: boolean = false, formatOnly: boolean = false): any {

    if (value) {
      const timeFormat = TimeFormatEnum.getFormat(hourFormat);
      if (isTimePicker) {
        return timeFormat.split(':').join(' ');
      } else if (formatOnly) {
        return timeFormat;
      } else {
        return moment(value, 'HH:mm').format(timeFormat);
      }
    }
    return null;
  }

}
