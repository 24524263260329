import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {IonicModule} from '@ionic/angular';

import {RemoteCommandMessageModalPage} from './remote-command-message-modal.page';

const routes: Routes = [
  {
    path: '',
    component: RemoteCommandMessageModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
  ],
  declarations: [RemoteCommandMessageModalPage]
})
export class RemoteCommandMessageModalPageModule {
}
