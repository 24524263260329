import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Order} from '@models/business/order.model';
import {Position} from '@models/business/position.model';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {PositionTypeEnum} from '@models/information/position-type.enum';
import {OptimizedResponse} from '@models/optimize-route/optimized-response.model';
import {RouteSummary} from '@models/optimize-route/route-summary.model';
import {PositionTypeEnumConverter} from '@models/push-messages/converters.model';
import {OrderMessage, SUPPORTED_STATUS} from '@models/push-messages/order-message.model';
import {UserProfile} from '@models/user-profile.model';
import {plainToClass} from '@utils/json-converter/json-converter';
import {LoadingController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {PushService} from '@services/push/push.service';
import * as moment from 'moment';
import {concat, EMPTY, Observable, of, throwError} from 'rxjs';
import {delay, mergeMap, map, retryWhen, take} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {AppEvents} from '@services/app-events/app-events';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

export const SYNC_CONFIG = {
  STORAGE_LAST_SYNC: 'mobilus_last_sync'
};

const CONFIG = {
  PATH_ORDERS: '/mobile/v2/getOrdersForHaulerEmployee',
  PATH_ORDER: '/mobile/v2/getOrderForHaulerEmployee',
  PATH_SORT_ORDERS: '/mobile/v2/getOptimizedOrderForHaulerEmployee',
  PATH_DATED_ORDERS: '/mobile/v2/getDatedOrdersForHaulerEmployee',
  PATH_HAULERS_ORDERS: '/mobile/v2/getOrdersForHauler',
  UPDATE_ORDERS_SEQUENCES_PATH: '/mobile/v2/updateSequence',
  DELIVERED_STATUS: 'DELIVERED',
  COMPLETED_STATUS: 'COMPLETED',
};

@Injectable({
  providedIn: 'root'
})
export class OrderSyncService {
  private haulerEmployeeId: number;
  private lastSyncTime: moment.Moment;
  private sequenceOrdersUuid: string;
  private positionTypeConverter = new PositionTypeEnumConverter();
  private isNetworkConnected = true;
  private orderToUpdate: Order;

  constructor(private pushService: PushService,
              private orderStoreService: OrderStoreService,
              private httpClient: HttpClient,
              private loadingCtrl: LoadingController,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              private endpointService: EndpointService,
              private mobileContextService: MobileContextService,
              private backgroundGeolocationService: BackgroundGeolocationService,
              private log: LogService,
              private connectionStatusService: ConnectionStatusService,
              private appEvents: AppEvents,
              private capacitorPlugins: CapacitorPlugins
  ) {

    this.mobileContextService.userProfileObservable
      .subscribe((profile: UserProfile) => {
        if (profile) {
          this.haulerEmployeeId = profile.haulerEmployeeId;
        } else {
          this.haulerEmployeeId = null;
        }
      });

    this.connectionStatusService.connectionStatusSubscription
      .subscribe((status: ConnectionStatus) => {
        this.isNetworkConnected = status.isNetworkConnected;
      });

    this.sequenceOrdersUuid = uuidv4();
    this.pushService.injectOrderSyncService(this);
  }

  sendPaymentUpdate(): Observable<Order> {
    return new Observable(subscriber => {
        subscriber.next(this.orderToUpdate);
        subscriber.complete();
      }
    );
  }

  refreshOrder(orderMessage: OrderMessage, orderMessageTimestamp: moment.Moment): Observable<Order | null> {
    if (orderMessageTimestamp && this.lastSyncTime) {
      if (orderMessageTimestamp.isBefore(this.lastSyncTime)) {
        return EMPTY;
      }
    }
    // TODO : drop message if it is not for a provider the user have selected and it is not assigned to him
    // console.log("Refreshing order : ", orderMessage);
    if (orderMessage.order && orderMessage.order.stateMachineWorkflow) { // make sure there is more than just an id in the order
      // console.log('Persisting provided order', orderMessage.order)
      return new Observable(subscriber => {
        this.orderStoreService.persistOrder(orderMessage.order).subscribe(order => {
          if (order && order.status === CONFIG.DELIVERED_STATUS ||
            order.status === CONFIG.COMPLETED_STATUS) {
            this.orderToUpdate = order;
            this.sendPaymentUpdate();
          }
          subscriber.next(order);
          subscriber.complete();
        });
      });
    } else {
      // console.log('Fetching order from store', orderMessage.id);
      this.orderStoreService.get(orderMessage.id)
        .subscribe(
          (orderFromDB: Order) => {
            // If we have the last version number of the order, do not refresh it
            if ((orderFromDB.version.toString() !== orderMessage.version.toString()) // would sometime fail without toString()
              || SUPPORTED_STATUS.indexOf(orderMessage.status) > -1
            ) {
              console.log('we know of this message at a different version', orderFromDB.version, orderMessage.version);
              return this.httpGetAndPersistOrder(orderMessage.id);
            } else {
              // console.log('We can manage this message locally');
              orderFromDB.update(orderMessage);
              return this.orderStoreService.persistOrder(orderFromDB); // modified
            }

          },
          error => {
            // console.log('on error, fetching order', orderMessage, error);
            if (orderMessage.isPertinent(this.haulerEmployeeId)) {
              return this.httpGetAndPersistOrder(orderMessage.id);
            }
          });
    }

  }

  httpGetAndPersistOrder(id: string): Observable<Order> {
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_ORDER + '?id=' + id;

    return new Observable(subscriber => {
      this.httpClient
        .get(url)
        .pipe(
          retryWhen(error => {
            return error.pipe(
              mergeMap((e: any) => {
                this.log.info('Got error on fetching at ' + url, JSON.stringify(e));
                if (e.status === 0 || e.status === 404) { // Sometimes, server is slow to provide order
                  this.log.error('Retrying after a 0 or 404', e);
                  return of(e.status).pipe(
                    delay(3 * 1000)
                  );
                }
                this.log.warn('Not retrying (error status different than 0)');

                return throwError({error: 'No retry', status: e.status});
              }),
              take(5),
              o => concat(o, throwError({error: '5 retries exceeded.', orderId: id}))
            );
          }),
          map((body: object) => plainToClass(Order, body))
        )
        .subscribe(
          order => {
            this.orderStoreService.persistOrder(order, 'OrderSyncService.httpGetAndPersistOrder')
              .subscribe(instance => {
                subscriber.next(instance);
              });
          },
          error => {
            this.log.error(`Unable to load order [${id}] from server`, error);

            // Remove order from list if status is 404 not found (unavailable to this user)
            // this.orderStoreService.removeOrderById(id);

            subscriber.error(`Unable to load order (${id}) from server`);
          },
          () => {
            subscriber.complete();
          });
    });
  }

  optimizeRoute(start: Position,
                end: Position,
                positionType: PositionTypeEnum,
                startAt: moment.Moment,
                withTimeConstraints: boolean): Observable<RouteSummary> {
    this.log.info('Optimizing route', withTimeConstraints);
    const positionTypeString = this.positionTypeConverter.serialize(positionType);
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_SORT_ORDERS;
    let summary: RouteSummary;
    return this.httpClient.post(url, {
      uuid: this.sequenceOrdersUuid,
      from: start,
      end: end,
      positionType: positionTypeString,
      withTimeConstraints: withTimeConstraints,
      startAt: startAt.toISOString()
    })
      .pipe(
        map((body: any) => {
          const response: OptimizedResponse = plainToClass(OptimizedResponse, body);
          summary = response.summary;
          return response.orders;
        }),
        mergeMap((orders: Array<Order>) => {
          this.sequenceOrdersUuid = uuidv4();
          this.orderStoreService.persistOrders(orders).subscribe();
          return new Observable(observer => observer.next(summary));
        })
      ) as Observable<RouteSummary>;
  }

  updateOrdersSequences(from: Position,
                        positionType: string,
                        startAt: moment.Moment,
                        orders: any,
                        withTimeConstraints: boolean): Observable<RouteSummary> {
    this.log.info('Updating orders sequences', withTimeConstraints);
    const url = this.endpointService.currentEndpoint + CONFIG.UPDATE_ORDERS_SEQUENCES_PATH;
    let summary: RouteSummary;
    return this.httpClient.post(url, {
      positionType,
      from,
      orders,
      withTimeConstraints: withTimeConstraints,
      startAt: startAt.toISOString()
    })
      .pipe(
        retryWhen(error => {
          return error.pipe(
            mergeMap((err: any) => {
              this.log.info('Got error on updating sequences at ' + url, JSON.stringify(err));
              return of(err.status).pipe(delay(2 * 1000));
            }),
            take(5),
            o => concat(o, throwError({error: '5 retries exceeded.'}))
          );
        }),
        map((body: any) => {
          const response: OptimizedResponse = plainToClass(OptimizedResponse, body);
          summary = response.summary;
          return response.orders;
        }),
        mergeMap((instances: Array<Order>) => {
          this.sequenceOrdersUuid = uuidv4();
          this.orderStoreService.persistOrders(instances).subscribe();
          return new Observable(observer => observer.next(summary));
        })
      ) as Observable<RouteSummary>;

  }

  fullSync(backgroundProcessing: boolean = false): Observable<any> {
    this.log.debug('doing full sync in background', backgroundProcessing);
    this.pushService.sendRefreshConnectedStatus();

    if (backgroundProcessing) {
      return this._fullSync();
    } else {
      return new Observable(observer => {
        if (this.appEvents.isUserAuthorized() !== true) {
          observer.error('User is not authenticated');
          return;
        }
        this.loadingCtrl.create()
          .then(loading => {
            loading.present().then(() => {
              this._fullSync().subscribe({
                error: (_err) => {
                  loading.dismiss();
                  observer.complete();
                },
                complete: () => {
                  loading.dismiss();
                  observer.complete();
                }
              });
            });
          });
      });
    }
  }

  private _fullSync(): Observable<any> {
    return new Observable(observer => {
      if (this.appEvents.isUserAuthorized() !== true) {
        observer.error('User is not authenticated');
        return;
      }
      this.preserveSyncTime();
      // WAIT for store to be ready
      this.orderStoreService.ready()
        .then(() => {
          if (this.isNetworkConnected) {
            this.orderStoreService.flagOrdersForDeletion()
              .subscribe(flag => {
                this.log.trace('Orders had been flagged for deletion');
                this.syncOrders()
                  .subscribe(
                    data => {
                      this.log.trace('New order list received from backend ', data);
                      observer.next(data);
                    },
                    error => {
                      this.log.error('syncOrders error', error);
                      this.fullSyncFailed();

                      if (error.status !== 0) { // 0 is no http connection
                        this.orderStoreService.deleteflaggedOrdersForDeletion(flag)
                          .subscribe();
                      }

                      this.backgroundGeolocationService.recheckPosition();
                      observer.complete();
                    },
                    () => {
                      this.log.trace('Order synchronization complete, deleting flagged orders');
                      this.orderStoreService.deleteflaggedOrdersForDeletion(flag)
                        .subscribe(() => {
                        }, () => {
                        }, () => {
                          this.backgroundGeolocationService.recheckPosition();
                          observer.complete();
                        });
                    });
              }, err => {
                observer.error(err);
              });

          } else {
            this.backgroundGeolocationService.recheckPosition();
            this.fullSyncFailed();
            observer.error('Not connected');
          }
        }, error => this.log.error('error on ready', error));
    });
  }

  getOrders(): Observable<Array<Order>> {
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_ORDERS;
    const params = new HttpParams().set('when', moment().format('YYYY-MM-DD'));
    return this.httpClient.get(url, {params: params})
      .pipe(
        map((body: object[]) => this.typeOrders(body))
      );
  }

  getOrdersForAllHaulerEmployees(): Observable<Array<Order>> {
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_HAULERS_ORDERS;
    const params = new HttpParams().set('when', moment().format('YYYY-MM-DD'));
    return this.httpClient.get(url, {params: params})
      .pipe(
        map((body: object[]) => this.typeOrders(body))
      );
  }

  getDatedOrders(deliveryDate: string): Observable<Array<Order>> {
    const params = new HttpParams().set('when', deliveryDate);
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_DATED_ORDERS;
    return this.httpClient.get(url, {params: params})
      .pipe(
        map((body: Object[]) => this.typeOrders(body)),
        map((body: Order[]) => this.assignUUIDToOrders(body)));
  }

  assignUUIDToOrders(orders: Order[] = []): Array<Order> {
    const out = [];
    for (let i = 0, len = orders.length; i < len; i++) {
      orders[i].uuid = uuidv4();
      out.push(orders[i]);
    }
    return out;
  }

  syncOrders(): Observable<Order> {
    this.log.trace('Syncing all orders.');
    return this.getOrders()
      .pipe(
        mergeMap((orders: Array<Order>) => {
          this.log.debug('syncOrders, persisting ' + orders.length + ' orders');
          return this.orderStoreService.persistOrders(orders, 'OrderSyncService.syncOrders');
        })
      );
  }

  typeOrders(orders: object[] = []): Array<Order> {
    const out = [];
    for (let i = 0, len = orders.length; i < len; i++) {
      out.push(this.typeOrder(orders[i]));
    }
    return out;
  }

  typeOrder(order: object): Order {
    return plainToClass(Order, order);
  }

  private preserveSyncTime() {
    this.lastSyncTime = moment();
    this.log.trace('Preserving full sync time : ', this.lastSyncTime);
    this.capacitorPlugins.getPreferencesPlugin().set({key: SYNC_CONFIG.STORAGE_LAST_SYNC, value: this.lastSyncTime.toISOString()});
  }

  private async fullSyncFailed(): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: this.translateService.instant('messages.errors.sync_timeout'),
      duration: 5 * 1000,
      position: 'bottom',
      cssClass: 'processing-toast'
    });
    await toast.present();
  }

}
