<ion-buttons slot="end">
  <ion-button (click)="closePopover()" class="text-xxlarge" style="margin-left: auto;">
    <fa-icon [icon]="['fal', 'times']"></fa-icon>
  </ion-button>
</ion-buttons>
<ion-card *ngIf="summary" style="margin-top: 0;">
  <ion-card-header>
    <ion-card-title>
      <span translate="optimizeOrders.currentRoute"></span>
    </ion-card-title>
  </ion-card-header>
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <span translate="routeSummary.duration"></span>
      </ion-col>
      <ion-col size="6">
        :&nbsp;{{ summary?.totalTravelTimeMin | duration:'minutes' }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <span translate="routeSummary.stopCount"></span>
      </ion-col>
      <ion-col size="6">
        :&nbsp;{{summary?.stopCount}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <span
          translate="routeSummary.distance"></span>
      </ion-col>
      <ion-col size="6">
        :&nbsp;{{summary?.totalDistanceKm | Distance:settings.distanceUnit:true:true:'1.1-1' }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <span translate="routeSummary.withTimeConstraints"></span>
      </ion-col>
      <ion-col size="6">
        <span *ngIf="summary.withTimeConstraints" class="summary-constraint-icon">
          :&nbsp;<fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
        </span>
        <span *ngIf="!summary.withTimeConstraints" class="summary-constraint-icon">
          :&nbsp;<fa-icon [icon]="['fas', 'empty-set']"
                          *ngIf="!summary.withTimeConstraints"></fa-icon>
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <span translate="routeSummary.startAt"></span>
      </ion-col>
      <ion-col size="6">
        :&nbsp;{{ summary?.startAt| Time: settings.hourFormat}}
      </ion-col>
    </ion-row>
    <ion-row *ngIf="endLocationName && endLocationAddress" (click)="openMap()">
      <ion-col size="6">
        {{endLocationName}}
      </ion-col>
      <ion-col size="6">
        :&nbsp;{{endLocationAddress}}
        &nbsp;<fa-icon [icon]="['fas', 'map']"></fa-icon>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          (click)="openDirectionsForOptimizedOrders()">{{'routeSummary.directionsForOptimizedRoute' | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
