import { Injectable } from '@angular/core';
import getDistance from 'geolib/es/getDistance';
import computeDestinationPoint from 'geolib/es/computeDestinationPoint';

@Injectable({
    providedIn: 'root',
})
export class GeoUtils {

    /**
     * Method to get the distance between two coordinates in meters
     */
    public getDistance(from: { longitude: number; latitude: number } | { lng: number; lat: number }, to: { longitude: number; latitude: number } | { lng: number; lat: number }, accuracy?: number): number {
        return getDistance(from, to, accuracy);
    }

    /**
     * Method to compute the destination point given an initial point, a distance (in meters) and a bearing
     * (in degrees). If no radius is given it defaults to the mean earth radius of 6,371,000 meters.
     *
     * For more details please see https://github.com/manuelbieh/geolib#computedestinationpointpoint-distance-bearing-radius--earthradius
     */
    public computeDestinationPoint(start: { lng: number; lat: number }, distance: number, bearing: number, radius?: number): {
        latitude: number;
        longitude: number;
    } {
        return computeDestinationPoint(start, distance, bearing, radius);
    }

}
