<ion-header>
  <ion-toolbar>
    <ion-title><span translate="manifest.confirmation.page"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <ion-list-header>
    <ion-title class="ion-text-wrap"><span translate="manifest.confirmation.title"></span></ion-title>
  </ion-list-header>

  <ion-list>
    <ion-item class="ion-text-wrap">
      <span translate="manifest.confirmation.introduction"></span>&nbsp;:
    </ion-item>
    <ion-grid>
      <!--
            <ion-row>
              <ion-col col-10><span translate="manifest.all.label"></span></ion-col>
              <ion-col col-2>
                <tri-state-toggle [model]="allSelected" (modelChange)="selectAll($event)"></tri-state-toggle>
              </ion-col>
            </ion-row>
      -->
      <ng-container *ngFor="let item of manifestItems">
        <ion-row *ngIf="item.acceptedOnDelivery !== false">
          <ion-col size="10">
            <mobilus-manifest-item [item]="item"></mobilus-manifest-item>
          </ion-col>
          <ion-col size="2">
            <mobilus-tri-state-toggle [(model)]="item.acceptedOnDelivery"></mobilus-tri-state-toggle>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>

    <ion-grid class="ion-text-wrap" *ngIf="confirmationText">
      <ion-row>
        <ion-col size="2">
          <ion-checkbox [(ngModel)]="confirmed"></ion-checkbox>
        </ion-col>
        <ion-col size="10">
          <div class="confirmationWrapped" (click)="confirmed = !confirmed">{{ confirmationText.text }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-item-group *ngIf="withRejected">
      <ion-label><span translate="manifest.rejected.label"></span></ion-label>

      <ion-grid>
        <ng-container *ngFor="let item of manifestItems">
          <ion-row *ngIf="item.acceptedOnDelivery === false">
            <ion-col size="10">
              <mobilus-manifest-item [item]="item"></mobilus-manifest-item>
            </ion-col>
            <ion-col size="2">
              <mobilus-tri-state-toggle [(model)]="item.acceptedOnDelivery"></mobilus-tri-state-toggle>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-grid>
    </ion-item-group>

  </ion-list>
</ion-content>

<ion-footer class="actions-footer">
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row>
        <ion-col
          size="6"
          class="transitions-buttons-col">
          <ion-button
            expand="full"
            size="large"
            class="transition-button"
            fill="solid"
            color="secondary"
            (click)="dismiss()">
            <fa-icon [icon]="['fas', 'times']" style="margin-right: 0.5em"></fa-icon>
            <span class="transitions-buttons-text" translate="actions.cancel"></span>
          </ion-button>
        </ion-col>
        <ion-col
          size="6"
          class="transitions-buttons-col">
          <ion-button
            expand="full"
            size="large"
            fill="solid"
            class="transition-button"
            [disabled]="invalid"
            color="success"
            (click)="confirm()">
            <fa-icon [icon]="['fas', 'check']" style="margin-right: 0.5em"></fa-icon>
            <span class="transitions-buttons-text" translate="actions.save"></span>
          </ion-button >
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-footer>
