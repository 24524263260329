import {CheckableItem} from '@models/reports/checkable-item.model';
import * as moment from 'moment';

export class ReportingRequest {

  constructor() {
    this.extraForDistanceAbove = 0;
    this.excludeAppNumber = false;
    this.startDate = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
    this.endDate = moment().set({hour: 23, minute: 59, second: 0, millisecond: 0}).toISOString();
  }

  startDate: string = undefined;
  endDate: string = undefined;
  haulerEmployees: Array<CheckableItem> = [];

  providers: Array<CheckableItem> = [];

  excludeAppNumber = false;
  extraForDistanceAbove = 0;

  hasActiveProviders(): boolean {
    return this.providers.filter(provider => provider.checked).length > 0;
  }

  hasActiveHaulerEmployees(): boolean {
    return this.haulerEmployees.filter(employee => employee.checked).length > 0;
  }

  forPost() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      haulerEmployees: this.haulerEmployees.filter(item => item.checked).map(item => item.id),
      providers: this.providers.filter(item => item.checked).map(item => item.id),
      excludeAppNumber: this.excludeAppNumber,
      extraForDistanceAbove: this.extraForDistanceAbove
    };
  }
}
