<ion-content class="ion-padding">

  <img (click)="tap()" src="assets/images/{{ logoFile }}" class="logo" width="379" height="103">

  <form [formGroup]="loginForm" (submit)="login()" (keyup.enter)="login()">

    <ion-list class="login-form">
      <ion-item [hidden]="isConnected === undefined ||  isConnected === true" class="warning-message" lines="none">
        <h3>{{ 'pages.login.messages.notConnected' | translate }}</h3>
      </ion-item>

      <ion-item
        [class.invalid]="!loginForm.controls.username.valid && (loginForm.controls.username.dirty || submitAttempt)">
        <ion-input formControlName="username" required type="text" autocorrect="off" autocapitalize="none"
                   autocomplete="on"
                   placeholder="{{ 'pages.login.username' | translate }}"></ion-input>
      </ion-item>

      <ion-item *ngIf="!loginForm.controls.username.valid  && (loginForm.controls.username.dirty || submitAttempt)">
        <p color="danger">{{ 'pages.login.validation.username.required' | translate }}</p>
      </ion-item>

      <ion-item
        [class.invalid]="!loginForm.controls.password.valid && (loginForm.controls.password.dirty || submitAttempt)">
        <ion-input formControlName="password" [type]="type"
                   placeholder="{{ 'pages.login.password' | translate }}" clearOnEdit="false"></ion-input>
        <ion-button *ngIf="!showPass" fill="clear" type="button" item-right icon-only (click)="showPassword()">
          <fa-icon [icon]="['fal', 'eye-slash']"></fa-icon>
        </ion-button>
        <ion-button *ngIf="showPass" fill="clear" type="button" item-right icon-only (click)="showPassword()">
          <fa-icon [icon]="['fal', 'eye']"></fa-icon>
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-select label="{{ 'pages.settings.languages-label' | translate }}" label-placement="stacked" formControlName="language" (ionChange)="languageChange()" [value]="currentLang" >
          <ion-select-option value="fr">Français</ion-select-option>
          <ion-select-option value="en">English</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="!loginForm.controls.password.valid  && (loginForm.controls.password.dirty || submitAttempt)">
        <p>{{ 'pages.login.validation.password.required' | translate }}</p>
      </ion-item>

      <ion-item *ngIf="errorMessage" class="invalid error-message" lines="none">
        <div>{{ errorMessage }}</div>
      </ion-item>

      <ion-button color="secondary" (click)="login()" expand="block" class="login-button"
                  [disabled]="!loginForm.valid || loginInProgress || (deviceInfo?.networkStatus?.isConnected !== undefined && deviceInfo?.networkStatus?.isConnected === false)">
        <span translate="pages.login.connect"></span>
      </ion-button>

      <ion-button color="secondary" (click)="biometricLogin()" expand="block" class="login-button"
                  *ngIf="biometricAuthenticationAvailable && isUsingBiometricAuthentication"
                  [disabled]="loginInProgress || (deviceInfo?.networkStatus?.isConnected !== undefined && deviceInfo?.networkStatus?.isConnected === false)">

        <ion-icon slot="start" name="finger-print-outline"
                  *ngIf="biometricAuthenticationMethod === biometricMethodEnum.FINGERPRINT"></ion-icon>
        <span translate="pages.login.fingerprintLogin"
              *ngIf="biometricAuthenticationMethod === biometricMethodEnum.FINGERPRINT"></span>

        <ion-icon slot="start" name="camera-outline"
                  *ngIf="biometricAuthenticationMethod === biometricMethodEnum.FACE"></ion-icon>
        <span translate="pages.login.faceLogin" *ngIf="biometricAuthenticationMethod === biometricMethodEnum.FACE"></span>

        <ion-icon slot="start" name="key-outline"
                  *ngIf="biometricAuthenticationMethod === biometricMethodEnum.COMMON"></ion-icon>
        <span translate="pages.login.commonLogin"
              *ngIf="biometricAuthenticationMethod === biometricMethodEnum.COMMON"></span>

      </ion-button>

    </ion-list>

  </form>

  <ion-list>
    <ion-item *ngIf="deployStatus.newVersionAvailable" style="text-align: center">
      <ion-button block color="success" (click)="installNewVersion()">
        <fa-layers class="new-version-icon">
          <fa-icon [icon]="['fas', 'download']" size="sm" transform="up-8"></fa-icon>
        </fa-layers>
        <span translate="deploy.newVersionInstallLong"></span>
      </ion-button>
    </ion-item>

    <ion-item class="item-diagnostic">
      <ion-button slot="end" fill="outline" (click)="showDiagnostic()" class="no-margin ion-text-right">
        <span translate="pages.drawer-menu.diagnostic"></span>
      </ion-button>
    </ion-item>

    <ion-item *ngIf="policies" class="item-policies">
      <mobilus-usage-policies [policies]="policies" [userSettings]="userSettings"></mobilus-usage-policies>
    </ion-item>

    <ion-item *ngIf="endpointHint">
      <fa-icon [icon]="['fas', 'server']" slot="start"></fa-icon>
      <ion-label>{{ endpointHint }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border ion-padding">
  <div class="version">
    <span translate="pages.login.version"></span>&nbsp;{{ deviceInfo.unifiedVersion }}
  </div>
</ion-footer>
