import {Position} from '@models/business/position.model';
import {ProviderConfig} from '@models/business/provider-config.model';
import {Tag} from '@models/business/tag.model';
import {JsonObject, JsonProperty} from 'json2typescript';
import {Address} from "@models/business/address.model";

@JsonObject('Provider')
export class Provider {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('tags', [Tag])
  tags: Array<Tag> = [];

  @JsonProperty('position', Position, true)
  position: Position = undefined;

  @JsonProperty('configuration', ProviderConfig, true)
  configuration: ProviderConfig = undefined;

  @JsonProperty('address', Address, true)
  address: Address = undefined;

  @JsonProperty('phoneNumber', String, true)
  phoneNumber: string = undefined;

  selected: boolean;
  visible: boolean;

}
