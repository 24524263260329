import {Injectable} from '@angular/core';
import {NotificationData} from '@models/push-messages/notification-data.model';
import {DbService} from '@utils/abstract/db-service';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationStoreService extends DbService<NotificationData> {

  constructor(mobileContextService: MobileContextService,
              log: LogService,
              toastCtrl: ToastController,
              translateService: TranslateService
  ) {
    super('notification', NotificationData, mobileContextService, log, toastCtrl, translateService);
  }

  allDocs(): Observable<Array<NotificationData>> {
    return new Observable(subscriber => {
      this.getAll()
        .subscribe(notifications => {
          notifications.sort((a: NotificationData, b: NotificationData) => {
            if (!a || !b || !a.timestamp || !b.timestamp) {
              return 0;
            }
            if (b.timestamp.isAfter(a.timestamp)) {
              return 1;
            } else if (b.timestamp.isBefore(a.timestamp)) {
              return -1;
            } else {
              return 0;
            }
          });
          subscriber.next(notifications);
          subscriber.complete();
        });
    });
  }

  put(notification: NotificationData): Observable<NotificationData> {
    if (!notification._id) {
      notification._id = notification.uuid;
    }
    return super.put(notification);
  }

  remove(notification: NotificationData): Observable<NotificationData> {
    if (!notification._id) {
      notification._id = notification.uuid;
    }
    return super.remove(notification);
  }

}
