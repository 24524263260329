<ion-tabs>

  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="ready">
      <fa-icon class="tab-button-icon" [icon]="['fas','building']"></fa-icon>
      <ion-label class="tab-button-text" translate="workflows.status.ready_for_haulage_abv"></ion-label>
      <ion-badge class="badge-nb-order" tab-badge>{{readyForHaulageSize}}</ion-badge>
    </ion-tab-button>

    <ion-tab-button tab="back">
      <fa-icon class="tab-button-icon" [icon]="['fas','arrow-circle-left']"></fa-icon>
      <ion-label class="tab-button-text" translate="workflows.status.on_the_way_to_provider_abv"></ion-label>
      <ion-badge class="badge-nb-order tab-badge">{{onTheWayToProviderSize}}</ion-badge>
    </ion-tab-button>

    <ion-tab-button tab="delivery">
      <fa-icon class="tab-button-icon"  [icon]="['fas','car']"></fa-icon>
      <ion-label class="tab-button-text" translate="workflows.status.on_the_way_to_customer_abv"></ion-label>
      <ion-badge class="badge-nb-order tab-badge">{{onTheWayToCustomerSize}}</ion-badge>
    </ion-tab-button>

  </ion-tab-bar>

</ion-tabs>
