import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Versions')
export class Versions {

  @JsonProperty('minimumRequiredVersion', String, true)
  minimumRequiredVersion: string = undefined;

  @JsonProperty('currentVersion', String, true)
  currentVersion: string = undefined;

  @JsonProperty('isOutdated', Boolean, true)
  isOutdated: boolean = undefined;

}
