import {Order} from '@models/business/order.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {UserSettings} from '@models/settings/settings.model';
import {StatusSettings} from '@models/settings/status-settings.model';

export const DEFAULT_ITEM_HEIGHT = 42; // 54
export const APPROX_ITEM_HEIGHT = 69;
export const DEFAULT_HEADER_HEIGHT = 35;

export function orderViewHeight(view: OrderView, statusSettings: StatusSettings, userSettings: UserSettings, onIos: boolean): number {
  if (view) {
    let height = DEFAULT_ITEM_HEIGHT;
    const order: Order = view.order;
    if (order.notes || order.client.notes || order.tags || order.client.tags) {
      height += 12;
    }
    if (order.averageDeliveryMoment) {
      if (order.desiredArrivalTime !== 'ANY') {
        height += 16;
      } else {
        height += 13;
      }
    }
    if (statusSettings && statusSettings.sortOrder === 'distance' && order.distance) {
      if (order.desiredArrivalTime !== 'ANY') {
        height += 16;
      } else {
        height += 5;
      }
      if (onIos) {
        height += 3;
      }
    }

    switch (order.desiredArrivalTime) {
      case 'ANY':
        break;
      case 'AFTER':
        height += 5;
        break;
      case 'BEFORE':
        height += 5;
        break;
      case 'BETWEEN':
        height += 20;
        break;
    }

    if (userSettings && userSettings.displayType === false) {
      height += 20;
    }

    return height;
  } else {
    return 0;
  }
}

export function headerHeight(header): number {
  if (header) {
    return DEFAULT_HEADER_HEIGHT;
  } else {
    return 0;
  }
}
