export enum TimeFormatEnum {
  MILITARY,
  STANDARD,
}

export namespace TimeFormatEnum {
  export function getFormat(format: TimeFormatEnum) {
    switch (+format) {
      case TimeFormatEnum.MILITARY:
        return 'HH:mm';
      case TimeFormatEnum.STANDARD:
        return 'h:mm a';
      default:
        return 'HH:mm';
    }
  }
}
