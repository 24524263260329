import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ActionButtons')
export class ActionButtons {

  @JsonProperty('multipleSelection', Boolean, true)
  multipleSelection = true;

  @JsonProperty('map', Boolean, true)
  map = true;

  @JsonProperty('reordering', Boolean, true)
  reordering = true;

  @JsonProperty('optimization', Boolean, true)
  optimization = true;

  @JsonProperty('grouping', Boolean, true)
  grouping = true;

  @JsonProperty('sorting', Boolean, true)
  sorting = true;

  @JsonProperty('filter', Boolean, true)
  filter = true;
}
