import { Injectable } from '@angular/core';

// MODELS
import { DevicePlugin, DeviceInfo } from '@capacitor/device';

// NATIVE
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

@Injectable({
    providedIn: 'root'
})
export class DeviceUtils {

    public devicePlugin: DevicePlugin;

    private _deviceInfo: DeviceInfo;
    private _isRunningOnRealDevice: boolean;
    private _isRunningOnRealDeviceOrSimulator: boolean;

    constructor(
        private capacitorPlugins: CapacitorPlugins
    ) {
        this.devicePlugin = this.capacitorPlugins.getDevicePlugin();

        this._deviceInfo = null;
        this._isRunningOnRealDevice = null;
        this._isRunningOnRealDeviceOrSimulator = null;
    }

    public async getDeviceInfo(): Promise<DeviceInfo> {
        if (this._deviceInfo == null) {
            const deviceInfo: DeviceInfo = await this.devicePlugin.getInfo();
            this._deviceInfo = deviceInfo;
        }
        return this._deviceInfo;
    }

    public async isRunningOnRealDevice(): Promise<boolean> {
        if (this._isRunningOnRealDevice == null) {
            const deviceInfo: DeviceInfo = await this.getDeviceInfo();
            this._isRunningOnRealDevice = ((deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') && deviceInfo.isVirtual === false);
        }
        return this._isRunningOnRealDevice;
    }
    public async isRunningOnRealDeviceOrSimulator(): Promise<boolean> {
        if (this._isRunningOnRealDeviceOrSimulator == null) {
            const deviceInfo: DeviceInfo = await this.getDeviceInfo();
            this._isRunningOnRealDeviceOrSimulator = ((deviceInfo.platform === 'ios' || deviceInfo.platform === 'android'));
        }
        return this._isRunningOnRealDeviceOrSimulator;
    }

}
