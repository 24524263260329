import {MqttConfiguration} from '@models/configuration/mqtt-configuration.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('ProvidedConfiguration')
export class ProvidedConfiguration {

  @JsonProperty('mqtt', MqttConfiguration)
  mqtt: MqttConfiguration = undefined;

  @JsonProperty('geolocationEndpoint', String, true)
  geolocationEndpoint: string = undefined;

}
