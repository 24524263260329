import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('TransitionDetail')
export class TransitionDetail {

  @JsonProperty('withPayment', Boolean, true)
  withPayment?: boolean = undefined;

  @JsonProperty('withComment', Boolean, true)
  withComment?: boolean = undefined;

  @JsonProperty('withSignature', Boolean, true)
  withSignature?: boolean = undefined;

  @JsonProperty('withPicture', Boolean, true)
  withPicture?: boolean = undefined;

  @JsonProperty('withMaxPicture', Number, true)
  withMaxPicture?: number = undefined;

  @JsonProperty('withReason', Boolean, true)
  withReason?: boolean = undefined;

  // FIXME pour l'instant, withReason et withReasonChoicesOther sont incompatibles
  @JsonProperty('withReasonChoices', [String], true)
  withReasonChoices?: Array<string> = [];

  @JsonProperty('withReasonChoicesOther', [String], true)
  withReasonChoicesOther?: Array<string> = [];

  @JsonProperty('withHaulerChoice', Boolean, true)
  withHaulerChoice?: boolean = undefined;

  @JsonProperty('withDeliveryConstraints', Boolean, true)
  withDeliveryConstraints?: boolean = undefined;

  @JsonProperty('withValidateDistance', Boolean, true)
  withValidateDistance?: boolean = undefined;

  @JsonProperty('withDoorCode', Boolean, true)
  withDoorCode?: boolean = undefined;
}
