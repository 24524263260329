import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DeviceInfo} from '@models/information/device-info.model';
import {PushMessage} from '@models/push-messages/push-message.model';
import {ToastController} from '@ionic/angular';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {NotificationService} from '@services/notification-service/notification.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import {MQTTService} from '@services/push/mqtt.services';
import {PushServiceInterface} from '@services/push/push-interface.services';
import {RemoteManagementService} from '@services/remote-management/remote-management.service';
import {ReportingService} from '@services/reporting-service/reporting.service';
import {concat} from 'rxjs';
import {throwError} from 'rxjs';
import {Observable} from 'rxjs';
import {forkJoin} from 'rxjs';
import {of} from 'rxjs';
import {take} from 'rxjs/operators';
import {delay} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import {retryWhen} from 'rxjs/operators';
import {NotificationData} from '@models/push-messages/notification-data.model';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {ObjectDatabaseChange} from '@models/pouchdb/object-database-change-model';
import {Order} from '@models/business/order.model';

export const PUSH_SERVICE_CONFIG = {
  TOKEN_REGISTRATION_PATH: '/mobile/v2/registerGenericPushToken',
  TOKEN_UNREGISTRATION_PATH: '/mobile/v2/unregisterGenericPushToken'
};

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private orderSyncService: OrderSyncService;
  private pushServices: Array<PushServiceInterface> = [];

  private hasBeenInitialized = false;

  private pendingNotificationData: Array<NotificationData> = [];

  constructor(
    private platform: Platform,
    private toastCtrl: ToastController,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private endpointService: EndpointService,
    private log: LogService,
    private translateService: TranslateService,
    private mobileContextService: MobileContextService,
    private remoteManagementService: RemoteManagementService,
    private deviceInfo: DeviceInfo,
    private authenticationService: AuthenticationService,
    private ionicDeploy: IonicDeploy,
    private reportingService: ReportingService,
    private ga: MblsAnalyticsService,
    private orderStoreService: OrderStoreService,
  ) {
    this.remoteManagementService.injectServices(authenticationService, ionicDeploy, reportingService);


  }


  public init() {
    this.pushServices.forEach(it => {
      this.log.info('Subscribing to push register token from ' + it.serviceName);
      it.observableRegisterToken
        .subscribe(registerToken => {
          if (registerToken) {
            this.log.trace(`New push token received ${registerToken} for service ${registerToken.service}`);
            this.deviceInfo[registerToken.service] = registerToken.token;
            this.registerPushToken(registerToken.service, registerToken.token);
          }
        });
      it.observablePushMessage
        .subscribe((pushMessage: PushMessage) => {
          if (pushMessage) {
            this.processPushMessage(pushMessage);
          }
        });
      it.register();
    });

    this.orderStoreService.getLiveChanges(false, true, true, null).subscribe(orderChange => {
      // We use this to check new orders arrival in order to extract the client information for notifications
      this.manageLiveChanges(orderChange);
    });
  }

  public sendRefreshConnectedStatus() {
    this.pushServices.forEach(it => {
      try {
        it.sendRefreshConnectedStatus();
      } catch (e) {
        this.log.error(`Problem while sending connection status to ${it.serviceName}`, e);
      }
    });
  }

  logout(): Observable<any> {
    const promises = [];
    this.pushServices.forEach(it => {
      this.log.info(`Unregistering ${it.serviceName} from push`);
      promises.push(it.unregister());
    });
    return forkJoin(promises);
    // return Promise.all(promises.map(p => p.catch(() => undefined)));
  }

  // noinspection JSMethodCanBeStatic
  public processPushMessage(pushMessage: PushMessage) {
    if (pushMessage) {
      if (pushMessage.remoteManagementCommandData) {
        this.log.debug('Got remote management command : ', pushMessage.remoteManagementCommandData);
        this.remoteManagementService.executeCommand(pushMessage.remoteManagementCommandData);
      }
      if (pushMessage.orderData) {
        this.log.trace('Notification order.', pushMessage.orderData);
        this.orderSyncService.refreshOrder(pushMessage.orderData, pushMessage.timestamp)
          .subscribe({
            error: (error) => {
              this.log.debug('Error while refreshing order', error);
            }
          });
      }
      if (pushMessage.notificationData) {
        this.log.debug('User notification received.');
        // When a notification comes in we put it in the pending notification list until the order arrives
        // Then we get all the info from the order and send the notification with the client data
        this.orderStoreService.get(pushMessage.notificationData.orderId.toString()).subscribe({
            next: (order) => {
              pushMessage.notificationData = this.writeNotificationWithClientInfos(order, pushMessage.notificationData);
              this.notificationService.manage(pushMessage.notificationData);
            },
            error: (err) => {
              this.log.error('Error getting order notification from order store', err);
              this.pendingNotificationData.push(pushMessage.notificationData);
            },
          }
        );
      }
    }
  }

  // FIXME : this should be done when calling /checkin /context instead
  private registerPushToken(serviceName: string, token: string) {
    const url = this.endpointService.currentEndpoint + PUSH_SERVICE_CONFIG.TOKEN_REGISTRATION_PATH;
    return this.httpClient
      .post(url, {
        deviceUUID: this.deviceInfo.uuid,
        pushToken: token,
        pushService: serviceName
      }, {
        responseType: 'text'
      })
      .pipe(
        retryWhen(error => {
          return error.pipe(
            mergeMap((err: any) => {
              this.log.error('Got error when getting configuration ', JSON.stringify(err));
              if (err.status === 0 || err.status === 500) { // preventing StaleObjectStateException
                this.log.error('Retrying after a 0 or 500', err);
                return of(err.status).pipe(delay(2 * 1000));
              }
              this.log.warn('Not retrying (error status different than 0)');

              return throwError({error: 'No retry', status: err.status});
            }),
            take(15),
            o => concat(o, throwError({error: '5 retries exceeded when trying to get the configuration.'}))
          );
        })
      )
      .subscribe({
        error: () => {
          this.log.fatal('Unable to submit push token after 5 retries...');
          this.toastCtrl.create({
            message: this.translateService.instant('messages.errors.unable_to_post_push_token'),
            duration: 2 * 1000,
            cssClass: 'processing-toast'
          }).then(t => t.present());
        }
      });
  }

  injectOrderSyncService(param: OrderSyncService) {
    this.orderSyncService = param;
    this.platform.ready()
      .then(() => {
        const mqttService = new MQTTService(this.deviceInfo, this.mobileContextService, this.log, this.ga, this.orderSyncService);
        this.pushServices.push(mqttService);
        this.mobileContextService.userProfileObservable
          .subscribe(userProfile => {
            if (userProfile && !this.hasBeenInitialized) {
              this.init();
              this.hasBeenInitialized = true;
            } else if (!userProfile && this.hasBeenInitialized) {
              this.logout();
              this.hasBeenInitialized = false;
            }
          });
      });
  }


  private manageLiveChanges(orderChange: ObjectDatabaseChange<Order>) {
    const orderNotificationDataIndex =
      this.pendingNotificationData
        .findIndex((notificationData: NotificationData) => notificationData.orderId.toString() === orderChange.doc.id.toString());
    if (orderNotificationDataIndex > -1) {
      const order = orderChange.doc;
      const orderNotificationData = this.pendingNotificationData[orderNotificationDataIndex];

      const notificationWithClientInfo = this.writeNotificationWithClientInfos(order, orderNotificationData);

      this.notificationService.manage(notificationWithClientInfo);

      this.pendingNotificationData.splice(orderNotificationDataIndex, 1);
    }
  }

  private writeNotificationWithClientInfos(order: Order, notificationData: NotificationData): NotificationData {
    if (order && order.client && !notificationData.messageHasClientInfo) {
      const clientFullName = order.client.fullName();

      const notificationMessageClientInfos = this.translateService
        .instant('notifications.clientInfos', {
          clientFullName: clientFullName,
        });

      const notificationMessageFromServer = notificationData.message;

      notificationData.message = notificationMessageClientInfos + (notificationMessageFromServer || '');
      notificationData.messageHasClientInfo = true;
    }

    return notificationData;
  }
}
