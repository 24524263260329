import {Component} from '@angular/core';
import {Position} from '@models/business/position.model';
import {UserSettings} from '@models/settings/settings.model';
import {PositionEntryPage} from '@app/pages/admin/position-entry/position-entry.page';
import {Platform} from '@ionic/angular';
import {ToastController} from '@ionic/angular';
import {ActionSheetController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {OverlayEventDetail} from '@ionic/core';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {Features} from '@services/features/features.model';
import {FeaturesService} from '@services/features/features.service';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {ErrorsLoggingAndSessionRecordingService} from '@services/sentry-service/errors-logging-and-session-recording.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage {

  ionicInfo = {
    currentChannel: '',
    binaryVersion: null,
    channel: null,
    deployUuid: null
  };

  private isIos = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private features: Features = new Features();
  protected isCordova = true;
  private userSettings: UserSettings;
  logLevel: string;
  logRocketEnabled = false;
  currentPosition: Position;

  constructor(private alertCtrl: AlertController,
              private actionSheetCtrl: ActionSheetController,
              private toastCtrl: ToastController,
              private platform: Platform,
              private featuresService: FeaturesService,
              private ionicDeploy: IonicDeploy,
              public endpointService: EndpointService,
              private mobileContextService: MobileContextService,
              private modalCtrl: ModalController,
              private log: LogService,
              private sessionRecording: ErrorsLoggingAndSessionRecordingService,
              private backgroundGeolocationService: BackgroundGeolocationService,
  ) {
    this.ionicInfo.currentChannel = ionicDeploy.channel;
    this.platform.ready()
      .then(() => {
        this.isIos = this.platform.is('ios');
        this.isCordova = this.platform.is('cordova');
      });
  }

  ionViewWillEnter(): void {
    this.featuresService.featuresObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(features => this.features = features);

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(settings => {
        if (settings) {
          this.userSettings = settings;
          this.logLevel = settings.logLevel;
          this.logRocketEnabled = settings.logRocketEnabled;
        } else {
          this.userSettings = new UserSettings(true);
          this.logLevel = this.userSettings.logLevel;
        }
      });

    this.backgroundGeolocationService.positionObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(position => {
        this.currentPosition = position;
      });
  }

  ionViewDidLeave(): void {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async changePosition() {
    const modal = await this.modalCtrl.create({
      component: PositionEntryPage,
      componentProps: {
        position: this.currentPosition
      }
    });
    modal.onDidDismiss()
      .then((overlayEventDetail: OverlayEventDetail) => {
        if (overlayEventDetail && overlayEventDetail.data && overlayEventDetail.data.position) {
          this.backgroundGeolocationService.setFallbackPosition(overlayEventDetail.data.position);
        }
      });
    modal.present().then();
  }

  resolveLocalIP(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // This code will try to resolve the current local IP address
      try {
        // @ts-ignore
        window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;   // compatibility for firefox and chrome
        const pc: any = new RTCPeerConnection({iceServers: []}), noop = function() {
        };
        pc.createDataChannel('');    // create a bogus data channel
        pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
        pc.onicecandidate = function(ice) {  // listen for candidate events
          if (!ice || !ice.candidate || !ice.candidate.candidate) {
            return;
          }
          const ipArray = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
          if(ipArray){
            const myIP = ipArray[1];
            pc.onicecandidate = noop;
            resolve(myIP);
          } else {
            resolve(null);
          }
        };
      } catch (error) {
        this.log.error('Unable to lookup private IP address.');
        resolve(null);
      }
    });
  }

  customEndpoint() {
    if (this.isIos) {
      this.presentCustomEndpointAlert('');
    } else {
      this.resolveLocalIP()
        .then(ip => {
          let url = '';
          if (ip) {
            url = 'http://' + ip + ':8080/mobilus-webapp';
          }
          this.presentCustomEndpointAlert(url);
        })
        .catch(error => this.presentCustomEndpointAlert(this.endpointService.currentEndpoint));
    }
  }

  async selectChannel() {
    const actionSheet = await this.actionSheetCtrl
      .create({
        header: 'Select deploy channel testing',
        buttons: [
          {
            text: 'Testing',
            handler: () => {
              this.changeDeployChannel('Testing');
            }
          },
          {
            text: 'Master',
            handler: () => {
              this.changeDeployChannel('Master');
            }
          },
          {
            text: 'Beta',
            handler: () => {
              this.changeDeployChannel('Beta');
            }
          },
          {
            text: 'Capacitor',
            handler: () => {
              this.changeDeployChannel('capacitor');
            }
          },
          {
            text: 'Red',
            handler: () => {
              this.changeDeployChannel('red');
            }
          },
          {
            text: 'Internal',
            handler: () => {
              this.changeDeployChannel('Internal');
            }
          },
          {
            text: 'Blue',
            handler: () => {
              this.changeDeployChannel('Blue');
            }
          },
          {
            text: 'Green',
            handler: () => {
              this.changeDeployChannel('Green');
            }
          },
          {
            text: 'Yellow',
            handler: () => {
              this.changeDeployChannel('Yellow');
            }
          },
          {
            text: 'Purple',
            handler: () => {
              this.changeDeployChannel('Purple');
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
    await actionSheet.present();
  }

  async selectEndpoint() {
    const actionSheet = await this.actionSheetCtrl
      .create({
        header: 'Select endpoint',
        buttons: [
          {
            text: 'Production',
            handler: () => {
              this.endpointService.setEndpointToProduction();
            }
          },
          {
            text: 'Staging',
            handler: () => {
              this.endpointService.setEndpointToStaging();
            }
          },
          {
            text: 'QA',
            handler: () => {
              this.endpointService.setEndpointToQa();
            }
          },
          {
            text: 'Testing',
            handler: () => {
              this.endpointService.setEndpointToTesting();
            }
          },
          {
            text: 'Local dev',
            handler: () => {
              this.endpointService.setEndpointToLocalDev();
            }
          },
          {
            text: 'Emulator',
            handler: () => {
              this.endpointService.setEndpointToEmulatorLocal();
            }
          },
          {
            text: 'Dev PM',
            handler: () => {
              this.endpointService.setEndpointToDevPaul();
            }
          },
          {
            text: 'Dev PT',
            handler: () => {
              this.endpointService.setEndpointToDevPatrick();
            }
          },
          {
            text: 'Custom',
            handler: () => {
              this.customEndpoint();
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
    await actionSheet.present();
  }

  async selectLogLevel() {
    const actionSheet = await this.actionSheetCtrl
      .create({
        header: 'Select Log Level (' + this.logLevel + ')',
        buttons: [
          {
            text: 'Trace',
            handler: () => {
              this.changeLogLevel('TRA');
            }
          },
          {
            text: 'Debug',
            handler: () => {
              this.changeLogLevel('DBG');
            }
          },
          {
            text: 'Info',
            handler: () => {
              this.changeLogLevel('INF');
            }
          },
          {
            text: 'Warn',
            handler: () => {
              this.changeLogLevel('WRN');
            }
          },
          {
            text: 'Error',
            handler: () => {
              this.changeLogLevel('ERR');
            }
          },
          {
            text: 'Fatal',
            handler: () => {
              this.changeLogLevel('FTL');
            }
          },
          {
            text: 'Off',
            handler: () => {
              this.changeLogLevel('OFF');
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
    await actionSheet.present();
  }

  private async presentCustomEndpointAlert(endpoint: string) {
    const prompt = await this.alertCtrl.create({
      header: 'Endpoint',
      inputs: [
        {
          name: 'url',
          placeholder: 'URL',
          value: endpoint
        },
      ],
      buttons: [
        {
          text: 'Cancel'
        },
        {
          text: 'Save',
          handler: data => {
            this.endpointService.currentEndpoint = data.url;
          }
        }
      ]
    });
    await prompt.present();
  }

  private changeDeployChannel(channelName: string) {
    this.log.info('changing to channel', channelName);
    if (this.isCordova) {
      this.ionicDeploy.forceChannelForUser(channelName);
      this.ionicInfo.currentChannel = this.ionicDeploy.channel;
    }
  }

  private changeLogLevel(level: string) {
    this.userSettings.logLevel = level;
    this.mobileContextService.settingsHaveChanged(true);
  }

  changedLogRocket() {
    this.sessionRecording.setSendLogRocketData(this.logRocketEnabled);
    this.userSettings.logRocketEnabled = this.logRocketEnabled;
    this.mobileContextService.settingsHaveChanged(true);
  }

  checkInfo() {
    if (this.isCordova) {
      this.ionicDeploy.info()
        .then(async (value: any) => {
          this.log.info('Ionic deploy info is', value);
          this.ionicInfo.binaryVersion = value.binaryVersion;
          this.ionicInfo.channel = value.channel;
          this.ionicInfo.deployUuid = value.currentVersionId;
          const toast = await this.toastCtrl.create({
            message: 'Current build ' + JSON.stringify(value, null, 2),
            position: 'bottom',
            duration: 15 * 1000,
            cssClass: 'processing-toast'
          });
          await toast.present();
        });
    }
  }

  updatedFeatures() {
    this.featuresService.featureChanged();
  }
}
