import {OnDestroy} from '@angular/core';
import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {OrdersAccessor} from '@models/order-helper/orders-accessor.model';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {OrderService} from '@services/order-service/order.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const CONFIG = {
  MAIN_MENU_ID: 'MAIN_MENU',
  GA: {
    PAGE_NAME: 'StatusTabs',
  },
};

@Component({
  templateUrl: './status-tabs.page.html',
  styleUrls: ['./status-tabs.page.scss'],
})
export class StatusTabs implements OnInit, OnDestroy {
  readyForHaulageSize = '0';
  onTheWayToProviderSize = '0';
  onTheWayToCustomerSize = '0';

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private orderService: OrderService,
              private log: LogService,
              private ga: MblsAnalyticsService,
  ) {

  }

  ngOnInit() {
    this.orderService.ordersAccessor
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((accessor: OrdersAccessor) => {
        this.badgeNumbers(accessor);
      });
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private badgeNumbers(accessor: OrdersAccessor) {
    let listForStatus = accessor.getList(OrderStatus.READY_FOR_HAULAGE);
    this.readyForHaulageSize = listForStatus ? listForStatus.size + '' : '0';

    listForStatus = accessor.getList(OrderStatus.ON_THE_WAY_TO_PROVIDER);
    this.onTheWayToProviderSize = listForStatus ? listForStatus.size + '' : '0';

    listForStatus = accessor.getList(OrderStatus.ON_THE_WAY_TO_CUSTOMER);
    this.onTheWayToCustomerSize = listForStatus ? listForStatus.size + '' : '0';
  }
}
