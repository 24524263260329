import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocationUtils {

    constructor() { }

    /**
     * Method to round and truncate the latitude or longitude to have required decimal precision (number of digits after decimal point)
     * Note: see https://stackoverflow.com/a/11832950/5151355
     *
     * @param {number} val latitude or longitude value
     * @param {number} decimalPrecision number of significant digits after decimal point to round to
     */
    public formatLatLng(val: number, decimalPrecision: number = 6): number {
        if (val != null && !isNaN(val)) {
            const roundingValue = Math.pow(10, decimalPrecision);
            const truncated = Math.round((val + Number.EPSILON) * roundingValue) / roundingValue;
            return truncated;
        }
    }

}
