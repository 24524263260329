<ion-content class="ion-padding">
  <ul class="timeline" *ngFor="let trace of order.traceabilities">
    <li>
      <div class="timeline-thumb bg-color-blue timeline-icon">
        <fa-icon [icon]="['far', getIcon(trace)]"></fa-icon>
      </div>
      <div class="timeline-item">
        <h4>
          {{ 'workflows.status.' + trace.targetState.toLowerCase() | translate}}
          <span
            class="operator-code">{{ trace.operatorCode || trace.actor.firstname + ' ' + trace.actor.lastname }}</span>
        </h4>
        <p class="timeline-stats">{{trace.dateTime | DatePipe:userSettings.hourFormat: userSettings.dateFormat:dateTimeType:false }}</p>
        <p class="message">{{ trace.message}}</p>
      </div>
    </li>
  </ul>
</ion-content>
