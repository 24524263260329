import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('SimInfoCheckin')
export class SimInfoCheckin {

  @JsonProperty('phoneNumber', String, true)
  phoneNumber: string = undefined;

  @JsonProperty('carrierName', String, true)
  carrierName: string = undefined;

  @JsonProperty('countryCode', String, true)
  countryCode: string = undefined;

  @JsonProperty('networkType', String, true)
  networkType: string = undefined;

}
