import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('ConnectionStatus')
export class ConnectionStatus {

  @JsonProperty('emitted', MomentDateConverter, true)
  emitted: moment.Moment = moment();

  @JsonProperty('isAuthenticated', Boolean, true)
  isAuthenticated = false;

  @JsonProperty('isNetworkConnected', Boolean, true)
  isNetworkConnected = true;

  @JsonProperty('isMqttConnected', Boolean, true)
  isMqttConnected = true;

  @JsonProperty('isMqttReconnecting', Boolean, true)
  isMqttReconnecting = false;

  @JsonProperty('isMqttConnectedForUser', Boolean, true)
  isMqttConnectedForUser = true;

  @JsonProperty('isNetworkConnectedForUser', Boolean, true)
  isNetworkConnectedForUser = true;

  isSameForUserAs(connectionStatus: ConnectionStatus) {
    return connectionStatus &&
      this.isMqttConnectedForUser === connectionStatus.isMqttConnectedForUser &&
      this.isNetworkConnectedForUser === connectionStatus.isNetworkConnectedForUser;
  }

}
