import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Policies} from '@models/check-in/policies.model';

@Component({
  templateUrl: './location-disclosure.page.html',
  styleUrls: ['./location-disclosure.page.scss'],
})
export class LocationDisclosurePage {

  stopShowing: boolean = false;
  policies: Policies;

  constructor(private modalCtrl: ModalController,
  ) {
  }

  close() {
    this.modalCtrl.dismiss({disable: this.stopShowing});
  }

  dismiss() {
    this.modalCtrl.dismiss({disable: false});
  }

}
