import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('LockerDataConfiguration')
export class LockerDataConfiguration {

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('communicationMode', String, true)
  communicationMode: string = undefined;

  @JsonProperty('integrationType', String, true)
  integrationType: string = undefined;
}
