import {Component} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import * as moment from 'moment';
import {Hauler} from '@models/business/hauler.model';
import {ReportingRequest} from '@models/reports/reporting-request.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {HaulerEmployeeProvider} from '@services/hauler-employee/hauler-employee-provider.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {ReportsService} from '@services/reports-service/reports.service';
import {Subject} from 'rxjs';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DateFormatEnum} from '@models/units-and-format/date-format.enum';
import {CheckableItem} from '@models/reports/checkable-item.model';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {NavigationExtras, Router} from '@angular/router';
import {DateTimeCombinationEnum} from '@models/units-and-format/date-format.enum';

const CONFIG = {
  ACCOUNTING_REPORT: 'accounting-reports',
  TRIPS_REPORT: 'trips-reports',
  ACCOUNTING_REPORT_PAGE: 'accounting-reports',
  TRIPS_REPORT_PAGE: 'trips-report',
  GA: {
    PAGE_NAME: 'ReportsFormsPage',
    EVENT: {
      REQUESTING_ACCOUNTING_REPORT: {
        NAME: 'accounting-report',
        ACTION: 'accounting-report_requested'
      },
      REQUESTING_TRIPS_REPORT: {
        NAME: 'trips-report',
        ACTION: 'trips-report_requested'
      }
    }
  }
};

@Component({
  templateUrl: './reports-forms.page.html',
  styleUrls: ['./reports-forms.page.scss'],
})
export class ReportsFormsPage {
  public reportRequest: ReportingRequest = new ReportingRequest();
  public reportTypes: Array<string> = [CONFIG.ACCOUNTING_REPORT];
  public reportTypeActive: string = CONFIG.ACCOUNTING_REPORT;
  public rootPage: any = ReportsFormsPage;
  public hauler: Hauler;
  public user: UserProfile;
  public isDateTimeDisabled = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private loading: any;
  userSettings: UserSettings = new UserSettings();
  dateFormatDMY: DateFormatEnum = DateFormatEnum.dateDMY;
  dateFormatMDY: DateFormatEnum = DateFormatEnum.dateMDY;
  dateFormatISO: DateFormatEnum = DateFormatEnum.dateISO;
  allCourierChecked = true;
  noneChecked = false;
  oneCourierChecked = true;
  includeTagsReport = false;

  public DateTimeCombinationEnum = DateTimeCombinationEnum;

  constructor(public navCtrl: NavController,
              public router: Router,
              private mobileContextService: MobileContextService,
              private reportsService: ReportsService,
              private haulerEmployeeProvider: HaulerEmployeeProvider,
              private loadingCtrl: LoadingController,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private translate: TranslateService, ) {
  }

  ionViewWillEnter() {
    combineLatest(this.mobileContextService.haulerObservable,
      this.mobileContextService.userProfileObservable,
      this.haulerEmployeeProvider.getAvailableEmployees(true),
      this.mobileContextService.userSettingsObservable)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(([hauler, user, employees, userSettings]) => {
        if (hauler && user && employees) {
          this.hauler = hauler;
          this.user = user;
          this.userSettings = userSettings;
          this.reportRequest.providers = this.hauler.linkedProviders.map(provider => new CheckableItem(provider.id, provider.name));
          this.reportRequest.haulerEmployees = employees.map(employee => new CheckableItem(employee.id, employee.fullName));
          const isHaulerManager = this.user.isHaulerEmployeeManager();
          if (!isHaulerManager) {
            this.reportRequest.haulerEmployees = this.reportRequest.haulerEmployees.filter(item => item.id === this.user.haulerEmployeeId);
          }
          this.isDateTimeDisabled = !isHaulerManager;
          this.reportTypes = [CONFIG.ACCOUNTING_REPORT, CONFIG.TRIPS_REPORT];
        }
      });
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  public onDateChange(value: string | string[]) {
    let dateTime: string;
    if (Array.isArray(value)) {
      this.log.error('[ReportsFormsPage] onDateChange - multiple selection is not supported in this component', value);
      if (value[0]) {
        dateTime = value[0];
      } else {
        dateTime = undefined;
      }
    } else {
      dateTime = value;
    }

    this.reportRequest.startDate = moment(dateTime).startOf('d').toISOString();
    this.reportRequest.endDate = moment(dateTime).endOf('d').toISOString();
  }

  onGenerateReport() {
    const gaEvent = CONFIG.ACCOUNTING_REPORT ? 'REQUESTING_ACCOUNTING_REPORT' : 'REQUESTING_TRIPS_REPORT';
    this.ga.trackEvent(CONFIG.GA.EVENT[gaEvent].NAME, CONFIG.GA.EVENT[gaEvent].ACTION).catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT[gaEvent]} with GA`, error));
    this.loading = this.loadingCtrl.create().then(async (res) => {
      await res.present();
      const observable = this.reportTypeActive === CONFIG.ACCOUNTING_REPORT ?
        this.reportsService.getAccountingReport(this.reportRequest) :
        this.reportsService.getTripsReport(this.reportRequest);
      const page = this.reportTypeActive === CONFIG.ACCOUNTING_REPORT ? CONFIG.ACCOUNTING_REPORT_PAGE : CONFIG.TRIPS_REPORT_PAGE;
      observable.subscribe(
        report => {
          res.dismiss();
          const navigationExtras: NavigationExtras = {
            state: {
              report: report,
              reportType: this.reportTypeActive,
              isHaulerManager: this.user.isHaulerEmployeeManager(),
              includeTags: this.includeTagsReport
            }
          };
          this.router.navigate([page], navigationExtras);
        }, error => {
          this.log.error('Problem with reports', error);
          res.dismiss();
        });
    });


  }

  translateDistanceText(key): string {
    if (this.userSettings.distanceUnit === DistanceSystemEnum.SI) {
      return this.translate.instant(key, {unit: this.translate.instant('distanceUnits.km')});
    } else {
      return this.translate.instant(key, {unit: this.translate.instant('distanceUnits.miles')});
    }
  }

  manageCourierCheckboxes(event: boolean) {
    this.allCourierChecked = event;
    this.oneCourierChecked = event;
    this.noneChecked = !event;
    if (this.reportRequest) {
      for (const employee of this.reportRequest.haulerEmployees) {
        employee.checked = this.allCourierChecked;
      }
    }
  }

}
