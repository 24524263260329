import {DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {DatePipe} from './date-pipe/date.pipe';
import {DistanceUnitPipe} from './distance/distance-unit.pipe';
import {DistancePipe} from './distance/distance.pipe';
import {MobilusDesiredArrivalTimePipe} from './distance/mobilus-distance.pipe';
import {DurationPipe} from './duration/duration.pipe';
import {PhonePipe} from './phone/phone.pipe';
import {TimePipe} from './time/time.pipe';
import { OrderByPipe } from './order-by/order-by';

@NgModule({
  declarations: [
    PhonePipe,
    DurationPipe,
    DistancePipe,
    DatePipe,
    TimePipe,
    MobilusDesiredArrivalTimePipe,
    DistanceUnitPipe,
    OrderByPipe
  ],
  imports: [],
  exports: [
    PhonePipe,
    DurationPipe,
    DistancePipe,
    DatePipe,
    TimePipe,
    MobilusDesiredArrivalTimePipe,
    DistanceUnitPipe,
    OrderByPipe
  ],
  providers: [
    DecimalPipe,
  ]
})
export class PipesModule {
}
