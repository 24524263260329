import {Injectable, Injector} from '@angular/core';
import {UserProfile} from '@models/user-profile.model';
import {classToPlain, plainToClass} from '@utils/json-converter/json-converter';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private userProfile: UserProfile;

  private mobileContextService: MobileContextService;

  constructor(
    private capacitorPlugins: CapacitorPlugins,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.mobileContextService = this.injector.get(MobileContextService);
      this.mobileContextService.userProfileObservable
        .subscribe((userProfile: UserProfile) => {
          this.userProfile = userProfile;
        });
    });
  }

  public removeObject(key: string, userBound: boolean = true): Promise<any> {
    if (this.userProfile && userBound) {
      return this.capacitorPlugins.getPreferencesPlugin().remove({key: this.buildKey(key)});
    } else if (!this.userProfile && userBound) {
      return Promise.reject('No user profile set.');
    } else {
      return this.capacitorPlugins.getPreferencesPlugin().remove({key: key});
    }
  }

  public persistObject(key: string, object: any, userBound: boolean = true): Promise<any> {
    return this.persist(key, JSON.stringify(classToPlain(object)), userBound);
  }

  public loadObject(key: string, clasz: any, userBound: boolean = true): Promise<any> {
    return this.load(key, userBound)
      .then((objectString: string) => {
        if (objectString) {
          const objectJson = JSON.parse(objectString);
          return plainToClass(clasz, objectJson);
        } else {
          throw new Error(`Object for key ${key} not found.`);
        }
      });
  }

  private buildKey(key: string): string {
    return this.userProfile.id.toString() + '_' + key;
  }

  public persistString(key: string, value: string, userBound: boolean = true) {
    return this.persist(key, value, userBound);
  }

  public loadString(key: string, userBound: boolean = true): Promise<string> {
    return this.load(key, userBound);
  }

  private load(key: string, userBound: boolean): Promise<string> {
    if (this.userProfile && userBound) {
      const storageKey = this.buildKey(key);
      return this.capacitorPlugins.getPreferencesPlugin().get({key: storageKey}).then(value => value.value);
    } else if (!this.userProfile && userBound) {
      return Promise.reject('No user profile set.');
    } else {
      return this.capacitorPlugins.getPreferencesPlugin().get({key: key}).then(value => value.value);
    }
  }

  private persist(key: string, value: string, userBound: boolean): Promise<void> {
    if (this.userProfile && userBound) {
      const storageKey = this.buildKey(key);
      return this.capacitorPlugins.getPreferencesPlugin().set({key: storageKey, value});
    } else if (!this.userProfile && userBound) {
      return Promise.reject('No user profile set.');
    } else {
      return this.capacitorPlugins.getPreferencesPlugin().set({key: key, value});
    }
  }

}
