import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';

@Component({
  selector: 'mobilus-offline-indicator',
  templateUrl: './offline-indicator.component.html',
  styleUrls: ['./offline-indicator.component.scss'],
})
export class OfflineIndicatorComponent {

  @Input() content = null;

  connected: boolean;

  constructor(private alertCtrl: AlertController,
              private translate: TranslateService,
              private connectionStatusService: ConnectionStatusService) {
    this.connectionStatusService.connectionStatusSubscription
      .subscribe(status => {
        this.connected = status.isNetworkConnectedForUser;
        if (this.content) {
          this.content.resize();
        }
      });
  }

  async helpInfo() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('pages.offline-info.title'),
      subHeader: this.translate.instant('pages.offline-info.details'),
      buttons: [this.translate.instant('actions.ok')]
    });
    await alert.present();
  }

}
