import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {UntypedFormBuilder} from '@angular/forms';
import {UntypedFormGroup} from '@angular/forms';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {SignaturePinEnum} from '@models/settings/signature-pin.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {SettingsManager} from '@services/settings-providers/settings.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './signature-pin-modal.page.html',
  styleUrls: ['./signature-pin-modal.page.scss'],
})
export class SignaturePinModalPage {
  private unsubscribe: Subject<void> = new Subject<void>();

  public title = 'Pin';
  public header: string;
  public pinForm: UntypedFormGroup;
  public confirmAttempt = false;
  public showPin = false;
  public type: SignaturePinEnum;
  public pinValue = '';
  public checkPinValue = '';
  public repeatPinValue = '';
  public pinPlaceHolder = '';
  public signaturePinValue = '';

  public editPin: SignaturePinEnum = SignaturePinEnum.editPin;
  public enterPin: SignaturePinEnum = SignaturePinEnum.enterPin;
  public createPin: SignaturePinEnum = SignaturePinEnum.createPin;

  constructor(private modalCtrl: ModalController,
              public navParams: NavParams,
              private formBuilder: UntypedFormBuilder,) {
    this.preparePinForm();

    this.type = this.navParams.get('type');
    this.signaturePinValue = this.navParams.get('signaturePinValue');

    if (this.type === SignaturePinEnum.editPin) {
      this.header = 'pages.settings.editPin';
      this.pinPlaceHolder = 'pages.settings.enterNewPin';
    } else {
      this.header = 'pages.settings.enterPin';
      this.pinPlaceHolder = 'pages.settings.enterPin';
    }
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  preparePinForm() {
    this.pinForm = this.formBuilder.group(
      {
        pin: ['', [Validators.required, Validators.minLength(3)]],
        repeatPin: ['', [Validators.required, Validators.minLength(3)]]
      }
    );
  }

  async confirm() {
    this.confirmAttempt = true;
    if (this.pinForm.get('pin').value === this.pinForm.get('repeatPin').value) {
      await this.modalCtrl.dismiss({
        ok: true,
        signaturePinValue: this.pinForm.get('pin').value.toString()
      });
    }
  }


  checkPin() {
    if (this.checkPinValue.toString() === this.signaturePinValue.toString()) {
      this.modalCtrl.dismiss({ok: true});
    }
  }

  async dismiss() {
    if (this.type === SignaturePinEnum.editPin) {
      await this.modalCtrl.dismiss({
        ok: true,
        signaturePinValue: this.signaturePinValue
      });
    } else {
      await this.modalCtrl.dismiss({
        ok: false,
        signaturePinValue: ''
      });
    }
  }

  showPinField() {
    this.showPin = !this.showPin;
  }


}
