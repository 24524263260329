
import { Component, Input, OnDestroy, OnInit, NgZone } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { filter, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

// MODELS
import { AppError } from '@models/base/base';
import { ConnectionStatus } from '@models/information/connection-status.model';
import { Position } from '@models/business/position.model';

// PROVIDERS
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { MapService } from '@services/map/map.service';
import { Loading } from '@services/loading/loading';
import { LocationUtils } from '@services/utils/location-utils';

// VALIDATORS
import { CoordinatesValidator } from '@app/shared/validators/coordinates-validator/coordinates-validator';

@Component({
  templateUrl: './position-entry.page.html',
  styleUrls: ['./position-entry.page.scss']
})
export class PositionEntryPage implements OnDestroy, OnInit {

  @Input() position: Position;
  public zoom: number = 14;
  public writing: boolean = false;
  public address: string;

  private _connected = false;
  public get connected() {
    return this._connected;
  }
  public set connected(isOnline: boolean) {
    if (isOnline !== this._connected) {
      this.ngZone.run(async () => {
        this._connected = isOnline;
        this.refreshMapView();
      });
    }
  }

  public map: google.maps.Map;
  public mapLoadFailed: boolean;
  public markers: Array<google.maps.Marker>;

  public form: UntypedFormGroup;

  public componentDestroyed: Subject<void> = new Subject<void>();

  constructor(
    private connectionStatusService: ConnectionStatusService,
    private formBuilder: UntypedFormBuilder,
    private mapService: MapService,
    private modalCtrl: ModalController,
    private loading: Loading,
    private locationUtils: LocationUtils,
    private ngZone: NgZone
  ) {

    this._connected = this.connectionStatusService.getLastKnownConnectionStatus().isNetworkConnected;

    this.map = null;
    this.mapLoadFailed = null;
    this.markers = [];

    this.form = null;
  }

  public async ngOnInit(): Promise<void> {
    if (!this.position) {
      // NOTE: fallback position
      this.position = new Position(45.400529, -71.896220); // Espace-inc Sherbrooke
    }
    
    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        distinctUntilChanged((prev, curr) => prev.isNetworkConnected === curr.isNetworkConnected),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((status: ConnectionStatus) => {
        this.connected = status.isNetworkConnected;
      });

    this.form = this.formBuilder.group({
      latitude: [this.position.latitude, Validators.compose([CoordinatesValidator.isLatitudeValid])],
      longitude: [this.position.longitude, Validators.compose([CoordinatesValidator.isLongitudeValid])],
      address: [null]
    });


    this.form.controls.latitude.valueChanges
      .pipe(
        debounceTime(1500),
        distinctUntilChanged(),
        filter(_ => this.form.controls.latitude.valid === true),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(async (val: number) => {
        this.position.latitude = val;
        await this.refreshMapView();
      });
    this.form.controls.longitude.valueChanges
      .pipe(
        debounceTime(1500),
        distinctUntilChanged(),
        filter(_ => this.form.controls.longitude.valid === true),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(async (val: number) => {
        this.position.longitude = val;
        await this.refreshMapView();
      });

    await this.initMapView();

  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public save(): void {
    if (this.form.valid) {
      this.modalCtrl.dismiss({ position: this.position });
    }
  }

  private mapDblClick(event: any): void {
    const positionDoubleclick: google.maps.LatLng = event.latLng;
    const position = new Position(this.locationUtils.formatLatLng(positionDoubleclick.lat()), this.locationUtils.formatLatLng(positionDoubleclick.lng()));
    position.dateEmitted = moment();
    this.position = position;
    this.form.controls.latitude.setValue(this.position.latitude, { emitEvent: false });
    this.form.controls.latitude.markAsDirty();
    this.form.controls.latitude.markAsTouched();
    this.form.controls.longitude.setValue(this.position.longitude, { emitEvent: false });
    this.form.controls.longitude.markAsDirty();
    this.form.controls.longitude.markAsTouched();
    this.refreshMapView();
  }

  /**
   * Main method to initialize/load map view (load google api lib) and initialize map.
   * If map view load/initialization failed the app will show a dialog prompting to retry
   */
  public async initMapView(): Promise<void> {
    // (1) check if we are online (note, google maps SDK does not support offline mode since caching tiles is not supported)
    if (this.connected === true) {
      await this.loading.present();
      try {
        // (2) make sure google maps API and libs are loaded
        try {
          await this.mapService.makeSureGoogleMapsAPILoaded();
          this.mapLoadFailed = false;
        } catch (error) {
          this.mapLoadFailed = true;
          throw error;
        }
        // (3) create map (if has not been created before); It is safe to use this.map in the subsequent method invokations
        if (!this.map) {
          this.map = this.initializeMap();
        }
        // (4) initialize autocomplete feature
        this.initializeAutocomplete();
        // (5) delete all markers and create/populate them again (in array and NOT on the map!)
        this.populateMarkers(this.position, this.markers);
        this.mapService.showTicketMarkers(this.map, this.markers);
        await this.loading.dismiss();
      } catch (error) {
        await this.loading.dismiss();
        if (error && error instanceof AppError) {
          const appError = error as AppError;
          if (!appError.handled) {
            if (appError.message) {
              await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this), appError.message);
            } else {
              await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this));
            }
          }
        } else {
          await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this));
        }
      }
    } else {
      // NOTE: just ignore this. we will re-draw the map when the connectivity is restored
    }
  }
  /**
   * Method to initialize the google map
   */
  public initializeMap(): google.maps.Map {
    const options: google.maps.MapOptions = {
      zoom: this.zoom,
      streetViewControl: true,
      panControl: true,
      fullscreenControl: false,
      center: {
        lat: this.position.latitude,
        lng: this.position.longitude
      }
    };
    const map = new google.maps.Map(document.getElementById('position_entry_page_map_canvas'), options);
    // NOTE: double click event
    map.addListener('dblclick', (event: any) => {
      this.mapDblClick(event);
    });
    return map;
  }

  /**
   * Method to set up/enable autocomplete feature
   */
  public initializeAutocomplete(): void {
    const circle = new google.maps.Circle({
      center: { lat: this.position.latitude, lng: this.position.longitude },
      radius: 50 * 1000
    });
    const nativeInputBox = document.getElementById('address').getElementsByTagName('input')[0];
    const autocomplete = new google.maps.places.Autocomplete(nativeInputBox, {
      bounds: circle.getBounds(),
      types: ['address']
    });
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }

      const newPosition = new Position(this.locationUtils.formatLatLng(place.geometry.location.lat()), this.locationUtils.formatLatLng(place.geometry.location.lng()));
      newPosition.dateEmitted = moment();
      this.position = newPosition;
      this.form.controls.latitude.setValue(this.position.latitude, { emitEvent: false });
      this.form.controls.latitude.markAsDirty();
      this.form.controls.latitude.markAsTouched();
      this.form.controls.longitude.setValue(this.position.longitude, { emitEvent: false });
      this.form.controls.longitude.markAsDirty();
      this.form.controls.longitude.markAsTouched();
      this.address = place.formatted_address;
      this.zoom = 14;
      this.refreshMapView();
    });
  }
  /**
   * Method to create marker objects based on the loaded providers, orders and car position and add them to the array (not on the map!)
   */
  public populateMarkers(position: Position, markers: Array<google.maps.Marker>): void {

    // NOTE: deletes all markers from the map and also remove all markers from the markers array
    this.mapService.deleteAllMarkers(markers);

    // NOTE: add the markers for the current user position
    if (position && position.latitude && position.longitude) {
      const coordinates: google.maps.LatLng = new google.maps.LatLng({ lat: position.latitude, lng: position.longitude });
      const marker = new google.maps.Marker({
        position: coordinates,
        title: "position"
      });
      markers.push(marker);
    }

  }

  private async refreshMapView(): Promise<void> {
    if (this.connected === true) {
      if (this.map) {
        if (this.position && this.position.latitude && this.position.longitude) {
          // (1) re-center map
          this.map.panTo({ lat: this.position.latitude, lng: this.position.longitude });
          // (2) set zoom
          this.map.setZoom(this.zoom);
          // (3) delete all markers and create/populate them again (in markers array and NOT on the map!)
          this.populateMarkers(this.position, this.markers);
          // (4) add/load markers to the map
          this.mapService.showTicketMarkers(this.map, this.markers);
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

}
