import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

// MODELS
import { AppError } from '@models/base/base';
import { ConnectionStatus } from '@models/information/connection-status.model';
import { Position } from '@models/business/position.model';
import { UserLocation } from '@models/settings/user-location.model';

// PROVIDERS
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { MapService } from '@services/map/map.service';
import { Loading } from '@services/loading/loading';
import { LogService } from '@services/log/log.service';
import { MblsAnalyticsService } from '@services/mbls-analytics-service/MblsAnalyticsService';
import { PositionService } from '@services/position-service/position.service';

const CONFIG = {
  GA: {
    PAGE_NAME: 'UserLocationSettingPage',
    EVENT: {
      USER_LOCATION_ADDED: {
        NAME: 'user_location-setting',
        ACTION: 'user_location-added'
      }
    }
  }
};

const defaultZoomLevel = 14;

@Component({
  templateUrl: './user-location-adder.page.html',
  styleUrls: ['./user-location-adder.page.scss'],
})
export class UserLocationAdderPage implements OnDestroy, OnInit {

  @ViewChild('addressInput', { static: false }) addressBlock: IonInput;

  private center: Position = null;
  private markerPosition: Position = null;

  private latitude = 54.526000;
  private longitude = -105.255100;
  private zoom = defaultZoomLevel;

  public name: string = null;
  public address: string = null;
  public writing: boolean = false;

  private _connected = false;
  public get connected() {
    return this._connected;
  }
  public set connected(isOnline: boolean) {
    if (isOnline !== this._connected) {
      this.ngZone.run(async () => {
        this._connected = isOnline;
        await this.refreshMapView();
      });
    }
  }

  public map: google.maps.Map;
  public mapLoadFailed: boolean;
  public markers: Array<google.maps.Marker>;

  public componentDestroyed: Subject<void> = new Subject<void>();

  constructor(
    private backgroundGeolocationService: BackgroundGeolocationService,
    private connectionStatusService: ConnectionStatusService,
    private ga: MblsAnalyticsService,
    private mapService: MapService,
    private modalCtrl: ModalController,
    private loading: Loading,
    private log: LogService,
    private ngZone: NgZone,
    private positionService: PositionService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
    this._connected = this.connectionStatusService.getLastKnownConnectionStatus().isNetworkConnected;
    this.map = null;
    this.mapLoadFailed = null;
    this.markers = [];
  }

  public async ngOnInit() {
    this.address = null;
    this.name = null;

    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        distinctUntilChanged((prev, curr) => prev.isNetworkConnected === curr.isNetworkConnected),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((status: ConnectionStatus) => {
        this.connected = status.isNetworkConnected;
      });

    this.setCurrentCenter();
    await this.initMapView();

    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public addLocation() {
    this.ga.trackEvent(CONFIG.GA.EVENT.USER_LOCATION_ADDED.NAME, CONFIG.GA.EVENT.USER_LOCATION_ADDED.ACTION, 'UserLocationSetting')
      .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.USER_LOCATION_ADDED} with GA`, error));
    this.writing = false;
    this.positionService.savePosition(this.markerPosition)
      .subscribe(position => {
        const userLocation: UserLocation = new UserLocation();
        userLocation.position = position;
        userLocation.name = this.name;
        userLocation.address = this.address;
        this.modalCtrl.dismiss({ location: userLocation });
      }, async _error => {
        const toast = await this.toastController.create({
          message: this.translateService.instant('location-settings.error'),
          duration: 3 * 1000
        });
        toast.present();
      });
  }

  private setCurrentCenter() {
    const position: Position = this.backgroundGeolocationService.getLastKnownPosition();
    if (position) {
      this.center = position;
      this.zoom = defaultZoomLevel;
    } else {
      this.center = new Position(this.latitude, this.longitude);
    }
  }

  /**
   * Main method to initialize/load map view (load google api lib) and initialize map.
   * If map view load/initialization failed the app will show a dialog prompting to retry
   */
  public async initMapView(): Promise<void> {
    // (1) check if we are online (note, google maps SDK does not support offline mode since caching tiles is not supported)
    if (this.connected === true) {
      await this.loading.present();
      try {
        // (2) make sure google maps API and libs are loaded
        try {
          await this.mapService.makeSureGoogleMapsAPILoaded();
          this.mapLoadFailed = false;
        } catch (error) {
          this.mapLoadFailed = true;
          throw error;
        }
        // (3) create map (if has not been created before); It is safe to use this.map in the subsequent method invokations
        if (!this.map) {
          this.map = this.initializeMap();
        }
        // (4) initialize autocomplete feature
        this.initializeAutocomplete();
        // (5) delete all markers and create/populate them again (in array and NOT on the map!)
        this.populateMarkers(this.markerPosition, this.markers);
        this.mapService.showTicketMarkers(this.map, this.markers);
        await this.loading.dismiss();
      } catch (error) {
        await this.loading.dismiss();
        if (error && error instanceof AppError) {
          const appError = error as AppError;
          if (!appError.handled) {
            if (appError.message) {
              await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this), appError.message);
            } else {
              await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this));
            }
          }
        } else {
          await this.mapService.showConfirmationDialogOnMapViewFailed(this.initMapView.bind(this));
        }
      }
    } else {
      // NOTE: just ignore this. we will re-draw the map when the connectivity is restored
    }
  }
  /**
   * Method to initialize the google map
   */
  public initializeMap(): google.maps.Map {
    const options: google.maps.MapOptions = {
      zoom: this.zoom,
      streetViewControl: true,
      panControl: true,
      fullscreenControl: false,
      center: {
        lat: this.center.latitude,
        lng: this.center.longitude
      }
    };
    const map = new google.maps.Map(document.getElementById('user_location_adder_page_map_canvas'), options);
    return map;
  }

  /**
   * Method to set up/enable autocomplete feature
   */
  public initializeAutocomplete(): void {
    // NOTE: Favorize addresses within 50km of the user position in results
    const lastKnownPosition: Position = this.backgroundGeolocationService.getLastKnownPosition();
    let circle = null;
    if (lastKnownPosition) {
      circle = new google.maps.Circle({
        center: { lat: lastKnownPosition.latitude, lng: lastKnownPosition.longitude },
        radius: 50 * 1000
      });
    } else {
      circle = new google.maps.Circle({
        center: { lat: 54.526000, lng: -105.255100 },
        radius: 50 * 1000
      });
      this.zoom = 2;
    }

    this.addressBlock.getInputElement().then((element: HTMLInputElement) => {
      const autocomplete = new google.maps.places.Autocomplete(element, {
        bounds: circle.getBounds(),
        types: ['address']
      });

      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        const position = new Position(place.geometry.location.lat(), place.geometry.location.lng());
        position.dateEmitted = moment();
        this.markerPosition = position;
        this.center = position;
        this.ngZone.run(() => {
          this.address = place.formatted_address;
        });
        this.zoom = defaultZoomLevel;

        await this.refreshMapView();
      });
    });

  }
  /**
   * Method to create marker objects based on the loaded providers, orders and car position and add them to the array (not on the map!)
   */
  public populateMarkers(position: Position, markers: Array<google.maps.Marker>): void {

    // NOTE: deletes all markers from the map and also remove all markers from the markers array
    this.mapService.deleteAllMarkers(markers);

    // NOTE: add the markers for the current user position
    if (position && position.latitude && position.longitude) {
      const coordinates: google.maps.LatLng = new google.maps.LatLng({ lat: position.latitude, lng: position.longitude });
      const marker = new google.maps.Marker({
        position: coordinates,
        title: "position"
      });
      markers.push(marker);
    }

  }

  private async refreshMapView(): Promise<void> {
    if (this.connected === true) {
      if (this.map) {
        if (this.center && this.center.latitude && this.center.longitude) {
          // (1) re-center map
          this.map.panTo({ lat: this.center.latitude, lng: this.center.longitude });
          // (2) set zoom
          this.map.setZoom(this.zoom);
          // (3) delete all markers and create/populate them again (in markers array and NOT on the map!)
          this.populateMarkers(this.markerPosition, this.markers);
          // (4) add/load markers to the map
          this.mapService.showTicketMarkers(this.map, this.markers);
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

}
