export class ValidatorHelpers {

    /**
    * Validates that the value is not defined (null or undefined) or empty string or empty array
    * NOTE: works for strings and for arrays
    * 
    * @param value value to check
    */
    public static isNullOrUndefinedOrEmptyStringOrEmptyArray(value: any): boolean {
        return ValidatorHelpers.isNullOrUndefinedOrEmptyString(value) || value.length === 0;
    }

    /**
     * Validates that the value is not defined (null or undefined) or empty string
     *
     * @param value value to check
     */
    public static isNullOrUndefinedOrEmptyString(value: any): boolean {
        return value == null || ((typeof value === 'string' || value instanceof String) && value.trim().length === 0);
    }

}
