import {TransitionDetail} from '@models/business/transition-detail.model';
import {Descriptor} from '@models/workflow/descriptor.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Event')
export class Event extends Descriptor {

  @JsonProperty('transitionDetail', TransitionDetail, true)
  transitionDetail: TransitionDetail = undefined;

  @JsonProperty('isAvailableToHaulerEmployee', Boolean, true)
  isAvailableToHaulerEmployee = true;

  constructor(name: string, labelKey: string, icon: string, transitionDetail?: TransitionDetail, rank?: number, style?: string, emphasized?: boolean, isAvailableToHaulerEmployee?: boolean) {
    super(name, labelKey, icon, rank, style, emphasized);
    this.isAvailableToHaulerEmployee = isAvailableToHaulerEmployee;
    this.transitionDetail = transitionDetail;
  }
}
