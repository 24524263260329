import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {Any} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('NotificationData')
export class NotificationData {

  @JsonProperty('_id', String, true)
  _id: string = undefined;

  @JsonProperty('_rev', String, true)
  _rev: string = undefined;

  @JsonProperty('_deleted', Boolean, true)
  _deleted: boolean = undefined;

  @JsonProperty('_rev_tree', Any, true)
  _rev_tree: any = undefined;

  @JsonProperty('notificationType', String, true)
  notificationType: string = undefined;

  @JsonProperty('title', String, true)
  title: string = undefined;
  @JsonProperty('body', String, true)
  message: string = undefined;
  @JsonProperty('uuid', String, true)
  uuid: string = undefined;

  @JsonProperty('orderId', Number, true)
  orderId: number = undefined;

  @JsonProperty('timestamp', MomentDateConverter, true)
  timestamp: moment.Moment = undefined;

  @JsonProperty('read', Boolean, true)
  read = false;

  @JsonProperty('generator', String, true)
  generator: string = undefined;

  @JsonProperty('recipient', String, true)
  recipient: string = undefined;

  @JsonProperty('localNotificationID', Number, true)
  localNotificationID: number = undefined;

  @JsonProperty('messageHasClientInfo', Boolean, true)
  messageHasClientInfo = false;

  constructor(title: string, message: string, uuid: string, notificationType: string) {
    this.title = title;
    this.message = message;
    this.uuid = uuid;
    this.notificationType = notificationType;
  }

}
