import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('TraceabilityActor')
export class TraceabilityActor {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('username', String)
  username: string = undefined;

  @JsonProperty('firstname', String)
  firstname: string = undefined;

  @JsonProperty('lastname', String)
  lastname: string = undefined;

}
