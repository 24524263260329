import {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'DistanceUnit',
})

export class DistanceUnitPipe implements PipeTransform {
  kmTranslation: string;
  milesTranslation: string;
  metersTranslation: string;
  yardsTranslation: string;

  constructor(private translateService: TranslateService, ) {

    this.kmTranslation = this.translateService.instant('distanceUnits.km');
    this.milesTranslation = this.translateService.instant('distanceUnits.miles');
    this.metersTranslation = this.translateService.instant('distanceUnits.meters');
    this.yardsTranslation = this.translateService.instant('distanceUnits.yards');
  }

  transform(defaultValue: any, distanceSystem: DistanceSystemEnum, isShortDistance: boolean = false): any {
    let out = '';

    if (+distanceSystem === DistanceSystemEnum.SI) {
      if (isShortDistance) {
        out = this.metersTranslation;
      } else {
        out = this.kmTranslation;
      }
    } else {
      if (isShortDistance) {
        out = this.yardsTranslation;
      } else {
        out = this.milesTranslation;
      }
    }

    return out;
  }

}
