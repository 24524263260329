import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {DeviceInfo} from '@models/information/device-info.model';
import * as Sentry from '@sentry/browser';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as LogRocket from 'logrocket';
import {combineLatest} from 'rxjs';
import { DeviceUtils } from '@services/utils/device-utils';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';

@Injectable({
  providedIn: 'root'
})
export class ErrorsLoggingAndSessionRecordingService {

  private logRocketStarted = false;

  public sendLogRocketData;

  constructor(private mobileContextService: MobileContextService,
              private deviceInfo: DeviceInfo,
              private log: LogService,
              private ga: MblsAnalyticsService,
              private platform: Platform,
              private deviceUtils: DeviceUtils,
              private isDebug: IsDebug
  ) {

    this.setSendLogRocketData(false);
    this.startRecording(this);

    this.disableSentryInDevelopment();
  }

  private startRecording(session) {
    combineLatest([
      this.mobileContextService.userProfileObservable,
      this.mobileContextService.haulerObservable,
      this.mobileContextService.userSettingsObservable
    ])
      .subscribe(([userProfile, hauler, userSettings]) => {
          if (userProfile && userSettings.logRocketEnabled) {
            this.setSendLogRocketData(true);
          } else {
            this.setSendLogRocketData(false);
          }
          if (userProfile && hauler && userSettings) {
            try {
              Sentry.setUser({
                username: userProfile.username,
                id: userProfile.id.toString(),
                name: userProfile.firstName + ' ' + userProfile.lastName,
                userId: userProfile.id.toString(),
                haulerEmployeeId: userProfile.haulerEmployeeId,
                haulerID: hauler.id.toString(),
                haulerName: hauler.name,
                providers: userSettings.providersToString(),
                deviceUUID: this.deviceInfo.uuid,
                release: this.deviceInfo.unifiedVersion
              });
            } catch (e) {
              this.log.error('Unable to register user to sentry : ', e);
            }

            try {
              const release = (this.deviceInfo && this.deviceInfo.buildInfo && this.deviceInfo.buildInfo.version) ? this.deviceInfo.buildInfo.version : 'experimental';
              if (userSettings && userSettings.logRocketEnabled) {
                this.logRocketStarted = true;
                this.log.info('LogRocket init');
                LogRocket.init('htvd4m/mobilus-mobileapp-v2', {
                  dom: {
                    baseHref: 'https://m.mbls.ca/',
                  },
                  release,
                  shouldSendData(): boolean {
                    return session.getSendLogRocketData();
                  },
                  uploadTimeInterval: 180000
                });
              } else {
                this.log.info('We keep LogRocket silent');
              }

              if (this.logRocketStarted) {
                this.log.info('LogRocket identify prepare');
                LogRocket.identify(this.deviceInfo.uuid, {
                  username: userProfile.username,
                  name: userProfile.firstName + ' ' + userProfile.lastName,
                  userId: userProfile.id.toString(),
                  haulerEmployeeId: userProfile.haulerEmployeeId,
                  haulerID: hauler.id.toString(),
                  haulerName: hauler.name,
                  haulerCode: hauler.code,
                  providers: userSettings.providersToString(),
                  deviceUUID: this.deviceInfo.uuid,
                  release: this.deviceInfo.unifiedVersion
                });

                LogRocket.getSessionURL(sessionURL => {
                  Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
                });
              }
            } catch (e) {
              this.log.error('Unable to register user to logrocket : ', e);
            }

            try {
              this.ga.setUserId('G-7RM1LEVQ9X', userProfile.id.toString());
              this.ga.setAppVersion(this.deviceInfo.unifiedVersion);
            } catch (e) {
              this.log.error('Unable to register user to google analytics : ', e);
            }
          } else {
            Sentry.getCurrentScope().clear();
          }
        }
      );
  }

  public setSendLogRocketData(send: boolean) {
    this.sendLogRocketData = send;
  }

  public getSendLogRocketData() {
    return this.sendLogRocketData;
  }

  public async disableSentryInDevelopment() {
    let isDevelopment: boolean = null;
    await this.platform.ready();
    if ((await this.deviceUtils.isRunningOnRealDeviceOrSimulator()) === true) {
      this.isDebug.getIsDebug().then(debug => {
        isDevelopment = debug;
      });
    } else {
      isDevelopment = true;
    }

    Sentry.getClient().getOptions().enabled = !isDevelopment;

  }

}
