import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('Permission')
export class Permission {
  @JsonProperty('authorizationStatus', String, true)
  authorizationStatus: string = undefined;
}

@JsonObject('PushType')
export class PushType {
  @JsonProperty('alert', Boolean, true)
  alert: boolean = undefined;
  @JsonProperty('badge', Boolean, true)
  badge: boolean = undefined;
  @JsonProperty('sound', Boolean, true)
  sound: boolean = undefined;
}

@JsonObject('PushPermission')
export class PushPermission extends Permission {
  @JsonProperty('isRemoteNotificationEnabled', Boolean, true)
  isRemoteNotificationEnabled: boolean = undefined;
  @JsonProperty('isRegistered', Boolean, true)
  isRegistered: boolean = undefined;
  @JsonProperty('type', PushType, true)
  type: PushType = undefined;
}

@JsonObject('LocalNotificationPermission')
export class LocalNotificationPermission extends Permission {
  @JsonProperty('granted', Boolean, true)
  granted: boolean = undefined;
  @JsonProperty('enabled', Boolean, true)
  enabled: boolean = undefined;
}

@JsonObject('AuthorizablePermission')
export class AuthorizablePermission extends Permission {
  @JsonProperty('isAuthorized', Boolean, true)
  isAuthorized: boolean = undefined;
  @JsonProperty('isAvailable', Boolean, true)
  isAvailable: boolean = undefined;
}

@JsonObject('HardwarePermission')
export class HardwarePermission extends AuthorizablePermission {
  @JsonProperty('isPresent', Boolean, true)
  isPresent: boolean = undefined;
}

@JsonObject('LocationPermission')
export class LocationPermission extends AuthorizablePermission {
  @JsonProperty('mode', String, true)
  mode: string = undefined;
  @JsonProperty('isEnabled', Boolean, true)
  isEnabled: boolean = undefined;
}

@JsonObject('Permissions')
export class Permissions {
  @JsonProperty('camera', HardwarePermission, true)
  camera: HardwarePermission = new HardwarePermission();
  @JsonProperty('push', PushPermission, true)
  push: PushPermission = new PushPermission();
  @JsonProperty('localNotification', LocalNotificationPermission, true)
  localNotification: LocalNotificationPermission = new LocalNotificationPermission();
  @JsonProperty('location', LocationPermission, true)
  location: LocationPermission = new LocationPermission();
  @JsonProperty('phone', Permission, true)
  phone: Permission = new Permission();
}

