export class TransitionParameters {

  transitionFooter = true;
  withPayment = true;
  withPaymentDiffCheck = true;
  withSignature = true;
  withInterlocutor = true;
  withPicture = true;
  withReasonChoices = true;
  withReason = true;
  withReasonChoicesOther = true;
  withHaulerChoice = true;
  withComment = true;
  withDoorCode = true;

  constructor(transitionFooter: boolean,
              withPayment: boolean,
              withComment: boolean,
              withSignature: boolean,
              withInterlocutor: boolean,
              withPicture: boolean,
              withReasonChoices: boolean,
              withReason: boolean,
              withReasonChoicesOther: boolean,
              withHaulerChoice: boolean,
              withDoorCode: boolean
  ) {
    this.transitionFooter = transitionFooter;
    this.withPayment = withPayment;
    this.withComment = withComment;
    this.withSignature = withSignature;
    this.withInterlocutor = withInterlocutor;
    this.withPicture = withPicture;
    this.withReasonChoices = withReasonChoices;
    this.withReason = withReason;
    this.withReasonChoicesOther = withReasonChoicesOther;
    this.withHaulerChoice = withHaulerChoice;
    this.withDoorCode = withDoorCode;
  }

  static allActivated(): TransitionParameters {
    return new TransitionParameters(true, true, true, true, true, true, true, true, true, true, true);
  }

  static allDeactivated(): TransitionParameters {
    return new TransitionParameters(false, false, false, false, false, false, false, false, false, false, false);
  }

  deactivateTransitionFooter(): TransitionParameters {
    this.transitionFooter = false;
    return this;
  }

  activateReasonChoices(): TransitionParameters {
    this.withReasonChoices = true;
    return this;
  }

  activateReasonChoicesOther(): TransitionParameters {
    this.withReasonChoicesOther = true;
    return this;
  }

  activateNoCheckingPayment(): TransitionParameters {
    this.withPayment = true;
    this.withPaymentDiffCheck = false;
    return this;
  }

  activateComment(): TransitionParameters {
    this.withComment = true;
    return this;
  }

  activateInterlocutor(): TransitionParameters {
    this.withInterlocutor = true;
    return this;
  }

  activateHauler(): TransitionParameters {
    this.withHaulerChoice = true;
    return this;
  }

}
