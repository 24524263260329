import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Position} from '@models/business/position.model';
import {plainToClass} from '@utils/json-converter/json-converter';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const CONFIG = {
  PATH_POSITION: '/mobile/v2/position'
};

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  constructor(private httpClient: HttpClient,
              private endpointService: EndpointService) {
  }

  savePosition(position: Position): Observable<Position> {
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_POSITION;

    return this.httpClient.post(url, {position: position})
      .pipe(
        map((body: any) => {
          return plainToClass(Position, body);
        })
      );
  }

}

