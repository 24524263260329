import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mobilus-reloader',
  templateUrl: 'reloader.html',
  styleUrls: ['./reloader.scss']
})
export class ReloaderComponent {

  @Input() public promptToNavigateBack: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onReload = new EventEmitter();

  public reload() {
    this.onReload.emit();
  }

}
