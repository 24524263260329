import {Injectable} from '@angular/core';
import {Hauler} from '@models/business/hauler.model';
import {LogEvent} from '@models/log/log-event.model';
import {LogLevelEnum} from '@models/log/log-level.enum';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  public userSettings: UserSettings;
  public userProfile: UserProfile;
  public hauler: Hauler;
  public userLogLevel: LogLevelEnum = null;

  constructor() {
  }

  public setHauler(hauler: Hauler) {
    this.hauler = hauler;
  }

  public setUserProfile(userProfile: UserProfile) {
    this.userProfile = userProfile;
  }

  public setUserSettings(userSettings: UserSettings) {
    this.userSettings = userSettings;
    if (userSettings && userSettings.logLevel) {
      try {
        this.userLogLevel = LogLevelEnum[userSettings.logLevel];
      } catch (e) {
        console.error('Unable to set user log level from settings : ', userSettings.logLevel);
        this.userLogLevel = LogLevelEnum.OFF;
      }
    } else if (this.userProfile && (!userSettings || !userSettings.logLevel)) {
      this.userLogLevel = LogLevelEnum.INF;
    } else {
      this.userLogLevel = LogLevelEnum.INF;
    }
  }

  private logMessage(level: LogLevelEnum, message: string, objects: any[]): void {
    try {
      if (level >= this.userLogLevel) {
        const logEvent = new LogEvent();
        logEvent.date = moment();
        logEvent.message = message;
        logEvent.objects = objects;
        logEvent.level = level;
        this.outputLogEvent(logEvent);
      }
    } catch (e) {
      console.log('error is', e);
      console.error('Unable to log message', level, message, objects);
    }
  }

  private outputLogEvent(logEvent: LogEvent): void {
    const logMessage = `MBLS [${LogLevelEnum[logEvent.level]}] ${logEvent.date.format('YYYY-MM-DDTHH:mm:ss.SSSZ')} - ${logEvent.message}`;
    if (logEvent.objects && logEvent.objects.length > 0) {
      console.log(logMessage, logEvent.objects);
    } else {
      console.log(logMessage);
    }
  }

  public info(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.INF, message, objects);
  }

  public debug(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.DBG, message, objects);
  }

  public error(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.ERR, message, objects);
  }

  public fatal(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.FTL, message, objects);
  }

  public trace(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.TRA, message, objects);
  }

  public warn(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.WRN, message, objects);
  }

  public off(message: string, ...objects: any[]): void {
    this.logMessage(LogLevelEnum.OFF, message, objects);
  }
}
