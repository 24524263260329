import { Injectable } from '@angular/core';

import { App, AppPlugin } from '@capacitor/app';
import { Camera, CameraPlugin } from '@capacitor/camera';
import { Device, DevicePlugin } from '@capacitor/device';
import { Geolocation, GeolocationPlugin } from '@capacitor/geolocation';
import { LocalNotifications, LocalNotificationsPlugin } from '@capacitor/local-notifications';
import { Network, NetworkPlugin } from '@capacitor/network';
import { StatusBar, StatusBarPlugin } from '@capacitor/status-bar';
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
// import { Storage, StoragePlugin } from '@capacitor/storage';
import { Preferences, PreferencesPlugin } from '@capacitor/preferences';

@Injectable({
    providedIn: 'root'
})
export class CapacitorPlugins {

    private readonly MIGRATION_TO_VERSION_3_KEY = 'hasMigratedToVersion3';
    constructor() {
        this.init();
    }

    private async init() {
        const preferences = this.getPreferencesPlugin();
        const { value } = await Preferences.get({ key: this.MIGRATION_TO_VERSION_3_KEY });
        if (value !== 'true') {
            const migrationResult = await preferences.migrate();
            await preferences.set({
                key: this.MIGRATION_TO_VERSION_3_KEY,
                value: 'true'
            });
        }
    }

    public getAppPlugin(): AppPlugin {
        return App;
    }

    public getCameraPlugin(): CameraPlugin {
        return Camera;
    }

    public getDevicePlugin(): DevicePlugin {
        return Device;
    }

    public getGeolocationPlugin(): GeolocationPlugin {
        return Geolocation;
    }

    public getLocalNotificationsPlugin(): LocalNotificationsPlugin {
        return LocalNotifications;
    }

    public getNetworkPlugin(): NetworkPlugin {
        return Network;
    }


    public getSplashScreenPlugin(): SplashScreenPlugin {
        return SplashScreen;
    }

    public getStatusBarPlugin(): StatusBarPlugin {
        return StatusBar;
    }

    public getPreferencesPlugin(): PreferencesPlugin {
        return Preferences;
    }

}
