import {JsonObject} from 'json2typescript';
import {JsonProperty} from 'json2typescript';

@JsonObject('DeliveryConstraints')
export class DeliveryConstraints {
  @JsonProperty('requireSignatureOnDelivery', Boolean, true)
  requireSignatureOnDelivery: boolean = undefined;

  @JsonProperty('requireIdentityOnDelivery', Boolean, true)
  requireIdentityOnDelivery: boolean = undefined;

  @JsonProperty('requireRelationWithClientOnDelivery', Boolean, true)
  requireRelationWithClientOnDelivery: boolean = undefined;

  @JsonProperty('displaySignatureOnDelivery', Boolean, true)
  displaySignatureOnDelivery: boolean = undefined;

  @JsonProperty('displayIdentityOnDelivery', Boolean, true)
  displayIdentityOnDelivery: boolean = undefined;

  @JsonProperty('displayRelationWithClientOnDelivery', Boolean, true)
  displayRelationWithClientOnDelivery: boolean = undefined;

  @JsonProperty('requireInfoOnBomDelivery', Boolean, true)
  requireInfoOnBomDelivery: boolean = undefined;

  @JsonProperty('authorizedRecipientsEnabled', Boolean, true)
  authorizedRecipientsEnabled: boolean = undefined;

  @JsonProperty('requirePictureOnDelivery', Boolean, true)
  requirePictureOnDelivery: boolean = undefined;

  @JsonProperty('manifestEnabled', Boolean, true)
  manifestEnabled: boolean = undefined;

  static doNothingConstraints(): DeliveryConstraints {
    const constraints = new DeliveryConstraints();
    constraints.requireInfoOnBomDelivery = false;
    constraints.requireIdentityOnDelivery = false;
    constraints.requireRelationWithClientOnDelivery = false;
    constraints.displaySignatureOnDelivery = false;
    constraints.displayIdentityOnDelivery = false;
    constraints.displayRelationWithClientOnDelivery = false;

    constraints.requireInfoOnBomDelivery = false;
    constraints.authorizedRecipientsEnabled = false;
    constraints.manifestEnabled = false;

    return constraints;
  }

  static displayAllWithoutRequirements(): DeliveryConstraints {
    const constraints = new DeliveryConstraints();
    constraints.requireInfoOnBomDelivery = false;
    constraints.requireIdentityOnDelivery = false;
    constraints.requireRelationWithClientOnDelivery = false;
    constraints.displaySignatureOnDelivery = true;
    constraints.displayIdentityOnDelivery = true;
    constraints.displayRelationWithClientOnDelivery = true;

    constraints.requireInfoOnBomDelivery = false;
    constraints.authorizedRecipientsEnabled = false;
    constraints.manifestEnabled = false;

    return constraints;
  }

}
