import {Injectable} from '@angular/core';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';
import {Platform} from '@ionic/angular';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {ColorModeEnum} from '@models/settings/ColorModeEnum.model';
import {UserSettings} from '@models/settings/settings.model';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private keepScreenOn = false;
  private lastUserSettings: UserSettings;

  constructor(private platform: Platform,
              private mobileContextService: MobileContextService,
              private insomnia: Insomnia,
              private log: LogService,
  ) {
    this.platform.ready()
      .then(() => {
        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

        this.toggleDarkTheme(prefersDark.matches);

        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addEventListener('change',(mediaQuery) => {
          if (!this.lastUserSettings || (this.lastUserSettings && this.lastUserSettings.colorMode === ColorModeEnum.AUTO)) {
            this.toggleDarkTheme(mediaQuery.matches);
          }
        });

        this.mobileContextService.userSettingsObservable
          .subscribe((userSettings) => {
            if (this.platform.is('android') || this.platform.is('ios')) {
              if (userSettings && userSettings.keepScreenOn !== this.keepScreenOn) {
                if (userSettings.keepScreenOn) {
                  this.keepAwake();
                } else {
                  this.allowSleepAgain();
                }
              }
            }
            if (userSettings.colorMode === ColorModeEnum.AUTO) {
              // Nothing to do
            } else if (userSettings.colorMode === ColorModeEnum.DARK) {
              this.toggleDarkTheme(true);
            } else if (userSettings.colorMode === ColorModeEnum.LIGHT) {
              this.toggleDarkTheme(false);
            }
          });
      });
  }

  // Add or remove the "dark" class based on if the media query matches
  private toggleDarkTheme(
    force: boolean
  ) {
    // document.body.classList.toggle('dark', force);
    document.documentElement.classList.toggle('ion-palette-dark', force);
  }

  private keepAwake(): Promise<void> {
    this.log.debug('Keeping screen on');
    return this.insomnia.keepAwake()
      .then(
        () => {
          this.keepScreenOn = true;
        },
        error => this.log.error('Cannot force keep awake: ', error)
      );
  }

  private allowSleepAgain(): Promise<void> {
    this.log.debug('Allowing screen to sleep');
    return this.insomnia.allowSleepAgain()
      .then(
        () => {
          this.keepScreenOn = false;
        },
        error => this.log.error('Cannot allow sleep again: ', error)
      );
  }

}

