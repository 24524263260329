import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';

@Component({
  selector: 'mobilus-manifest-item',
  templateUrl: './manifest-item.component.html',
  styleUrls: ['./manifest-item.component.scss'],
})
export class ManifestItemComponent {

  @Input() item: OrderManifestItem;

  constructor() {
  }

}

