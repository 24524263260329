import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('LockerIntegration')
export class LockerIntegration {

  @JsonProperty('description', String, true)
  description: string = undefined;

  @JsonProperty('lockerId', String, true)
  lockerId: string = undefined;
}
