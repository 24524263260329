import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('MqttServer')
export class MqttServer {

  @JsonProperty('host', String)
  host: string = undefined;

  @JsonProperty('port', Number)
  port: number = undefined;
}
