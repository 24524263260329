<ion-header>

  <ion-toolbar>
    <ion-title>{{title | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">
  <div *ngIf="type!== enterPin">
    <form [formGroup]="pinForm" (submit)="confirm()" (keyup.enter)="confirm()">
      <ion-list>

        <ion-item lines="none" class="ion-text-center ion-text-capitalize">
          <h1>{{ header| translate}}</h1>
        </ion-item>

        <ion-item [class.invalid]="!pinForm.controls.pin.valid && (pinForm.controls.pin.dirty || confirmAttempt)">
          <ion-input formControlName="pin" type="number" class="see-pin" [ngClass]="{'see-pin': !showPin}"
                     placeholder="{{pinPlaceHolder | translate}}" clearOnEdit="false"></ion-input>
          <ion-button fill="clear" type="button" item-right icon-only (click)="showPinField()">
            <fa-icon [icon]="['fas', 'eye-slash']"></fa-icon>
          </ion-button>
        </ion-item>

        <ion-item
          [class.invalid]="!pinForm.controls.repeatPin.valid && (pinForm.controls.repeatPin.dirty || confirmAttempt)">
          <ion-input formControlName="repeatPin" type="number" [ngClass]="{'see-pin': !showPin}"
                     placeholder="{{'pages.settings.confirmPin' | translate}}" clearOnEdit="false"></ion-input>
          <ion-button fill="clear" type="button" item-right icon-only (click)="showPinField()">
            <fa-icon [icon]="['fas', 'eye-slash']"></fa-icon>
          </ion-button>
        </ion-item>
        <ion-item
          *ngIf="pinForm.get('pin').value !== pinForm.get('repeatPin').value" lines="none" class="ion-text-center">
          <ion-label color="danger">{{'pages.settings.mismatchPin' | translate}}</ion-label>
        </ion-item>

      </ion-list>

      <ion-row>
        <ion-col style="text-align: center">
          <ion-button class="button-block" color="secondary"
                      [disabled]="!pinForm.valid" (click)="confirm()"> Confirm
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </div>


<!--  FIXME: should be in a different page ...-->
  <ion-list *ngIf="type===enterPin">
    <ion-label>{{ header| translate}}</ion-label>

    <ion-item>
      <ion-input
        placeholder="{{pinPlaceHolder | translate}}" clearOnEdit="false"
        [(ngModel)]="checkPinValue" (ionChange)="checkPin()" type="number" [ngClass]="{'see-pin': !showPin}"
        autofocus="true"></ion-input>
      <ion-button fill="clear" type="button" item-right icon-only (click)="showPinField()">
        <fa-icon [icon]="['fas', 'eye-slash']"></fa-icon>
      </ion-button>
    </ion-item>

    <ion-item *ngIf="checkPinValue !== signaturePinValue" lines="none"
              class="ion-text-center">
      <ion-label color="danger">{{'pages.settings.wrongPin' | translate}}</ion-label>
    </ion-item>

  </ion-list>

</ion-content>
