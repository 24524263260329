import {LogLevelEnum} from '@models/log/log-level.enum';
import {ObjectArrayConverter} from '@utils/json-converter/json-converter';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonCustomConvert} from 'json2typescript';
import {JsonConverter} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';


@JsonConverter
export class LogLevelEnumConverter implements JsonCustomConvert<LogLevelEnum> {
  serialize(enumValue: LogLevelEnum): string {
    return LogLevelEnum[enumValue];
  }

  deserialize(enumValue: string): LogLevelEnum {
    return LogLevelEnum[enumValue.toUpperCase()];
  }
}

@JsonObject('LogEvent')
export class LogEvent {

  @JsonProperty('date', MomentDateConverter)
  date: moment.Moment = undefined;

  @JsonProperty('message', String)
  message: string = undefined;

  @JsonProperty('objects', ObjectArrayConverter, true)
  objects: any[] = undefined;

  @JsonProperty('level', LogLevelEnumConverter)
  level: LogLevelEnum = undefined;

}
