import { UntypedFormControl } from '@angular/forms';
import { validationLatitudeLongitude } from 'validation-latitude-longitude';
import { ValidatorHelpers } from '../validator-helpers/validator-helpers';

export class CoordinatesValidator {

    public static isLatitudeValid(control: UntypedFormControl): { [key: string]: any } {
        if (ValidatorHelpers.isNullOrUndefinedOrEmptyString(control.value)) {
            return null;
        }
        const result = validationLatitudeLongitude.latitude(control.value);
        if (result) {
            return null;
        }
        return {
            invalid: true
        };
    }

    public static isLongitudeValid(control: UntypedFormControl): { [key: string]: any } {
        if (!ValidatorHelpers.isNullOrUndefinedOrEmptyString(control.value)) {
            return null;
        }
        const result = validationLatitudeLongitude.longitude(control.value);
        if (result) {
            return null;
        }
        return {
            invalid: true
        };
    }

}
