import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {PipesModule} from '@app/pipes/pipes.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {DriverNotesModalPage} from './driver-notes-modal.page';

const routes: Routes = [
  {
    path: '',
    component: DriverNotesModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FontAwesomeModule,
    PipesModule,
    ComponentsModule
  ],
  declarations: [DriverNotesModalPage]
})
export class DriverNotesModalPageModule {}
