import {Component} from '@angular/core';
import {OrderManifestItem} from '@models/business/order-manifest-item.model';
import {Order} from '@models/business/order.model';
import {NavParams} from '@ionic/angular';

@Component({
  selector: 'mobilus-order-manifest',
  templateUrl: './order-manifest.page.html',
  styleUrls: ['./order-manifest.page.scss'],
})
export class OrderManifestPage {
  order: Order;
  manifestItems: Array<OrderManifestItem> = [];

  constructor(private navParams: NavParams) {
    const view = this.navParams.get('orderView');
    this.order = view.order;
    if (this.order.manifest) {
      this.manifestItems = this.order.manifest.items;
      this.manifestItems.sort((a, b) => a.item.label.localeCompare(b.item.label));
    }
  }

}
