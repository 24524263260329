import {DecimalPipe} from '@angular/common';
import {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {DistanceUnitPipe} from './distance-unit.pipe';


const kmToMilesConverter = 0.621371192;
const metersToYards = 1.09361;
const kmToMeters = 1000;

@Pipe({
  name: 'Distance',
})

export class DistancePipe implements PipeTransform {


  constructor(private distanceUnitPipe: DistanceUnitPipe,
              private decimalPipe: DecimalPipe, ) {
  }

  /**
   * value, number : distance in meters
   * distanceSystem, string: distance system ('imperial' or 'si')
   * appendUnit, Boolean: append unit (add unit to the output)
   * distanceType, string: long or short (to calculate km/miles or meters/yards)
   * digitsInfo, string: number of digits
   *
   */
  transform(value: any, distanceSystem: DistanceSystemEnum, appendUnit: boolean = false, convertToBestUnit: boolean = true, digitsInfo?: string): any {
    let out = '';
    let isShortDistance = false;

    if (value) {
      value = parseFloat(value);

      if (convertToBestUnit && value < 1) {
        isShortDistance = true;
        value = value * kmToMeters;
      }

      if (+distanceSystem === DistanceSystemEnum.SI) {
        out = this.decimalPipe.transform(value, digitsInfo);
      } else if (+distanceSystem === DistanceSystemEnum.IMPERIAL) {
        if (isShortDistance) {
          out = this.decimalPipe.transform(value * metersToYards, digitsInfo);
        } else {
          out = this.decimalPipe.transform(value * kmToMilesConverter, digitsInfo);
        }
      }

      if (appendUnit) {
        out = out.toString() + ' ' + this.distanceUnitPipe.transform('km', distanceSystem, isShortDistance);
      }

      return out;
    } else {
      return null;
    }
  }


}
