import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {ComponentsModule} from '@app/components/components.module';
import {OrderDetailPageModule} from '@app/pages/order-detail/order-detail.module';
import {OrderManifestPageModule} from '@app/pages/order-manifest/order-manifest.module';
import {OrderTraceabilityPageModule} from '@app/pages/order-traceability/order-traceability.module';
import {PipesModule} from '@app/pipes/pipes.module';

import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {OrderDetailTabsPage} from './order-detail-tabs.page';

const routes: Routes = [
  {
    path: '',
    component: OrderDetailTabsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ComponentsModule,
    PipesModule,
    OrderDetailPageModule,
    OrderManifestPageModule,
    OrderTraceabilityPageModule,
  ],
  declarations: [OrderDetailTabsPage]
})
export class OrderDetailTabsPageModule {
}
