import {ObjectDatabaseChange} from '@models/pouchdb/object-database-change-model';
import {UserProfile} from '@models/user-profile.model';
import {classToPlain} from '@utils/json-converter/json-converter';
import {plainToClass} from '@utils/json-converter/json-converter';
import {ClassType} from '@utils/json-converter/json-converter';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
// @ts-ignore
import PouchDB from 'pouchdb';
import {defer} from 'q';
import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {} from 'rxjs';
import {DBListenChangesMode} from '@models/base/base';

export class DbService<T> {

  protected userProfile: UserProfile;

  private d = defer<string>();
  private _readyPromise: Q.Promise<string> = this.d.promise;

  private db: any;

  private _changeTrigger: BehaviorSubject<string> = new BehaviorSubject('initial');
  public changeObservable: Observable<string> = this._changeTrigger.asObservable();

  private _liveChangeTrigger: Subject<ObjectDatabaseChange<T>> = new Subject();
  public liveChangeObservable: Observable<ObjectDatabaseChange<T>> = this._liveChangeTrigger.asObservable();

  private _countTrigger: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public countObservable: Observable<number> = this._countTrigger.asObservable();

  private storeName: string;
  private cls: ClassType<T>;
  private bdListenChangesMode: DBListenChangesMode;

  constructor(storeName: string,
              cls: ClassType<T>,
              protected mobileContextService: MobileContextService,
              protected log: LogService,
              private toastCtrl: ToastController,
              private translateService: TranslateService,
              bdListenChangesMode?: DBListenChangesMode
  ) {
    this.storeName = storeName;
    this.cls = cls;
    this.mobileContextService.userProfileObservable
      .subscribe((userProfile: UserProfile) => {
        if (!userProfile) {
          this.makeStoreNotReady();
          this.userProfile = userProfile;
        } else {
          if (!this.userProfile || (this.userProfile.id.toString() !== userProfile.id.toString())) {
            this.userProfile = userProfile;
            this.initDatabase();
          }
        }
      });
    this.bdListenChangesMode = bdListenChangesMode || DBListenChangesMode.SINCE_NOW;
  }

  // Database initialization and management
  ready(): Q.Promise<string> {
    return this._readyPromise;
  }

  // Database operations
  get(id: string): Observable<T> {
    return new Observable(subscriber => {
      this.ready()
        .then(() => {
          this.db.get(id, {revs: false})
            .then(
              object => {
                subscriber.next(plainToClass(this.cls, object));
                subscriber.complete();
              },
              async error => {
                if (error.status === 500 && error.reason === 'QuotaExceededError') {
                  this.log.fatal('IndexDB quota exceeded : ', error);
                  const toast = await this.toastCtrl.create({
                    message: this.translateService.instant('messages.errors.quota_exceeded_error'),
                    duration: 2 * 1000,
                    cssClass: 'processing-toast'
                  });
                  toast.present();
                }
                subscriber.error(error);
                subscriber.complete();
              }
            );
        });
    });
  }

  getInfo(): Promise<any> {
    if (this.db) {
      return this.db.info();
    } else {
      return Promise.resolve(null);
    }
  }

  dbName(): string {
    if (this.db) {
      return this.db.name;
    } else {
      return null;
    }
  }

  getLiveChanges(): Observable<ObjectDatabaseChange<T>> {
    return this.liveChangeObservable;
  }

  /**
   * DO NOT USE. Accessor set to public only for unit tests
   */
  public initDatabase_FOR_TESTS_ONLY(userProfile: UserProfile): Promise<string> {
    return this.initDatabase();
  }

  put(object: object): Observable<T> {
    // console.warn('Putting something in db', this.storeName, object);
    return new Observable(subscriber => {
      this.ready()
        .then(() => {
          this.db.put(classToPlain(object))
            .then(
              (result) => {
                subscriber.next(result);
                subscriber.complete();
              },
              (err) => {
                // Traitement different si status == 409, existing object
                if (err.status === 409) {
                  if (object.hasOwnProperty('version') && object.hasOwnProperty('id')) {
                    // Recover object from db
                    // @ts-ignore
                    this.get(object.id.toString())
                      .subscribe(dbObject => {
                        // Found corresponding object
                        if (object.hasOwnProperty('version') && dbObject.hasOwnProperty('version')) {
                          // @ts-ignore
                          if (object.version > dbObject.version ||
                            // @ts-ignore
                            (object.version === dbObject.version && (dbObject.hasOwnProperty('local') && dbObject.local === true))) {
                            // Now, we replace with desired version
                            // @ts-ignore
                            object._id = dbObject._id;
                            // @ts-ignore
                            object._rev = dbObject._rev;
                            try {
                              this.db.put(object)
                                .then(
                                  (result) => {
                                    subscriber.next(result);
                                    subscriber.complete();
                                  },
                                  (error2) => {
                                    console.error('Quitting updating object after 2 failed attempts', error2);
                                    this.log.error('Quitting updating object after 2 failed attempts', error2);
                                    subscriber.error(error2);
                                    subscriber.complete();
                                  });
                            } catch (exception) {
                              this.log.error('Problem updating object', exception);
                              subscriber.error(exception);
                              subscriber.complete();
                            }
                          } else {
                            // Better version already in store, send that one back
                            subscriber.next(dbObject);
                            subscriber.complete();
                          }
                        } else {
                          this.log.error('Unable to put object in the database ', this.storeName, object, err);
                          subscriber.error(err);
                          subscriber.complete();
                        }
                      }, error => {
                        this.log.error('Unable to put object in the database ', this.storeName, object, err);
                        subscriber.error(error);
                        subscriber.complete();
                      });
                  } else {
                    this.log.error('Unable to put object in the database ', this.storeName, object, err);
                    subscriber.error(err);
                    subscriber.complete();
                  }
                } else {
                  this.log.error('Unable to put object in the database ', this.storeName, object, err);
                  subscriber.error(err);
                  subscriber.complete();
                }
              });
        });
    });
  }

  bulkDocs(objects: Array<T>): Observable<Array<T>> {
    return new Observable(subscriber => {
      this.ready()
        .then(() => {
          this.db.bulkDocs(objects)
            .then(
              results => {
                subscriber.next(results);
                subscriber.complete();
              }, err => {
                subscriber.error(err);
                subscriber.complete();
              });
        });
    });
  }

  remove(object: T): Observable<T> {
    return new Observable(subscriber => {
      this.ready()
        .then(() => {
          this.db.remove(object)
            .then(result => {
              subscriber.next(result);
              subscriber.complete();
            }, err => {
              if (err.status === 404) { // If not present nothing to do
                subscriber.next(null);
                subscriber.complete();
              } else if (err.status === 409) {
                // On 409 (conflict), recover info and delete again if still existing
                // @ts-ignore
                const objectId = object.id;
                if (objectId) {
                  this.db.get(objectId.toString(), {revs: false})
                    .then(
                      recovered => {
                        this.db.remove(recovered._id, recovered._rev).then(removed => {
                          subscriber.next(removed);
                          subscriber.complete();
                        }, removeError => {
                          if (removeError.status === 404 || removeError.status === 409) {
                            subscriber.next(null);
                            subscriber.complete();
                          } else {
                            subscriber.error(removeError);
                            subscriber.complete();
                          }
                        });
                      },
                      () => {
                        // We now are sure why the remove failed
                        subscriber.next(null);
                        subscriber.complete();
                      });
                } else {
                  subscriber.next(null);
                  subscriber.complete();
                }
              } else { // Another type of error, we fail
                subscriber.error(err);
                subscriber.complete();
              }
            });
        });
    });
  }

  removeAll(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await this.ready()
      const allDocs = await this.db.allDocs({ include_docs: true });
      const deleteDocs = allDocs.rows.map(row => {
        return { _id: row.id, _rev: row.doc._rev, _deleted: true };
      });
      this.bulkDocs(deleteDocs).subscribe(
        _ => { },
        _ => { },
        () => {
          console.info('bulkDocs complete');
          resolve();
        });
    });
  }


  getAll(): Observable<Array<T>> {
    return new Observable(subscriber => {
      this.ready()
        .then(() => {
          this.db.allDocs({
            include_docs: true
          })
            .then(
              allDocs => {
                const objects: Array<T> = [];
                allDocs.rows.forEach((row) => {
                  if (!row.doc._deleted) {
                    objects.push(plainToClass(this.cls, row.doc));
                  }
                });
                subscriber.next(objects);
                subscriber.complete();
              },
              err => {
                subscriber.error(err);
                subscriber.complete();
              }
            );
        });
    });
  }

  /**
   * For unit tests only !
   */
  public recreateDatabse_FOR_TESTS_ONLY(userProfile: UserProfile): Promise<string> {
    return this.destroyDatabase()
      .then(() => this.initDatabase());
  }

  private initDatabase(): Promise<string> {
    this._changeTrigger.next('retryRegistering');

    const dbName = 'mbls_' + this.storeName + this.userProfile.id;
    this.log.info(`Initializing ${this.storeName} database with name : ${dbName}`);

    console.info('SQLite plugin is installed?: ' + (!!(window as any).sqlitePlugin));

    if ((!!(window as any).sqlitePlugin)) {
      PouchDB.plugin(require('pouchdb-adapter-cordova-sqlite'));
    }
    const conf = {
      iosDatabaseLocation: 'default', // default means that the db subdirectory is NOT visible to iTunes and NOT backed up by iCloud see https://github.com/storesafe/cordova-sqlite-storage#opening-a-database
      androidDatabaseProvider: 'system' // see https://github.com/storesafe/cordova-sqlite-storage#android-database-provider
    };
    if ((!!(window as any).sqlitePlugin)) {
      conf['adapter'] = 'cordova-sqlite';
    }

    this.db = new PouchDB(dbName, conf as any);

    this.log.trace(`${dbName} PouchDB database adapter is : ${this.db.adapter}`);
    this.db.info()
      .then(info => {
        this.log.info('info on db', dbName, info);
      });

    this.listenChanges(); // TODO : be sure that when recreating the database, we don't listen again the changes and emit 2 event for 1 change

    this.d.resolve(dbName);
    return Promise.resolve(dbName);
  }

  public destroyDatabase(): Promise<void> {
    if (this.db) {
      return this.db.destroy()
        .catch(error => this.log.error(error));
    } else {
      return Promise.resolve();
    }
  }

  private makeStoreNotReady() {
    this.log.trace(`Cleaning ${this.storeName} store on command`);
    this.d = defer<string>();
    this._readyPromise = this.d.promise;
    from(this.destroyDatabase())// FIXME
      .subscribe(
        () => {
          this.db = null;
        },
        null,
        () => {
          this._changeTrigger.next('onReset');
        }
      );
  }

  // Database live changes
  private listenChanges() {
    const since = this.bdListenChangesMode === DBListenChangesMode.SINCE_BEGINNIG ? 0 : 'now';
    this.db.changes({
      since,
      live: true,
      include_docs: true
    }).on('change', change => {
      this.getInfo()
        .then((information) => {
          if (information) {
            // console.info('COUNTER', information.doc_count);
            this._countTrigger.next(information.doc_count);
          } else {
            // console.info('COUNTER', 0);
            this._countTrigger.next(0);
          }
        });

      const changeItem = new ObjectDatabaseChange<T>();

      if (change.deleted) {
        changeItem.deleted = true;
      } else {
        if (change.doc._rev.startsWith('1-')) {
          changeItem.created = true;
          changeItem.updated = false;
        } else {
          changeItem.created = false;
          changeItem.updated = true;
        }
      }
      if (change.deleted) {
        changeItem.doc = new this.cls(change.doc._id);
      } else {
        changeItem.doc = plainToClass(this.cls, change.doc);
      }

      this._liveChangeTrigger.next(changeItem);
    }).on('error', err => {
      this.log.error('Live changes error:', err);
    });
  }
}
