import {OrderView} from '@models/order-helper/order-view.model';
import {Transition} from '@models/workflow/transition.model';

export class TransitionOrders {
  transition: Transition;
  orders: Array<OrderView> = [];

  constructor(transition: Transition) {
    this.transition = transition;
  }

  matches(transition: Transition) {
    return this.transition.workflowName === transition.workflowName && this.transition.event.name === transition.event.name;
  }
}
