import {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';


@Pipe({
  name: 'MobilusDesiredArrivalTime',
})

export class MobilusDesiredArrivalTimePipe implements PipeTransform {

  transform(value: any, hourFormat: TimeFormatEnum) {
    if (value) {
      if (+hourFormat === TimeFormatEnum.MILITARY) {
        return value;
      } else {
        const hour = value.substring(0, 2);
        const minutes = value.substring(3, 5);
        const hourToInt = parseInt(hour);
        let out = '';
        if (hourToInt > 12) {
          const newTime = hourToInt - 12;
          out = newTime.toString() + ':' + minutes + 'PM';
          return out;
        } else {
          out = value + 'AM';
          return out;
        }
      }
    }
  }
}
