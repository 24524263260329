import {MqttConfiguration} from '@models/configuration/mqtt-configuration.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {MqttMessageModel} from '@models/push-messages/mqtt-message.model';
import {MQTTStatusEnum} from '@models/push-messages/mqtt-status-enums.model';
import {MqttStatusMessage} from '@models/push-messages/mqtt-status-message.model';
import {PushMessage} from '@models/push-messages/push-message.model';
import {RemoteManagementCommandData} from '@models/push-messages/remote-management-command.data.model';
import {UserProfile} from '@models/user-profile.model';
import {classToPlain} from '@utils/json-converter/json-converter';
import {plainToClass} from '@utils/json-converter/json-converter';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import {PushAbstractService} from '@services/push/push-abstract.services';
// import {RegisterToken} from '@services/push/register-token.model';
import Mqtt from 'mqtt'
import {MqttClient} from 'mqtt';
import {QoS} from "mqtt-packet";

const CONFIG = {
  GA: {
    EVENT: {
      NEW_CONNECTION: {
        NAME: 'force-sync',
        ACTION: 'on-mqtt-new-connection'
      },
      NEW_DEVICE: {
        NAME: 'force-sync',
        ACTION: 'on-mqtt-new-device'
      },
      BAD_DISCONNECT: {
        NAME: 'force-sync',
        ACTION: 'on-mqtt-bad-disconnect'
      },
      LONG_TIMEOUT: {
        NAME: 'force-sync',
        ACTION: 'on-long-timeout-connection'
      }
    }
  }
};

// @ts-ignore
export class MQTTService extends PushAbstractService {

  public serviceName = 'MQTT';
  private canRegister = false;

  private client: MqttClient;
  private configuration: MqttConfiguration = null;
  private userProfile: UserProfile = null;

  private lastStatus: MqttStatusMessage = null;
  private connectedStatus: MqttStatusMessage = null;
  private initialFetchPerformed: Boolean = false;

  private topicStatuses: string;
  private topicMessages: string;
  private topicHaulerMessages: string;

  private haulerEmployeeUuid: string;

  private clientConnectionResolved = false;

  constructor(private deviceInfo: DeviceInfo,
              private mobileContextService: MobileContextService,
              private log: LogService,
              private ga: MblsAnalyticsService,
              private orderSyncService: OrderSyncService,
  ) {
    super();

    // setTimeout(() => this.orderSync = this.injector.get(OrderSyncService));
    this.log.debug(`${this.serviceName} - initializing...`);

    this.mobileContextService.providedConfigurationObservable
      .subscribe(configuration => {
        this.log.debug('New configuration', configuration);
        if (configuration && configuration.mqtt) {
          this.configuration = configuration.mqtt;
          this.log.info('reconfigure and restart mqtt client');
          this.topicStatuses = this.configuration.topics.haulerEmployeeStatuses;
          this.topicMessages = this.configuration.topics.haulerEmployeeMessages;
          this.topicHaulerMessages = this.configuration.topics.haulerMessages;
          this.configure()
            .then(() => {
              this.log.info('MQTT configure completed');
            }, error => {
              this.log.debug('MQTT configure error 3', error);
            });
        } else {
          this.configuration = null;
        }
      });

    this.mobileContextService.userProfileObservable
      .subscribe(userProfile => {
        if (userProfile) {
          this.userProfile = userProfile;
          this.haulerEmployeeUuid = this.userProfile.haulerEmployeeUuid;
          this.configure()
            .then(() => {
            }, error => {
              this.log.debug('MQTT configure error 4', error);
            });
        } else if (this.userProfile) {
          this.cleanup();
          this.userProfile = null;
          this.haulerEmployeeUuid = null;
        }
      });
  }


  public init(): Promise<void> {
    this.log.debug(`${this.serviceName} : subscribing to push notifications`);
    this.canRegister = true;
    return this.configure();
  }

  convert(source: any): PushMessage {
    try {
      const mqttMessage: MqttMessageModel = plainToClass(MqttMessageModel, JSON.parse(source).data);

      const pushMessage = new PushMessage();
      pushMessage.timestamp = mqttMessage.timestamp;
      if (mqttMessage.isRemoteManagementRequest) {
        const map = new Map<string, string>();
        if (mqttMessage.payload) {
          try {
            mqttMessage.payload.forEach(pair => {
              map.set(pair.key, pair.value);
            });
          } catch (e) {
            this.log.error('Unable to parse remote command payload', mqttMessage.payload);
            return null;
          }
        }
        pushMessage.remoteManagementCommandData = new RemoteManagementCommandData(mqttMessage.remoteManagementCommand, map, mqttMessage.userSettings);
      } else if (mqttMessage.hasOrderData) {
        pushMessage.orderData = mqttMessage.orderMessage;
      } else if (mqttMessage.isUserNotification) {
        mqttMessage.notification.timestamp = mqttMessage.timestamp;
        pushMessage.notificationData = mqttMessage.notification;
        pushMessage.notificationData.generator = this.serviceName;
      } else {
        this.log.error('received message is not of known type', mqttMessage, source);
      }
      return pushMessage;
    } catch (error) {
      console.error(error);
      this.log.error('Unable to parse push message. Skipping.', source);
      return null;
    }
  }

  public cleanup(): Promise<void> {
    this.canRegister = false;
    this.clientConnectionResolved = false;
    this.log.debug(`${this.serviceName} - unregistering`);
    return new Promise((resolve, reject) => {
      this.log.debug('Unsubscribing from topics');
      this.client.unsubscribe(this.topicStatuses);
      this.client.unsubscribe(this.topicMessages);
      this.client.unsubscribe(this.topicHaulerMessages);
      this.log.debug('Publishing DISCONNECTED_GRACEFULLY status' + this.client.connected + ' ' + this.client.disconnected + ' ' + this.topicStatuses, this.client);
      this.sendConnectionStatus(MQTTStatusEnum.DISCONNECTED_GRACEFULLY)
        .then(() => {
          this.log.debug('Unregister status sent');
        }, error => {
          this.log.error('Error while sending unregister status', error);
        })
        .then(() => {
          this.log.debug('Publish status done, ending the MQTT client');
          this.lastStatus = null;
          this.initialFetchPerformed = false;
          this.connectedStatus = null;
          this.client.end(false, () => {
            this.log.debug('MQTT client ended.');
          });
        });
      resolve();
    });
  }

  public sendRefreshConnectedStatus() {
    this.sendConnectionStatus(MQTTStatusEnum.CONNECTED);
  }

  private sendConnectionStatus(status: MQTTStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.haulerEmployeeUuid) {
        this.connectedStatus = new MqttStatusMessage(status, this.haulerEmployeeUuid, this.deviceInfo.uuid);
        const messageString = JSON.stringify(classToPlain(this.connectedStatus));
        this.log.debug('Publishing ' + status + ' status on topic ' + this.topicStatuses + ' ' + this.haulerEmployeeUuid + ' ' + this.deviceInfo.uuid);
        this.client.publish(this.topicStatuses, messageString, {
          qos: <QoS>this.configuration.qos || 2,
          retain: true
        }, error => {
          if (error) {
            reject(error);
            this.log.trace('Status message cannot be published', error);
          } else {
            resolve();
            this.log.trace('Status message has been published');
          }
        });
      } else {
        reject('No user with haulerEmployeeUuid available');
      }
    });
  }

  reconnect() {
    this.log.info('Reconnecting MQTT client');
    if (this.client && !this.client.connected) {
      this.configure();
    }
  }


  disconnect() {
    this.log.info('Disconnecting MQTT client');
    if (this.client) {
      this.client.end(false, () => {
        this.log.info('removing dead client');
      });
    }
  }

  private configure(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.client && this.client.connected) {
        this.log.trace('MQTT client existing : ending it now');
        return this.client.end(true, () => {
          setTimeout(() => {
            this.handleLongTimeout();
          }, 5 * 1000);
          this.log.trace('MQTT client ended');
          this.initAndConnectClient()
            .then(() => {
              resolve();
            }, () => {
              reject();
            });
        });
      } else {
        if (!this.canRegister || !this.configuration || !this.userProfile) {
          this.log.trace('MQTT configure called but lacking the necessary data to do it', this.canRegister, this.configuration, this.userProfile);
          resolve();
          return;
        } else {
          setTimeout(() => {
            this.handleLongTimeout();
          }, 5 * 1000);
          this.initAndConnectClient()
            .then(() => {
              resolve();
            }, () => {
              reject();
            });
        }
      }
    });
  }

  private initAndConnectClient(): Promise<void> {
    return new Promise((resolve, reject) => {
      const connectionOptions = this.configuration.asOptions(this.userProfile.haulerEmployeeUuid, this.deviceInfo.uuid);
      const brokerUrl = this.configuration.brokerUrl || 'ws://localhost:8000';
      this.log.debug('Old MQTT client', this.client);
      this.log.trace(`Configuring and connecting ${this.serviceName} on ${brokerUrl} with options : `, connectionOptions);
      try {
        this.client = Mqtt.connect(brokerUrl, connectionOptions);
      } catch (e) {
        console.error('Failed to connect mqtt', e);
        reject('unable to connect');
      }

      const channels = [this.topicStatuses, this.topicMessages, this.topicHaulerMessages];
      this.log.info('MQTT subscribing to channels: ', channels);
      this.client.subscribe(channels, {qos: <QoS>this.configuration.qos || 2}, (err, granted) => {
        if (err) {
          this.log.error('Error while subscribing: ', err);
          reject('unable to subscribe');
          this.orderSyncService.fullSync().subscribe();
          this.initialFetchPerformed = true;
          return;
        }
        if (granted) {
          this.log.debug('Subscription granted, ', granted);
        }
      });


      this.log.debug('New MQTT Client', this.client);
      this.client.on('message', (topic, message) => {
        this.log.debug(`MQTT message received on topic 22 ${topic}`);
        this.log.trace(`Complete message`, message.toString());
        if (topic === this.topicStatuses) {
          let statusMessage: MqttStatusMessage;
          try {
            statusMessage = plainToClass(MqttStatusMessage, JSON.parse(message.toString()));
          } catch (error) {
            this.log.error('Unable to convert MQTT message : ', error);
          }
          if (statusMessage) {
            if (!this.lastStatus) {
              this.lastStatus = statusMessage;
              if (!this.connectedStatus) {
                this.log.debug('Never was connected, performing a full sync.');
                this.ga.trackEvent(CONFIG.GA.EVENT.NEW_CONNECTION.NAME, CONFIG.GA.EVENT.NEW_CONNECTION.ACTION)
                  .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.NEW_CONNECTION} with GA`, error));
                this.orderSyncService.fullSync().subscribe();
                this.initialFetchPerformed = true;
              } else if (this.lastStatus.date.isSame(this.connectedStatus.date)) {
                this.log.debug('Last status has been emitted by this current session : this is the first time the user connect with this device, performing a full sync.');
                this.ga.trackEvent(CONFIG.GA.EVENT.NEW_DEVICE.NAME, CONFIG.GA.EVENT.NEW_DEVICE.ACTION)
                  .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.NEW_DEVICE} with GA`, error));
                this.orderSyncService.fullSync().subscribe();
                this.initialFetchPerformed = true;
              } else if (this.lastStatus.status !== MQTTStatusEnum.CONNECTED) {
                this.log.debug('Last status has NOT been emitted by this current session AND was a disconnected status : performing a full sync.');
                this.ga.trackEvent(CONFIG.GA.EVENT.BAD_DISCONNECT.NAME, CONFIG.GA.EVENT.BAD_DISCONNECT.ACTION)
                  .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.BAD_DISCONNECT} with GA`, error));
                this.orderSyncService.fullSync().subscribe();
                this.initialFetchPerformed = true;
              } else {
                this.log.debug('Nothing to sync.');
              }
            } else {
              this.log.debug('Nothing to do : already got our status message.', this.lastStatus);
            }
          }
        } else if (topic === this.topicMessages || topic === this.topicHaulerMessages) {
          this.handlePush(message.toString());
        } else {
          this.log.debug(`MQTT message received on unknown topic ${topic}, dropping the message : `, message);
        }
      });

      this.client.on('end', () => {
        this.log.info('MQTT client ended.');
        this.updateMqttConnectionStatus();
      });

      this.client.on('reconnect', () => {
        this.log.info('MQTT client reconnect start...');
        this.updateMqttConnectionStatus();
      });

      this.client.on('offline', () => {
        this.log.info('MQTT client offline.');
        this.updateMqttConnectionStatus();
      });

      this.client.on('error', error => {
        this.log.fatal('MQTT client encountered an error!', error);
        this.updateMqttConnectionStatus();
      });

      this.client.on('connect', connack => {
        this.log.info('MQTT client successfully connected.', connack);
        if (this.client) {
          this.sendConnectionStatus(MQTTStatusEnum.CONNECTED);
          this.updateMqttConnectionStatus();
          if (!this.configuration.useWillAndTestament) {
            if (!this.initialFetchPerformed) {
              // Backup for full sync if not happening with that status message
              this.log.info('Full sync because will not be happening with that status message - no will and testament (aws iot core)');
              this.orderSyncService.fullSync().subscribe();
              this.initialFetchPerformed = true;
            }
          }
        } else {
          this.log.warn('MQTT Connection confirmed but client is now null');
        }
      });

      this.client.on('close', () => {
        this.log.info('MQTT client closed.');
        this.updateMqttConnectionStatus();
      });

      this.client.on('packetsend', packet => {
        this.log.trace('MQTT client packetsend', packet);
      });

      this.client.on('packetreceive', packet => {
        this.log.trace('MQTT client packetreceive', packet);
      });

      this.log.trace('MQTT client initialization and subscriptions completed.');

      this.clientConnectionResolved = true;
      resolve();
    });
  }

  private updateMqttConnectionStatus() {
    this.deviceInfo.networkStatus.isMqttConnected = {
      connected: this.client.connected,
      reconnecting: this.client.reconnecting
    };
  }

  private handleLongTimeout() {
    if (this.clientConnectionResolved !== true) {
      this.ga.trackEvent(CONFIG.GA.EVENT.LONG_TIMEOUT.NAME, CONFIG.GA.EVENT.LONG_TIMEOUT.ACTION)
        .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.LONG_TIMEOUT} with GA`, error));
      if (!this.initialFetchPerformed) {
        this.orderSyncService.fullSync().subscribe();
        this.initialFetchPerformed = true;
      }
    }
  }
}
