import { Injectable } from '@angular/core';
import { DistancePipe } from '@app/pipes/distance/distance.pipe';
import { DurationPipe } from '@app/pipes/duration/duration.pipe';
import { ToastController } from '@ionic/angular';
import { Order } from '@models/business/order.model';
import { Position } from '@models/business/position.model';
import { Tag } from '@models/business/tag.model';
import { RouteSummary } from '@models/optimize-route/route-summary.model';
import { OrderGroup } from '@models/order-helper/order-group.model';
import { OrderListForStatus } from '@models/order-helper/order-list-for-status.model';
import { OrderStatusConverter } from '@models/order-helper/order-status.enum';
import { OrderStatus } from '@models/order-helper/order-status.enum';
import { OrderView } from '@models/order-helper/order-view.model';
import { OrdersAccessor } from '@models/order-helper/orders-accessor.model';
import { PickupGroup } from '@models/order-helper/pickup-group.model';
import { StatusInfo } from '@models/order-helper/status-info.model';
import { ObjectDatabaseChange } from '@models/pouchdb/object-database-change-model';
import { UserSettings } from '@models/settings/settings.model';
import { StatusSettings } from '@models/settings/status-settings.model';
import { DistanceSystemEnum } from '@models/units-and-format/distance-system.enum';
import { TranslateService } from '@ngx-translate/core';
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { OrderStoreService } from '@services/order-store/order-store.service';
import { StorageService } from '@services/storage-service/storage.service';
import { TransitionService } from '@services/transition/transition.service';
import * as geolib from 'geolib';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';

const CONFIG = {
  UPDATE_ORDER_PAYMENT_PATH: '/mobile/v2/updatePaymentInformation',
  ROUTE_SUMMARY_STORAGE_KEY: 'ROUTE_SUMMARY_STORAGE_KEY',
};


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  public ordersByStatus: Map<OrderStatus, OrderListForStatus> = new Map();
  private _ordersAccessor: OrdersAccessor = new OrdersAccessor(this.ordersByStatus);

  private _ordersAccessorSubject: BehaviorSubject<OrdersAccessor> = new BehaviorSubject((this._ordersAccessor));
  public ordersAccessor: Observable<OrdersAccessor> = this._ordersAccessorSubject.asObservable();

  private _summarySubject: BehaviorSubject<RouteSummary> = new BehaviorSubject<RouteSummary>(null);
  public summaryObservable: Observable<RouteSummary> = this._summarySubject.asObservable();

  private listStatuses: Array<StatusInfo> = [
    new StatusInfo(OrderStatus.READY_FOR_HAULAGE, true),
    new StatusInfo(OrderStatus.ON_THE_WAY_TO_PROVIDER),
    new StatusInfo(OrderStatus.ON_THE_WAY_TO_CUSTOMER)
  ];
  private settings: UserSettings;
  private firstRun = true;
  private currentStatus: OrderStatus;
  private withDistance = false;

  constructor(private orderStoreService: OrderStoreService,
    private transitionService: TransitionService,
    private storageService: StorageService,
    private log: LogService,
    private mobileContextService: MobileContextService,
    private backgroundGeolocationService: BackgroundGeolocationService,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private durationPipe: DurationPipe,
    public distancePipe: DistancePipe,
  ) {
    this.mobileContextService.orderStatusObservable
      .subscribe(status => {
        this.currentStatus = status;
      });

    this.mobileContextService.userSettingsObservable
      .subscribe((next) => {
        this.settings = next;
        if (this.settings) {
          this.withDistance = this.settings.geotrackingEnabled;
          if (!this.withDistance) {
            this.resetDistances();
          }
          this._ordersAccessor.setOptions(this.settings);
          this.applyFilterAndSort();
        } else {
          this.withDistance = false;
        }
      });

    this.orderStoreService.changeObservable
      .subscribe((value) => {
        if (value === 'onReset') {
          // Cleanup
          this.emptyAllLists();
        } else {
          this.fetchFromStoreWhenReady();
        }
      });
    this.backgroundGeolocationService.positionObservable
      .pipe(
        // NOTE: we skip the first emitted value as this is always null and makes the app to reset/hide the order distance on the order list page
        // and these distances are not displayed until the next position emittion which might happen sometime in the future (undetermined) so there is
        // no orders distance shown
        // See the appropriate issue description here https://mobilus.myjetbrains.com/youtrack/issue/CAPP-44/Sort-by-distance-not-working-anymore#focus=Comments-4-400.0-0
        // To force getting the current position to be used instead of the first initial null we invoke
        // backgroundGeolocationService.recheckPosition (see below) which should make the orders distances to be recalculated
        // and refreshed. Please note that there might be a small delay until the position is received
        skip(1)
      )
      .subscribe((position: Position) => {
        if (position) {
          this.calculateDistances(position);
        } else {
          this.resetDistances();
        }
      });
      this.backgroundGeolocationService.recheckPosition();

  }

  calculateDistances(position: Position) {
    if (this.currentStatus) {
      const orderList = this.ordersByStatus.get(this.currentStatus);
      if (orderList && orderList.rawOrders) {
        orderList.rawOrders.orders.forEach(view => {
          const order: Order = view.order;
          try {
            const orderDistanceInKm = geolib.getDistance(position, order.deliveryAddress.position) / 1000;
            if (+this.settings.distanceUnit === DistanceSystemEnum.SI) {
              order.distance = orderDistanceInKm;
              order.distanceUnit = this.translateService.instant('distanceUnits.km');
            } else {
              order.distance = orderDistanceInKm * 0.621371;
              order.distanceUnit = this.translateService.instant('distanceUnits.miles');
            }
            order.bearing = geolib.getCompassDirection(position, order.deliveryAddress.position);
          } catch (e) {
            console.error('Unable to calculate position', position);
          }
        });
        const statusSettings = this.settings.settingsForStatus(this.currentStatus);
        if (statusSettings.sortOrder === 'distance') {
          this.applyFilterAndSort();
        }
      }
    }
  }

  resetDistances() {
    this.ordersByStatus.forEach(orderListForAStatus => {
      if (orderListForAStatus.rawOrders) {
        orderListForAStatus.rawOrders.orders.forEach(view => {
          view.order.distance = null;
        });
      }
    });
  }

  applyFilterAndSort(filterOnly: boolean = false): boolean {
    if (this.currentStatus && this.settings) {
      let changed = false;
      this.listStatuses.forEach((status: StatusInfo) => {
        const withStatus = this.ordersByStatus.get(status.name);
        const statusSettings = this.settings.settingsForStatus(status.name);
        if (withStatus) {
          // Sort provider grouped orders
          withStatus._groupedOrders.forEach((orderGroup: OrderGroup) => {
            this.filterAndSortOrders(orderGroup.orders, statusSettings, filterOnly);
          });

          // Sort ungrouped orders
          this.filterAndSortOrders(withStatus.rawOrders.orders, statusSettings, filterOnly);
          changed = true;
        }
      });

      if (changed) {
        this._ordersAccessorSubject.next(this._ordersAccessor);
      }
      return !changed; // Should notify
    } else {
      return true; // Should notify
    }
  }

  public constructOrderView(order: Order, forImpersonation: boolean = false): OrderView {
    const transitions = this.transitionService.getAvailableTransitionsForOrder(order);
    const view = new OrderView(order, transitions);
    if (forImpersonation) {
      view.withImpersonation();
    }
    return view;
  }

  getOrderById(orderId: string): Observable<Order> {
    return this.orderStoreService.get(orderId);
  }
  // getOrderById2(orderId: string): Promise<Order> {
  //   return new Promise((resolve: (val: Order) => void, reject: (reason?: any) => void) => {
  //     this.orderStoreService.allDocs()
  //       .subscribe(orders => {
  //         let order: Order = null;
  //         if (orders.length > 0) {
  //           order = orders.find(val => { val.id === orderId });
  //         }
  //         resolve(order);
  //       }, error => {
  //         reject(error);
  //       });
  //   });
  // }

  private filterAndSortOrders(orders: Array<OrderView>, settings: StatusSettings, filterOnly: boolean = false) {
    // FILTER
    orders
      .forEach((orderView: OrderView) => {
        const order = orderView.order;

        const allFieldsFilterContribution = !settings.activeFilter.allFields
          || order.client.lastName.toLowerCase().indexOf(settings.activeFilter.allFields.toLowerCase()) > -1
          || order.client.firstName.toLowerCase().indexOf(settings.activeFilter.allFields.toLowerCase()) > -1
          || order.deliveryAddress.addressOneLine.toLowerCase().indexOf(settings.activeFilter.allFields.toLowerCase()) > -1
          ;

        const tagContribution = !settings.activeFilter.tagFilter // make sure to return true if not activated
          || settings.activeFilter.tagFilter.length < 1
          || order.tags.filter((tag: Tag) => {
            return settings.activeFilter.tagFilter.indexOf(tag.label) > -1;
          }).length > 0
          || order.client.tags.filter((tag: Tag) => {
            return settings.activeFilter.tagFilter.indexOf(tag.label) > -1;
          }).length > 0
          ;

        const timeConstraintContribution = !settings.activeFilter.withTimeConstraint
          || (order.desiredArrivalTime && order.desiredArrivalTimeHour)
          ;

        orderView.hidden = !(allFieldsFilterContribution
          && tagContribution
          && timeConstraintContribution
        );
      });

    // SORT
    if (!filterOnly) {
      orders
        .sort((a: OrderView, b: OrderView) => {
          if (settings.sortOrder === 'sequence') {
            if (!a.order.sequence && !b.order.sequence) {
              return a.order.compareByLastnameFallbackFirstNameAndId(b.order);
            } else if (a.order.sequence && b.order.sequence) {
              return a.order.sequence - b.order.sequence;
            } else if (a.order.sequence || b.order.sequence) { // s'il y en a juste un, le mettre en premier
              if (a.order.sequence) {
                return -1;
              } else {
                return 1;
              }
            }
          } else if (settings.sortOrder === 'distance') {
            if (!a.order.distance && !b.order.distance) {
              return a.order.compareByLastnameFallbackFirstNameAndId(b.order);
            } else if (a.order.distance && b.order.distance) {
              return a.order.distance - b.order.distance;
            } else {
              if (a.order.distance) {
                return -1;
              } else {
                return 1;
              }
            }
          } else if (settings.sortOrder === 'lastName') {
            return a.order.compareByLastnameFallbackFirstNameAndId(b.order);
          } else if (settings.sortOrder === 'avgtime') {
            if (!a.order.averageDeliveryMoment && !b.order.averageDeliveryMoment) {
              return a.order.compareByLastnameFallbackFirstNameAndId(b.order);
            } else if (a.order.averageDeliveryMoment && b.order.averageDeliveryMoment) {
              return a.order.averageDeliveryMoment.localeCompare(b.order.averageDeliveryMoment);
            } else {
              if (a.order.averageDeliveryMoment) {
                return -1;
              } else {
                return 1;
              }
            }
          } else {
            return 0;
          }
        });
    }
  }

  private manageLiveChanges(orderChange: ObjectDatabaseChange<Order>) {
    // Find corresponding order anywhere (grouped and raw) and remove
    this.listStatuses.forEach((status: StatusInfo) => {
      const ordersList = this.ordersByStatus.get(status.name);

      const indexInRaw = ordersList.rawOrders.orders.findIndex((view: OrderView) => view.order.id.toString() === orderChange.doc.id.toString());
      // We found the order in the status without grouping
      if (indexInRaw > -1) {
        ordersList.rawOrders.orders.splice(indexInRaw, 1);
      }

      ordersList._groupedOrders.some((group: OrderGroup) => {
        // if the group matches the tenant
        // PM-166 Sometime, the orders do not match. Using toString make sure they are always compared in the same way
        const index = group.orders.findIndex((value) => value.order.id.toString() === orderChange.doc.id.toString());
        if (index > -1) {
          group.orders.splice(index, 1);
          return true;
        }
        return false;
      });
    });

    // Add to fitting status (grouped and raw)
    let shouldNotify = true;
    if (!orderChange.deleted) {
      const newView = this.constructOrderView(orderChange.doc);

      const ordersList = this.ordersByStatus.get(OrderStatusConverter.convert(orderChange.doc.status));

      const currentOrderStatus = this.listStatuses.find(it => it.name === OrderStatusConverter.convert(orderChange.doc.status));

      if (ordersList) {
        const existingRaw = ordersList.rawOrders.orders.findIndex((value) => value.order.id.toString() === newView.order.id.toString());
        if (existingRaw < 0) { // PM-436
          ordersList.rawOrders.orders.push(newView);
        } else {
          this.log.warn('Trying to put an order twice in raw list', newView);
        }

        if (currentOrderStatus.byOrderType && orderChange.doc.isPickup()) {
          const pickupGroup = ordersList._groupedOrders.find(it => it.isWorkflowGroup());
          if (pickupGroup) {
            const existingInPickupGroup = pickupGroup.orders.findIndex((value) => value.order.id.toString() === newView.order.id.toString());
            if (existingInPickupGroup < 0) { // PM-436
              pickupGroup.orders.push(newView);
            } else {
              this.log.warn('Trying to put an order twice in pickup list', newView);
            }
          } else {
            const newPickupGroup = new PickupGroup();
            newPickupGroup.orders.push(newView); // Neuf, ne peut contenir de doublons
            ordersList.addGroup(newPickupGroup);
          }
        } else {
          // Resulting status may not be tracked (like DELIVERED)
          let found = false;
          ordersList._groupedOrders.forEach((group: OrderGroup) => {
            if (group.name === orderChange.doc.tenantName) {
              const existingInGroup = group.orders.findIndex((value) => value.order.id.toString() === newView.order.id.toString());
              if (existingInGroup < 0) { // PM-436
                group.orders.push(newView);
              } else {
                this.log.warn('Trying to put an order twice in a specific group', newView);
              }
              found = true;
            }
          });

          // If tenant group not existing yet
          if (!found) {
            const newGroup = new OrderGroup(orderChange.doc.tenantName);
            newGroup.orders.push(newView); // Neuf, ne peut contenir de doublons
            ordersList.addGroup(newGroup);
          }
        }
      }
      // We only need to re-sort on addition. Deletion removes in place.
      shouldNotify = this.applyFilterAndSort(this.settings.actions.canReorder);
    }

    if (shouldNotify) {
      this._ordersAccessorSubject.next(this._ordersAccessor);
    }
  }

  private emptyAllLists() {
    this.listStatuses.forEach((status: StatusInfo) => {
      const statusEntry = new OrderListForStatus(status.name);
      this.ordersByStatus.set(status.name, statusEntry);
    });

    this._ordersAccessorSubject.next(this._ordersAccessor);
  }

  private fetchFromStoreWhenReady() {
    this.orderStoreService.ready()
      .then((msg) => {
        // Apply filter when all promises are resolved
        combineLatest(this.prepareData())
          .subscribe(() => {
            this.applyFilterAndSort();
          });

        this.orderStoreService
          .getLiveChanges(false, true, true, null)
          .subscribe(orderChange => {
            // Reordering manages array in place,
            // no need to go through the process again
            this.manageLiveChanges(orderChange);
          });
      });
  }

  async updateSummary(summary: RouteSummary) {
    this.persistRouteSummary(summary);
    if (this.settings && this.settings.routeEtaActivated) {
      const travelDistance = this.distancePipe.transform(summary.totalDistanceKm, this.settings.distanceUnit, true);
      const message = this.translateService
        .instant('optimizeOrders.routeHasBeenOptimized', {
          stops: summary.stopCount,
          distance: travelDistance,
          travelTime: this.durationPipe.transform(summary.totalTravelTimeMin, 'minutes'),
        });

      const toast = await this.toastCtrl
        .create({
          message,
          position: 'bottom',
          buttons: [{
            text: this.translateService.instant('actions.ok'),
            role: 'cancel'
          }],
          duration: 10 * 1000,
          cssClass: 'processing-toast'
        });
      toast.present();
    }

    this._summarySubject.next(summary);
  }

  public loadRouteSummary() {
    if (!this.firstRun) {
      return;
    }
    const ordersOnTheWayToCustomer: OrderListForStatus = this.ordersByStatus.get(OrderStatus.ON_THE_WAY_TO_CUSTOMER);
    if (ordersOnTheWayToCustomer && ordersOnTheWayToCustomer.rawOrders && ordersOnTheWayToCustomer.rawOrders.orders && ordersOnTheWayToCustomer.rawOrders.orders.length !== 0) {
      this.storageService.loadObject(CONFIG.ROUTE_SUMMARY_STORAGE_KEY, RouteSummary)
        .then(routeSummary => {
          this.log.trace('Checking if route summary is still valid ', routeSummary);
          const atLeastOne = ordersOnTheWayToCustomer.rawOrders.orders.some((orderView: OrderView) => {
            const order = orderView.order;
            if (!order.routeId || order.routeId !== routeSummary.id) {
              this.log.trace('An order does not have or have a different route summary : deleting route summary');
              return true;
            }
          });
          this.firstRun = false;
          if (!atLeastOne) {
            this.log.trace('Previously saved route summary is still valid.');
            this._summarySubject.next(routeSummary);
          }
        }, () => this._summarySubject.next(null));
    }
  }

  private persistRouteSummary(routeSummary: RouteSummary) {
    this.storageService.persistObject(CONFIG.ROUTE_SUMMARY_STORAGE_KEY, routeSummary);
  }

  private prepareData() {
    const observables = [];
    // Prepare data structure for each status
    this.listStatuses.forEach((status: StatusInfo) => {
      const statusEntry = new OrderListForStatus(status.name);

      this.ordersByStatus.set(status.name, statusEntry);
      observables.push(
        new Observable(subscriber => {
          this.orderStoreService.allDocs(status.name.toString())
            .subscribe(
              orders => {
                if (status.byOrderType) {
                  orders.sort((a: Order, b: Order) => {
                    if (a.stateMachineWorkflow === b.stateMachineWorkflow) {
                      return a.tenantName.localeCompare(b.tenantName);
                    } else if (a.isPickup() || b.isPickup()) {
                      return a.isPickup() ? -1 : 1;

                    }
                    return a.tenantName.localeCompare(b.tenantName);
                  });
                } else {
                  // sort by tenant
                  orders.sort((a: Order, b: Order) => {
                    return a.tenantName.localeCompare(b.tenantName);
                  });
                }

                let currentTenant = '';
                let ordersView: Array<OrderView>;
                let specialGroup: OrderGroup;

                if (status.byOrderType) {
                  specialGroup = new PickupGroup();
                  statusEntry.addGroup(specialGroup);
                  ordersView = specialGroup.orders;
                }

                orders.forEach(order => {
                  const orderView = this.constructOrderView(order);
                  statusEntry.rawOrders.orders.push(orderView);
                  // FIXME make more flexible/robust by putting workflow name in statusInfo
                  if (!status.byOrderType || order.stateMachineWorkflow !== 'PICKUP') {
                    if (order.tenantName !== currentTenant) {
                      currentTenant = order.tenantName;
                      const newGroup = new OrderGroup(order.tenantName);
                      ordersView = newGroup.orders;
                      statusEntry.addGroup(newGroup);
                    }
                  }
                  ordersView.push(orderView);
                });

                // Remove pickup if empty
                if (status.byOrderType && specialGroup && specialGroup.orders.length < 1) {
                  statusEntry._groupedOrders.splice(statusEntry._groupedOrders.indexOf(specialGroup), 1);
                }

                subscriber.next(statusEntry);
              }
            ),
            null,
            () => {
              subscriber.complete();
            };
        }));
    });

    return observables;
  }
}
