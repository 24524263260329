import {NetworkType} from '@models/information/network-type.enum';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

@JsonObject('NetworkStatus')
export class NetworkStatus {

  type: NetworkType;
  @JsonProperty('networkType', String, true)
  typeAsString: string = undefined;

  constructor() {
  }

  @JsonProperty('isConnected', Boolean, true)
  private _isConnected: boolean = undefined;

  private mqttChangeSubject: BehaviorSubject<{ connected: boolean, reconnecting: boolean }> = new BehaviorSubject({
    connected: true,
    reconnecting: false
  });

  private networkChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._isConnected);
  public networkChange: Observable<boolean> = this.networkChangeSubject.asObservable();
  public mqttChange: Observable<{ connected: boolean, reconnecting: boolean }> = this.mqttChangeSubject.asObservable();

  private _isMqttConnected: { connected: boolean, reconnecting: boolean } = undefined;

  get isConnected(): boolean {
    return this._isConnected;
  }

  set isConnected(value: boolean) {
    if (this._isConnected !== value) {
      // only broadcast real changes
      this._isConnected = value;
      this.networkChangeSubject.next(value);
    }
  }

  get isMqttConnected() {
    return this._isMqttConnected;
  }

  set isMqttConnected(mqtt: { connected: boolean, reconnecting: boolean }) {
    this._isMqttConnected = mqtt;
    this.mqttChangeSubject.next(mqtt);
  }

  isConnectedBasedOnType(): boolean {
    return (this.type && (this.type === NetworkType.ETHERNET || this.type === NetworkType.WIFI || this.type === NetworkType.SECOND_GENERATION || this.type === NetworkType.THIRD_GENERATION || this.type === NetworkType.FOURTH_GENERATION || this.type === NetworkType.CELLULAR));
  }

  setType(type: string) {
    this.typeAsString = type;
    switch (type) {
      case 'unknown': {
        this.type = NetworkType.UNKNOWN;
        break;
      }
      case 'ethernet': {
        this.type = NetworkType.ETHERNET;
        break;
      }
      case 'wifi': {
        this.type = NetworkType.WIFI;
        break;
      }
      case '2g': {
        this.type = NetworkType.SECOND_GENERATION;
        break;
      }
      case '3g': {
        this.type = NetworkType.THIRD_GENERATION;
        break;
      }
      case '4g': {
        this.type = NetworkType.FOURTH_GENERATION;
        break;
      }
      case 'cellular': {
        this.type = NetworkType.CELLULAR;
        break;
      }
      case 'none': {
        this.type = NetworkType.NONE;
        break;
      }
      default: {
        this.type = NetworkType.UNKNOWN;
        break;
      }
    }

  }
}
