import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('BackendEnum')
export class BackendEnum {

  @JsonProperty('enumType', String)
  enumType: string = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

}

@JsonObject('Tag')
export class Tag {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('archived', Boolean)
  archived: boolean = undefined;

  @JsonProperty('system', Boolean)
  system: boolean = undefined;

  @JsonProperty('color', String)
  color: string = undefined;

  @JsonProperty('icon', String)
  icon: string = undefined;

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('shortLabel', String, true)
  shortLabel: string = undefined;

  @JsonProperty('onMobile', Boolean)
  onMobile: boolean = undefined;

  @JsonProperty('invisibleToHauler', Boolean, true)
  invisibleToHauler: boolean = undefined;

  @JsonProperty('displayOption', String)
  displayOption: string = undefined;

}
