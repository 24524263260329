import { Component } from '@angular/core';
import { Order } from '@models/business/order.model';
import { ModalController, NavParams } from '@ionic/angular';
import { ClientService } from '@services/client/client.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimeFormatEnum } from '@models/units-and-format/time-format.enum';
import { DateTimeCombinationEnum } from '@models/units-and-format/date-format.enum';

// PROVIDERS
import { LogService } from '@services/log/log.service';

@Component({
  templateUrl: './driver-notes-modal.page.html',
  styleUrls: ['./driver-notes-modal.page.scss'],
})
export class DriverNotesModalPage {
  public title = 'pages.order-detail.driver_notes';

  private order: Order;

  withTimePreference = false;

  notes: string = undefined;

  desiredArrivalTime: string;

  desiredArrivalTimeHour: string;

  desiredArrivalTimeHourEnd: string;

  private unsubscribe: Subject<void> = new Subject<void>();

  public DateTimeCombinationEnum = DateTimeCombinationEnum;
  public TimeFormatEnum = TimeFormatEnum;
  public displayFormat: TimeFormatEnum = TimeFormatEnum.STANDARD;

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    private clientService: ClientService,
    private mobileContextService: MobileContextService,
    private log: LogService
  ) {
    this.order = this.navParams.get('order');
    if (this.order) {
      const driverOwnedInfo = this.order.client.driverOwnedInfo;

      this.notes = driverOwnedInfo.notes;

      this.desiredArrivalTime = driverOwnedInfo.desiredArrivalTime;

      if (driverOwnedInfo.desiredArrivalTime && driverOwnedInfo.desiredArrivalTime !== 'ANY') {
        this.withTimePreference = true;
      }

      if (!this.desiredArrivalTime || driverOwnedInfo.desiredArrivalTime === 'ANY') {
        this.desiredArrivalTime = 'BEFORE';
      }

      if (driverOwnedInfo.desiredArrivalTimeHour) {
        this.desiredArrivalTimeHour = driverOwnedInfo.desiredArrivalTimeHour;
      } else {
        this.desiredArrivalTimeHour = '08:00';
      }

      if (driverOwnedInfo.desiredArrivalTimeHourEnd) {
        this.desiredArrivalTimeHourEnd = driverOwnedInfo.desiredArrivalTimeHourEnd;
      } else {
        this.desiredArrivalTimeHourEnd = '12:00';
      }

    }
  }

  ionViewWillEnter(): void {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(settings => {
        if (settings) {
          this.displayFormat = settings.hourFormat;
        }
      });
  }

  public onArrivalTimeHourChange(time: string | string[]) {
    if (Array.isArray(time)) {
      this.log.error('[DriverNotesModalPage] onArrivalTimeHourChange - multiple selection is not supported in this component', time);
      if (time[0]) {
        this.desiredArrivalTimeHour = time[0];
      } else {
        this.desiredArrivalTimeHour = undefined;
      }
    } else {
      this.desiredArrivalTimeHour = time;
    }
  }
  public onArrivalTimeHourEndChange(time: string | string[]) {
    if (Array.isArray(time)) {
      this.log.error('[DriverNotesModalPage] onArrivalTimeHourEndChange - multiple selection is not supported in this component', time);
      if (time[0]) {
        this.desiredArrivalTimeHourEnd = time[0];
      } else {
        this.desiredArrivalTimeHourEnd = undefined;
      }
    } else {
      this.desiredArrivalTimeHourEnd = time;
    }
  }


  ionViewDidLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  save() {
    const driverOwnedInfo = this.order.client.driverOwnedInfo;
    driverOwnedInfo.notes = this.notes;

    if (!this.withTimePreference) {
      driverOwnedInfo.desiredArrivalTime = 'ANY';
      driverOwnedInfo.desiredArrivalTimeHour = null;
      driverOwnedInfo.desiredArrivalTimeHourEnd = null;
    } else if (this.desiredArrivalTime === 'BEFORE' || this.desiredArrivalTime === 'AFTER') {
      driverOwnedInfo.desiredArrivalTime = this.desiredArrivalTime;
      driverOwnedInfo.desiredArrivalTimeHour = this.desiredArrivalTimeHour;
      driverOwnedInfo.desiredArrivalTimeHourEnd = null;
    } else if (this.desiredArrivalTime === 'BETWEEN') {
      driverOwnedInfo.desiredArrivalTime = this.desiredArrivalTime;
      driverOwnedInfo.desiredArrivalTimeHour = this.desiredArrivalTimeHour;
      driverOwnedInfo.desiredArrivalTimeHourEnd = this.desiredArrivalTimeHourEnd;
    }

    this.clientService.updateClient(this.order.client, driverOwnedInfo);

    this.dismiss();
  }
}
