import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('UserProfile')
export class UserProfile {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('firstName', String)
  firstName: string = undefined;

  @JsonProperty('lastName', String)
  lastName: string = undefined;

  @JsonProperty('username', String)
  username: string = undefined;

  @JsonProperty('haulerEmployeeId', Number)
  haulerEmployeeId: number = undefined;

  @JsonProperty('haulerEmployeeUuid', String, true)
  haulerEmployeeUuid: string = undefined;

  @JsonProperty('roles', [String], true)
  roles: string[] = undefined;

  @JsonProperty('phoneNumber', String, true)
  phoneNumber: string = undefined;

  @JsonProperty('email', String, true)
  email: string = undefined;

  isHaulerEmployeeManager() {
    return this.roles.find(role => role === 'ROLE_HAULER_MANAGER');
  }

  isHaulerEmployee() {
    return this.roles.find(role => role === 'ROLE_HAULER_EMPLOYEE');
  }

  isAuthenticated() {
    return this.id !== undefined && this.id > 0;
  }

}
