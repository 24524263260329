import {Injectable} from '@angular/core';
import {OAuthToken} from '@models/oauth-token.model';
import {classToPlain, plainToClass} from '@utils/json-converter/json-converter';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as moment from 'moment';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

const SECURE_STORAGE_OAUTH_TOKENS = 'oauth_tokens';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public oauthToken: OAuthToken;

  constructor(private log: LogService,
              private mobileContextService: MobileContextService,
              private capacitorPlugins: CapacitorPlugins
  ) {
    this.mobileContextService.userProfileObservable
      .subscribe(userProfile => {
        if (!userProfile && this.oauthToken) {
          // Logout : clearing auth data
          this.log.info('Clearing local authentication data.');
          this.clearAuth();
        }
      });
  }

  init(): Promise<void | OAuthToken> {
    return this.capacitorPlugins.getPreferencesPlugin().get({key: SECURE_STORAGE_OAUTH_TOKENS})
      .then(
        result => {
          if (result && result.value) {
            return JSON.parse(result.value.toString());
          } else {
            return Promise.reject('Unable to get oauth token from native storage');
          }
        }
      )
      .then(
        (oauthData: Object) => {
          this.oauthToken = plainToClass(OAuthToken, oauthData);
          return this.oauthToken;
        },
        error => {
          this.log.error(error);
        }
      ).catch(error => {
        this.log.error('Unable to parse oauth token from native storage ! ', error);
      });
  }

  isOfflineConnectionAllowed() {
    this.log.info('Checking if token is still valid for offline mode.', this.oauthToken);
    return this.oauthToken && moment(this.oauthToken.expiration_date).isAfter(moment());
  }

  clearAuth(): Promise<string | void> {
    this.oauthToken = null;

    return this.capacitorPlugins.getPreferencesPlugin().remove({key: SECURE_STORAGE_OAUTH_TOKENS});
  }

  recordAuth(oauthToken: OAuthToken): OAuthToken {
    this.log.trace('Recording auth token.', oauthToken, this.oauthToken);
    oauthToken.setExpiration_date();
    this.oauthToken = oauthToken;
    this.capacitorPlugins.getPreferencesPlugin().set({key: SECURE_STORAGE_OAUTH_TOKENS, value: JSON.stringify(classToPlain(oauthToken))});
    return this.oauthToken;
  }

}
