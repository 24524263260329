import {LockerDataConfiguration} from '@models/business/locker-data-configuration.model';
import {LockerDataDetails} from '@models/business/locker-data-details.model';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('LockerData')
export class LockerData {

  @JsonProperty('data', LockerDataDetails, true)
  details: LockerDataDetails = undefined;

  @JsonProperty('configuration', LockerDataConfiguration, true)
  configuration: LockerDataConfiguration = undefined;

}
